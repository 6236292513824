<template>
    <div
        class="w-full fixed bg-primary z-10"
        :class="{ 'lucky-club': id === 7151 }"
    >
        <div
            class="flex justify-between text-primary px-3 container-lg"
            :class="{ 'pr-5': isBao }"
        >
            <router-link
                v-if="acceptsPat && tableId && patNotDisabled"
                :to="{ name: 'LandingPage', params: { tableId: tableId } }"
                class="h-12 flex items-center justify-center flex-shrink-0"
            >
                <back-icon v-if="!isBao" />
                <span
                    class="text-base font-heading pl-2 font-bold underline underline-offset-4"
                    :class="{ 'text-sm': isBao }"
                    >View bill</span
                >
            </router-link>
            <span
                v-else-if="table && $store.state.cart.selectedTableId"
                class="h-12 flex items-center justify-center text-base font-heading pl-2 font-bold underline flex-shrink-0"
            >
                <span></span>
                {{ table.name }}
            </span>
            <div v-else class="h-12 w-12"></div>
            <div class="grow flex justify-end items-center">
                <BaoHeaderToggle v-if="isBao" />
                <img
                    v-else-if="logo"
                    :src="logo + '-/format/auto/-/resize/220x/'"
                    class="h-8"
                    alt="Logo"
                />
                <h1
                    v-else
                    class="break-anywhere font-heading text-heading text-xl font-black leading-tight"
                >
                    {{ name }}
                </h1>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import BackIcon from '@/components/icons/BackIcon';
import BaoHeaderToggle from '@/components/BaoHeaderToggle.vue';
export default {
    name: 'VenueHeaderNew',
    components: { BackIcon, BaoHeaderToggle },
    computed: {
        ...mapState({
            name: state => state.venue.venue.name,
            id: state => state.venue.venue.id,
            logo: state => state.venue.venue.logoNav,
            acceptsPat: state => state.venue.venue.acceptsPat,
            tableId: state => state.cart.selectedTableId
        }),
        ...mapGetters('venue', ['findTableById', 'selectedArea']),
        table() {
            return this.findTableById(this.$store.state.cart.selectedTableId);
        },
        patNotDisabled() {
            if (
                this.selectedArea &&
                (this.selectedArea.id ===
                    '681410c1-ea04-4e7b-b78e-4af113594b5d' ||
                    this.selectedArea.id ===
                        '7441a335-576d-4595-a009-e7747e72de05')
            ) {
                return false;
            }
            return true;
        },
        isBao() {
            return (
                this.id === 8376 ||
                this.id === 9708 ||
                this.id === 9710 ||
                this.id === 9711 ||
                this.id === 9714 ||
                this.id === 9713 ||
                this.id === 9712
            );
        }
    }
};
</script>

<style>
.lucky-club {
    background-color: rgb(252, 242, 236) !important;
}
</style>
