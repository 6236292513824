export default class PaymentMethodsEnum {
    static get CASH() {
        return 'Cash';
    }

    static get ADYEN() {
        return 'Adyen';
    }

    static get options() {
        return {
            CASH: this.CASH,
            ADYEN: this.ADYEN
        };
    }
}
