import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: import.meta.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash === '#product' || from.hash === '#product') {
            return;
        }

        if (savedPosition) {
            return savedPosition;
        }

        return { x: 0, y: 0 };
    }
});

router.beforeEach(async (to, from, next) => {
    // if (!store.state.location.geolocation) {
    //     geolocation.getCurrentPosition(function (err, position) {
    //         if (err) {
    //             return false;
    //         } else {
    //             store.dispatch('location/setGeolocation', position);
    //         }
    //     })
    // }

    next();
});

export default router;
