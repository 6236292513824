<template>
    <div class="w-full text-subheading text-sm lg:w-1/2">
        <div
            v-for="sortedVenueHoursObject in sortedVenueHoursPerDay"
            :key="sortedVenueHoursObject.day"
            class="grid grid-cols-2 justify-between"
        >
            <div
                :class="
                    isToday(sortedVenueHoursObject.day)
                        ? 'font-medium'
                        : 'font-normal'
                "
            >
                {{ daysOfWeek[sortedVenueHoursObject.day] }}
            </div>

            <div class="text-right">
                <div
                    v-for="(hours, index) in sortedVenueHoursObject.hours"
                    :key="`${sortedVenueHoursObject.day}-${index}`"
                >
                    <span
                        v-if="hours[0] && hours[1]"
                        :class="
                            isToday(sortedVenueHoursObject.day) &&
                            isCurrentTimeInterval(hours[0], hours[1])
                                ? 'font-medium'
                                : 'font-normal'
                        "
                    >
                        {{ [hours[0], 'HH:mm:ss'] | dayjs('h:mm a') }} -
                        {{ [hours[1], 'HH:mm:ss'] | dayjs('h:mm a') }}
                    </span>
                </div>

                <span v-if="!sortedVenueHoursObject.hours.length">
                    {{ $t('venueOpeningHours.closed') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'VenueOpeningHours',

    computed: {
        ...mapState('venue', {
            venueHours: state => state.venue.venueHours
        })
    },
    data() {
        return {
            daysOfWeek: {
                0: this.$t('daysOfWeek.monday'),
                1: this.$t('daysOfWeek.tuesday'),
                2: this.$t('daysOfWeek.wednesday'),
                3: this.$t('daysOfWeek.thursday'),
                4: this.$t('daysOfWeek.friday'),
                5: this.$t('daysOfWeek.saturday'),
                6: this.$t('daysOfWeek.sunday')
            },
            sortedVenueHoursPerDay: [
                { day: 0, hours: [] },
                { day: 1, hours: [] },
                { day: 2, hours: [] },
                { day: 3, hours: [] },
                { day: 4, hours: [] },
                { day: 5, hours: [] },
                { day: 6, hours: [] }
            ]
        };
    },
    mounted() {
        this.sortVenueHoursPerDays();
    },
    methods: {
        sortVenueHoursPerDays() {
            this.venueHours.forEach(venueHoursObject => {
                const foundSortedVenueHoursItem = this.sortedVenueHoursPerDay.find(
                    sortedVenueHoursObject =>
                        sortedVenueHoursObject.day === venueHoursObject.day
                );

                const foundSortedVenueHoursIndex = this.sortedVenueHoursPerDay.findIndex(
                    sortedVenueHoursObject =>
                        sortedVenueHoursObject.day === venueHoursObject.day
                );

                foundSortedVenueHoursItem.hours.push([
                    venueHoursObject.openTime,
                    venueHoursObject.closeTime
                ]);

                this.sortedVenueHoursPerDay.splice(
                    foundSortedVenueHoursIndex,
                    1,
                    foundSortedVenueHoursItem
                );
            });
        },
        isToday(day) {
            return this.$dayjs().day() - 1 === day;
        },
        isCurrentTimeInterval(openTime, closeTime) {
            const currentTime = this.$dayjs();
            const fromTime = this.$dayjs(openTime, 'HH:mm:ss');
            const toTime = this.$dayjs(closeTime, 'HH:mm:ss');

            return this.$dayjs(currentTime).isAfter(fromTime) && this.$dayjs(currentTime).isBefore(toTime)
        }
    }
};
</script>
