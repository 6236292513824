<template>
    <modal
        name="custom-message-modal"
        height="auto"
        width="575"
        :maxWidth="575"
        :adaptive="true"
        :scrollable="true"
        :clickToClose="true"
        :reset="true"
        transition="pop-out"
        :class="{ 'mobile-item-modal': pivotY === 1 }"
        :pivotY="pivotY"
        @before-open="beforeOpen"
        @before-close="beforeClose"
    >
        <div class="item-modal-content relative flex flex-col w-full">
            <div class="item-modal-details bg-secondary flex-auto p-8 overflow-y-auto">
              <h4 class="pb-1 text-primary text-xl font-bold">
                {{ displayText }}
              </h4>
            </div>
        </div>
    </modal>
</template>

<script>
import isMobile from "@/helpers/isMobileDevice";

export default {
    name: 'custom-message-modal',

    data() {
        return {
            text: '',
            customText: false
        };
    },
    methods: {
        beforeOpen({ params }) {
            this.text = params.text;
            this.customText = params.customText || false;
        },

        beforeClose() {
            this.text = '';
            this.customText = false
        },

        hideModal() {
            this.$modal.hide('custom-message-modal');
        }
    },
    computed: {
      pivotY() {
        return isMobile()? 1 : 0.5;
      },
      displayText() {
        return this.customText ? this.text : this.$t(`customMessageModal.${this.text}`);
      }
    }
};
</script>
