import { DiscountTypesEnum } from '@/enums';

const discountCode = value => {
    if (value) {
        if (value.type === DiscountTypesEnum.PERCENT) {
            return `-${value.codeAmount / 10}%`;
        }

        return `-${value.codeAmount / 100}`;
    }
};

export default discountCode;
