<template>
    <modal
        name="customer-auth-modal"
        height="auto"
        width="575"
        :maxWidth="575"
        :adaptive="true"
        :scrollable="true"
        :clickToClose="true"
        :reset="true"
        transition="pop-out"
        :class="{ 'mobile-item-modal': pivotY === 1 }"
        :pivotY="pivotY"
    >
        <div class="item-modal-content relative flex w-full flex-col">
            <div class="bg-secondary flex-auto overflow-y-auto">
                <div
                    class="flex items-center justify-between border-b pl-4 py-2 select-none"
                >
                    <h2 class="text-base font-heading text-heading font-bold">
                        {{
                            step === 0
                                ? getLoginHelperText()
                                : 'Confirm your login code'
                        }}
                    </h2>
                    <button
                        @click="closeModal"
                        class="text-primary bg-secondary rounded-full p-1.5"
                    >
                        <close-icon class="h-5 w-5" />
                    </button>
                </div>
                <div class="px-4 py-8">
                    <div v-if="step === 0" class="mb-6">
                        <div class="relative mb-4">
                            <input
                                :type="isPhone ? 'tel' : 'email'"
                                :name="isPhone ? 'phoneNumber' : 'email'"
                                :autocomplete="isPhone ? 'tel' : 'email'"
                                v-model.trim.lazy="inputValue"
                                :id="isPhone ? 'phoneNumber' : 'email'"
                                style="height: 50px"
                                :class="{
                                    'form-error': isPhone
                                        ? $v.phoneNumber.$error
                                        : $v.email.$error,
                                    'shadow-sm': isPhone
                                        ? !$v.phoneNumber.$error
                                        : !$v.email.$error
                                }"
                                class="email-input block pt-3 px-4 w-full text-sm bg-secondary border rounded"
                                required
                            />
                            <label
                                :for="isPhone ? 'phoneNumber' : 'email'"
                                class="email-input-label absolute pt-1 px-4 rounded pointer-events-none select-none"
                            >
                                {{
                                    isPhone
                                        ? 'Phone Number'
                                        : $t('customerDetails.emailAddress')
                                }}
                            </label>
                            <span v-if="error" class="mb-2 text-red-500">
                                {{ error }}
                            </span>
                        </div>
                        <div class="flex items-right">
                            <button
                                @click="sendVerificationCode"
                                :class="{ 'text-button spinner': isLoading }"
                                class="border-button-primary no-highlight flex-auto px-12 py-4 w-full text-center text-button text-sm font-bold bg-button font-button rounded-sm shadow select-none sm:mb-3 md:mb-0 md:w-auto"
                            >
                                <span v-show="!isLoading">Login</span>
                                <span class="inline-block"></span>
                            </button>
                        </div>
                    </div>
                    <div v-if="step === 1">
                        <div class="relative mb-4">
                            <input
                                type="text"
                                name="code"
                                autocomplete="one-time-code"
                                autofocus
                                v-model.trim="code"
                                id="code"
                                style="height: 50px"
                                :class="{
                                    'form-error': $v.code.$error,
                                    'shadow-sm': !$v.code.$error
                                }"
                                class="email-input block pt-3 px-4 w-full text-sm bg-secondary border rounded"
                                required
                            />
                            <label
                                for="code"
                                class="email-input-label absolute pt-1 px-4 rounded pointer-events-none select-none"
                            >
                                Login code
                            </label>
                            <span v-if="error" class="mb-2 text-red-500">
                                {{ error }}
                            </span>
                        </div>
                        <div class="flex items-right">
                            <button
                                @click="confirmLoginCode"
                                :class="{ 'text-button spinner': isLoading }"
                                class="border-button-primary no-highlight flex-auto px-12 py-4 w-full text-center text-button text-sm font-bold bg-button font-button rounded-sm shadow select-none sm:mb-3 md:mb-0 md:w-auto"
                            >
                                <span v-show="!isLoading">Confirm</span>
                                <span class="inline-block"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import isMobile from '@/helpers/isMobileDevice';
import { required, email } from 'vuelidate/lib/validators';
import CloseIcon from '@/components/icons/CloseIcon';
import { mapMutations, mapGetters, mapActions } from 'vuex';

export default {
    name: 'LoyaltyContactVerificationModal',
    components: {
        CloseIcon
    },
    validations() {
        const validators = {
            code: {
                required
            }
        };

        if (this.isPhone) {
            validators.phoneNumber = {
                required
            };
        } else {
            validators.email = {
                required,
                email
            };
        }

        return validators;
    },
    data() {
        return {
            step: 0,
            error: null,
            code: null,
            isLoading: false,
            phoneNumber: ''
        };
    },
    computed: {
        ...mapGetters({
            venueValue: 'venue/venueValue'
        }),
        email: {
            get() {
                return this.$store.state.customer.email;
            },
            set(value) {
                const val = value.toLowerCase();
                this.$store.commit('customer/setEmail', val);
            }
        },
        phone: {
            get() {
                return this.$store.state.customer.phone;
            },
            set(value) {
                this.$store.commit('customer/setPhone', value);
            }
        },
        isPiggyEnabled() {
            return this.$store.state.venue.venue.piggySettings?.enabled;
        },
        comoEnabled() {
            return this.$store.state.venue.venue.comoEnabled;
        },
        allowedInputTypes() {
            // If Piggy is enabled, only allow email
            if (this.isPiggyEnabled) {
                return 'email';
            }

            // If Como is enabled, check comoUserIdentifier
            if (this.comoEnabled) {
                const userIdentifier =
                    this.$store.state.venue.venue.comoUserIdentifier;

                if (userIdentifier === 'email') {
                    return 'email';
                } else if (userIdentifier === 'phone') {
                    return 'phone';
                } else if (userIdentifier === 'email_and_phone') {
                    return 'email_and_phone';
                }

                // Default to email  if comoUserIdentifier is not specified
                return 'email';
            }

            // Default to email if neither Piggy nor Como is enabled
            return 'email';
        },
        inputValue: {
            get() {
                return this.isPhone ? this.phoneNumber : this.email;
            },
            set(value) {
                // Store the current cursor position
                const input = document.getElementById(
                    this.isPhone ? 'phoneNumber' : 'email'
                );
                const cursorPosition = input ? input.selectionStart : 0;

                // Auto-detect if input is email or phone, but respect venue settings
                const isEmailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
                const isPhonePattern = /^[0-9+\-\s()]*$/.test(value);

                if (this.allowedInputTypes === 'email') {
                    // Only allow email input
                    this.$store.commit('customer/SET_IDENTIFIER_TYPE', 'email');
                    this.email = value;
                } else if (this.allowedInputTypes === 'phone') {
                    // Only allow phone input
                    this.$store.commit('customer/SET_IDENTIFIER_TYPE', 'phone');
                    this.phoneNumber = value;
                } else {
                    // Auto-detect between email and phone
                    if (isEmailPattern) {
                        this.$store.commit(
                            'customer/SET_IDENTIFIER_TYPE',
                            'email'
                        );
                        this.$store.commit('customer/setEmail', value);
                        this.email = value;
                    } else if (isPhonePattern) {
                        this.$store.commit(
                            'customer/SET_IDENTIFIER_TYPE',
                            'phone'
                        );
                        this.$store.commit('customer/setPhone', value);
                        this.phoneNumber = value;
                    } else {
                        // Default to email for other inputs
                        this.$store.commit(
                            'customer/SET_IDENTIFIER_TYPE',
                            'email'
                        );
                        this.$store.commit('customer/setEmail', value);
                        this.email = value;
                    }
                }

                this.$nextTick(() => {
                    const input = document.getElementById(
                        this.isPhone ? 'phoneNumber' : 'email'
                    );
                    if (input) {
                        input.setSelectionRange(cursorPosition, cursorPosition);
                    }
                });
            }
        },
        identifierType: {
            get() {
                return this.$store.state.customer.identifierType;
            }
        },
        pivotY() {
            return isMobile() ? 1 : 0.5;
        },
        accountId() {
            return this.venueValue('accountId');
        },
        venueId() {
            return this.venueValue('id');
        },
        isPhone() {
            // Respect venue settings for allowed input types
            if (this.allowedInputTypes === 'email') {
                return false;
            }
            if (this.allowedInputTypes === 'phone') {
                return true;
            }
            // For 'email_and_phone', use the stored identifier type
            return this.identifierType === 'phone';
        }
    },
    methods: {
        ...mapMutations({
            setIdentifierType: 'customer/SET_IDENTIFIER_TYPE'
        }),
        ...mapActions({
            setAuth: 'customer/setAuth'
        }),
        getLoginHelperText() {
            const inputType = this.allowedInputTypes;

            if (inputType === 'email') {
                return 'Enter your email to login';
            } else if (inputType === 'phone') {
                return 'Enter your phone number to login';
            } else if (inputType === 'email_and_phone') {
                return 'Enter your email or phone number to login';
            }

            // Default fallback
            return 'Enter your email to login';
        },
        closeModal() {
            this.$modal.hide('customer-auth-modal');
        },
        async sendVerificationCode() {
            if (this.isPhone) {
                this.$v.phoneNumber.$touch();
                if (this.$v.phoneNumber.$invalid) {
                    this.error = 'Please enter a valid phone number';
                    return;
                }
            } else {
                this.$v.email.$touch();
                if (this.$v.email.$invalid) {
                    this.error = 'Please enter a valid email';
                    return;
                }
            }

            try {
                this.isLoading = true;

                const payload = {
                    venueId: this.venueId,
                    accountId: this.accountId,
                    identifierType: this.identifierType
                };

                if (this.isPhone) {
                    payload.phoneNumber = this.phoneNumber;
                } else {
                    payload.email = this.email;
                }

                const { data } = await this.$axios.post(
                    '/loyalty/send-login',
                    payload
                );

                if (data.type === 'success' || data.status === 'ok') {
                    this.step = 1;
                    this.error = null;
                }
            } catch (e) {
                this.error = e.response?.data?.message;
            } finally {
                this.isLoading = false;
            }
        },
        async confirmLoginCode() {
            try {
                this.isLoading = true;

                const payload = {
                    code: this.code,
                    accountId: this.accountId,
                    venueId: this.venueId
                };

                if (this.isPhone) {
                    payload.phoneNumber = this.phoneNumber;
                } else {
                    payload.email = this.email;
                }

                const { data } = await this.$axios.post(
                    '/loyalty/confirm-login',
                    payload
                );

                await this.setAuth({ accountId: this.accountId, value: true });

                this.fillCustomerDetails(data);

                this.closeModal();
            } catch (e) {
                this.error = 'Invalid code';
            } finally {
                this.isLoading = false;
            }
        },
        fillCustomerDetails(data) {
            if (!data.membership) {
                return;
            }

            const { firstName, lastName, phoneNumber, email } = data.membership;

            if (firstName) {
                this.$store.commit('customer/setFirstName', firstName);
            }

            if (lastName) {
                this.$store.commit('customer/setLastName', lastName);
            }

            if (this.isPhone) {
                this.$store.commit('customer/setPhone', phoneNumber);
            } else {
                this.$store.commit('customer/setEmail', email);
            }
        }
    }
};
</script>
