<template>
    <modal
        name="allergen-modal"
        height="auto"
        width="575"
        :maxWidth="575"
        :adaptive="true"
        :scrollable="true"
        :clickToClose="false"
        :reset="true"
        transition="pop-out"
        :class="{ 'mobile-item-modal': pivotY === 1 }"
        :pivotY="pivotY"
    >
        <div class="item-modal-content relative flex flex-col w-full">
            <div
                class="item-modal-details bg-secondary flex-auto p-8 overflow-y-auto"
            >
                <h4 class="pb-4 text-primary text-xl font-bold">
                    {{ $t('allergenModal.title') }}
                </h4>
                <p class="pb-4">
                    {{ $t('allergenModal.description') }}
                </p>
                <div class="justify-end pt-4">
                    <button
                        class="no-highlight flex-auto mb-3 p-3 px-12 w-full text-center text-button text-sm font-bold bg-button border border-button-primary rounded-sm focus:opacity-75 active:opacity-75 select-none md:mb-0 md:ml-6 md:w-auto"
                        @click="onNoClick"
                    >
                        {{ $t('allergenModal.no') }}
                    </button>
                    <button
                        class="no-highlight flex-auto mb-3 p-3 px-12 w-full text-center text-button text-sm font-bold bg-transparent border border-gray-800 rounded-sm focus:opacity-75 active:opacity-75 select-none md:mb-0 md:ml-6 md:w-auto"
                        @click="onYesClick"
                    >
                        {{ $t('allergenModal.yes') }}
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import isMobile from '@/helpers/isMobileDevice';
import { mapActions } from 'vuex';

export default {
    name: 'allergen-modal',

    computed: {
        pivotY() {
            return isMobile() ? 1 : 0.5;
        }
    },

    methods: {
        ...mapActions({
            setAllergenDisabled: 'customer/setAllergenDisabled'
        }),

        onYesClick() {
            this.setAllergenDisabled({
                venueId: this.$store.state.venue.venue.id,
                value: true
            });
            this.$modal.hide('allergen-modal');
        },

        onNoClick() {
            this.setAllergenDisabled({
                venueId: this.$store.state.venue.venue.id,
                value: false
            });
            this.$modal.hide('allergen-modal');
        }
    }
};
</script>
