<template>
    <modal
        name="offer-unavailable-modal"
        height="auto"
        width="575"
        :maxWidth="575"
        :adaptive="true"
        :scrollable="true"
        :clickToClose="true"
        transition="pop-out"
        :pivotY="pivotY"
        :reset="true"
    >
        <div
            class="item-modal-content relative flex w-full flex-col bg-primary"
        >
            <div class="item-modal-details flex-auto overflow-y-auto p-8">
                <h4
                    class="mb-3 pb-1 font-heading text-xl font-bold text-heading"
                >
                    {{ $tc('offerUnavailableModal.notAvailable') }}
                </h4>

                <div class="mt-5 text-right">
                    <button
                        @click="hideModal()"
                        type="button"
                        class="border-button-primary no-highlight w-full flex-auto select-none rounded-sm bg-button font-button px-12 py-4 text-center text-sm font-bold text-button shadow sm:mb-3 md:mb-0 md:ml-6 md:w-auto"
                    >
                        {{ $t('offerUnavailableModal.close') }}
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import isMobile from "@/helpers/isMobileDevice";

export default {
    name: 'offer-unavailable-modal',
    methods: {
        hideModal() {
            this.$modal.hide('offer-unavailable-modal');
        }
    },
    computed: {
        pivotY() {
            return isMobile()? 1 : 0.5;
        }
    }
};
</script>
