const state = {
    id: null,
    tip: null,
    posConnectionError: false,
    bill: null
};

const getters = {
    showMobileBasket(state) {
        return state.showMobileBasket;
    },
    posConnectionError(state) {
        return state.posConnectionError;
    },
    tip: state => {
        return state.tip || 0;
    },
    id: state => {
        return state.id;
    },
    bill: state => {
        return state.bill;
    },
    billItems: state => {
        return state?.bill?.items || [];
    }
};

const mutations = {
    setBillId(state, billId) {
        state.id = billId;
    },
    setTip(state, val) {
        state.tip = val;
    },
    setBill(state, val) {
        state.bill = val;
    }
};

const actions = {
    setTip({ commit }, tip) {
        commit('setTip', tip);
    },
    async updateAndFetchBill({ commit }, { venueId, tableId }) {
        const { data } = await this.$axios.get(`/bills`, {
            params: {
                venueId,
                tableId
            }
        });

        if (data.id) {
            commit('setBillId', data.id);
        }

        commit('setBill', data);

        return data;
    },
    async fetchBill({ commit }, { venueId, billId }) {
        const { data } = await this.$axiosRetry.get(`/bills/fetch-only`, {
            params: {
                venueId,
                billId
            }
        });

        commit('setBill', data);

        return data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
