<template>
    <div>
        <span
            v-if="hasTag(TAGS.VEGETARIAN)"
            :tooltip="$t('menuItem.vegetarian')"
            class="dietary-type text-[#00a82d] text-sm tooltip bottom"
            @click.stop
        >
            V
        </span>
        <span
            v-if="hasTag(TAGS.VEGAN)"
            :tooltip="$t('menuItem.vegan')"
            class="dietary-type text-[#00a82d] text-sm tooltip bottom"
            @click.stop
        >
            Vg
        </span>
        <span
            v-if="hasTag(TAGS.GLUTEN_FREE)"
            :tooltip="$t('menuItem.glutenFree')"
            class="dietary-type text-[#e60000] text-sm tooltip bottom"
            @click.stop
        >
            GF
        </span>
        <span
            v-if="hasTag(TAGS.HALAL)"
            :tooltip="$t('menuItem.halal')"
            class="dietary-type text-[#2200e6] text-sm tooltip bottom"
            @click.stop
        >
            H
        </span>
    </div>
</template>

<script>
export default {
    name: 'MenuItemTags',
    props: {
        tags: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            TAGS: {
                VEGETARIAN: 5,
                VEGAN: 4,
                HALAL: 2,
                GLUTEN_FREE: 1101,
                SUGAR_FREE: 1128,
                LAC_FREE: 1129
            }
        };
    },
    methods: {
        hasTag(tagId) {
            return this.tags?.includes(tagId);
        }
    }
};
</script>
