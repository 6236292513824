// Globally register all base components for convenience, because they
// will be used very frequently. Components are registered using the
// PascalCased version of their file name.

import Vue from 'vue';

function upperFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function toPascalCase(string) {
    return string
        .split('-')
        .map(word => upperFirst(word))
        .join('');
}

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = import.meta.glob('./base/**/*.vue');

// For each matching file name...
Object.keys(requireComponent).forEach(fileName => {
    // Get the component config
    const componentConfig = requireComponent[fileName];
    // Get the PascalCase version of the component name
    // Get PascalCase name of component
    const componentName = toPascalCase(
        // Gets the file name regardless of folder depth
        fileName
            .split('/')
            .pop()
            .replace(/\.\w+$/, '')
    );

    // Globally register the component
    Vue.component(componentName, componentConfig.default || componentConfig);
});
