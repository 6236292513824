// @/plugins/axios.js
import Axios from 'axios';
import axiosRetryLib from 'axios-retry';

// References that will be set during plugin installation
let notifyOffline = null;
let sentryHandler = null;

const createAxiosInstance = () => {
    return Axios.create({
        baseURL: import.meta.env.VITE_API_URL,
        headers: {
            common: {
                Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        },
        timeout: 30000 // Add a reasonable timeout
    });
};

const axiosRetry = createAxiosInstance();
const axios = createAxiosInstance();
// Add retry functionality
axiosRetryLib(axiosRetry, {
    retries: 8,
    shouldResetTimeout: true,
    retryDelay: retryCount => retryCount * 500,
    retryCondition: error =>
        axiosRetryLib.isNetworkError(error) ||
        axiosRetryLib.isRetryableError(error)
});

axiosRetry.interceptors.response.use(
    response => response,
    error => {
        // Handle network errors
        if (error.isAxiosError) {
            if (error.code === 'ECONNABORTED' || error.code === 'ERR_NETWORK') {
                // Show notification if the function is available
                if (notifyOffline) {
                    notifyOffline();
                }
            }
        }

        // Handle Sentry reporting
        if (sentryHandler) {
            if (!error.response) {
                // Network error handling with Sentry
                sentryHandler.handleNetworkError(error);
            } else {
                // Response error handling with Sentry
                sentryHandler.handleResponseError(error);
            }
        }

        return Promise.reject(error);
    }
);

export { axiosRetry, axios };

export default {
    install(Vue, options = {}) {
        const Sentry = options.Sentry;

        // Set up notification function
        notifyOffline = () => {
            Vue.prototype.$notify({
                title: `It seems that you're offline`,
                group: 'offline',
                text: 'Please check your internet connection and try again.',
                duration: 8000
            });
        };

        // Set up Sentry handlers if Sentry is available
        if (Sentry) {
            sentryHandler = {
                handleNetworkError: error => {
                    Sentry.withScope(scope => {
                        scope.setTag('error_type', 'network_error');
                        scope.setContext('request', {
                            url: error.config?.url,
                            method: error.config?.method,
                            timestamp: new Date().toISOString(),
                            userAgent: navigator.userAgent,
                            onLine: navigator.onLine,
                            connection: navigator.connection
                                ? {
                                      effectiveType:
                                          navigator.connection.effectiveType,
                                      downlink: navigator.connection.downlink,
                                      rtt: navigator.connection.rtt
                                  }
                                : null
                        });
                        Sentry.captureException(
                            new Error('Network request failed'),
                            {
                                level: 'warning' // Lower severity since often user-side
                            }
                        );
                    });
                },

                handleResponseError: error => {
                    const { response } = error;
                    const { data } = response;

                    if (data) {
                        Sentry.setContext('response', data);
                    }
                    Sentry.captureException(error);
                }
            };
        }

        // Attach to Vue
        Vue.prototype.$axiosRetry = axiosRetry;
        Vue.prototype.$axios = axios;

        if (options.store) {
            options.store.constructor.prototype.$axiosRetry = axiosRetry;
            options.store.constructor.prototype.$axios = axios;
        }
    }
};
