<template>
    <div
        class="relative flex px-1 pt-3 sm:pt-5 md:px-3"
        v-if="hasDiscountCodes && !isRewardActive"
    >
        <div class="w-full">
            <div>
                <div
                    @click="toggleShowDiscount"
                    style="height: 50px"
                    class="email-input flex items-center justify-between p-2 pl-0 rounded cursor-pointer"
                >
                    <span class="text-itembody text-sm font-black select-none">
                        {{ $t('discountCodeBox.addDiscountCode') }}
                    </span>
                    <span class="text-itembody text-sm font-medium">
                        <dropdown-icon
                            class="transition duration-300"
                            :class="{ 'rotate-180': showDiscount }"
                        />
                    </span>
                </div>
            </div>
            <div v-if="showDiscount" class="relative flex mt-1">
                <div class="relative w-2/3 md:pr-2">
                    <input
                        type="text"
                        name="discount-code"
                        v-model="discountCode"
                        id="discount-code"
                        autocomplete="discount-code"
                        style="height: 50px"
                        :class="{
                            'ring ring-red-200 border-red-300':
                                discountCodeError || $v.discountCode.$error
                        }"
                        class="email-input block pt-3 px-4 w-full text-sm bg-secondary border rounded shadow-sm disabled:opacity-50"
                        required
                        :disabled="disableAll || discountCodeDetails"
                        @blur="clearDiscountCodeError"
                        @keyup.enter.prevent="checkDiscountCode"
                    />
                    <label
                        for="discount-code"
                        class="email-input-label absolute pt-1 px-4 rounded pointer-events-none select-none"
                    >
                        {{ $t('discountCodeBox.discountCode') }}
                    </label>
                </div>

                <div class="relative pl-2 w-1/3">
                    <base-button
                        v-if="!isDiscountApplied"
                        class="p-1 py-3 h-full"
                        :button-text="$t('discountCodeBox.submit')"
                        :class="{
                            spinner: discountIsLoading
                        }"
                        :disabled="disableAll"
                        :is-border-transparent="true"
                        rounded="rounded-md"
                        @clicked="checkDiscountCode"
                    />

                    <base-button
                        v-else
                        class="p-1 py-3 h-full"
                        :button-text="$t('discountCodeBox.remove')"
                        :class="{
                            spinner: discountIsLoading
                        }"
                        :disabled="disableAll"
                        :is-border-transparent="true"
                        rounded="rounded-md"
                        @clicked="handleRemoveDiscount"
                    />
                </div>
            </div>
            <span
                v-show="discountCodeErrorMessage"
                class="py-2 text-red-600 text-xs"
            >
                {{ discountCodeErrorMessage }}
            </span>
            <span
                v-if="$v.discountCode.$error"
                class="py-2 text-red-600 text-xs"
                >Discount code cannot be empty</span
            >
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import DropdownIcon from '../icons/DropdownIcon';
import { required } from 'vuelidate/lib/validators';
import { TrackingEventsEnum } from '@/enums';

export default {
    name: 'DiscountCodeBox',
    components: { DropdownIcon },
    props: {
        pickupDate: {
            required: true,
            default: null,
            type: String
        },
        pickupTimeSlot: {
            required: true,
            default: null,
            type: String
        },
        disableAll: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    validations: {
        discountCode: {
            required
        }
    },
    data() {
        return {
            discountIsLoading: false,
            discountCode: null,
            discountCodeError: null,
            discountCodeErrorMessage: null,
            showDiscount: false,
            isDiscountApplied: false
        };
    },

    computed: {
        ...mapGetters({
            discountCodeDetails: 'discount/details',
            isRewardActive: 'cart/isPiggyRewardActive'
        }),
        ...mapState({
            venueId: state => state.venue.venue.id,
            fulfillmentMethod: state => state.cart.fulfillmentMethod,
            acceptsPreOrders: state => state.venue.venue.acceptsPreOrders,
            venueDiscountCodes: state => state.venue.venue.discountCodes,
            venueAutoAppliedDiscountCodes: state =>
                state.venue.venue.autoAppliedDiscountCode,
            customerEmail: state => state.customer.email
        }),
        hasDiscountCodes() {
            return (
                this.venueDiscountCodes?.length ||
                this.venueAutoAppliedDiscountCodes?.length
            );
        }
    },

    created() {
        if (
            this.discountCodeDetails &&
            this.discountCodeDetails.code &&
            !this.isRewardActive
        ) {
            this.discountCode = this.discountCodeDetails.code;
        }
    },

    mounted() {
        if (
            !this.discountCodeDetails &&
            this.venueAutoAppliedDiscountCodes &&
            this.venueAutoAppliedDiscountCodes.length &&
            this.venueAutoAppliedDiscountCodes[0].fulfillmentMethods.includes(
                this.fulfillmentMethod
            ) &&
            !this.isRewardActive
        ) {
            this.setDiscountCode({
                code: this.venueAutoAppliedDiscountCodes[0]
            });

            this.isDiscountApplied = true;
            this.discountCode = this.venueAutoAppliedDiscountCodes[0].code;
        }
    },
    watch: {
        pickupTimeSlot(newVal) {
            if (this.discountCode && newVal && this.discountCodeDetails) {
                this.checkDiscountCode();
            }
        }
    },

    methods: {
        ...mapActions({
            discountCheck: 'discount/checkDiscountCode',
            setDiscountCode: 'discount/setDiscountCode',
            cartTotalWithDiscount: 'cart/cartTotalWithDiscount',
            removeDiscount: 'cart/removeDiscount'
        }),
        toggleShowDiscount() {
            this.showDiscount = !this.showDiscount;
        },

        async checkDiscountCode() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }

            if (this.$analytics) {
                this.$analytics.track(TrackingEventsEnum.DISCOUNT_ENTERED, {
                    code: this.discountCode
                });
            }

            this.discountIsLoading = true;

            this.discountCodeError = false;
            this.discountCodeErrorMessage = null;

            const codeError = await this.discountCheck({
                venueId: this.venueId,
                code: this.discountCode,
                date: this.isPreOrder ? this.pickupDate : '',
                timeSlot: this.isPreOrder ? this.pickupTimeSlot : '',
                fulfillmentMethod: this.fulfillmentMethod,
                email: this.customerEmail || null
            });

            if (codeError) {
                this.showDiscount = true;
                this.discountCodeErrorMessage = codeError.message;

                if (codeError.statusCode === 404) {
                    this.discountCodeErrorMessage = this.$t(
                        'discountCodeBox.errors.codeNotValid'
                    );
                }

                if (this.$analytics) {
                    this.$analytics.track(TrackingEventsEnum.DISCOUNT_DENIED, {
                        discount_code: this.discountCode,
                        reason: codeError.message || 'unknown',
                        status_code: codeError.statusCode
                    });
                }
            }

            this.cartTotalWithDiscount();

            if (
                !this.discountCodeDetails ||
                (this.discountCodeDetails &&
                    this.discountCodeDetails.minimumAmount > this.cartSubtotal)
            ) {
                this.discountCodeError = true;
            }

            if (!this.discountCodeError) {
                this.isDiscountApplied = true;

                this.$notify({
                    group: 'PiggyRewards',
                    title: `Discount applied`
                });

                if (this.$analytics && this.discountCodeDetails) {
                    this.$analytics.track(TrackingEventsEnum.DISCOUNT_APPLIED, {
                        discount_id: this.discountCodeDetails.id,
                        discount_name: this.discountCodeDetails.name,
                        discount_amount: this.discountCodeDetails.codeAmount,
                        discount_type: this.discountCodeDetails.type
                    });
                }
            }

            this.discountIsLoading = false;
        },
        isPreOrder() {
            return (
                this.acceptsPreOrders &&
                this.$store.state.venue.venue.pickupSlots &&
                this.$store.state.venue.venue.pickupSlots.length > 0
            );
        },
        handleRemoveDiscount() {
            this.removeDiscount();

            this.discountCode = null;
            this.isDiscountApplied = false;
        },
        clearDiscountCodeError() {
            this.discountCodeError = null;
            this.discountCodeErrorMessage = null;
        }
    }
};
</script>

<style scoped>
.spinner {
    color: white !important;
    opacity: 0.9;
}

.spinner:after {
    left: auto;
    right: 15px;
}
</style>
