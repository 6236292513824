const state = {
    geolocation: null
};

const mutations = {
    GEOLOCATION(state, payload) {
        state.geolocation = payload;
    }
};

const getters = {
    geolocation(state) {
        return state.geolocation;
    }
};

const actions = {
    async setGeolocation({ commit }, data) {
        commit('GEOLOCATION', data);
    }
};

export default {
    namespaced: true,
    getters,
    mutations,
    state,
    actions
};
