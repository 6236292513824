<template>
    <modal
        name="loyalty-logout-modal"
        height="auto"
        width="575"
        :maxWidth="575"
        :adaptive="true"
        :scrollable="true"
        :clickToClose="true"
        :pivotY="pivotY"
        :reset="true"
        :class="{ 'mobile-item-modal': pivotY === 1 }"
    >
        <div class="item-modal-content relative flex flex-col w-full">
            <div class="item-modal-body">
                <div class="item-modal-details flex-auto p-6 pb-1">
                    <div class="relative block w-full pb-3">
                        <h4
                            class="font-heading text-lg pr-2 font-bold text-subheading"
                        >
                            Are you sure you want to log out of your loyalty
                            account?
                        </h4>
                        <div class="absolute right-0 top-0">
                            <button
                                type="button"
                                aria-label="Hide loyalty logout"
                                class="text-primary transition duration-150 ease-in-out hover:text-gray-500 focus:text-gray-500 focus:outline-none"
                                @click="close"
                            >
                                <close-icon />
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    class="item-modal-footer flex flex-col-reverse justify-center p-6 md:flex-row"
                >
                    <base-button
                        class="p-3 px-10 sm:block md:w-auto"
                        size="sm"
                        variant="secondaryActions"
                        :is-border-transparent="true"
                        button-text="Cancel"
                        @clicked="close"
                    />

                    <base-button
                        class="px-12 py-4 sm:mb-3 md:mb-0 md:ml-6 md:w-auto"
                        size="sm"
                        button-text="Yes"
                        @clicked="confirm"
                    />
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import isMobile from '@/helpers/isMobileDevice';
import CloseIcon from '@/components/icons/CloseIcon';

export default {
    name: 'loyalty-logout-modal',
    components: { CloseIcon },
    computed: {
        pivotY() {
            return isMobile() ? 1 : 0.5;
        }
    },
    methods: {
        close() {
            this.$modal.hide('loyalty-logout-modal');
        },
        confirm() {
            this.$emit('confirm-logout');
            this.close();
        }
    }
};
</script>
