<template>
    <div v-if="payload" class="relative block m-auto" >
        <input
            class="block w-full px-3 py-2 cursor-pointer border border-gray-200 shadow-sm rounded-md focus:outline-none font-medium"
            :class="{ 'rounded-b-none': optionsShown }"
            name="table-selection-dropdown"
            @focus="showOptions()"
            @blur="exit()"
            v-model="searchFilter"
            :readonly="!searchable"
            :placeholder="placeholder"
        />

        <ul class="w-full absolute border-x border-b border-gray-200 z-50 overflow-auto shadow-sm text-left max-h-56 rounded-b-md bg-white"
             v-show="optionsShown">
            <li
                class="dropdown-item block cursor-pointer px-3 py-2 font-semibold text-sm hover:text-white"
                :class="{'bg-gray-100 text-gray-400': !option.id}"
                @mousedown="option.id ? selectOption(option) : $event.preventDefault()"
                v-for="(option, index) in filteredOptions"
                :key="index">
                {{ option.name }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'TableSelectionDropdown',
    props: {
        value: {
            type: String,
            required: false,
        },
        payload: {
            type: Array,
            required: true,
            default: Array,
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Please select an option',
        },
        searchable: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            selected: {},
            lastSelected: {},
            optionsShown: false,
            searchFilter: '',
            options: []
        }
    },
    created() {
        this.$emit('selected', this.selected);
    },
    mounted() {
        const array = this.payload.flatMap(area => [
            { name: area.name, id: null },
            ...area.tables.map(table => ({ name: table.name, id: table.id }))
        ]);

        const selectedItem = array.find(item => item.id === this.value);
        this.selectOption(selectedItem);
        this.showOptions();
        this.options = array;
    },
    computed: {
        filteredOptions() {
            const sanitizedFilter = this.searchFilter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const regOption = new RegExp(sanitizedFilter, 'ig');

            return this.options.filter(option => {
                if (this.searchFilter.length > 0 && !option.id) {
                    return true;
                }
                return this.searchFilter.length < 1 || option.name.match(regOption) && option.id;
            });
        }
    },
    methods: {
        selectOption(option) {
            this.selected = option;
            this.lastSelected = option;
            this.optionsShown = false;
            this.searchFilter = this.selected.name;
            this.$emit('selected', this.selected);
        },
        showOptions(){
            this.searchFilter = '';
            this.optionsShown = true;
        },
        exit() {
            if (!this.selected.id) {
                this.selected = this.lastSelected.id ? this.lastSelected : {};
                this.searchFilter = this.selected.name || '';

                return;
            }

            this.searchFilter = this.selected.name;
            this.optionsShown = false;
        }
    },
    watch: {
        searchFilter() {
            this.selected = this.filteredOptions.length === 0 ? {} : this.lastSelected;
            this.$emit('filter', this.searchFilter);
        }
    }
};
</script>
