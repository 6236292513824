<template>
    <div
        v-click-outside="onClickOutside"
        @click="select"
        class="px-2 py-2 rounded-md"
        :class="selected ? 'border cursor-default' : 'cursor-pointer'"
    >
        <div class="flex text-subheading">
            <cash-icon class="px-0.5 rounded-sm" />
            <span class="ml-3 text-sm">{{ $t('cashPayment.payCash') }}</span>
        </div>

        <div v-show="selected">
            <button
                @click="checkout"
                :disabled="isLoading"
                class="flex inline-block justify-center mt-4 px-2 py-3 w-full bg-black rounded-md cursor-pointer"
            >
                <span v-if="!isLoading" class="text-white font-medium">
                    {{ $t('cashPayment.placeOrder') }}
                </span>
                <span v-else class="text-white font-medium">
                    <span class="spinner white ml-5"></span>
                </span>
            </button>

            <p v-show="errors" class="mt-4 text-red-600 text-xs">
                {{ $t('cashPayment.error') }}
            </p>
        </div>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import CashIcon from '@/components/icons/CashIcon';

export default {
    name: 'CashPaymentOption',
    props: {
        errors: {
            type: Boolean,
            required: true
        },
        isLoading: {
            type: Boolean,
            required: true
        }
    },
    components: {
        CashIcon
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    data() {
        return {
            selected: false
        };
    },
    methods: {
        select() {
            this.selected = true;
        },
        onClickOutside() {
            if (!this.isLoading) {
                this.selected = false;
            }
        },
        async checkout() {
            await this.$emit('is-validated');

            if (this.errors) {
                return;
            }

            this.$emit('checkout');
        }
    }
};
</script>
