<template>
    <div v-if="menuNotice && menuNotice.length > 0" class="menu-notice mt-16" v-once>
        <div class="py-3 px-3 text-center break-words sm:mb-4 sm:px-4 sm:text-left">
            <div class="flex justify-start">
                <div
                    class="text-muted text-sm leading-5 sm:text-xs text-pretty whitespace-pre-line overflow-hidden mx-auto sm:mx-0"
                    v-html="menuNotice"
                >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'MenuNotice',
    computed: {
        ...mapState('venue', {
            menuNotice: state => state.venue.menuNotice
        })
    }
};
</script>
