<template>
    <modal
        name="menu-summary-modal"
        width="100%"
        height="auto"
        :adaptive="false"
        :scrollable="true"
        :click-to-close="false"
    >
        <div class="flex max-h-full flex-col w-full bg-primary">
            <div class="w-full flex items-center justify-end p-1">
                <button
                    class="p-2 text-gray-500 rounded-t-lg hover:text-gray-800 hover:bg-gray-100"
                    @click="$modal.hide('menu-summary-modal')"
                >
                    <close-icon />
                </button>
            </div>

            <div class="overflow-y-auto px-4 gap-3 flex flex-col pb-8">
                <button
                    v-for="category in navCategories"
                    :key="`category-${category.id}`"
                    class="flex w-full gap-4 items-center"
                    @click="onCategoryClick(category)"
                >
                    <div class="size-32 flex items-center justify-center">
                        <img
                            v-if="category.navImage"
                            class="object-contain flex-1 max-w-full max-h-full"
                            :src="`${category.navImage}/-/format/auto/-/resize/300x/`"
                            :alt="category.name"
                        />
                        <div
                            v-else
                            aria-label="Category image"
                            class="crossed mb-2 w-full h-20 rounded border-2 border-gray-500"
                        />
                    </div>
                    <div
                        class="flex flex-1 text-start items-stretch justify-start flex-col"
                    >
                        <div class="text-xl break-words font-heading">
                            {{ category.name }}
                        </div>
                        <div class="text-sm tracking-[0.1px]">
                            {{ category.description }}
                        </div>
                    </div>
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import CloseIcon from '../icons/CloseIcon';

export default {
    name: 'menu-summary-modal',
    components: { CloseIcon },
    props: {
        categories: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    computed: {
        navCategories() {
            return this.categories.filter(category => !category.hiddenInMenu);
        }
    },
    methods: {
        onCategoryClick({ id }) {
            if (id) {
                this.$emit('scroll', `category-${id}`);
            }

            this.$modal.hide('menu-summary-modal');
        }
    }
};
</script>

<style scoped>
.crossed {
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M0 99 L99 0 L100 1 L1 100' fill='grey' /></svg>");
    background-repeat: no-repeat;
    background-position: center center;
    background-size:
        100% 100%,
        auto;
}
</style>
