<template>
    <div>
        <notifications
            :duration="1500"
            :max="2"
            :ignoreDuplicates="true"
            position="bottom center"
            classes="toast-style"
        />
        <notifications
            :duration="8000"
            :ignoreDuplicates="true"
            group="Adyen"
            position="top center"
            classes="toast-style border border-gray-100 rounded-md shadow-md"
        />
        <notifications
            :duration="1000"
            :ignoreDuplicates="true"
            group="BillPay"
            position="top center"
            classes="toast-style"
        />
        <notifications
            :duration="1000"
            :ignoreDuplicates="true"
            :width="320"
            group="offline"
            position="top center"
            classes="offline-toast shadow-md"
        />
        <notifications
            :duration="5000"
            :ignoreDuplicates="true"
            group="PiggyRewards"
            position="top center"
            classes="toast-style shadow-md"
        />
    </div>
</template>

<script>
export default {
    name: 'notification-groups'
};
</script>
