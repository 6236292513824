<template>
    <modal
        name="item-unavailable-modal"
        height="auto"
        width="575"
        :maxWidth="575"
        :adaptive="true"
        :scrollable="true"
        :clickToClose="true"
        transition="pop-out"
        :pivotY="pivotY"
        :reset="true"
        @before-open="beforeOpen"
    >
        <div class="item-modal-content bg-primary relative flex flex-col w-full">
            <div class="item-modal-details flex-auto p-8 overflow-y-auto">
                <h4 class="mb-3 pb-1 font-heading text-heading text-xl font-bold">
                    {{ $tc('itemUnavailableModal.notAvailable', items.length) }}
                </h4>

                <h5
                    v-for="(item, index) of items"
                    :key="`unavailable-item-${index}`"
                    class="pb-1 text-subheading text-xl"
                >
                    {{ item.name }}
                    <span v-show="item.productName">({{ item.productName }})</span>
                </h5>

				<h5
					v-if="otherProductsCount"
					class="pb-1 text-subheading text-xl"
				>
					& {{otherProductsCount}} {{ $tc('itemUnavailableModal.otherProduct', +otherProductsCount) }}
				</h5>

                <div class="mt-5 text-right">
                    <button
                        @click="hideModal()"
                        type="button"
                        class="border-button-primary no-highlight flex-auto px-12 py-4 w-full text-center text-button text-sm font-bold bg-button font-button rounded-sm shadow select-none sm:mb-3 md:mb-0 md:ml-6 md:w-auto"
                    >
                        {{ $t('itemUnavailableModal.close') }}
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import isMobile from "@/helpers/isMobileDevice";

export default {
    name: 'item-unavailable-modal',
    data() {
        return {
            items: [],
			otherProductsCount: null
        };
    },
    methods: {
        beforeOpen({ params }) {
            this.items = params.items;
			this.otherProductsCount = params.otherProductsCount;
        },
        hideModal() {
            this.$modal.hide('item-unavailable-modal');
        }
    },
    computed: {
        pivotY() {
          return isMobile()? 1 : 0.5;
        }
    }
};
</script>
