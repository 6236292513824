const state = {
    showMobileBasket: false
};

const getters = {
    showMobileBasket(state) {
        return state.showMobileBasket;
    }
};
const mutations = {
    setShowMobileBasket(state, value) {
        state.showMobileBasket = value;
    }
};
const actions = {
    setShowMobileBasket({ commit }, value) {
        commit('setShowMobileBasket', value);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
