const state = {
    deliveryAddress: {
        buildingNumber: '',
        street: '',
        city: '',
        postCode: '',
        country: '',
        coordinates: {
            lat: '',
            lng: ''
        },
        notes: '',
        userConfirmedCoordinates: false
    },
    formattedAddress: ''
};

const getters = {
    address(state) {
        return state.deliveryAddress;
    },
    formattedAddress(state) {
        return state.formattedAddress;
    }
};

const mutations = {
    setAddress(state, data) {
        state.deliveryAddress = data;
    },
    setStreet(state, data) {
        state.deliveryAddress.street = data;
    },
    setCity(state, data) {
        state.deliveryAddress.city = data;
    },
    setCountry(state, data) {
        state.deliveryAddress.country = data;
    },
    setBuildingNumber(state, data) {
        state.deliveryAddress.buildingNumber = data;
    },
    setPostCode(state, data) {
        state.deliveryAddress.postCode = data;
    },
    clearAddress(state) {
        state.deliveryAddress = {
            buildingNumber: '',
            street: '',
            city: '',
            postCode: '',
            country: state.deliveryAddress?.country,
            coordinates: {
                lat: '',
                lng: ''
            },
            notes: '',
            userConfirmedCoordinates: false
        };
        state.formattedAddress = '';
    },
    setCoordinates(state, data) {
        state.deliveryAddress.coordinates = data;
    },
    setFormattedAddress(state, data) {
        state.formattedAddress = data;
    },
    setUserConfirmedCoordinates(state, data) {
        state.deliveryAddress.userConfirmedCoordinates = data;
    },
    setNotes(state, data) {
        state.deliveryAddress.notes = data;
    },
};

const actions = {
    setAddress({ commit }, data) {
        commit('setAddress', data);
    },
    setStreet({ commit }, data) {
        commit('setStreet', data);
    },
    setCity({ commit }, data) {
        commit('setCity', data);
    },
    setBuildingNumber({ commit }, data) {
        commit('setBuildingNumber', data);
    },
    setPostCode({ commit }, data) {
        commit('setPostCode', data);
    },
    clearAddress({ commit }) {
        commit('clearAddress');
    },
    setCountry({ commit }, data) {
        commit('setCountry', data);
    },
    setCoordinates({ commit }, data) {
        commit('setCoordinates', data);
    },
    setFormattedAddress({ commit }, data) {
        commit('setFormattedAddress', data);
    },
    setUserConfirmedCoordinates({ commit }, data) {
        commit('setUserConfirmedCoordinates', data);
    },
    setNotes({ commit }, data) {
        commit('setNotes', data);
    },
};

export default {
    namespaced: true,
    getters,
    mutations,
    state,
    actions
};
