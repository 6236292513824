<template>
    <modal
        name="calculation-method-modal"
        height="auto"
        width="575"
        :maxWidth="575"
        :adaptive="true"
        :scrollable="true"
        :clickToClose="true"
        :pivotY="pivotY"
        :reset="true"
        @before-open="beforeOpen"
        @before-close="beforeClose"
    >
        <div class="item-modal-content relative flex flex-col w-full">
            <div class="item-modal-details flex-auto p-8 overflow-y-auto">
                <div>
                    <h4 class="pb-2 text-gray-800 text-xl font-bold">
                        {{
                            $t(
                                'calculationMethodModal.preferredCalculationMethod'
                            )
                        }}
                    </h4>

                    <div
                        v-if="isOpen && options?.length > 0"
                        class="question-content custom-radio-box-container -mx-2 w-full"
                    >
                        <label
                            v-for="(option, i) in options"
                            :key="i"
                            :for="`${option.id}-${i}`"
                            :class="{
                                checked: selectedOption === option.id
                            }"
                            class="custom-radio-box relative block pr-0 px-2 w-full rounded cursor-pointer"
                        >
                            <input
                                v-model="selectedOption"
                                :id="`${option.id}-${i}`"
                                :value="option.id"
                                :checked="selectedOption === option.id"
                                type="radio"
                                class="custom-control-input form-check-input absolute opacity-0"
                            />
                            <div class="flex p-2 px-0 rounded">
                                <span class="custom-radio-indicator" />
                                <div class="custom-radio-box-align">
                                    <div class="pl-2 text-itembody text-sm">
                                        {{ option.label }}
                                        ({{ option.value | currency }})
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div
                class="item-modal-footer flex flex-col-reverse justify-center p-6 md:flex-row"
            >
                <base-button
                    class="p-3 px-10 mb-3 sm:block sm:mb-0 md:w-auto"
                    size="sm"
                    variant="secondaryActions"
                    :button-text="$t('basket.cancel')"
                    :is-border-transparent="true"
                    @clicked="hide"
                />
                <base-button
                    class="px-12 py-4 sm:mb-3 mb-0 md:ml-6 md:w-auto"
                    size="sm"
                    button-text="Save"
                    @clicked="save"
                />
            </div>
        </div>
    </modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { CalculationMethodsEnum } from '@/enums';
import isMobile from '@/helpers/isMobileDevice';

export default {
    name: 'calculation-method-modal',
    data() {
        return {
            isLoading: false,
            selectedOption: this.$store.state.cart.calculationMethod,
            isOpen: false
        };
    },
    computed: {
        ...mapGetters({
            allIncludedPricePerPerson: 'cart/getAllIncludedPricePerPerson',
            subtotalPricePerPerson: 'cart/getSubtotalPricePerPerson',
            calculationMethod: 'cart/getCalculationMethod'
        }),
        options() {
            return [
                {
                    id: CalculationMethodsEnum.ALL_INCLUDED,
                    label: this.$t('calculationMethodModal.allInPricing'),
                    value: this.allIncludedPricePerPerson / 100
                },
                {
                    id: CalculationMethodsEnum.JUST_FOOD,
                    label: this.$t('calculationMethodModal.justFood'),
                    value: this.subtotalPricePerPerson / 100
                }
            ];
        },
        pivotY() {
            return isMobile() ? 1 : 0.5;
        }
    },
    methods: {
        ...mapMutations({
            setCalculationMethod: 'cart/SET_CALCULATION_METHOD'
        }),
        beforeOpen() {
            this.isOpen = true;
        },
        beforeClose() {
            this.isOpen = false;
        },
        save() {
            this.setCalculationMethod(this.selectedOption);
            this.$modal.hide('calculation-method-modal');
        },
        hide() {
            this.$modal.hide('calculation-method-modal');
        }
    }
};
</script>
<style src="@/assets/css/radio-button.css"></style>
