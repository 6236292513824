export const getGatewayMerchantId = currencyCode => {
    const merchantIds = {
        GBP: import.meta.env.VITE_ADYEN_MERCHANT_ID,
        EUR: import.meta.env.VITE_ADYEN_MERCHANT_ID_EUR,
        SEK: import.meta.env.VITE_ADYEN_MERCHANT_ID_SEK,
        CHF: import.meta.env.VITE_ADYEN_MERCHANT_ID_CHF,
        DKK: import.meta.env.VITE_ADYEN_MERCHANT_ID
    };

    if (!merchantIds[currencyCode]) {
        throw new Error(`Currency code "${currencyCode}" is not supported`);
    }

    return merchantIds[currencyCode];
};
