import router from './../router';
import Vue from 'vue';
import { axiosRetry } from '@/plugins/axios';
import isLocalStorageAvailable from '@/helpers/isLocalStorageAvailable';
import checkoutErrors from '@/helpers/checkoutErrors';
import isPwa from '@/helpers/isPwa';
import { sendConversionData } from '@/helpers/rwgTokenHelper';
import {
    FulfillmentMethodsEnum,
    PaymentMethodsEnum,
    TrackingEventsEnum,
    CalculationMethodsEnum
} from '@/enums';
import {
    isMatchingProduct,
    getDiscountType,
    getDiscountValue
} from '@/util/piggyHelper';

import {
    findMatchingCartItem,
    calculateDiscount,
    calculatePiggyDiscount,
    calculateItemTotal
} from '@/services/cart';

const ALCOHOL_TAG_ID = 1;

const state = {
    items: [],
    fulfillmentMethod: null,
    customer: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        notes: ''
    },
    isLoading: false,
    paymentType: PaymentMethodsEnum.CASH,
    isPreOrder: false,
    deliveryFee: null,
    serviceCharge: 1,
    total: 0,
    orderStatus: 'Pending',
    deliveryPostCode: null,
    deliveryTime: 0,
    selectedTableId: null,
    totalWithDiscount: null,
    selectedTip: null,
    totalAmountDiscounted: 0,
    orderId: null,
    lastOrdersIds: [],
    fetchPickupSlotsKey: 0,
    piggyProductDiscount: [],
    totalPiggyDiscount: 0,
    cutlery: null,
    zoneDeliveryMinCost: null,
    piggyRewardSelected: {},
    giftCardPaymentAmount: null,
    noOfDiners: 1,
    pickupDate: '',
    pickupTimeSlot: '',
    preOrderDateTime: '',
    ageOver18Confirmed: false,
    calculationMethod: CalculationMethodsEnum.ALL_INCLUDED,
    discountedDeliveryFee: null,
    orderCurrentCheckoutError: false,
    poNumber: '' // Add PO Number field
};

const mutations = {
    setDeliveryFeeFromZone(state, fee) {
        state.deliveryFee = fee;
    },
    setTip(state, val) {
        state.selectedTip = val;
    },
    setPreOrder(state, val) {
        state.isPreOrder = val;
    },
    setPoNumber(state, value) {
        state.poNumber = value;
    },
    pushProductToCart(state, payload) {
        let item = {
            categoryId: payload.product.categoryId,
            categoryPosId: payload.product.categoryPosId,
            id: payload.product.id,
            name: payload.product.name,
            plu: payload.product.plu,
            price: payload.product.price,
            tags: payload.product.tags,
            image: payload.product.image,
            trackInventory: payload.product.trackInventory,
            compareAtPrice: payload.product.compareAtPrice,
            popular: payload.product.popular,
            video: payload.product.video,
            quantity: payload.quantityDelta,
            index: payload.product.index,
            posId: payload.product.posId || payload.product.deliverectId,
            inventory: payload.product.inventory,
            notes: payload.product.notes || '',
            modifiers: [],
            discountAmount: 0
        };

        for (const {
            id,
            name,
            plu,
            price,
            posId,
            modifierGroupId
        } of payload.modifiers) {
            const modifierGroup = payload.product.modifierGroups.find(
                modifierGroup => modifierGroup.id === modifierGroupId
            );

            item.modifiers.push({
                id,
                name,
                plu,
                price,
                posId,
                group: {
                    id: modifierGroup.id,
                    name: modifierGroup.name,
                    posId: modifierGroup.posId
                }
            });
        }

        state.items.push(item);
    },
    incrementItemQuantity(state, payload) {
        const targetModifiersString = JSON.stringify(payload.cart.modifiers);

        const cartItemIndex = state.items.findIndex(
            item =>
                item.id === payload.cart.id &&
                item.notes === payload.cart.notes &&
                JSON.stringify(item.modifiers) === targetModifiersString
        );
        const cartItem = state.items[cartItemIndex];

        if (payload.quantityDelta) {
            cartItem.quantity += payload.quantityDelta;
        } else {
            cartItem.quantity++;
        }

        if (cartItem.quantity === 0) {
            state.items.splice(cartItemIndex, 1);
        }
    },
    setOrderCurrentCheckoutError(state, value) {
        state.orderCurrentCheckoutError = value;
    },
    setCartItems(state, { items }) {
        state.items = items;
    },
    setCheckoutStatus(state, status) {
        state.checkoutStatus = status;
    },
    setFulfillmentMethod(state, method) {
        state.fulfillmentMethod = method;
    },
    setTable(state, payload) {
        state.selectedTableId = payload;
    },
    setDeliveryTime(state, value) {
        state.deliveryTime = value;
    },
    setTotalWithDiscount(state, value) {
        state.totalWithDiscount = value;
    },
    setTotalAmountDiscounted(state, value) {
        state.totalAmountDiscounted = value;
    },
    setFetchPickupSlotsKey(state, value) {
        state.fetchPickupSlotsKey = value;
    },
    setGiftCardPaymentAmount(state, value) {
        state.giftCardPaymentAmount = value;
    },
    CHECKOUT_START(state) {
        state.isLoading = true;
    },
    CHECKOUT_END(state, id) {
        state.isLoading = false;

        if (id) {
            router.replace({ name: 'orderStatus', params: { id } });
        }
    },
    SET_PAYMENT_TYPE(state, type) {
        state.paymentType = type;
    },
    SET_ORDER_ID(state, id) {
        state.orderId = id;
    },
    SET_CART(state, order) {
        state.items = order.items;
        state.fulfillmentMethod = order.fulfillmentMethod;
        state.paymentType = order.paymentType;
        state.isPreOrder = order.isPreOrder;
        state.deliveryFee = order.deliveryFee;
        state.serviceCharge = order.serviceCharge;
        state.totalWithDiscount = order.total;
        state.selectedTip = order.tip;
    },
    CLEAR_TOTAL(state) {
        state.totalWithDiscount = 0;
    },
    SET_LAST_ORDERS_IDS(state, orderId) {
        state.lastOrdersIds = orderId;
    },
    SET_ORDER_STATUS(state, orderStatus) {
        state.orderStatus = orderStatus;
    },
    UPDATE_PIGGY_PRODUCT_DISCOUNT(state, { id, value, modifierIds }) {
        const index = state.piggyProductDiscount.findIndex(product =>
            isMatchingProduct(product, id, modifierIds)
        );

        if (~index) {
            state.piggyProductDiscount[index].value = value;
        }
    },
    SET_PIGGY_PRODUCT_DISCOUNT(state, { id, value, modifierIds }) {
        state.piggyProductDiscount.push({ id, value, modifierIds });
    },
    SET_PIGGY_TOTAL_DISCOUNT(state, payload) {
        state.totalPiggyDiscount = payload;
    },
    REMOVE_PIGGY_PRODUCT_DISCOUNT(state, { id, modifierIds }) {
        const index = state.piggyProductDiscount.findIndex(product =>
            isMatchingProduct(product, id, modifierIds)
        );

        if (~index) {
            state.piggyProductDiscount.splice(index, 1);

            if (state.piggyProductDiscount.length === 0) {
                state.totalPiggyDiscount = 0;
            }
        }

        if (
            (state.piggyProductDiscount[0]?.id === 'basket_amount' ||
                state.piggyProductDiscount[0]?.id === 'basket_percentage') &&
            state.items.length === 0
        ) {
            state.piggyProductDiscount = [];
            state.totalPiggyDiscount = 0;
        }
    },
    CLEAR_PIGGY_DISCOUNT(state) {
        state.piggyProductDiscount = [];
        state.totalPiggyDiscount = 0;
    },
    SET_CUTLERY(state, val) {
        state.cutlery = val;
    },
    SET_ZONE_DELIVERY_MIN_COST(state, val) {
        state.zoneDeliveryMinCost = val;
    },
    SET_PIGGY_REWARD_SELECTED(state, value) {
        state.piggyRewardSelected = value;
    },
    SET_NUMBER_OF_DINERS(state, value) {
        state.noOfDiners = value;
    },
    SET_PICKUP_DATE(state, value) {
        state.pickupDate = value;
    },
    SET_PICKUP_TIME_SLOT(state, value) {
        state.pickupTimeSlot = value;
    },
    SET_PRE_ORDER_DATE_TIME_SLOT(state, value) {
        state.preOrderDateTime = value;
    },
    RESET_PICKUP_AND_PRE_ORDER_DATES(state) {
        state.pickupDate = '';
        state.pickupTimeSlot = '';
        state.preOrderDateTime = '';
    },
    SET_AGE_OVER_18_CONFIRMED(state, value) {
        state.ageOver18Confirmed = value;
    },
    SET_CALCULATION_METHOD(state, value) {
        state.calculationMethod = value;
    },
    SET_DISCOUNTED_DELIVERY_FEE(state, value) {
        state.discountedDeliveryFee = value;
    },
    SET_ITEM_DISCOUNT(state, { lineId, quantity, netAmount, discountAmount }) {
        state.items.forEach(item => {
            const itemFullPrice =
                (item.price +
                    item.modifiers.reduce((sum, mod) => sum + mod.price, 0)) *
                item.quantity;

            if (
                item.id === lineId &&
                item.quantity === quantity &&
                itemFullPrice === netAmount
            ) {
                // Ensure discount cannot exceed the item's full price
                const maxDiscount = itemFullPrice;
                const newDiscountAmount =
                    (item.discountAmount || 0) + discountAmount;

                item.discountAmount = Math.min(newDiscountAmount, maxDiscount);
            }
        });
    },
    RESET_ITEM_DISCOUNT(state) {
        state.items.forEach(item => {
            item.discountAmount = 0;
        });
    }
};

const getters = {
    cartItemById: state => itemId => {
        return state.items.some(item => item.id === itemId);
    },
    cartItemQuantity: state => {
        const quantityMap = new Map();
        state.items.forEach(item => {
            const current = quantityMap.get(item.id) || 0;
            quantityMap.set(item.id, current + item.quantity);
        });
        return id => quantityMap.get(id) || 0;
    },
    cartQuantity: state =>
        state.items.reduce((total, item) => total + item.quantity, 0),
    cartSubtotal: state => {
        return state.items.reduce((total, item) => {
            return total + calculateItemTotal(item);
        }, 0);
    },
    cartSubtotalWithoutAlcohol: state => {
        return state.items
            .filter(item => !item.tags?.includes(ALCOHOL_TAG_ID))
            .reduce((total, item) => total + calculateItemTotal(item), 0);
    },
    availableTips: (state, getters, rootState) => {
        const { venue } = rootState.venue;
        if (venue.acceptTips === 'disabled') return null;
        if (venue.acceptTips === 'enabled') return venue.tipPercentages;
        if (venue.acceptTips === 'smart') {
            if (getters.cartSubtotal < 1000) return [0, 0.5, 1, 2];
            if (getters.cartSubtotal < 3000) return [0, 1, 2, 4];
            return [0, 2, 4, 6];
        }
    },
    tip: (state, getters, rootState, rootGetters) => {
        if (state.selectedTip) {
            if (rootGetters['venue/tipVariant'] === 'smart') {
                return Math.floor(state.selectedTip * 100);
            }

            return Math.floor(
                (getters.cartSubtotal * state.selectedTip) / 10000
            );
        }

        return 0;
    },
    cartTotal: (state, getters, rootState) => {
        let total = 0;
        const subtotal = getters.cartSubtotal || 0;
        total += subtotal;

        if (state.fulfillmentMethod === FulfillmentMethodsEnum.DELIVERY) {
            const deliveryFee =
                state.deliveryFee || rootState.venue.venue.deliveryFee;
            total += deliveryFee;
        }

        const serviceChange = rootState.venue.venue.serviceCharge || 0;

        total += serviceChange;

        if (getters.tip) {
            total += getters.tip;
        }

        return total;
    },
    cartTotalWithDiscount: state => {
        return state.totalWithDiscount;
    },
    totalAmountDiscounted: state => {
        return state.totalAmountDiscounted;
    },
    deliveryPostCode: state => {
        return state.deliveryPostCode;
    },
    orderId: state => {
        return state.orderId;
    },
    selectedTip: state => {
        return state.selectedTip;
    },
    paymentType: state => {
        return state.paymentType;
    },
    items: state => {
        return state.items;
    },
    fulfillmentMethod: state => {
        return state.fulfillmentMethod;
    },
    cutlery: state => {
        return state.cutlery;
    },
    isPiggyRewardActive: state => {
        return !!Object.keys(state.piggyProductDiscount).length;
    },
    giftCardPaymentAmount: state => {
        return state.giftCardPaymentAmount;
    },
    getAllIncludedPricePerPerson: state => {
        return state.totalWithDiscount / state.noOfDiners;
    },
    getSubtotalPricePerPerson: (state, getters) => {
        return getters.cartSubtotal / state.noOfDiners;
    },
    getCalculatedPricePerPerson: (state, getters) => {
        if (state.calculationMethod === CalculationMethodsEnum.ALL_INCLUDED) {
            return getters.getAllIncludedPricePerPerson;
        }

        return getters.getSubtotalPricePerPerson;
    },
    getPickupDate: state => {
        return state.pickupDate;
    },
    getPickupTimeSlot: state => {
        return state.pickupTimeSlot;
    },
    getPreOrderDateTimeSlot: state => {
        return state.preOrderDateTime;
    },
    ageOver18Confirmed: state => {
        return state.ageOver18Confirmed;
    },
    getCalculationMethod: state => {
        return state.calculationMethod;
    },
    getOrderCurrentCheckoutError: state => {
        return state.orderCurrentCheckoutError;
    },
    poNumber: state => {
        return state.poNumber;
    },
    productsMap: (state, getters, rootState) => {
        // Return null if categories aren't loaded yet
        if (!rootState.venue.categories || !rootState.venue.categories.length) {
            return null;
        }

        // Only create the map once and reuse it
        if (!rootState.venue._productsMap) {
            rootState.venue._productsMap = rootState.venue.categories.reduce(
                (map, category) => {
                    category.products?.forEach(product => {
                        if (product?.id) {
                            map[product.id] = product;
                        }
                    });
                    return map;
                },
                {}
            );
        }
        return rootState.venue._productsMap;
    },
    getProductById: (state, getters) => productId => {
        const productsMap = getters.productsMap;

        // Return null if productsMap isn't ready
        if (!productsMap) {
            return null;
        }

        const baseProduct = productsMap[productId];

        if (!baseProduct) {
            return null;
        }

        // Find cart-specific data if it exists
        const cartItem = state.items.find(item => item.id === productId);

        if (cartItem) {
            return {
                ...baseProduct,
                cartQuantity: cartItem.quantity,
                cartModifiers: cartItem.modifiers,
                inCart: true
            };
        }

        return {
            ...baseProduct,
            cartQuantity: 0,
            cartModifiers: [],
            inCart: false
        };
    }
};

const getAutoAppliedDiscount = (rootState, fulfillmentMethod) => {
    const venue = rootState.venue.venue;
    const autoDiscount = (venue.autoAppliedDiscountCode || [])[0];

    if (
        autoDiscount &&
        Array.isArray(autoDiscount.fulfillmentMethods) &&
        autoDiscount.fulfillmentMethods.includes(fulfillmentMethod)
    ) {
        return autoDiscount;
    }

    return null;
};

const actions = {
    async checkout(
        { state, commit, getters, rootState, rootGetters, dispatch },
        payload
    ) {
        const menuCheck = await dispatch(
            'venue/checkMenuVersion',
            {},
            { root: true }
        );

        if (!menuCheck) {
            await router.replace({ name: 'menu' });

            return;
        }

        const venueId = rootState.venue.venue.id;

        const client = this.$axios.create();

        const order = {
            items: state.items,
            customer: payload.customer,
            preOrderDateTime: payload.preOrderDateTime,
            fulfillmentMethod:
                state.fulfillmentMethod || FulfillmentMethodsEnum.DELIVERY,
            paymentType: state.paymentType,
            isPreOrder: state.isPreOrder,
            deliveryAddress: payload.deliveryAddress,
            deliveryFee: state.deliveryFee || rootState.venue.venue.deliveryFee,
            serviceCharge: rootState.venue.venue.serviceCharge,
            venueId,
            accountId: rootState.venue.venue.accountId,
            total: getters.cartTotalWithDiscount,
            orderStatus: state.orderStatus,
            tableId: state.selectedTableId || null,
            discountCodeId: getters.isPiggyRewardActive
                ? null
                : rootState.discount.details
                  ? rootState.discount.details.id
                  : (
                          (
                              (rootState.venue.venue.autoAppliedDiscountCode ||
                                  false)[0] || false
                          ).fulfillmentMethods || []
                      ).includes(state.fulfillmentMethod)
                    ? rootState.venue.venue.autoAppliedDiscountCode[0].id
                    : null,
            cartSubtotal: getters.cartSubtotalWithoutAlcohol,
            tip: getters.tip,
            billId: rootState.bill.id,
            cutlery: getters.cutlery,
            channel: isPwa() ? 'PWA' : 'WEB',
            giftCardPaymentAmount: getters.giftCardPaymentAmount,
            giftCardReference: rootGetters['giftCard/giftCardReference'],
            noOfDiners: state.noOfDiners,
            poNumber: state.poNumber // Add PO number to order
        };

        if (payload.preOrderDateTime === 'ASAP') {
            order.isPreOrder = false;
            order.preOrderDateTime = null;
        }

        commit('setCheckoutStatus', null);
        commit('CHECKOUT_START');

        axiosRetry(client, {
            retries: 3,
            retryDelay: retryCount => {
                return retryCount * 2000;
            },
            retryCondition: error => {
                return error.response && error.response.status === 408;
            }
        });

        try {
            const { data: newOrder } = await client.post('/orders', order);

            commit('setCheckoutStatus', 'successful');

            const orderId = newOrder.id;

            commit('SET_ORDER_ID', orderId);

            if (order.paymentType !== PaymentMethodsEnum.ADYEN) {
                dispatch('trackOrder', {
                    order,
                    orderId,
                    orderTotal: order.total
                });
                commit('CHECKOUT_END', orderId);
                commit('setCartItems', { items: [] });
                commit('CLEAR_TOTAL');
                commit('SET_ORDER_ID', null);
                commit('customer/setNotes', '', {
                    root: true
                });
            }

            dispatch(
                'customer/setPolicyAgreed',
                {
                    accountId: rootGetters['venue/venueValue']('accountId')
                },
                { root: true }
            );

            if (order.paymentType !== PaymentMethodsEnum.ADYEN) {
                dispatch('clearDiscount');
            }

            if (state.fulfillmentMethod === FulfillmentMethodsEnum.IN_STORE) {
                dispatch('setLastOrders', { venueId, orderId });
            }

            if (state.piggyProductDiscount.length > 0) {
                commit('CLEAR_PIGGY_DISCOUNT');
                commit('SET_PIGGY_REWARD_SELECTED', {});
            }

            if (rootState.venue.venue.comoEnabled) {
                commit('customer/CLEAR_COMO_REWARDS', null, { root: true });
                commit('customer/CLEAR_COMO_DATA', null, { root: true });
            }

            commit('delivery/clearMapMatching', null, { root: true });
        } catch (error) {
            commit('setCheckoutStatus', 'failed');
            commit('CHECKOUT_END');

            if (error.response?.data?.message) {
                const isDisplayableError = checkoutErrors({
                    error,
                    modal: this._vm.$modal,
                    dispatch
                });

                if (isDisplayableError) {
                    /* eslint-disable-next-line */
                    console.error(error.response?.data?.message);
                }
            }

            throw error;
        }
    },
    setOrderCurrentCheckoutError({ commit }, value) {
        commit('setOrderCurrentCheckoutError', value);
    },
    setSoldOutItems(
        { state, commit, dispatch, rootState },
        { items, redirect }
    ) {
        const itemIds = items.map(item => item.id);
        const availableItems = state.items.filter(item => {
            const containModifier = item.modifiers.filter(modifier =>
                itemIds.includes(modifier.id)
            );

            return !containModifier.length && !itemIds.includes(item.id);
        });

        commit('setCartItems', { items: availableItems || [] });

        dispatch('cartTotalWithDiscount');
        dispatch('venue/getMenuCategories', rootState.venue.venue.menu[0].id, {
            root: true
        });

        if (!availableItems.length && redirect) {
            router.go(-1);
        }

        this._vm.$modal.show('item-unavailable-modal', {
            items
        });
    },
    addProductToCart(
        { state, commit, rootState, dispatch },
        [product, quantityDelta, modifiers, source]
    ) {
        // Find a cart item that matches the product, notes, and exact set of modifiers
        const existingCartItem = findMatchingCartItem(
            state.items,
            product,
            modifiers
        );

        if (existingCartItem) {
            commit('incrementItemQuantity', {
                cart: existingCartItem,
                quantityDelta,
                modifiers
            });
        } else if (!existingCartItem && quantityDelta > 0) {
            commit('pushProductToCart', { product, quantityDelta, modifiers });
            dispatch('updatePiggyDiscount', {
                productId: product.id,
                quantity: quantityDelta
            });
        }

        if (Vue.prototype.$analytics && quantityDelta !== 0) {
            const eventType =
                quantityDelta > 0
                    ? TrackingEventsEnum.ADD_TO_CART
                    : TrackingEventsEnum.REMOVE_FROM_CART;

            Vue.prototype.$analytics.track(eventType, {
                id: product.id,
                name: product.name,
                price: product.price,
                image: product.image,
                quantity: quantityDelta,
                plu: product.plu,
                currency: rootState.venue.venue.currencyCode,
                source: source
            });
        }
    },
    emptyCart({ commit }) {
        commit('setCartItems', { items: [] });
    },
    setTable({ commit, rootState }, { table, venueId, isQrCode }) {
        if (!isLocalStorageAvailable()) {
            commit('setTable', table);

            return;
        }

        const key = `selected-table-venue-${venueId}`;
        const existingSelectedTable = localStorage.getItem(key);

        if (existingSelectedTable) {
            const { tableId } = JSON.parse(existingSelectedTable);

            if (!isQrCode && !table) {
                return commit('setTable', tableId);
            }

            if (rootState.bill.id && table !== tableId) {
                commit('bill/setBillId', null, {
                    root: true
                });
            }
        } else {
            commit('bill/setBillId', null, {
                root: true
            });
        }

        if (table) {
            const SIX_HOURS_IN_MS = 21600000; // 60 * 60 * 6 * 1000
            const currentTime = new Date().getTime();
            let expiry = currentTime + SIX_HOURS_IN_MS;

            if (rootState.venue.venue?.tableStorageTime > 0) {
                const tableStorageTimeInMs =
                    rootState.venue.venue.tableStorageTime * 6000;
                expiry = currentTime + tableStorageTimeInMs;
            }

            const selectedTable = {
                tableId: table,
                expiry
            };

            localStorage.setItem(key, JSON.stringify(selectedTable));

            commit('setTable', table);

            return;
        }

        commit('setTable', null);
    },
    async cartTotalWithDiscount({ commit, state, rootState, getters }) {
        // Get active discount
        const discount =
            rootState.discount.details ||
            getAutoAppliedDiscount(rootState, state.fulfillmentMethod);
        const subtotal = getters.cartSubtotal;
        const deliveryFee =
            state.deliveryFee || rootState.venue.venue.deliveryFee || 0;
        const serviceCharge = rootState.venue.venue.serviceCharge;
        let tip = getters.tip || 0;

        // Initialize with no discount
        let subtotalWithDiscount = subtotal;
        let deliveryFeeWithDiscount = deliveryFee;
        // Calculate discount if applicable
        if (
            discount &&
            discount.minimumAmount <= subtotal &&
            !getters.isPiggyRewardActive
        ) {
            const categories = rootState.venue.categories;

            ({ subtotalWithDiscount, deliveryFeeWithDiscount } =
                await calculateDiscount({
                    discount,
                    state: {
                        items: state.items,
                        fulfillmentMethod: state.fulfillmentMethod
                    },
                    subtotal,
                    deliveryFee,
                    categories
                }));
        }

        // Handle Piggy rewards if active
        if (state.piggyProductDiscount.length > 0) {
            const {
                subtotalWithDiscount: piggySubtotal,
                deliveryFeeWithDiscount: piggyDeliveryFee,
                totalPiggyDiscount
            } = await calculatePiggyDiscount({
                piggyProductDiscount: state.piggyProductDiscount,
                piggyRewardSelected: state.piggyRewardSelected,
                fulfillmentMethod: state.fulfillmentMethod,
                subtotalWithDiscount,
                deliveryFeeWithDiscount,
                deliveryFee,
                piggySettings: rootState.venue.venue.piggySettings
            });

            subtotalWithDiscount = piggySubtotal;
            deliveryFeeWithDiscount = piggyDeliveryFee;
            commit('SET_PIGGY_TOTAL_DISCOUNT', totalPiggyDiscount);
        }

        if (
            rootState.customer.como.discountSum > 0 &&
            rootState.customer.como.transactionId &&
            rootState.customer.como.openTime
        ) {
            subtotalWithDiscount -= rootState.customer.como.discountSum;
        }

        // Calculate final totals
        const baseTotal = subtotalWithDiscount + serviceCharge + tip;
        const deliveryTotal = FulfillmentMethodsEnum.isDeliveryMethod(
            state.fulfillmentMethod
        )
            ? deliveryFeeWithDiscount
            : 0;

        const totalWithDiscount = baseTotal + deliveryTotal;
        const totalDiscount = totalWithDiscount - getters.cartTotal;

        // Update state in order of most specific to most general
        commit('SET_DISCOUNTED_DELIVERY_FEE', deliveryFeeWithDiscount);
        commit('setTotalWithDiscount', totalWithDiscount);
        commit('setTotalAmountDiscounted', totalDiscount);
    },
    setPaymentType({ commit }, type) {
        commit('SET_PAYMENT_TYPE', type);
    },
    checkoutEnd({ commit, getters, dispatch }, id) {
        if (getters.paymentType === PaymentMethodsEnum.ADYEN) {
            commit('setCartItems', { items: [] });
            commit('CLEAR_TOTAL');
            commit('SET_ORDER_ID', null);
            commit('customer/setNotes', '', {
                root: true
            });
            dispatch('giftCard/resetGiftCardStoreValues', '', { root: true });
        }

        commit('CHECKOUT_END', id);
    },
    async catchOrder(
        { commit, dispatch },
        { orderId, venueId, isPaymentRedirect }
    ) {
        try {
            const { data: order } = await this.$axios.get(
                `/orders/${orderId}`,
                { params: { venueId } }
            );

            if (isPaymentRedirect && !order.isDraft) {
                router.replace({
                    name: 'orderStatus',
                    params: { id: orderId }
                });
            }

            if (isPaymentRedirect) {
                commit('SET_ORDER_ID', orderId);
                commit('setTable', order.tableId);
                commit('SET_ORDER_STATUS', order.orderStatus);
            }

            if (order.customer) {
                commit('customer/setCustomer', order.customer, {
                    root: true
                });
            }

            if (order.deliveryAddress) {
                commit('deliveryAddress/setAddress', order.deliveryAddress, {
                    root: true
                });
            }

            if (order.orderDiscountCode && order.orderDiscountCode.length) {
                const discount = order.orderDiscountCode[0];

                const checkDiscount = await dispatch(
                    'discount/checkDiscountCode',
                    {
                        venueId: order.venueId,
                        code: discount.code,
                        date: '',
                        timeSlot: '',
                        fulfillmentMethod: order.fulfillmentMethod,
                        email: order.customer.email || null
                    },
                    { root: true }
                );

                const discountToApply =
                    checkDiscount && checkDiscount.error ? null : discount;

                commit('discount/DETAILS', discountToApply, {
                    root: true
                });
            }

            commit('SET_CART', order);
        } catch (error) {
            throw new Error(`API ${error}`);
        }
    },
    setLastOrders({ commit }, { venueId, orderId }) {
        if (!isLocalStorageAvailable()) {
            return;
        }

        const TWENTY_FOUR_HOURS = 60 * 60 * 24 * 1000;
        const expiry = new Date().getTime() + TWENTY_FOUR_HOURS;
        const key = `last-orders-venue-${venueId}`;
        const existingLastOrders = localStorage.getItem(key);
        const ids = [];

        if (existingLastOrders) {
            const { ids: existingIds } = JSON.parse(existingLastOrders);

            ids.push(existingIds[0]);
            ids.unshift(orderId);
        } else {
            ids.push(orderId);
        }

        const lastOrders = {
            ids,
            expiry
        };

        localStorage.setItem(
            `last-orders-venue-${venueId}`,
            JSON.stringify(lastOrders)
        );

        commit('SET_LAST_ORDERS_IDS', ids);
    },
    setTip({ commit }, tip) {
        commit('setTip', tip);
    },
    initTipValue({ state, rootGetters, getters, dispatch, commit }) {
        const tipVariant = rootGetters['venue/tipVariant'];
        let tipToInit = null;

        if (state.selectedTip !== null && state.selectedTip > 0) {
            const relativeTipValue = getters.availableTips.find(
                tip =>
                    state.selectedTip ===
                    Math.floor((getters.cartSubtotal * tip) / 10000)
            );
            const absoluteTipValue = getters.availableTips.find(
                tip => state.selectedTip === tip
            );

            tipToInit = relativeTipValue
                ? relativeTipValue
                : absoluteTipValue
                  ? absoluteTipValue
                  : 0;
        } else if (
            router.currentRoute.query.redirectResult &&
            tipVariant === 'smart'
        ) {
            tipToInit = null;
        } else {
            tipToInit = getters.availableTips[0];
        }

        commit('setTip', tipToInit);
        dispatch('cartTotalWithDiscount');
    },
    async trackOrder({ rootState }, { order, orderId, orderTotal }) {
        try {
            const { $analytics, $tracker } = Vue.prototype;
            if ($analytics) {
                if (rootState.customer?.email) {
                    $analytics.identify(rootState.customer.email);
                }
                const totalNumeric = parseFloat((orderTotal / 100).toFixed(2));
                $analytics.track(TrackingEventsEnum.ORDER_COMPLETED, {
                    orderId,
                    total: totalNumeric,
                    currency: rootState.venue.venue.currencyCode,
                    venueSlug: rootState.venue.venue.slug || '',
                    order // @TODO refactor
                });
            }

            if ($tracker) {
                if (rootState.customer?.email) {
                    $tracker.setUserID(rootState.customer.email);
                }

                $tracker.setMetadata('orderId', orderId);
                $tracker.setMetadata('venueSlug', rootState.venue.venue.slug);
            }
            await sendConversionData(rootState.venue.venue.slug);
        } catch (error) {
            console.error(error);
        }
    },
    clearDiscount({ rootState, dispatch }) {
        if (
            rootState.discount.details &&
            !rootState.discount.details.autoApply
        ) {
            dispatch(
                'discount/setDiscountCode',
                { code: null },
                { root: true }
            );
        }
    },
    fetchPickupSlots({ commit, state }) {
        commit('setFetchPickupSlotsKey', ++state.fetchPickupSlotsKey);
    },
    SET_PIGGY_PRODUCT_DISCOUNT({ commit }, payload) {
        commit('SET_PIGGY_PRODUCT_DISCOUNT', payload);
    },
    removeDiscount({ dispatch }) {
        dispatch('discount/setDiscountCode', { code: null }, { root: true });
        dispatch('venue/clearAutoAppliedDiscountCodes', null, { root: true });
        dispatch('cartTotalWithDiscount');
    },
    updatePiggyDiscount({ commit, getters, state }, { productId, quantity }) {
        const reward = state.piggyRewardSelected;

        if (Object.keys(reward).length === 0) {
            return;
        }

        const index = state.piggyProductDiscount.findIndex(
            discount => discount.id === productId
        );
        const discountType = getDiscountType(reward);
        const discountValue = getDiscountValue(reward);

        const sumModifiersPrice = product => {
            if (product.modifiers.length) {
                return product.modifiers.reduce(
                    (total, modifier) => total + modifier.price,
                    0
                );
            }

            return 0;
        };

        const handleBasketDiscount = () => {
            if (discountType === 'basket_amount') {
                commit('UPDATE_PIGGY_PRODUCT_DISCOUNT', {
                    id: 'basket_amount',
                    value: Math.min(discountValue * 100, getters.cartSubtotal),
                    modifierIds: []
                });
            }

            if (discountType === 'basket_percentage') {
                commit('UPDATE_PIGGY_PRODUCT_DISCOUNT', {
                    id: 'basket_percentage',
                    value: getters.cartSubtotal * (discountValue / 100),
                    modifierIds: []
                });
            }
        };

        if (
            discountType === 'basket_amount' ||
            discountType === 'basket_percentage'
        ) {
            handleBasketDiscount();
            return;
        }

        if (~index) {
            const product = getters.items.find(
                product => product.id === productId
            );
            const isMulti = discountType.includes('multi');
            const isModifiers = discountType.includes('modifiers');

            let newDiscountValue;

            if (isMulti) {
                const basePrice = product.price * quantity;
                const modifiersPrice = isModifiers
                    ? sumModifiersPrice(product) * quantity
                    : 0;

                if (
                    discountType.includes('product_percent') ||
                    discountType.includes('category_percent')
                ) {
                    newDiscountValue =
                        (basePrice + modifiersPrice) * (discountValue / 100);
                }

                if (discountType.includes('product_amount')) {
                    newDiscountValue = Math.min(
                        discountValue * 100,
                        basePrice + modifiersPrice
                    );
                }

                commit('UPDATE_PIGGY_PRODUCT_DISCOUNT', {
                    id: productId,
                    value: newDiscountValue,
                    modifierIds: product.modifiers.map(modifier => modifier.id)
                });
            }
        }
    },
    async prefillCart({ dispatch, rootGetters, commit }, payload) {
        commit('setCartItems', { items: [] });
        commit('CLEAR_TOTAL');
        commit('SET_ORDER_ID', null);

        const products = rootGetters['venue/products'];
        const unavailableProducts = [];

        const handleUnavailableProduct = (plu, name, reason, message = '') => {
            unavailableProducts.push({ name, plu });
            console.error(`Product with PLU: ${plu} ${reason}${message}`);
        };

        for (const item of payload.split(',')) {
            const [plu, qty] = item.split(':');

            const product = products.find(product => product.plu === plu);

            if (!product) {
                handleUnavailableProduct(plu, null, `not found`);
                continue;
            }

            if (!product.isAvailable && !product.trackInventory) {
                handleUnavailableProduct(plu, product.name, `not available`);
                continue;
            }

            if (product.trackInventory && !product.inventory) {
                handleUnavailableProduct(plu, product.name, `out of stock`);
                continue;
            }

            if (product.trackInventory && +qty > product.inventory) {
                handleUnavailableProduct(
                    plu,
                    product.name,
                    `insufficient stock`,
                    `: ${product.inventory}, required: ${qty}`
                );
                continue;
            }

            const modifierRequired = product.modifierGroups.find(
                modifierGroup => modifierGroup.min !== 0
            );

            if (modifierRequired) {
                handleUnavailableProduct(
                    plu,
                    product.name,
                    `requires a modifier`
                );
                continue;
            }

            commit('pushProductToCart', {
                product,
                quantityDelta: +qty,
                modifiers: []
            });
        }

        if (unavailableProducts.length > 0) {
            const namedProducts = unavailableProducts.filter(
                product => product.name
            );
            const unnamedCount =
                unavailableProducts.length - namedProducts.length;

            this._vm.$modal.show('item-unavailable-modal', {
                items: namedProducts,
                otherProductsCount: unnamedCount
            });
        }

        dispatch('cartTotalWithDiscount');
    },
    setPickupDate({ commit }, value) {
        commit('SET_PICKUP_DATE', value);
    },
    setPickupTimeSlot({ commit }, value) {
        commit('SET_PICKUP_TIME_SLOT', value);
    },
    setPreOrderDateTimeSlot({ commit }, value) {
        commit('SET_PRE_ORDER_DATE_TIME_SLOT', value);
    },
    applyComoDiscounts({ commit }, payload) {
        commit('RESET_ITEM_DISCOUNT');

        const applyDiscounts = benefits => {
            benefits?.forEach(benefit => {
                benefit.extendedData?.forEach(data => {
                    commit('SET_ITEM_DISCOUNT', {
                        lineId: parseInt(data.item.lineId),
                        quantity: parseInt(data.item.quantity),
                        netAmount: parseInt(data.item.netAmount),
                        discountAmount: Math.abs(data.discount)
                    });
                });
            });
        };

        payload.deals?.forEach(deal => applyDiscounts(deal.benefits));
        payload.redeemAssets?.forEach(asset => applyDiscounts(asset.benefits));
    }
};

export default {
    namespaced: true,
    getters,
    mutations,
    actions,
    state
};
