import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/tailwind.css';

// Plugins
import VueScrollactive from 'vue-scrollactive';
import VModal from 'vue-js-modal';
import Vuelidate from 'vuelidate';
import Notifications from 'vue-notification';
import VueSnip from 'vue-snip';
import VueParallaxJs from 'vue-parallax-js';
import Vue2TouchEvents from 'vue2-touch-events';
import * as Sentry from '@sentry/vue';
import { createHead } from '@unhead/vue';
import { UnheadPlugin } from '@unhead/vue/vue2';

// Utils
import discountCode from './util/discountCode';
import currency from './util/currency';

// Custom plugins
import Growthbook from './plugins/growthbook';
import { i18n } from './plugins/i18n';
import './plugins/dayjs';
import AxiosPlugin from './plugins/axios'; // Import the new axios plugin

// Globally register all `base`-prefixed components
import './components/_globals';

// Init filters (do this early as they're simple)
Vue.filter('discountCode', discountCode);
Vue.filter('currency', currency);

const head = createHead();

// Initialize notifications first since axios error handling uses it
Vue.use(Notifications);

// Initialize Axios with Sentry reference (must happen before we use $axiosRetry)
Vue.use(AxiosPlugin, {
    Sentry,
    store,
    baseURL: import.meta.env.VITE_API_URL,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
});

// Init other UI plugins
Vue.use(UnheadPlugin);
Vue.use(VModal, { dynamic: true, injectModalsContainer: true });
Vue.use(VueScrollactive);
Vue.use(Growthbook);
Vue.use(Vuelidate);
Vue.use(VueSnip);
Vue.use(VueParallaxJs);
Vue.use(Vue2TouchEvents, {
    disableClick: true
});

// Initialize Sentry after axios since we want tracing of all subsequent operations
if (import.meta.env.PROD && import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        Vue,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        attachProps: true,
        logErrors: true,
        // replaysSessionSampleRate: 0.1,
        // replaysOnErrorSampleRate: 1.0,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.httpClientIntegration({
                // This array can contain tuples of `[begin, end]` (both inclusive),
                // single status codes, or a combination of both.
                // default: [[500, 599]]
                failedRequestStatusCodes: [[400, 599]]
            }),
            Sentry.extraErrorDataIntegration()
            // new Sentry.Replay({
            //     // Additional SDK configuration goes in here, for example:
            //     maskAllText: true,
            //     blockAllMedia: true,
            // }),
        ],
        denyUrls: [
            /^safari-extension:\/\//i,
            /^safari-web-extension:\/\//i,
            /^moz-extension:\/\//i,
            /^chrome-extension:\/\//i,
            /moz-extension/i
        ],
        ignoreErrors: ['@webkit-masked-url', /no_open_bills/],
        tracesSampleRate: 0.1
    });
}

Vue.config.productionTip = false;

// Initialize Vue instance last
new Vue({
    router,
    store,
    i18n,
    unhead: head,
    render: h => h(App)
}).$mount('#app');

// Global chunk error handling
window.addEventListener('vite:preloadError', () => {
    // eslint-disable-next-line no-console
    console.error('An error occurred while preloading an asset, reloading');
    window.location.reload();
});
