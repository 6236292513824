import Vue from 'vue';
import Vuex from 'vuex';
import venue from './venue';
import cart from './cart';
import brand from './brand';
import location from './location';
import discount from './discount';
import customer from './customer';
import deliveryAddress from './deliveryAddress';
import storeDesigner from './storeDesigner';
import giftCard from './giftCard';
import basket from './basket';
import bill from './bill';
import delivery from './delivery';
import helpers from './helpers';
import VuexPersistence from 'vuex-persist';
import Cookies from 'js-cookie';

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    key: 'sk_takeaway',
    reducer: state => ({
        customer: {
            firstName: state.customer.firstName,
            lastName: state.customer.lastName,
            phone: state.customer.phone,
            email: state.customer.email,
            policyAgreed: state.customer.policyAgreed,
            // auth is now stored in localStorage with accountId-specific key
            como: {
                transactionId: state.customer.como.transactionId,
                openTime: state.customer.como.openTime
            }
        }
    })
});

const { hostname, pathname } = window.location;
const slug = pathname.split('/')[1];
const prefix = `sk_${hostname}_${slug || 'store'}`;
const cartKey = `${prefix}_cart`;
const billKey = `${prefix}_bill`;
const deliveryAddressKey = `${prefix}_delivery_address`;

if (!Cookies.get(cartKey)) {
    localStorage.removeItem(cartKey);
}

if (!Cookies.get(billKey)) {
    localStorage.removeItem(billKey);
}

if (!Cookies.get(deliveryAddressKey)) {
    localStorage.removeItem(deliveryAddressKey);
}

const cartStorage = new VuexPersistence({
    storage: window.localStorage,
    key: cartKey,
    saveState: (key, state, storage) => {
        Cookies.set(cartKey, 1, { expires: 1 / 288 });
        return storage.setItem(key, JSON.stringify(state));
    },
    reducer: state => ({
        cart: {
            items: state.cart.items,
            fulfillmentMethod: state.cart.fulfillmentMethod,
            totalWithDiscount: state.cart.totalWithDiscount,
            deliveryFee: state.cart.deliveryFee,
            selectedTip: state.cart.selectedTip,
            serviceCharge: state.cart.serviceCharge,
            piggyProductDiscount: state.cart.piggyProductDiscount,
            zoneDeliveryMinCost: state.cart.zoneDeliveryMinCost,
            piggyRewardSelected: state.cart.piggyRewardSelected,
            pickupDate: state.cart.pickupDate,
            pickupTimeSlot: state.cart.pickupTimeSlot,
            preOrderDateTime: state.cart.preOrderDateTime,
            ageOver18Confirmed: state.cart.ageOver18Confirmed,
            calculationMethod: state.cart.calculationMethod,
            noOfDiners: state.cart.noOfDiners,
            poNumber: state.cart.poNumber // Add PO number to persistence
        },
        discount: {
            details: state.discount.details
        },
        delivery: state.delivery
    })
});

const billStorage = new VuexPersistence({
    storage: window.localStorage,
    key: billKey,
    saveState: (key, state, storage) => {
        Cookies.set(billKey, 1, { expires: 1 / 144 });
        return storage.setItem(key, JSON.stringify(state));
    },
    reducer: state => ({
        bill: {
            id: state.bill.id
        }
    })
});

const deliveryAddressStorage = new VuexPersistence({
    storage: window.localStorage,
    key: deliveryAddressKey,
    saveState: (key, state, storage) => {
        Cookies.set(deliveryAddressKey, 1, { expires: 1 / 288 });
        return storage.setItem(key, JSON.stringify(state));
    },
    reducer: state => ({
        deliveryAddress: state.deliveryAddress
    })
});

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        venue,
        cart,
        brand,
        location,
        discount,
        customer,
        deliveryAddress,
        storeDesigner,
        giftCard,
        basket,
        helpers,
        bill,
        delivery
    },
    plugins: [
        vuexLocal.plugin,
        cartStorage.plugin,
        billStorage.plugin,
        deliveryAddressStorage.plugin
    ]
});
