export const isMatchingProduct = (product, id, modifiers) => {
    return (
        product.id === id &&
        (modifiers.length === 0 ||
            (product.modifierIds.length &&
                product.modifierIds.every(
                    (id, index) => id === modifiers[index]
                )))
    );
};

export const getDiscountValue = reward => {
    if (reward?.attributes?.storekit_discount_value) {
        return reward.attributes.storekit_discount_value;
    } else {
        return (
            reward.storekit_discount_value ||
            reward.reward.storekit_discount_value
        );
    }
};

export const getDiscountType = reward => {
    if (reward?.attributes?.storekit_discount_type) {
        return reward.attributes.storekit_discount_type;
    } else {
        return (
            reward.storekit_discount_type ||
            reward.reward.storekit_discount_type
        );
    }
};

export default { isMatchingProduct, getDiscountValue, getDiscountType };
