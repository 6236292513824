import { FetchRequest, WebSocketTransport } from 'ably/modular';
import Push from 'ably/push';
import { Realtime } from 'ably';

const ably = new Realtime({
    key: import.meta.env.VITE_ABLY_API_KEY,
    pushServiceWorkerUrl: '/ably-push-service-worker.js',
    plugins: {
        FetchRequest,
        WebSocketTransport,
        Push
    }
});

export default ably;
