<template>
    <div>
        <div class="relative">
            <input
                type="text"
                name="autocomplete"
                v-model="formattedAddress"
                autocomplete="shipping postal-code"
                ref="autocomplete"
                style="height: 50px"
                class="email-input bg-secondary block w-full rounded border px-4 pt-3 text-sm"
                :class="{
                    'ring ring-red-200 border-red-300': hasError
                }"
                required
                @change="$emit('onChange')"
                @keydown="handleKeyDown"
                @blur="hideNoAddressDiv"
            />
            <label
                for="autocomplete"
                class="email-input-label pointer-events-none absolute w-full select-none rounded px-4 pt-1"
            >
                {{ $t('fulfillmentMethodModal.autocompletePrompt') }}
            </label>
            <div
                class="absolute inset-y-0 right-2 flex cursor-pointer items-center pl-3 text-gray-600"
                :class="{
                    loader: hasAccessToGeolocation && isLocationLoading
                }"
                @click="geolocation"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    width="26"
                    height="26"
                    viewBox="0 0 24 24"
                    class="transition-opacity ease-in-out duration-300 hover:opacity-100"
                    :class="{ 'opacity-25': formattedAddress.length > 0 }"
                >
                    <circle cx="12" cy="12" r="4" />
                    <path
                        d="M13,4.069V2h-2v2.069C7.389,4.522,4.523,7.389,4.069,11H2v2h2.069c0.454,3.611,3.319,6.478,6.931,6.931V22h2v-2.069 c3.611-0.453,6.478-3.319,6.931-6.931H22v-2h-2.069C19.478,7.389,16.611,4.522,13,4.069z M12,18c-3.309,0-6-2.691-6-6s2.691-6,6-6 s6,2.691,6,6S15.309,18,12,18z"
                    />
                </svg>
            </div>
        </div>
        <div class="mt-6" v-if="showMap && hasMap">
            <static-map :coordinates="coordinates" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import StaticMap from '@/components/elements/StaticMap';

export default {
    name: 'Autocomplete',
    components: { StaticMap },
    props: {
        hasGeolocate: {
            type: Boolean,
            default: true
        },
        hasMap: {
            type: Boolean,
            default: true
        },
        hasError: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            autocomplete: null,
            API_KEY: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
            isLocationLoading: false,
            hasAccessToGeolocation: null
        };
    },
    created() {
        window.initMap = this.initMap;
    },
    mounted() {
        if (!window.isScriptLoaded) {
            this.loadScript();
        } else {
            this.$nextTick(() => {
                this.initMap();
            });
        }
    },
    beforeDestroy() {
        document.querySelectorAll('[class*="pac-"]').forEach(el => el.remove());

        if (this.autocomplete) {
            window.removeEventListener('resize', this.getInputPosition);
        }
    },
    computed: {
        ...mapState('venue', {
            venueCountry: state => state.venue.address.country
        }),
        ...mapGetters({
            deliveryAddress: 'deliveryAddress/address',
            getFormattedAddress: 'deliveryAddress/formattedAddress'
        }),
        formattedAddress: {
            get() {
                return this.getFormattedAddress;
            },
            set(value) {
                this.setFormattedAddress(value);
            }
        },
        coordinates: {
            get() {
                return this.deliveryAddress.coordinates;
            },
            set(value) {
                this.setCoordinates(value);
            }
        },
        showMap() {
            return !!(this.coordinates.lat && this.coordinates.lng);
        }
    },
    watch: {
        showMap() {
            setTimeout(() => {
                this.$nextTick(() => {
                    this.getInputPosition();
                });
            }, 300);
        }
    },
    methods: {
        ...mapActions({
            setStreet: 'deliveryAddress/setStreet',
            setBuildingNumber: 'deliveryAddress/setBuildingNumber',
            setCity: 'deliveryAddress/setCity',
            setPostCode: 'deliveryAddress/setPostCode',
            setCountry: 'deliveryAddress/setCountry',
            setFormattedAddress: 'deliveryAddress/setFormattedAddress',
            setCoordinates: 'deliveryAddress/setCoordinates',
            setUserConfirmedCoordinates:
                'deliveryAddress/setUserConfirmedCoordinates'
        }),
        loadScript() {
            const script = document.createElement('script');

            script.src = `https://maps.googleapis.com/maps/api/js?key=${this.API_KEY}&libraries=places&callback=initMap`;
            script.defer = true;
            script.async = true;

            document.head.appendChild(script);

            window.isScriptLoaded = true;
        },
        async initMap() {
            setTimeout(() => this.createAutocomplete(), 300);
        },
        createAutocomplete() {
            const autocompleteInput = this.$refs.autocomplete;

            const types =
                this.venueCountry === 'GB' ? ['geocode'] : ['address'];

            const componentRestrictions = { country: this.venueCountry };

            this.autocomplete = new window.google.maps.places.Autocomplete(
                autocompleteInput,
                {
                    componentRestrictions,
                    types,
                    fields: [
                        'address_components',
                        'geometry',
                        'formatted_address'
                    ]
                }
            );

            autocompleteInput.placeholder = '';

            this.createNoAddressesDiv();

            this.autocomplete.addListener('place_changed', this.onPlaceChanged);

            window.addEventListener('resize', this.getInputPosition);

            autocompleteInput.addEventListener('input', () => {
                setTimeout(() => {
                    const div = document.querySelector('.no-location');
                    const suggestions = document.querySelectorAll('.pac-item');

                    if (
                        !suggestions.length &&
                        autocompleteInput.value.length > 1
                    ) {
                        div.classList.add('pac-container');
                        div.style.display = 'block';
                    }

                    if (suggestions.length > 1) {
                        div.style.display = 'none';
                    }
                }, 300);
            });
        },
        handleKeyDown(event) {
            const autocompleteInput = this.$refs.autocomplete;
            const suggestionSelected =
                document.querySelector('.pac-item-selected');
            document.querySelector('.no-location').style.display = 'none';

            if (event.keyCode === 13 && !suggestionSelected) {
                const simulatedDownArrow = new KeyboardEvent('keydown', {
                    keyCode: 40,
                    which: 40
                });

                autocompleteInput.dispatchEvent(simulatedDownArrow);
            }
        },
        hideNoAddressDiv() {
            document.querySelector('.no-location').style.display = 'none';
        },
        getInputPosition() {
            const div = document.querySelector('.no-location');
            const autocompleteInput = this.$refs.autocomplete;

            if (!autocompleteInput) {
                return;
            }

            const { top, left } = autocompleteInput.getBoundingClientRect();

            const inputTop = top + window.scrollY;
            const inputLeft = left + window.scrollX;

            div.style.top = inputTop + autocompleteInput.offsetHeight + 'px';
            div.style.left = inputLeft + 'px';
            div.style.width = autocompleteInput.offsetWidth - 4 + 'px';
        },
        createNoAddressesDiv() {
            const div = document.createElement('div');
            div.classList.add('no-location', 'pac-logo', 'hdpi');
            document.body.appendChild(div);

            const noAddressesDiv = document.createElement('div');
            noAddressesDiv.textContent = this.$t(
                'fulfillmentMethodModal.noAddressesFound'
            );
            noAddressesDiv.style.padding = '15px';

            div.appendChild(noAddressesDiv);

            this.getInputPosition();
        },
        onPlaceChanged() {
            this.$store.commit('deliveryAddress/clearAddress');

            const place = this.autocomplete.getPlace();

            if (!place.geometry) {
                return;
            }

            this.coordinates = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            };
            this.setAddress(place);
            this.$emit('place-selected', place);
        },
        async geolocation() {
            if (!navigator.geolocation) {
                this.hasAccessToGeolocation = false;

                return false;
            }

            this.hasAccessToGeolocation = true;
            this.isLocationLoading = true;

            navigator.geolocation.getCurrentPosition(
                async position => {
                    this.coordinates = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };

                    try {
                        const address = await this.$axios.get(
                            '/geo/address-by-coords',
                            { params: this.coordinates }
                        );

                        this.hasAccessToGeolocation = true;
                        this.setAddress(address.data.results[0]);
                    } catch (error) {
                        this.hasAccessToGeolocation = false;

                        throw new Error(`API ${error}`);
                    } finally {
                        this.isLocationLoading = false;
                    }
                },
                error => {
                    this.hasAccessToGeolocation = false;
                    console.error('Geolocation error:', error.message);
                },
                { timeout: 5000 }
            );
        },
        setAddress(address) {
            this.setFormattedAddress(address.formatted_address);

            let number = '';

            for (const component of address.address_components) {
                const componentType = component.types[0];

                switch (componentType) {
                    case 'street_number':
                        number = component.long_name;
                        break;
                    case 'subpremise':
                        this.setBuildingNumber(component.long_name);
                        break;
                    case 'route':
                        this.setStreet(`${number} ${component.long_name}`);
                        break;
                    case 'postal_code':
                        this.setPostCode(component.long_name);
                        break;
                    case 'locality':
                        this.setCity(component.long_name);
                        break;
                    case 'postal_town':
                        this.setCity(component.long_name);
                        break;
                    case 'country':
                        this.setCountry(component.short_name);
                        break;
                    default:
                        break;
                }
            }
            this.$emit('place-selected');
            this.setUserConfirmedCoordinates(false);
        }
    }
};
</script>

<style>
.pac-item {
    padding: 4px 4px;
    font-size: 12px;
    color: var(--color-text-primary);
}

.no-location {
    position: absolute;
    z-index: 999;
    display: none;
}
</style>
<style src="@/assets/css/pulse-loading-animation_scoped.css" scoped></style>
