export default function currency(code) {
    const currencyMap = {
        GBP: {
            name: 'Pound Sterling',
            fractionSize: 2,
            symbol: '£',
            thousandsSeparator: ',',
            fractionSeparator: '.',
            symbolPosition: 'front'
        },
        EUR: {
            name: 'Euro',
            fractionSize: 2,
            symbol: '€',
            thousandsSeparator: '.',
            fractionSeparator: ',',
            symbolPosition: 'front'
        },
        USD: {
            name: 'US Dollar',
            fractionSize: 2,
            symbol: '$',
            thousandsSeparator: ',',
            fractionSeparator: '.',
            symbolPosition: 'front'
        },
        MAD: {
            name: 'Moroccan dirham',
            fractionSize: 2,
            symbol: 'د.م',
            thousandsSeparator: ',',
            fractionSeparator: '.',
            symbolPosition: 'front'
        },
        AED: {
            name: 'Arab Emirates Dirham',
            fractionSize: 2,
            symbol: 'د.إ',
            thousandsSeparator: ',',
            fractionSeparator: '.',
            symbolPosition: 'front'
        },
        SEK: {
            name: 'Swedish Krona',
            fractionSize: 2,
            symbol: 'kr',
            thousandsSeparator: ' ',
            fractionSeparator: ',',
            symbolPosition: 'back'
        },
        NOK: {
            name: 'Norwegian krone',
            fractionSize: 2,
            symbol: ' kr',
            thousandsSeparator: ' ',
            fractionSeparator: ',',
            symbolPosition: 'back'
        },
        ZAR: {
            name: 'South African Rand',
            fractionSize: 2,
            symbol: 'R',
            thousandsSeparator: ' ',
            fractionSeparator: ',',
            symbolPosition: 'front'
        },
        CHF: {
            name: 'Swiss franc',
            fractionSize: 2,
            symbol: 'CHF',
            thousandsSeparator: "'",
            fractionSeparator: ',',
            symbolPosition: 'back'
        },
        AUD: {
            name: 'Australian Dollar',
            fractionSize: 2,
            symbol: '$',
            thousandsSeparator: ',',
            fractionSeparator: '.',
            symbolPosition: 'front'
        },
        CAD: {
            name: 'Canadian Dollar',
            fractionSize: 2,
            symbol: '$',
            thousandsSeparator: ',',
            fractionSeparator: '.',
            symbolPosition: 'front'
        },
        DKK: {
            name: 'Danish Krone',
            fractionSize: 2,
            symbol: 'kr',
            thousandsSeparator: '.',
            fractionSeparator: ',',
            symbolPosition: 'front'
        },
        HUF: {
            name: 'Hungarian Forint',
            fractionSize: 0,
            symbol: 'Ft',
            thousandsSeparator: ' ',
            fractionSeparator: ',',
            symbolPosition: 'back'
        },
        ISK: {
            name: 'Icelandic Króna',
            fractionSize: 0,
            symbol: 'kr',
            thousandsSeparator: '.',
            fractionSeparator: ',',
            symbolPosition: 'back'
        },
        PLN: {
            name: 'Polish Złoty',
            fractionSize: 2,
            symbol: 'zł',
            thousandsSeparator: ' ',
            fractionSeparator: ',',
            symbolPosition: 'back'
        },
        RON: {
            name: 'Romanian Leu',
            fractionSize: 2,
            symbol: 'lei',
            thousandsSeparator: '.',
            fractionSeparator: ',',
            symbolPosition: 'back'
        },
        UAH: {
            name: 'Ukrainian Hryvnia',
            fractionSize: 2,
            symbol: '₴',
            thousandsSeparator: ' ',
            fractionSeparator: ',',
            symbolPosition: 'front'
        }
    };

    return currencyMap[code || 'GBP'];
}
