import { TrackingEventsEnum } from "@/enums";

function facebookPixel(config = {}) {
    return {
        name: 'facebookPixel',
        config,
        initialize: ({ config }) => {
            const { apiKey } = config;

            if (!apiKey) {
                return
            }

            if (!window.fbq) {
                const content = `!function(f,b,e,v,n,t,s)
                                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                                          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                                    n.queue=[];t=b.createElement(e);t.async=!0;
                                    t.src=v;s=b.getElementsByTagName(e)[0];
                                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                                          'https://connect.facebook.net/en_US/fbevents.js');
                                  fbq('init', '${apiKey}');
                                  fbq('track', 'PageView');`;
                let script = document.createElement('script');
                script.text = content;
                document.body.appendChild(script);
            }
        },
        page: () => {},
        track: ({ payload }) => {
            const { event, properties } = payload;

            switch (event) {
                case TrackingEventsEnum.ADD_TO_CART:
                    window.fbq('track', 'AddToCart', {
                        content_ids: [properties.id],
                        content_name: properties.name,
                        currency: properties.currency,
                        value: (properties.price / 100).toFixed(2),
                        content_type: 'product'
                    });

                    break;
                case TrackingEventsEnum.ORDER_COMPLETED:
                    window.fbq('track', 'Purchase', {
                        currency: properties.currency,
                        value: (properties.total / 100).toFixed(2),
                        content_type: 'product'
                    });

                    break;
                case TrackingEventsEnum.STARTED_CHECKOUT:
                    window.fbq('track', 'InitiateCheckout', {
                        currency: properties.currency,
                        value: properties.value
                    });

                    break;
                default:
                    break;
            }
        },
        identify: () => {},
        loaded: () => {
            return  !!window.fbq
        }
    }
}

export default facebookPixel
