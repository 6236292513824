<template>
    <fieldset  class="flex items-center w-full px-1 pb-4 pt-2 text-sm">
        <div class="flex pr-2">
            <input
                v-model="cutlery"
                id="cutlery"
                name="cutlery"
                class="optin-checkbox form-checkbox h-4 w-4 cursor-pointer rounded border-gray-200 transition duration-150 ease-in-out focus:border-blue-200 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                type="checkbox"
            />
        </div>

        <label class="text-price" for="cutlery">
          <span>
              {{  $t('cutleryCheckbox.label') }}
          </span>
        </label>
    </fieldset>
</template>

<script>

export default {
    name: 'CutleryCheckbox',
    mounted() {
        this.cutlery = true;
    },
    computed: {
        cutlery: {
            get() {
                return this.$store.state.cart.cutlery;
            },
            set(value) {
                this.$store.commit(
                    'cart/SET_CUTLERY',
                    value
                );
            }
        }
    }
};
</script>

<style scoped>
.optin-checkbox {
    color: #172d48;
    color: var(--color-bg-button, #292a31);
}
</style>
