<template>
    <div class="scrolling-wrapper-flexbox no-scrollbar -mb-4 py-4 px-3">
        <vue-content-loading
            :height="94"
            :width="200"
            :speed="2"
            class="card rounded-md"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
        >
            <rect x="0%" y="0" rx="3" ry="3" width="100%" height="100%" />
        </vue-content-loading>
        <vue-content-loading
            :height="94"
            :width="200"
            :speed="2"
            class="card rounded-md"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
        >
            <rect x="0%" y="0" rx="3" ry="3" width="100%" height="100%" />
        </vue-content-loading>
        <vue-content-loading
            :height="94"
            :width="200"
            :speed="2"
            class="card rounded-md"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
        >
            <rect x="0%" y="0" rx="3" ry="3" width="100%" height="100%" />
        </vue-content-loading>
    </div>
</template>

<script>
import VueContentLoading from 'vue-content-loading';

export default {
    name: 'MenuOffersSkeleton',
    components: {
        VueContentLoading
    }
};
</script>
