<template>
    <modal
        ref="rewardsModal"
        height="auto"
        name="piggy-rewards-modal"
        width="525"
        :maxWidth="575"
        :adaptive="true"
        :scrollable="true"
        :clickToClose="true"
        :reset="true"
        transition="pop-out"
        :pivotY="pivotY"
    >
        <div class="relative bg-secondary flex flex-col">
            <h4
                class="text-heading border-b border-solid border-gray-200 px-4 py-2 font-heading text-lg font-bold"
            >
                {{ title }}
            </h4>
            <div class="absolute right-0 top-0 pr-4 pt-2">
                <button
                    type="button"
                    aria-label="Hide basket"
                    class="text-primary focus:outline-none transition duration-150 ease-in-out hover:text-gray-500 focus:text-gray-500"
                    @click="closeModal"
                >
                    <close-icon />
                </button>
            </div>
            <piggy-rewards-list @update-title="title = $event" />
        </div>
    </modal>
</template>

<script>
import isMobile from '@/helpers/isMobileDevice';
import CloseIcon from '@/components/icons/CloseIcon';
import PiggyRewardsList from '@/components/PiggyRewardsList';

export default {
    name: 'rewards-modal',
    components: { CloseIcon, PiggyRewardsList },
    data() {
        return {
            title: 'Rewards'
        };
    },
    watch: {
        title() {
            this.resizeModal();
        }
    },
    computed: {
        pivotY() {
            return isMobile() ? 1 : 0.5;
        }
    },
    methods: {
        closeModal() {
            this.$modal.hide('piggy-rewards-modal');
            this.title = 'Rewards';
        },
        resizeModal() {
            setTimeout(() => {
                const windowHeight = window.innerHeight;
                const modal = this.$refs.rewardsModal.$refs.modal;

                const offset =
                    (windowHeight - modal.clientHeight) / (isMobile ? 1 : 2);

                modal.style.top = `${offset}px`;
            }, 1000);
        }
    }
};
</script>
