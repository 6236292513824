<template>
    <modal
        ref="cateringFulfillmentModal"
        id="catering-fulfillment-modal"
        name="catering-fulfillment-method-modal"
        height="auto"
        width="530"
        :maxWidth="530"
        :adaptive="true"
        :scrollable="true"
        :clickToClose="false"
        :pivotY="pivotY"
        :reset="true"
    >
        <div
            class="item-modal-content rounded-md bg-primary relative flex flex-col w-full"
        >
            <div
                class="item-modal-details flex-auto p-6 sm:p-8 sm:pb-6 flex flex-col"
            >
                <h4 class="pb-1 text-heading font-heading text-xl font-bold">
                    {{ $t('fulfillmentMethodModal.chooseDeliveryMethod') }}
                </h4>

                <div
                    class="flex justify-center mb-6 bg-gray-50 p-1.5 rounded-lg space-x-2 mt-2 text-sm font-semibold"
                >
                    <button
                        @click="
                            setActive(FulfillmentMethodsEnum.CATERING_DELIVERY)
                        "
                        :class="[
                            'w-1/2 text-center p-3 rounded-lg font-button transition-colors duration-300',
                            activeTab ===
                            FulfillmentMethodsEnum.CATERING_DELIVERY
                                ? 'bg-button text-white'
                                : 'text-gray-600'
                        ]"
                    >
                        {{ $t('fulfillmentMethods.CateringDelivery') }}
                    </button>
                    <button
                        @click="
                            setActive(FulfillmentMethodsEnum.CATERING_PICKUP)
                        "
                        :class="[
                            'w-1/2 text-center p-3 rounded-lg font-button transition-colors duration-300 ',
                            activeTab === FulfillmentMethodsEnum.CATERING_PICKUP
                                ? 'bg-button text-white'
                                : 'text-gray-600'
                        ]"
                    >
                        {{ $t('fulfillmentMethods.CateringPickup') }}
                    </button>
                </div>

                <div
                    v-if="
                        activeTab === FulfillmentMethodsEnum.CATERING_DELIVERY
                    "
                    class="relative w-full order-2 md:order-1"
                >
                    <div
                        class="relative mb-2 md:my-4 w-full"
                        :class="{ spinner: isLoading }"
                    >
                        <autocomplete
                            v-if="hasAutocomplete"
                            @onChange="resetDistance"
                            :hasMap="false"
                            :hasError="$v.postCode.$error"
                        />

                        <input
                            v-else
                            type="text"
                            name="postCode"
                            autocomplete="shipping postal-code"
                            :value="postCode"
                            @input="
                                postCode = $event.target.value
                                    .toUpperCase()
                                    .trim()
                            "
                            @change="resetDistance"
                            id="postCode"
                            style="height: 50px"
                            class="postcode-input block w-full rounded border px-4 pt-3 text-sm text-gray-800 shadow-sm"
                            :class="{
                                'ring ring-red-200 border-red-300':
                                    $v.postCode.$error
                            }"
                            required
                        />

                        <label
                            v-if="!hasAutocomplete"
                            for="postCode"
                            class="sk-form-label postcode-input-label pointer-events-none absolute select-none rounded px-4 pt-1"
                        >
                            {{ $t('fulfillmentMethodModal.enterPostCode') }}
                        </label>

                        <span
                            class="block mt-3 text-red-800 text-xs font-bold"
                            v-if="$v.postCode.$error"
                            v-html="errorMessage"
                        >
                        </span>
                    </div>
                </div>

                <div class="relative w-full order-3 md:order-2">
                    <input
                        v-model="cateringNoOfDiners"
                        type="number"
                        name="noOfDiners"
                        id="noOfDiners"
                        autocomplete="off"
                        style="height: 50px"
                        class="email-input block pt-3 px-4 w-full text-sm bg-secondary border rounded"
                        :class="{
                            'ring ring-red-200 border-red-300':
                                $v.cateringNoOfDiners.$error
                        }"
                        required
                    />
                    <label
                        for="noOfDiners"
                        class="email-input-label absolute pt-1 px-4 rounded pointer-events-none select-none"
                    >
                        {{ $t('cateringFulfillmentMethodModal.noOfDiners') }}
                    </label>
                </div>
                <PreOrder
                    v-if="isPreOrder"
                    class="order-1 md:order-3 md:mt-4 mb-4 md:mb-0"
                    :v="$v"
                    :key="activeTab"
                    :venueId="venueId"
                    :availablePreorderDays="selectedMethodPreorderDays"
                    :fulfillmentMethod="activeTab"
                    :blockAutoDatePreFill="true"
                    :disableAll="disableAll"
                    :size="isMobile ? 'small' : 'medium'"
                />
            </div>
            <div class="px-8 py-6">
                <base-button
                    class="py-4"
                    size="sm"
                    :class="{ 'text-button spinner': isLoading }"
                    @clicked="startOrder(activeTab)"
                    :button-text="
                        $t('cateringFulfillmentMethodModal.startOrder')
                    "
                ></base-button>
            </div>
        </div>
    </modal>
</template>

<script>
import isMobile from '@/helpers/isMobileDevice';
import { FulfillmentMethodsEnum } from '@/enums';
import {
    helpers,
    required,
    requiredIf,
    requiredUnless
} from 'vuelidate/lib/validators';
import Autocomplete from '@/components/formElements/Autocomplete';
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import PreOrder from '@/components/formElements/PreOrder';

// https://ideal-postcodes.co.uk/guides/postcode-validation#simpleregularexpression
const postcodeRegex = helpers.regex(
    'alpha',
    /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i
);

export default {
    name: 'catering-fulfillment-method-modal',
    components: {
        Autocomplete,
        PreOrder
    },
    data() {
        return {
            isLoading: false,
            activeTab: '',
            disableAll: false,
            showPostcode: false,
            distance: null,
            distanceIsTooFar: false,
            errorMessage: null,
            hasPostCodeError: false,
            FulfillmentMethodsEnum
        };
    },
    validations() {
        return {
            cateringNoOfDiners: {
                required,
                minValue: value => value > 0
            },
            pickupDate: {
                required: requiredUnless('!isPreOrder')
            },
            pickupTimeSlot: {
                required: requiredUnless('!isPreOrder')
            },
            preOrderDateTime: {
                required: requiredUnless('!isPreOrder')
            },
            postCode: {
                required: requiredIf(function () {
                    return this.country === 'GB';
                }),
                ...(this.country === 'GB' && !this.hasAutocomplete
                    ? { postcodeRegex }
                    : false),
                hasError() {
                    return !this.hasPostCodeError;
                }
            }
        };
    },
    computed: {
        ...mapState({
            country: state => state.venue.venue.address.country,
            acceptsPreOrders: state => state.venue.venue.acceptsPreOrders,
            venueId: state => state.venue.venue.id,
            fulfillmentMethod: state => state.cart.fulfillmentMethod,
            availablePreorderDays: state =>
                state.venue.venue.availablePreorderDays,
            noOfDiners: state => state.cart.noOfDiners
        }),
        ...mapGetters({
            deliveryAddress: 'deliveryAddress/address',
            formattedAddress: 'deliveryAddress/formattedAddress',
            pickupDate: 'cart/getPickupDate',
            pickupTimeSlot: 'cart/getPickupTimeSlot',
            preOrderDateTime: 'cart/getPreOrderDateTimeSlot'
        }),
        cateringNoOfDiners: {
            get() {
                return this.noOfDiners;
            },
            set(value) {
                this.setNoOfDiners(value);
            }
        },
        selectedMethodPreorderDays() {
            if (!this.availablePreorderDays) {
                return [];
            }

            if (this.activeTab === FulfillmentMethodsEnum.CATERING_DELIVERY) {
                return this.availablePreorderDays.deliveryDates;
            }

            return this.availablePreorderDays.pickupDates;
        },
        isMobile() {
            return isMobile();
        },
        pivotY() {
            return this.isMobile ? 1 : 0.5;
        },
        isPreOrder() {
            return (
                this.acceptsPreOrders &&
                this.$store.state.venue.venue.pickupSlots.length > 0
            );
        },
        postCode: {
            get() {
                return this.deliveryAddress.postCode;
            },
            set(value) {
                this.setPostCode(value);
            }
        },
        hasDeliveryZones() {
            return !!(
                this.$store.state.venue.venue.deliveryZones &&
                this.$store.state.venue.venue.deliveryZones.length > 0
            );
        },
        deliveryZones() {
            if (!this.hasDeliveryZones) {
                return null;
            }

            return this.$store.state.venue.venue.deliveryZones;
        },
        venueMaxDistance() {
            if (!this.hasDeliveryZones) {
                return null;
            }

            return Math.max.apply(
                Math,
                this.$store.state.venue.venue.deliveryZones.map(
                    zone => zone.radius
                )
            );
        },
        isDeliveryOnly() {
            return (
                this.$store.state.venue.venue.acceptsDelivery &&
                !this.$store.state.venue.venue.acceptsPickup
            );
        },
        coordinates: {
            get() {
                return this.deliveryAddress.coordinates;
            }
        },
        venuePostcode() {
            if (!this.$store.state.venue.venue.address) {
                return false;
            }

            return this.$store.state.venue.venue.address.postCode;
        },
        hasAutocomplete() {
            if (this.country !== 'GB') {
                return true;
            }

            return this.$growthbook.isOn('address_autocomplete');
        },
        hasDeliveryZonesV2() {
            return this.$growthbook.isOn('delivery_zones_v2');
        }
    },
    mounted() {
        this.setActive(FulfillmentMethodsEnum.CATERING_DELIVERY);
    },
    watch: {
        activeTab() {
            this.$v.$reset();
            this.resetPickupAndPreOrdersDates();
        }
    },
    methods: {
        ...mapMutations({
            setFulfillmentMethod: 'cart/setFulfillmentMethod',
            resetPickupAndPreOrdersDates:
                'cart/RESET_PICKUP_AND_PRE_ORDER_DATES',
            setNoOfDiners: 'cart/SET_NUMBER_OF_DINERS'
        }),
        ...mapActions({
            setPostCode: 'deliveryAddress/setPostCode',
            setPickupDate: 'cart/setPickupDate',
            setPickupTimeSlot: 'cart/setPickupTimeSlot',
            setPreOrderDateTimeSlot: 'cart/setPreOrderDateTimeSlot'
        }),
        setActive(option) {
            this.activeTab = option;
        },
        async startOrder(fulfilmentMethod) {
            this.isLoading = true;

            if (!this.validate()) {
                this.isLoading = false;

                return false;
            }

            if (
                this.activeTab === FulfillmentMethodsEnum.CATERING_DELIVERY &&
                !(await this.getDistance())
            ) {
                return false;
            }

            if (this.activeTab === FulfillmentMethodsEnum.CATERING_PICKUP) {
                this.$store.commit('deliveryAddress/clearAddress');
            }

            setTimeout(() => {
                this.$store.commit('cart/setDeliveryTime', 0);
                this.setFulfillmentMethod(fulfilmentMethod);
                this.isLoading = false;
            }, 350);

            this.$modal.hide('catering-fulfillment-method-modal');
        },
        handleTypeChange(value) {
            this.activeTab = value;
        },
        validate() {
            return !(this.isPreOrder && !this.pickupDateValidated());
        },
        pickupDateValidated() {
            this.$v.$touch();

            if (this.activeTab === FulfillmentMethodsEnum.CATERING_PICKUP) {
                if (
                    this.$v.pickupDate.$invalid ||
                    this.$v.pickupTimeSlot.$invalid ||
                    this.$v.cateringNoOfDiners.$invalid
                ) {
                    return false;
                }
            } else if (
                this.activeTab === FulfillmentMethodsEnum.CATERING_DELIVERY
            ) {
                if (
                    this.$v.preOrderDateTime.$invalid ||
                    this.$v.postCode.$invalid ||
                    this.$v.cateringNoOfDiners.$invalid
                ) {
                    return false;
                }
            }

            return true;
        },
        resetDistance() {
            this.hasPostCodeError = false;
            this.errorMessage = null;
            this.distanceIsTooFar = false;
        },
        async getDistance() {
            this.isLoading = true;

            let params = {
                origins: `${this.$store.state.venue.venue.address.street}, ${this.$store.state.venue.venue.address.city}, ${this.venuePostcode}, ${this.$store.state.venue.venue.address.country}`,
                destinations: this.hasAutocomplete
                    ? this.formattedAddress
                    : `${this.postCode}, ${this.country}`,
                units: 'imperial',
                departure_time: 'now',
                hasAutocomplete: this.hasAutocomplete,
                postCode: this.postCode,
                country: this.country
            };

            if (
                this.deliveryAddress.coordinates &&
                this.deliveryAddress.coordinates.lat &&
                this.deliveryAddress.coordinates.lng
            ) {
                params.destinations = `${this.coordinates.lat},${this.coordinates.lng}`;
            }
            try {
                if (this.hasDeliveryZonesV2) {
                    return await this.geoDistanceV2(params);
                } else {
                    const { data, status } = await this.$axios.get(
                        `/geo/distance`,
                        {
                            params
                        }
                    );

                    if (status !== 200) {
                        this.distanceIsTooFar = true;
                        this.hasPostCodeError = true;
                        this.errorMessage = this.$t(
                            'fulfillmentMethodModal.errors.outOfRange'
                        );

                        return;
                    }

                    if (data.status === 'REQUEST_DENIED') {
                        console.error('REQUEST_DENIED', data.error_message);
                        this.isLoading = false;

                        return;
                    }

                    if (
                        data.status === 'INVALID_REQUEST' ||
                        (data.rows &&
                            data.rows.length &&
                            data.rows[0].elements &&
                            data.rows[0].elements.length &&
                            data.rows[0].elements[0].status !== 'OK')
                    ) {
                        this.errorMessage = this.$t(
                            'fulfillmentMethodModal.errors.notValidPostcode'
                        );
                        this.hasPostCodeError = true;

                        return;
                    }

                    const distance =
                        data.rows[0]?.elements[0]?.distance?.value || 0;
                    const duration =
                        data.rows[0]?.elements[0]?.duration?.value || 0;

                    if (distance * 0.000621371 > this.venueMaxDistance) {
                        this.distanceIsTooFar = true;
                        this.hasPostCodeError = true;
                        this.errorMessage = this.$t(
                            'fulfillmentMethodModal.errors.outOfRange'
                        );
                        return;
                    }

                    this.distanceIsTooFar = false;

                    this.$store.commit(
                        'cart/setDeliveryTime',
                        parseInt(duration / 60)
                    );

                    if (this.hasDeliveryZones) {
                        const { deliveryFee } = this.deliveryZones.find(
                            zone => {
                                return zone.radius > distance * 0.000621371;
                            }
                        );

                        this.$store.commit(
                            'cart/setDeliveryFeeFromZone',
                            deliveryFee
                        );
                    }
                }

                return true;
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        async geoDistanceV2(params) {
            const { data } = await this.$axios.get(
                `/venues/${this.venueId}/delivery-fee`,
                {
                    params
                }
            );

            if (!data || data === 'ZERO_RESULTS') {
                this.distanceIsTooFar = true;
                this.hasPostCodeError = true;
                this.errorMessage = this.$t(
                    'fulfillmentMethodModal.errors.outOfRange'
                );

                return;
            }

            this.distanceIsTooFar = false;

            this.$store.commit('cart/setDeliveryTime', parseInt(data.duration));
            this.$store.commit('cart/setDeliveryFeeFromZone', data.deliveryFee);
            this.$store.commit(
                'cart/SET_ZONE_DELIVERY_MIN_COST',
                data.minDeliveryAmount
            );

            return true;
        }
    }
};
</script>

<style>
.v--modal-overlay[data-modal='catering-fulfillment-method-modal'] {
    background: rgba(0, 0, 0, 0.5);
}

.v--modal-overlay[data-modal='catering-fulfillment-method-modal']
    .v--modal-box {
    overflow: visible;
}
</style>

<style src="@/assets/css/fulfillment-method-modal_scoped.css" scoped></style>
