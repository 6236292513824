<template>
    <modal
        id="item-modal"
        name="item-modal"
        height="auto"
        width="550"
        :maxWidth="550"
        :adaptive="true"
        :scrollable="true"
        :reset="true"
        transition="pop-out"
        :class="{ 'mobile-item-modal': isMobile }"
        :pivotY="isMobile ? 1 : 0.5"
        :styles="{ marginTop }"
        class="modal-visible-overflow overscroll-none"
        @before-open="beforeOpen"
        @opened="opened"
        @closed="closed"
    >
        <div
            v-if="item"
            v-touch:swipe.bottom="swipeHandler"
            v-touch:end="endHandler"
            v-touch:start="startHandler"
            id="modal-id"
            class="item-modal-content relative flex flex-col w-full"
        >
            <div class="sticky z-50 top-0 flex justify-end h-0">
                <div class="mr-4 mt-4">
                    <button
                        @click="hideItemModal()"
                        :class="{
                            'text-muted': !item.image && !item.video,
                            hidden: !itemHasModifiers && !item.image
                        }"
                        class="no-highlight p-1.5 text-primary bg-secondary rounded-full shadow-md"
                        aria-label="Close item"
                        tabindex="0"
                    >
                        <long-back-icon
                            v-if="isBao"
                            class="w-6 h-6 rotate-180"
                        />
                        <svg
                            v-else
                            class="w-6 h-6"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div
                id="imageContainer"
                class="product-item-modal-details overflow-hidden sm:rounded-t-md rounded-t-lg select-none skeleton-loading-background flex-auto"
                :class="{
                    'item-modal-image-shadow relative': item.image,
                    'item-modal-image-shadow-fade':
                        item.image && isLargeImageStyle && !item.video,
                    'bg-black': item.video
                }"
            >
                <ProductVideo v-if="item.video" :playback-id="item.video" />
                <img
                    v-if="!item.video && item.image"
                    id="itemModalImage"
                    class="item-modal-main-image overflow-hidden sm:rounded-t-md rounded-t-lg relative w-full bg-center object-cover"
                    :class="{
                        'item-modal-main-image-large': isLargeImageStyle
                    }"
                    :srcset="`${item.image}-/resize/550x/-/format/auto/ 1x,
                              ${item.image}-/resize/1100x/-/format/auto/ 2x,
                              ${item.image}-/resize/1650x/-/format/auto/ 3x`"
                    :src="item.image + '-/resize/550x/-/format/auto/'"
                    :alt="item.name"
                />
            </div>
            <div
                class="item-modal-body relative rounded-b-none sm:rounded-b-md flex flex-col z-20"
                :class="{
                    'transition-height duration-300 ease-in-out': isOpened,
                    'rounded-t-lg': !item.image && !item.video
                }"
            >
                <div class="overflow-y-auto">
                    <div
                        class="mb-6 transition-height duration-300 ease-in-out"
                        ref="contentWrapper"
                    >
                        <transition
                            name="fade"
                            mode="out-in"
                            :duration="300"
                            @before-enter="beforeEnterTransition"
                            @enter="enterTransition"
                            @after-enter="afterEnterTransition"
                        >
                            <div v-if="tab === 0" :key="1">
                                <div
                                    class="product-item-modal-details select-none flex-auto overscroll-contain sm:overscroll-none"
                                >
                                    <div
                                        :class="{
                                            'border-b border-gray-200':
                                                itemHasModifiers
                                        }"
                                        class="relative p-6 py-4 select-none"
                                    >
                                        <h4
                                            class="break-word w-10/12 text-subheading font-heading text-xl font-bold"
                                            :class="{
                                                'pb-1': item.description
                                            }"
                                        >
                                            {{ item.name }}
                                        </h4>
                                        <p
                                            class="text-itembody whitespace-pre-line text-sm"
                                        >
                                            {{ item.description }}
                                        </p>
                                        <div
                                            class="flex justify-between items-end"
                                        >
                                            <div>
                                                <div
                                                    @click="tab = 1"
                                                    v-if="
                                                        allergenTags.length >
                                                            0 ||
                                                        possibleAllergenTags.length >
                                                            0
                                                    "
                                                    class="no-highlight mt-2 cursor-pointer"
                                                >
                                                    <span
                                                        class="text-muted underline text-sm select-none"
                                                    >
                                                        {{
                                                            $t(
                                                                'itemModal.allergens'
                                                            )
                                                        }}
                                                    </span>
                                                </div>

                                                <div
                                                    v-if="
                                                        item.nutrition &&
                                                        item.nutrition.calories
                                                    "
                                                    class="mt-2"
                                                >
                                                    <p
                                                        class="text-itembody whitespace-pre-line text-sm"
                                                    >
                                                        {{ totalItemCalories }}
                                                        kcal
                                                    </p>
                                                </div>
                                            </div>

                                            <div>
                                                <span
                                                    v-if="
                                                        stockValue =
                                                            getStockValue(item)
                                                    "
                                                    class="inline-flex items-center rounded-md bg-primary px-2 py-1 text-xs font-medium text-primary ring-1 ring-blue-700/10 ring-inset"
                                                >
                                                    {{ stockValue }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="itemHasModifiers">
                                    <div
                                        v-for="(
                                            group, index
                                        ) in item.modifierGroups"
                                        :key="group.id"
                                        :id="group.id"
                                        class=""
                                    >
                                        <div
                                            class="px-6 py-3.5 bg-page"
                                            :ref="`modifier-group-${index}`"
                                            v-if="
                                                group.products &&
                                                group.products.length
                                            "
                                            :class="{
                                                'cursor-pointer':
                                                    group.initialDisplaySetting ===
                                                    initialDisplaySettingEnum.COLLAPSED
                                            }"
                                            @click="
                                                toggleAccordion(
                                                    $event,
                                                    group.id
                                                )
                                            "
                                        >
                                            <span
                                                v-if="
                                                    $v.item.modifierGroups
                                                        .$each[index] &&
                                                    $v.item.modifierGroups
                                                        .$each[index].$error
                                                "
                                                id="modifier-error"
                                            />

                                            <div
                                                class="flex flex-row select-none items-start justify-between pointer-events-none"
                                            >
                                                <div>
                                                    <h5
                                                        :class="{
                                                            'error-text':
                                                                $v.item
                                                                    .modifierGroups
                                                                    .$each[
                                                                    index
                                                                ].$error
                                                        }"
                                                        class="text-subheading text-sm font-bold"
                                                    >
                                                        {{ group.name }}
                                                    </h5>
                                                    <div
                                                        v-if="
                                                            !(
                                                                group.min ===
                                                                    1 &&
                                                                group.max === 1
                                                            )
                                                        "
                                                    >
                                                        <h6
                                                            v-if="
                                                                group.max === 1
                                                            "
                                                            class="text-itembody text-xs"
                                                        >
                                                            {{
                                                                $t(
                                                                    'itemModal.selectOne'
                                                                )
                                                            }}
                                                        </h6>
                                                        <h6
                                                            v-else-if="
                                                                group.min > 0 &&
                                                                group.min ===
                                                                    group.max
                                                            "
                                                            class="text-itembody text-xs"
                                                        >
                                                            {{
                                                                $t(
                                                                    'itemModal.selectMin',
                                                                    {
                                                                        value: group.min
                                                                    }
                                                                )
                                                            }}
                                                        </h6>
                                                        <h6
                                                            v-else-if="
                                                                group.max !== 0
                                                            "
                                                            class="text-itembody text-xs"
                                                        >
                                                            {{
                                                                $t(
                                                                    'itemModal.selectMax',
                                                                    {
                                                                        value: group.max
                                                                    }
                                                                )
                                                            }}
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div class="flex text-itembody">
                                                    <div
                                                        v-if="
                                                            !visualMenu &&
                                                            !allergenDisabled &&
                                                            group.min > 0
                                                        "
                                                        class="flex items-center pl-4 text-sm font-medium"
                                                        style="
                                                            color: rgb(
                                                                57,
                                                                178,
                                                                56
                                                            );
                                                        "
                                                    >
                                                        <svg
                                                            v-if="
                                                                group.selectedOption >=
                                                                group.min
                                                            "
                                                            width="18"
                                                            height="18"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            aria-hidden="true"
                                                            style="
                                                                flex-shrink: 0;
                                                            "
                                                        >
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M18.7071 7.29289C19.0976 7.68342 19.0976 8.31658 18.7071 8.70711L10.2071 17.2071C9.81658 17.5976 9.18342 17.5976 8.79289 17.2071L5.29289 13.7071C4.90237 13.3166 4.90237 12.6834 5.29289 12.2929C5.68342 11.9024 6.31658 11.9024 6.70711 12.2929L9.5 15.0858L17.2929 7.29289C17.6834 6.90237 18.3166 6.90237 18.7071 7.29289Z"
                                                                fill="currentColor"
                                                            ></path>
                                                        </svg>
                                                        <span>
                                                            {{
                                                                $t(
                                                                    'itemModal.required'
                                                                )
                                                            }}
                                                        </span>
                                                    </div>
                                                    <div
                                                        v-if="
                                                            group.initialDisplaySetting ===
                                                            initialDisplaySettingEnum.COLLAPSED
                                                        "
                                                        class="caret text-secondaryactions transform transition duration-300 ease-in-out"
                                                        :class="{
                                                            'rotate-90':
                                                                group.initialDisplaySetting ===
                                                                initialDisplaySettingEnum.COLLAPSED
                                                        }"
                                                    >
                                                        <dropdown-icon
                                                            class="pointer-events-none"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            :ref="group.id"
                                            v-if="
                                                group.products &&
                                                group.products.length
                                            "
                                            class="px-6 overflow-hidden transition transition-height duration-300 ease-in-out"
                                            :class="{
                                                'accordion collapsed':
                                                    group.initialDisplaySetting ===
                                                    initialDisplaySettingEnum.COLLAPSED
                                            }"
                                        >
                                            <radio-button
                                                v-if="
                                                    group.min === 1 &&
                                                    group.max === 1
                                                "
                                                v-model="group.selectedOption"
                                                :options="group.products"
                                                :item-price="item.price"
                                                :keyId="group.id"
                                                value-field="id"
                                                full-width
                                                label-field="name"
                                                class="my-2.5"
                                                @input="scrollToNext(group.id)"
                                            />
                                            <checkbox
                                                v-else
                                                v-model="group.selectedOptions"
                                                :options="group.products"
                                                :item-price="item.price"
                                                :optionGroupId="group.id"
                                                valueField="id"
                                                labelField="name"
                                                :max="group.max"
                                                class="my-2.5"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="shouldShowItemNotes"
                                    class="item-modal-details flex-auto pb-1 overflow-y-auto"
                                >
                                    <div
                                        @click="
                                            !shouldExpandItemNotes
                                                ? toggleAccordion(
                                                      $event,
                                                      'itemNotes'
                                                  )
                                                : false
                                        "
                                        :class="{
                                            'cursor-pointer':
                                                !shouldExpandItemNotes
                                        }"
                                        class="select-none flex flex-row items-start justify-between px-6 py-3.5 bg-page"
                                    >
                                        <h4
                                            class="text-sm text-subheading font-bold pointer-events-none"
                                        >
                                            {{ $t('itemModal.addNotes') }}
                                        </h4>
                                        <div
                                            v-if="!shouldExpandItemNotes"
                                            class="caret text-secondaryactions transform transition duration-300 ease-in-out pointer-events-none"
                                            :class="{
                                                'rotate-90': true
                                            }"
                                        >
                                            <dropdown-icon
                                                class="pointer-events-none"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        ref="itemNotes"
                                        :class="{
                                            collapsed: !shouldExpandItemNotes
                                        }"
                                        class="accordion overflow-hidden transition transition-height duration-300 ease-in-out"
                                    >
                                        <div class="relative px-6 mt-4">
                                            <textarea
                                                v-model.trim="item.notes"
                                                type="text"
                                                rows="2"
                                                name="notes"
                                                id="notes"
                                                class="email-input block pt-4 px-4 w-full text-sm bg-secondary border rounded shadow-sm"
                                                :class="{
                                                    'ring ring-red-200 border-red-300':
                                                        $v.item.notes.$error
                                                }"
                                                required
                                            ></textarea>
                                            <label
                                                for="notes"
                                                class="email-input-label absolute pt-1 px-4 rounded pointer-events-none select-none"
                                            >
                                                {{
                                                    $t(
                                                        'itemModal.notesRequests'
                                                    )
                                                }}
                                            </label>
                                        </div>
                                        <div class="flex justify-end px-6">
                                            <span
                                                class="flex flex-auto justify-start my-2 text-red-500 text-xs"
                                                v-show="$v.item.notes.$error"
                                            >
                                                {{
                                                    item?.note?.length > 150
                                                        ? $t(
                                                              'orderNotes.errors.tooLong'
                                                          )
                                                        : 'required'
                                                }}
                                            </span>
                                            <span
                                                class="flex justify-end my-2 text-xs"
                                                :class="{
                                                    'text-red-500':
                                                        $v.item.notes.$error
                                                }"
                                            >
                                                {{
                                                    `${item?.notes?.length}/150`
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="z-20"
                                ref="allergensTab"
                                :key="2"
                                v-else
                            >
                                <div
                                    @click="tab = 0"
                                    class="no-highlight mt-2 flex items-center cursor-pointer text-muted px-6"
                                >
                                    <svg
                                        class="w-4 h-4 mr-2"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="1.5"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                                        ></path>
                                    </svg>

                                    <span class="underline text-sm select-none">
                                        {{ $t('itemModal.details') }}
                                    </span>
                                </div>
                                <div
                                    class="product-item-modal-details relative flex-auto overflow-y-auto overscroll-contain"
                                    :class="{ 'pt-6': !item.image }"
                                >
                                    <div
                                        v-if="
                                            allergensList &&
                                            allergensList.length > 0
                                        "
                                        class="mt-6 px-6"
                                    >
                                        <h3
                                            class="text-subheading font-semibold"
                                        >
                                            {{
                                                $t('itemModal.containAllergens')
                                            }}
                                        </h3>

                                        <div class="grid grid-cols-2 mt-2">
                                            <template
                                                v-for="tag in allergensList"
                                            >
                                                <h4
                                                    v-if="tag"
                                                    :key="tag"
                                                    class="pb-1 text-muted text-sm font-semibold"
                                                >
                                                    {{ tag }}
                                                </h4>
                                            </template>
                                        </div>
                                    </div>

                                    <div
                                        v-if="
                                            possibleAllergensList &&
                                            possibleAllergensList.length > 0
                                        "
                                        class="mt-6 px-6"
                                    >
                                        <h3
                                            class="text-subheading font-semibold"
                                        >
                                            {{
                                                $t(
                                                    'itemModal.mayContainAllergens'
                                                )
                                            }}
                                        </h3>

                                        <div class="grid grid-cols-2 mt-2">
                                            <template
                                                v-for="tag in possibleAllergensList"
                                            >
                                                <h4
                                                    v-if="tag"
                                                    :key="tag"
                                                    class="pb-1 text-muted text-sm font-semibold"
                                                >
                                                    {{ tag }}
                                                </h4>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                    <div
                        v-if="soldOut"
                        class="-mt-6 pb-2 text-center text-red-600"
                    >
                        {{
                            item.inventory > 0
                                ? $t('itemModal.almostSoldOut', {
                                      value: item.inventory
                                  })
                                : $t('itemModal.soldOut')
                        }}
                    </div>
                </div>
                <!--                <menu-recommendations-->
                <!--                    v-if="hasRecommendationsEnabled"-->
                <!--                    :instance-id="'modal'"-->
                <!--                    key="modal"-->
                <!--                    :current-item="item"-->
                <!--                    class="px-4 mb-4"-->
                <!--                />-->
                <div
                    ref="addToCart"
                    v-if="!visualMenu && !allergenDisabled"
                    class="item-modal-footer footer-blurred sticky bottom-0 mt-auto p-3 pb-5"
                >
                    <div
                        class="flex flex-row items-center justify-between w-full"
                    >
                        <div
                            v-if="!visualMenu"
                            class="quantity-stepper relative flex flex-row items-center justify-between mr-4 w-36 text-center text-xl font-bold select-none sm:mr-2 sm:w-24"
                        >
                            <div
                                class="cursor-pointer"
                                @click="
                                    quantity > 1 ? quantity-- : (quantity = 1)
                                "
                            >
                                <minus-circle-icon
                                    class="w-6 h-6"
                                    :class="{ 'opacity-10': quantity === 1 }"
                                />
                            </div>
                            <div v-if="hasQuantityInput" class="px-1">
                                <input
                                    type="text"
                                    class="py-1 text-secondaryactions w-full text-center text-base sm:text-lg border rounded-full focus:outline-gray-700"
                                    v-model="quantity"
                                    @change="updateQty"
                                />
                            </div>
                            <div v-else class="text-primary text-2xl">
                                {{ quantity }}
                            </div>
                            <div
                                class="text-secondaryactions cursor-pointer"
                                @click="increaseQty"
                            >
                                <plus-circle-icon
                                    class="w-6 h-6"
                                    :class="{
                                        'text-gray-400':
                                            item.trackInventory &&
                                            item.inventory <= quantity
                                    }"
                                />
                            </div>
                        </div>
                        <base-button
                            class="px-12 py-4 sm:mb-3 md:mb-0 md:ml-3 md:w-auto"
                            size="sm"
                            :class="{ 'text-button spinner': isLoading }"
                            @clicked="addToCartWithDelay"
                        >
                            <template #slot>
                                <span v-show="!isLoading">
                                    {{ $t('itemModal.addFor') }}
                                    {{ totalItemPrice | customCurrency }}
                                </span>
                                <span class="inline-block"></span>
                            </template>
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import isMobile from '@/helpers/isMobileDevice';
import { maxLength, requiredIf } from 'vuelidate/lib/validators';
import { mapActions, mapState, mapGetters } from 'vuex';
import RadioButton from '../formElements/RadioButton';
import Checkbox from '../formElements/Checkbox';
import PlusCircleIcon from '../icons/PlusCircleIcon';
import MinusCircleIcon from '../icons/MinusCircleIcon';
import DropdownIcon from '../icons/DropdownIcon';
import { FulfillmentMethodsEnum, TrackingEventsEnum } from '@/enums';
import {
    processItemAllergens,
    filterAllergens,
    filterPossibleAllergens,
    getTagsFromModifiers,
    translateTags
} from '@/helpers/allergens';
const LongBackIcon = () => import('@/components/icons/LongBackIcon');
const ProductVideo = () => ({
    component: import('../elements/ProductVideo')
});

const initialDisplaySettingEnum = Object.freeze({
    COLLAPSED: 'collapsed',
    EXPANDED: 'expanded',
    HIDDEN: 'hidden'
});

const itemNotesEnum = Object.freeze({
    DISABLED: 'disabled',
    OPTIONAL_HIDDEN: 'optional_hidden',
    OPTIONAL_EXPANDED: 'optional_expanded'
});

export default {
    name: 'item-modal',
    components: {
        LongBackIcon,
        ProductVideo,
        Checkbox,
        RadioButton,
        PlusCircleIcon,
        MinusCircleIcon,
        DropdownIcon
    },
    validations: {
        item: {
            modifierGroups: {
                $each: {
                    selectedOption: {
                        required: requiredIf(function (group) {
                            if (group.min === 0) {
                                return false;
                            }
                            if (group.max === 1) {
                                return !group.selectedOption;
                            }
                            // Multiple selection case
                            const selections = group.selectedOptions || [];
                            if (group.max === 0) {
                                return selections.length < group.min;
                            }
                            // Limited range of selections
                            return (
                                selections.length < group.min ||
                                selections.length > group.max
                            );
                        })
                    }
                }
            },
            notes: {
                maxLength: maxLength(150)
            }
        }
    },
    data() {
        return {
            modal: null,
            windowHeight: window.innerHeight,
            item: null,
            quantity: 1,
            isLoading: false,
            tab: 0,
            selectedOption: 1,
            initialY: 0,
            initialScroll: -1,
            marginTop: null,
            soldOut: false,
            contentWrapperHeight: 'auto',
            isOpened: false,
            initialDisplaySettingEnum,
            itemNotesEnum,
            isBrowserBackButton: false,
            tHandler: null
        };
    },
    mounted() {
        window.addEventListener('popstate', this.handleBackButton);
    },
    beforeDestroy() {
        window.removeEventListener('popstate', this.handleBackButton);
        if (this.tHandler) {
            const element = document.getElementById('modal-id');
            if (element) {
                element.removeEventListener('touchmove', this.tHandler);
            }
        }
    },
    computed: {
        allergenTranslations() {
            return this.$t('allergens');
        },
        ...mapState({
            cartItems: state => state.cart.items,
            visualMenu: state => state.venue.venue.visualMenu,
            fulfillmentMethod: state => state.cart.fulfillmentMethod,
            showInventoryQuantity: state =>
                state.venue.venue.showInventoryQuantity
        }),
        ...mapGetters({
            cartItemQuantity: 'cart/cartItemQuantity',
            ageOver18Confirmed: 'cart/ageOver18Confirmed',
            allergenDisabled: 'customer/allergenDisabled'
        }),
        isMobile() {
            return isMobile();
        },
        isLargeImageStyle() {
            return this.$store.state.venue.venue.modalImageStyle === 'large';
        },
        hasRecommendationsEnabled() {
            return this.$growthbook.isOn('shaped_recs');
        },
        itemHasModifiers() {
            return this.item?.modifierGroups?.length > 0;
        },
        totalItemPrice() {
            let price = this.item.price;

            if (!this.item.modifierGroups) {
                return (price * this.quantity) / 100;
            }

            const { price: totalModifiersPrice } = this.totalsOfModifiers;

            price += totalModifiersPrice;

            return (price * this.quantity) / 100;
        },
        totalItemCalories() {
            let calories = this.item?.nutrition?.calories || 0;

            if (!this.item.modifierGroups) {
                return calories;
            }

            const { calories: totalCaloriesOfModifiers } =
                this.totalsOfModifiers;

            calories += totalCaloriesOfModifiers;

            return calories;
        },
        possibleAllergensList() {
            return this.translateTags(this.possibleAllergenTags);
        },
        tagsInSelectedModifiers() {
            return getTagsFromModifiers(this.item.modifierGroups);
        },
        allergenTags() {
            return [
                ...new Set([
                    ...this.filterAllergens(this.item.tags),
                    ...this.filterAllergens(this.tagsInSelectedModifiers)
                ])
            ];
        },
        possibleAllergenTags() {
            return [
                ...new Set([
                    ...this.filterPossibleAllergens(
                        this.tagsInSelectedModifiers
                    ),
                    ...this.filterPossibleAllergens(this.item.tags)
                ])
            ];
        },
        allergensList() {
            return this.translateTags(this.allergenTags);
        },
        totalsOfModifiers() {
            let price = 0;
            let calories = 0;
            let modifiers = [];

            this.item.modifierGroups.map(el => {
                if (el.selectedOptions) {
                    for (const option of el.selectedOptions) {
                        const modifier = el.products.find(x => x.id === option);

                        price += modifier.price;
                        calories += modifier?.nutrition?.calories || 0;
                        modifiers.push(modifier);
                    }
                }

                if (el.selectedOption) {
                    const modifier = el.products.find(
                        x => x.id === parseInt(el.selectedOption)
                    );

                    if (modifier) {
                        calories += modifier?.nutrition?.calories || 0;
                        price += modifier.price;
                        modifiers.push(modifier);
                    }
                }
            });

            return { price, calories, modifiers };
        },
        isBao() {
            return (
                this.$store.state.venue?.venue?.accountId ===
                '5f4e9ddc-3431-43f5-9de0-7aa356564afc'
            );
        },
        hasQuantityInput() {
            return FulfillmentMethodsEnum.isCateringMethod(
                this.fulfillmentMethod
            );
        },
        enableItemNotes() {
            const setting = this.$store.state.venue.venue.enableItemNotes;
            // Handle boolean values for backward compatibility
            if (typeof setting === 'boolean') {
                return setting
                    ? this.itemNotesEnum.OPTIONAL_HIDDEN
                    : this.itemNotesEnum.DISABLED;
            }
            return setting;
        },
        shouldShowItemNotes() {
            return this.enableItemNotes !== this.itemNotesEnum.DISABLED;
        },
        shouldExpandItemNotes() {
            return (
                this.enableItemNotes === this.itemNotesEnum.OPTIONAL_EXPANDED
            );
        }
    },
    methods: {
        handleBackButton() {
            this.isBrowserBackButton = true;
        },
        ...mapActions({
            addProductToCart: 'cart/addProductToCart',
            cartTotalWithDiscount: 'cart/cartTotalWithDiscount'
        }),
        translateTags(tags) {
            return translateTags(tags, this.allergenTranslations);
        },
        filterAllergens(tags) {
            return filterAllergens(tags);
        },
        filterPossibleAllergens(tags) {
            return filterPossibleAllergens(tags);
        },
        getStockValue(item) {
            if (!item.trackInventory || this.showInventoryQuantity === 'hide') {
                return null;
            }

            if (this.showInventoryQuantity === 'exact') {
                return this.$t('itemModal.stockQuantity', {
                    value: item.inventory || 0
                });
            } else if (
                this.showInventoryQuantity === 'low_only' &&
                item.inventory <= 10
            ) {
                return this.$t('itemModal.stockLow');
            }
        },
        beforeEnterTransition() {
            if (!this.$refs.contentWrapper) {
                return;
            }

            this.$refs.contentWrapper.style.height = this.contentWrapperHeight;
        },
        enterTransition(el) {
            if (!this.$refs.contentWrapper) {
                return;
            }

            this.$refs.contentWrapper.style.height = el.scrollHeight + 'px';
        },
        afterEnterTransition() {
            if (!this.$refs.contentWrapper) {
                return;
            }

            this.contentWrapperHeight =
                this.$refs.contentWrapper.style.height || 0;
            this.$refs.contentWrapper.style.height = 'auto';
        },
        toggleAccordion(event, id) {
            const element = Array.isArray(this.$refs[id])
                ? this.$refs[id][0]
                : this.$refs[id];
            const caret = event.target.querySelector('.caret');

            if (!element.classList.contains('accordion')) {
                return;
            }

            if (element.classList.contains('collapsed')) {
                element.classList.remove('collapsed');
                element.style.height = 0;
                setTimeout(() => {
                    caret.classList.remove('rotate-90');

                    element.style.height = element.scrollHeight + 'px';
                    element.style.visibility = 'visible';
                }, 50);

                return;
            }

            element.style.height = element.scrollHeight + 'px';
            setTimeout(() => {
                element.style.height = 0;
                element.classList.add('collapsed');
                caret.classList.add('rotate-90');
            }, 50);
        },
        async addToCartWithDelay() {
            this.isLoading = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                await this.$nextTick();

                if (document.getElementById('modifier-error')) {
                    document
                        .getElementById('modifier-error')
                        .scrollIntoView({ behavior: 'smooth' });

                    this.animateErrorGroup();
                }

                this.tab = 0;
                this.isLoading = false;

                return;
            }
            if (this.item.trackInventory) {
                const isAvailable = await this.checkAvailability();

                if (!isAvailable) {
                    this.soldOut = true;
                    this.isLoading = false;

                    return;
                }
            }
            setTimeout(
                () => {
                    const { modifiers } = this.totalsOfModifiers;

                    this.addProductToCart([
                        this.item,
                        this.quantity,
                        modifiers,
                        'item_modal'
                    ]);

                    if ('vibrate' in navigator) {
                        window.navigator.vibrate(200);
                    }

                    this.$v.$reset();

                    this.cartTotalWithDiscount();

                    this.isLoading = false;
                    this.hideItemModal();
                },
                this.item.trackInventory ? 0 : 150
            );
        },
        increaseQty() {
            if (!this.item.trackInventory) {
                this.quantity++;
            } else {
                const cartItemQuantity = this.cartItemQuantity(this.item.id);
                let inventory = this.item.inventory;

                if (cartItemQuantity) {
                    inventory -= cartItemQuantity;
                }

                if (inventory > this.quantity) {
                    this.quantity++;
                }
            }
        },
        beforeOpen(event) {
            this.marginTop = null;
            this.item = event.params.item;
            this.item.notes = '';
            this.tab = 0;
            this.quantity = 1;
            this.soldOut = false;
            this.$v.$reset();

            this.triggerAgeGate();

            if (this.item && this.item.modifierGroups) {
                this.item.modifierGroups.map(el => {
                    el.selectedOption ? (el.selectedOption = null) : null;
                    el.selectedOptions ? (el.selectedOptions = []) : null;
                });
            }

            // https://github.com/euvl/vue-js-modal/issues/529#issuecomment-600733392
            // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
            let vh = window.innerHeight * 0.01;
            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        },
        opened() {
            this.isBrowserBackButton = false;

            document.body.style.width = '100%';
            document.body.style.overflow = 'hidden';

            this.contentWrapperHeight =
                this.$refs.contentWrapper.scrollHeight + 'px';
            const element = document.getElementById('modal-id');

            if (element) {
                if (!this.isMobile) {
                    const positionInfo = element.getBoundingClientRect();
                    const elementHeight = positionInfo.height;
                    const clientHeight = document.documentElement.clientHeight;

                    if (clientHeight < elementHeight) {
                        this.marginTop = '20px';
                    }
                } else {
                    const handler = event => this.moveHandler(event);
                    this.tHandler = this.throttled(10, handler);
                    element.addEventListener('touchmove', this.tHandler);
                }
            }
            setTimeout(() => {
                this.isOpened = true;
            }, 200);

            this.waitForImageToLoad();

            this.$router.push({ hash: '#product' }).catch(() => {});

            if (this.$analytics) {
                this.$analytics.track(TrackingEventsEnum.VIEWED_PRODUCT, {
                    id: this.item.id,
                    name: this.item.name,
                    currency: this.$store.state.venue?.venue?.currencyCode,
                    price: this.item.price,
                    image: this.item.image,
                    plu: this.item.plu,
                    sku: this.item.sku
                });
            }
        },
        closed() {
            document.body.style.width = '';
            document.body.style.overflow = '';

            this.isOpened = false;
            this.$router.replace({ hash: null }).catch(() => {});

            if (!this.isBrowserBackButton) {
                history.back();
            }
        },
        hideItemModal() {
            document.body.style.width = '';
            document.body.style.overflow = '';

            this.$modal.hide('item-modal');
        },
        startHandler(event) {
            if (event.type !== 'touchstart') {
                return;
            }

            // If the user is using two fingers (a pinch gesture), do nothing
            if (event.touches.length > 1) return;

            document.body.style.width = '100%';
            document.body.style.overflow = 'hidden';

            this.initialY = event.touches[0].clientY;
            this.initialScroll =
                document.getElementsByClassName(
                    'v--modal-overlay'
                )[0].scrollTop;
        },
        throttled(delay, fn) {
            let lastCall = 0;

            return function (...args) {
                const now = new Date().getTime();

                if (now - lastCall < delay) {
                    return;
                }

                lastCall = now;

                return fn(...args);
            };
        },
        moveHandler(event) {
            // If the user is pinching, ignore
            if (event.touches.length > 1) return;

            const modal = document.getElementById('modal-id');

            if (!modal || modal.scrollTop > 10) {
                return;
            }

            if (
                this.initialScroll === 0 &&
                event.touches[0].clientY > this.initialY
            ) {
                document.getElementsByClassName(
                    'v--modal-overlay'
                )[0].scrollTop = 0;

                modal.style.transition = 'none';
                modal.style.transform =
                    'translateY(' +
                    (event.touches[0].clientY - this.initialY) +
                    'px)';
            }
        },
        swipeHandler() {
            const modal = document.getElementById('modal-id');

            if (modal.scrollTop > 10) {
                return;
            }

            if (this.initialScroll === 0) {
                setTimeout(() => {
                    document.body.style.width = '';
                    document.body.style.overflow = '';
                }, 100);

                this.$modal.hide('item-modal');
            }
        },
        endHandler(event) {
            // If the user was pinching, ignore
            if (event.touches && event.touches.length > 1) return;

            if (event.type !== 'touchend') {
                return;
            }

            const modal = document.getElementById('modal-id');
            modal.style.transition = 'transform .26s';
            modal.style.transform = 'translateY(0)';
        },
        async checkAvailability() {
            try {
                const { data: inventory } = await this.$axios.get(
                    `/menus-items/${this.item.id}/inventory`
                );

                this.item.inventory = inventory;

                return inventory >= this.quantity;
            } catch (error) {
                this.isLoading = false;
                throw new Error(`API ${error}`);
            }
        },
        waitForImageToLoad() {
            const img = document.getElementById('itemModalImage');

            if (!img) {
                return;
            }

            img.onload = () => {
                let el = document.getElementById('imageContainer');

                if (el) {
                    el.classList.remove('skeleton-loading-background');
                }
            };
        },
        updateQty(event) {
            const qty = parseInt(event.target.value);

            if (isNaN(qty) || qty < 1) {
                this.quantity = 1;
            } else {
                let inventory = this.item.trackInventory
                    ? this.item.inventory
                    : 999;
                const cartItemQuantity = this.cartItemQuantity(this.item.id);

                if (cartItemQuantity && this.item.trackInventory) {
                    inventory -= cartItemQuantity;
                }

                this.quantity = Math.min(qty, inventory);
            }
        },
        animateErrorGroup() {
            for (
                let index = 0;
                index < this.item.modifierGroups.length;
                index++
            ) {
                if (this.$v.item.modifierGroups.$each[index]?.$error) {
                    const groupElement =
                        this.$refs[`modifier-group-${index}`][0];

                    if (groupElement) {
                        groupElement.classList.add('wobble');

                        setTimeout(() => {
                            groupElement.classList.remove('wobble');
                        }, 1600);
                    }

                    break;
                }
            }
        },
        triggerAgeGate() {
            if (
                !this.$store.state.venue?.venue?.ageGated ||
                this.ageOver18Confirmed
            ) {
                return;
            }

            const hasAlcoholTag = this.item.tags?.includes(1);

            if (hasAlcoholTag) {
                this.$modal.show('age-verification-modal');
            }
        },
        scrollToNext(groupId) {
            const { modifierGroups: groups } = this.item;
            const index = groups.findIndex(({ id }) => id === groupId);

            if (!~index) {
                return;
            }

            const scrollTo = id => {
                const element = document.getElementById(id);

                if (!element) {
                    return;
                }

                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            };

            const isLastModifier = groups.length === index + 1;
            const nextItem = groups[index + 1];

            if (isLastModifier || nextItem?.selectedOption) {
                const unselectedModifier = groups.find(
                    modifier =>
                        (!modifier.selectedOption &&
                            modifier.min === 1 &&
                            modifier.max === 1) ||
                        (!modifier.selectedOptions?.length &&
                            modifier.min > 0 &&
                            modifier.max > 1)
                );

                if (unselectedModifier) {
                    scrollTo(unselectedModifier.id);
                }

                return;
            }

            scrollTo(nextItem.id);
        }
    }
};
</script>
<style lang="css">
.collapsed {
    height: 0;
    visibility: collapse;
}
</style>
<style src="@/assets/css/item-modal.css"></style>
