<template>
    <div class="scrolling-wrapper-flexbox no-scrollbar -mb-4 py-4 px-3">
        <div
            v-for="item in offers"
            :key="item.id"
            class="card rounded-md shadow-md cursor-pointer relative"
        >
            <MenuOfferItem :offer="item" @action="handleOfferAction" />
        </div>
    </div>
</template>

<script>
import { OfferLinkTypesEnum, TrackingEventsEnum } from '@/enums';
import { mapActions, mapGetters, mapState } from 'vuex';
import MenuOfferItem from './MenuOfferItem';

export default {
    name: 'MenuOffers',
    components: { MenuOfferItem },
    props: {
        offers: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            OfferLinkTypesEnum
        };
    },
    computed: {
        ...mapState({
            venueId: state => state.venue.venue.id,
            fulfillmentMethod: state => state.cart.fulfillmentMethod
        }),
        ...mapGetters({
            discountCodeDetails: 'discount/details',
            auth: 'customer/auth'
        })
    },
    methods: {
        ...mapActions({
            discountCheck: 'discount/checkDiscountCode',
            setAppliedStatus: 'discount/setAppliedStatus'
        }),

        handleOfferAction({ type, value, id, linkId }) {
            if (type === OfferLinkTypesEnum.VISUAL) return;

            const actionMap = {
                [OfferLinkTypesEnum.CATEGORY]: () =>
                    this.scrollToCategory(value),
                [OfferLinkTypesEnum.PRODUCT]: () => this.showItemModal(value),
                [OfferLinkTypesEnum.DISCOUNT]: () =>
                    this.proceedDiscountCheck(value),
                [OfferLinkTypesEnum.LOYALTY]: () => this.showAuthModal(),
                [OfferLinkTypesEnum.MENU_SUMMARY]: () =>
                    this.showMenuSummaryModal()
            };

            const action = actionMap[type];
            if (action) action();

            this.trackOfferEvent({
                type,
                value,
                id,
                linkId,
                event:
                    type === OfferLinkTypesEnum.EXTERNAL
                        ? TrackingEventsEnum.CLICK_OFFER
                        : TrackingEventsEnum.VIEWED_OFFER
            });
        },
        scrollToCategory(categoryId) {
            const element = document.getElementById(`category-${categoryId}`);

            if (!element || !element.offsetTop) {
                return this.$modal.show('offer-unavailable-modal');
            }

            window.scrollTo({
                top: element.offsetTop - 25,
                behavior: 'smooth'
            });
        },
        showItemModal(itemId) {
            this.$emit('showItemModalById', +itemId);
        },
        async proceedDiscountCheck(code) {
            await this.discountCheck({
                venueId: this.venueId,
                fulfillmentMethod: this.fulfillmentMethod,
                code
            });

            const discountInfo = !!this.discountCodeDetails;

            await this.setAppliedStatus(discountInfo);

            await this.$modal.show('applying-discount-modal');

            setTimeout(() => {
                this.$modal.hide('applying-discount-modal');
            }, 2000);
        },
        trackOfferEvent({ type, value, id, linkId, event }) {
            if (!this.$analytics) return;

            this.$analytics.track(event, {
                offerId: id,
                linkId,
                type,
                value
            });
        },
        showAuthModal() {
            if (!this.auth) {
                this.$modal.show('customer-auth-modal');
            }
        },
        showMenuSummaryModal() {
            this.$modal.show('menu-summary-modal');
        }
    }
};
</script>
<style src="@/assets/css/menu-offers.css"></style>
