export default class TrackingEventsEnum {
    static get ADD_TO_CART() {
        return 'add_to_cart';
    }

    static get REMOVE_FROM_CART() {
        return 'remove_from_cart';
    }
    static get VIEWED_EXPERIMENT() {
        return 'viewed_experiment';
    }

    static get VIEWED_PRODUCT() {
        return 'viewed_product';
    }

    static get ACTIVE_ON_SITE() {
        return 'active_on_site';
    }

    static get STARTED_CHECKOUT() {
        return 'started_checkout';
    }

    static get MENU_LOADED() {
        return 'menu_loaded';
    }

    static get ORDER_COMPLETED() {
        return 'order_completed';
    }

    static get CROSS_SELL_MODAL_VIEW() {
        return 'cross_sell_modal_view';
    }

    static get VIEWED_OFFER() {
        return 'viewed_offer';
    }

    static get CLICK_OFFER() {
        return 'click_offer';
    }

    static get PRODUCTS_SEARCHED() {
        return 'products_searched';
    }

    static get DISCOUNT_ENTERED() {
        return 'discount_entered';
    }

    static get DISCOUNT_APPLIED() {
        return 'discount_applied';
    }

    static get DISCOUNT_DENIED() {
        return 'discount_denied';
    }

    static get options() {
        return {
            ADD_TO_CART: this.ADD_TO_CART,
            VIEWED_EXPERIMENT: this.VIEWED_EXPERIMENT,
            VIEWED_PRODUCT: this.VIEWED_PRODUCT,
            ACTIVE_ON_SITE: this.ACTIVE_ON_SITE,
            STARTED_CHECKOUT: this.STARTED_CHECKOUT,
            MENU_LOADED: this.MENU_LOADED,
            ORDER_COMPLETED: this.ORDER_COMPLETED,
            CROSS_SELL_MODAL_VIEW: this.CROSS_SELL_MODAL_VIEW,
            VIEWED_OFFER: this.VIEWED_OFFER,
            CLICK_OFFER: this.CLICK_OFFER,
            PRODUCTS_SEARCHED: this.PRODUCTS_SEARCHED,
            DISCOUNT_ENTERED: this.DISCOUNT_ENTERED,
            DISCOUNT_APPLIED: this.DISCOUNT_APPLIED,
            DISCOUNT_DENIED: this.DISCOUNT_DENIED
        };
    }
}
