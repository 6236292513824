<template>
    <div
        class="-mx-2"
        :class="[
            {
                'grid gap-3 auto-rows-fr grid-cols-3 sm:grid-cols-4':
                    hasCardStyleModifier
            }
        ]"
    >
        <label
            v-for="(option, index) in options"
            :key="getOptionKey(option, index)"
            :for="getCheckboxId(option)"
            :aria-label="getOptionLabel(option)"
            tabindex="0"
            role="button"
            class="sk-checkbox no-highlight relative flex w-full items-center mb-1 rounded outline-none cursor-pointer select-none"
            :class="[
                {
                    'px-2 bg-transparent': !hasCardStyleModifier,
                    checked: isSelected(option),
                    'rounded-xl ring-2 ring-gray-700 bg-modifier-selectedcard shadow-modifier':
                        isSelected(option) && hasCardStyleModifier,
                    'rounded-xl ring-1 ring-gray-200 bg-modifier-card shadow-modifier':
                        !isSelected(option) && hasCardStyleModifier
                }
            ]"
        >
            <input
                type="checkbox"
                :value="option[valueField]"
                class="custom-control-input form-check-input form-checkbox absolute w-4 h-4 text-gray-700 opacity-0 transition duration-150 ease-in-out"
                :id="getCheckboxId(option)"
                v-model="selectedValues"
                :disabled="isDisabled(option)"
                @change="onChange(option)"
                :checked="isSelected(option)"
            />
            <div
                v-if="hasCardStyleModifier"
                class="p-3 pb-6 relative flex gap-0 items-center h-full justify-center flex-col"
            >
                <div>
                    <header class="line-clamp-2 text-center text-xs sm:text-sm">
                        {{ getOptionLabel(option) }}
                    </header>
                </div>

                <img
                    v-if="option.modifierImage"
                    :src="getOptimizedImageSrc(option.modifierImage)"
                    :srcset="getOptimizedImageSrcset(option.modifierImage)"
                    :alt="getOptionLabel(option)"
                    loading="lazy"
                    decoding="async"
                    class="size-full p-2 object-contain transition-all duration-150 ease-in-out"
                />
                <p
                    v-if="option.price"
                    class="absolute inset-x-0 pb-1 text-center bottom-0 text-price text-xs"
                >
                    <span v-if="option.price > 0">{{ pricePrefix }}</span>
                    {{ (option.price / 100) | customCurrency }}
                    <s v-if="option.compareAtPrice" class="ml-1 text-gray-400">
                        {{ pricePrefix
                        }}{{ (option.compareAtPrice / 100) | customCurrency }}
                    </s>
                </p>
            </div>
            <div
                v-else
                class="custom-checkbox z-10 flex items-center p-2 pl-0 w-full rounded"
                :class="{ 'opacity-50': isDisabled(option) }"
            >
                <modifier-image
                    v-if="option.modifierImage"
                    :image="option.modifierImage"
                    :alt="getOptionLabel(option)"
                    :is-selected="isSelected(option)"
                />
                <span
                    v-if="!visualMenu && !option.modifierImage"
                    class="custom-checkbox-check mt-[2px] self-start"
                />
                <div class="custom-checkbox-align">
                    <div class="pl-2 text-itembody text-sm">
                        {{ getOptionLabel(option) }}
                        <div class="flex">
                            <span
                                v-if="option.nutrition?.calories"
                                class="block whitespace-pre-line mr-1 text-sm text-muted"
                            >
                                {{ option.nutrition.calories }} kcal
                            </span>
                            <span
                                v-if="
                                    !isUnavailable(option) &&
                                    hasModifierAllergenDisplay &&
                                    hasAllergens(option)
                                "
                                class="block whitespace-pre-line text-sm text-muted mr-1"
                            >
                                {{ getAllergenText(option) }}
                            </span>
                            <span
                                class="text-red-600"
                                v-if="isUnavailable(option)"
                                >{{ $t('checkbox.currentlyUnavailable') }}</span
                            >
                            <span
                                v-if="option.popular && option.isAvailable"
                                class="flex items-center text-orange-400 text-xs"
                            >
                                <star-icon class="mr-0.5 w-4 h-4" />
                                {{ $t('menuItem.popular') }}
                            </span>
                        </div>
                    </div>

                    <div class="flex items-center justify-between">
                        <div>
                            <p
                                v-if="option.price"
                                class="shrink-0 whitespace-nowrap pl-2 text-price text-sm"
                            >
                                <span v-if="option.price > 0">{{
                                    pricePrefix
                                }}</span>
                                {{ (option.price / 100) | customCurrency }}

                                <s
                                    v-if="option.compareAtPrice"
                                    class="ml-1 text-gray-400"
                                >
                                    {{ pricePrefix
                                    }}{{
                                        (option.compareAtPrice / 100)
                                            | customCurrency
                                    }}
                                </s>
                            </p>
                        </div>
                        <div
                            v-if="isSelected(option) && multiMax > 1"
                            class="quantity-stepper-small ml-2 relative flex flex-row items-center justify-between w-auto flex-shrink-0 select-none"
                        >
                            <div class="flex items-center cursor-pointer">
                                <minus-circle-icon
                                    class="w-5 h-5 flex-shrink-0"
                                />
                            </div>
                            <div class="w-6 text-center text-primary">
                                {{ getSelectedOptionQuantity(option) }}
                            </div>
                            <div class="flex items-center cursor-pointer">
                                <plus-circle-icon
                                    class="w-5 h-5 flex-shrink-0"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </label>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import isMobile from '@/helpers/isMobileDevice';
import { processItemAllergens } from '@/helpers/allergens';
import StarIcon from '@/components/icons/StarIcon';
import ModifierImage from '@/components/ModifierImage.vue';
import PlusCircleIcon from '@/components/icons/PlusCircleIcon';
import MinusCircleIcon from '@/components/icons/MinusCircleIcon';

export default {
    name: 'checkboxInput',
    props: {
        options: {
            type: Array,
            required: true
        },
        value: {
            type: Array,
            required: false,
            default: () => []
        },
        optionGroupId: {
            type: String,
            required: false,
            default: ''
        },
        labelField: {
            type: String,
            required: false,
            default: 'label'
        },
        valueField: {
            type: String,
            required: false,
            default: 'value'
        },
        max: {
            type: Number,
            required: false,
            default: null
        },
        itemPrice: {
            type: Number,
            required: false
        },
        multiMax: {
            type: Number,
            required: false,
            default: 1
        }
    },
    components: {
        ModifierImage,
        PlusCircleIcon,
        MinusCircleIcon,
        StarIcon
    },
    data() {
        return {
            selectedValues: [],
            isInputDisabled: false
        };
    },
    methods: {
        isSelected(option) {
            return this.selectedValues.includes(option[this.valueField]);
        },
        isUnavailable(option) {
            return option.snooze || option.isAvailable === false;
        },
        getCheckboxId(option) {
            return `checkbox-${this.optionGroupId}-${option[this.valueField]}`;
        },
        getOptionKey(option, index) {
            return `${this.optionGroupId}-${option[this.valueField]}-${index}`;
        },
        getOptionLabel(option) {
            return option[this.labelField];
        },
        isDisabled(option) {
            return (
                !this.isSelected(option) &&
                (this.isInputDisabled || this.isUnavailable(option))
            );
        },
        async onChange() {
            this.setInputDisabled();

            this.$emit('input', this.selectedValues);
        },
        setInputDisabled() {
            this.isInputDisabled = this.max
                ? this.selectedValues.length >= this.max
                : false;
        },
        hasAllergens(option) {
            const allergenInfo = processItemAllergens(
                option,
                [],
                this.$t('allergens')
            );
            return (
                allergenInfo.allergens.length > 0 ||
                allergenInfo.possibleAllergens.length > 0
            );
        },
        getAllergenText(option) {
            const { allergens, possibleAllergens } = processItemAllergens(
                option,
                [],
                this.$t('allergens')
            );

            const allergenText = [];

            if (allergens.length > 0) {
                allergenText.push(`Contains: ${allergens.join(', ')}`);
            }

            if (possibleAllergens.length > 0) {
                allergenText.push(
                    `May contain: ${possibleAllergens.join(', ')}`
                );
            }

            return allergenText.join(' • ');
        },
        getSelectedOptionQuantity(option) {
            const selectedOption = this.selectedValues.find(
                item => item.value === option[this.valueField]
            );
            return selectedOption ? selectedOption.quantity : 1;
        },
        getOptimizedImageSrc(imageUrl) {
            return `${imageUrl}/-/format/auto/`;
        },
        getOptimizedImageSrcset(imageUrl) {
            return `${imageUrl}/-/format/auto/-/resize/128x/ 1x, ${imageUrl}/-/format/auto/-/resize/192x/ 2x, ${imageUrl}/-/format/auto/-/resize/256x/ 3x`;
        }
    },
    computed: {
        ...mapState({
            visualMenu: state => state.venue.venue.visualMenu
        }),
        pricePrefix() {
            return this.itemPrice === 0 ? '' : '+';
        },
        hasModifierAllergenDisplay() {
            return this.$growthbook.isOn('modifier_allergens');
        },
        hasCardStyleModifier() {
            return this.$growthbook.isOn('card_style_modifier');
        },
        isMobile() {
            return isMobile();
        }
    },
    created() {
        if (this.value) {
            this.selectedValues = this.value;
        }

        this.setInputDisabled();
    },
    watch: {
        value() {
            this.selectedValues = this.value;
        }
    }
};
</script>
