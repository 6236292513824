export default class ImageSizeEnum {
    static get SM() {
        return 'sm';
    }

    static get MD() {
        return 'md';
    }

    static get LG() {
        return 'lg';
    }

    static get XL() {
        return 'xl';
    }

    static get options() {
        return {
            SM: this.SM,
            MD: this.MD,
            LG: this.LG,
            XL: this.XL
        };
    }

    static getFromLegacyFlags(isLarge, isBao) {
        if (isBao) return this.SM;
        return isLarge ? this.LG : this.MD;
    }
}
