<template>
    <div class="flex flex-col basis-full pb-2 px-2 w-full">
        <slot />
        <div
            class="no-scrollbar flex-initial overflow-x-auto w-full whitespace-nowrap"
        >
            <button
                v-for="(tag, index) in tags"
                :key="'tag-' + index"
                class="transition-colors border inline-flex items-center rounded-md px-3 py-1 font-medium mr-2 cursor-pointer"
                :class="[
                    activeFilterTags.includes(tag.tagId)
                        ? 'bg-gray-100 border-gray-200'
                        : 'border-gray-100',
                    size === 'sm' ? 'text-xs' : 'text-sm'
                ]"
                @click="setActiveFilterTags(tag.tagId)"
            >
                <component
                    :is="iconComponent"
                    v-if="showIcons"
                    :class="iconClasses"
                />
                {{ tag.tooltip }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

const ClassicTagIcon = () => import('@/components/icons/ClassicTagIcon.vue');
const FancyTagIcon = () => import('@/components/icons/FancyTagIcon.vue');

export default {
    name: 'DietaryFilters',
    components: {
        ClassicTagIcon,
        FancyTagIcon
    },
    props: {
        showIcons: {
            type: Boolean,
            default: false
        },
        iconStyle: {
            type: String,
            default: 'classic'
        },
        size: {
            type: String,
            default: 'sm'
        }
    },
    data() {
        return {
            tags: [
                {
                    tooltip: this.$t('menuItem.vegetarian'),
                    tagId: 5,
                    class: 'bg-green-50 text-green-600 bottom-right'
                },
                {
                    tooltip: this.$t('menuItem.vegan'),
                    tagId: 4,
                    class: 'bg-green-50 text-green-600 bottom'
                },
                {
                    tooltip: this.$t('menuItem.glutenFree'),
                    tagId: 1101
                },
                {
                    tooltip: this.$t('menuItem.halal'),
                    tagId: 2
                },
                {
                    tooltip: this.$t('menuItem.kosher'),
                    tagId: 3
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            activeFilterTags: 'helpers/activeFilterTags'
        }),
        iconComponent() {
            return this.iconStyle === 'fancy'
                ? 'FancyTagIcon'
                : 'ClassicTagIcon';
        },
        iconClasses() {
            return [
                'mr-1',
                this.iconStyle === 'fancy'
                    ? 'text-indigo-600'
                    : 'text-green-600'
            ];
        }
    },
    methods: {
        ...mapActions({
            setActiveFilterTags: 'helpers/setActiveFilterTags'
        })
    }
};
</script>
