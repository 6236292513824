<template>
    <div>
        <img
            v-if="reward?.media?.value"
            :src="
                reward.reward
                    ? `https://71f65abd77a8d66ef2e1.ucr.io/-/resize/800x/-/format/auto/${reward?.reward?.media?.value}`
                    : `https://71f65abd77a8d66ef2e1.ucr.io/-/resize/800x/-/format/auto/${reward?.media?.value}`
            "
            alt="reward"
            class="w-fit"
        />
        <div class="flex flex-col px-8 py-4">
            <p
                v-if="rewardCredits"
                class="text-sm text-subheading font-bold opacity-75"
                >{{ rewardCredits }}</p
            >
            <p class="text-subheading">{{ rewardText }}</p>
            <div v-if="isBasketDiscount" class="text-subheading mt-2">
                <span v-if="discountType.type === 'basket_amount'">
                    By clicking 'Claim', a
                    {{
                        reward?.attributes?.storekit_discount_value
                            ? reward?.attributes?.storekit_discount_value
                            : reward.storekit_discount_value | currency
                    }}
                    discount will be applied to your cart.
                </span>
                <span v-else>
                    By clicking 'Claim', a
                    {{
                        reward?.attributes?.storekit_discount_value
                            ? reward?.attributes?.storekit_discount_value
                            : reward.storekit_discount_value
                    }}% discount will be applied to your cart.
                </span>
            </div>
            <div v-else class="rounded-md shadow-sm">
                <span class="text-subheading"
                    >Choose the
                    {{
                        discountType.prefix === 'category'
                            ? 'category'
                            : 'product'
                    }}
                    you want to apply the discount to:</span
                >
                <select
                    v-model="selectedProduct"
                    class="form-select block w-full rounded border-gray-200 bg-gray-50 py-3 text-sm transition duration-150 ease-in-out sm:leading-5"
                    :disabled="!selectedProduct"
                >
                    <option
                        v-for="product in products"
                        :value="product.id"
                        :key="product.id"
                    >
                        {{ product.name }}
                    </option>
                </select>
            </div>
            <div class="mt-4 flex justify-end">
                <button
                    class="border-button-primary w-fit text-button bg-button font-button rounded-md p-2 text-xs font-bold shadow"
                    @click="claim"
                    :disabled="!isItemInCart && !isBasketDiscount"
                    :class="{
                        'opacity-60': !isItemInCart && !isBasketDiscount
                    }"
                >
                    {{ RedeemButton }}</button
                >
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { getDiscountValue, getDiscountType } from '@/util/piggyHelper';

export default {
    name: 'PiggyRewardSelected',
    props: {
        reward: {
            type: Object,
            required: true
        },
        contact: {
            type: Object
        }
    },
    data() {
        return {
            products: [],
            selectedProduct: {},
            isItemInCart: false,
            categoryPosId: '',
            discountType: {},
            isModifierInCart: true
        };
    },
    watch: {
        selectedProduct() {
            const { categoryProperty, cartItemProperty } =
                this.getCategoryAndCartItemProperties();

            const categoryPosId = this.categories.find(
                category => category.id === this.selectedProduct
            )?.[categoryProperty];

            if (this.discountType.prefix === 'modifier') {
                this.isItemInCart = this.isModifierInCart;
                return;
            }

            this.isItemInCart =
                this.cartItemById(this.selectedProduct) ||
                this.cartItems.some(
                    cartItem => cartItem[cartItemProperty] === categoryPosId
                );
        }
    },
    mounted() {
        this.onRewardSelected(this.reward);
    },
    computed: {
        ...mapState({
            categories: state => state.venue.categories,
            cartItems: state => state.cart.items,
            piggySettings: state => state.venue.venue.piggySettings,
            posProvider: state => state.venue.venue.posProvider
        }),
        ...mapGetters({
            cartItemById: 'cart/cartItemById',
            cartSubtotal: 'cart/cartSubtotal',
            venueValue: 'venue/venueValue',
            venueProducts: 'venue/products'
        }),
        rewardText() {
            if (this.reward.required_credits >= 0) {
                return `You will exchange your ${this.piggyCreditName} to redeem this reward.`;
            }

            return `Ready to use your reward? You can not change your decision afterwards.`;
        },
        rewardCredits() {
            if (this.reward.required_credits) {
                return `${this.reward.required_credits} ${this.piggyCreditName}`;
            }

            if (this.reward?.expires_at) {
                return `Pick up before: ${this.$dayjs(
                    this.reward?.expires_at
                ).format('dddd, MMMM D, YYYY h:mm A')}`;
            }

            return false;
        },
        RedeemButton() {
            return !this.reward.expires_at ? 'CLAIM' : 'Confirm pick up';
        },
        isBasketDiscount() {
            return this.discountType.prefix === 'basket';
        },
        piggyCreditName() {
            return this.piggySettings.creditUnitName || 'points';
        },
        accountId() {
            return this.venueValue('accountId');
        }
    },
    methods: {
        ...mapActions({
            setPiggyProductDiscount: 'cart/SET_PIGGY_PRODUCT_DISCOUNT',
            cartTotalWithDiscount: 'cart/cartTotalWithDiscount',
            removeDiscount: 'cart/removeDiscount'
        }),
        back() {
            this.$emit('back');
        },
        parseDiscountType(type) {
            const prefix = type.split('_')[0];

            return {
                prefix,
                type
            };
        },
        onRewardSelected() {
            if (
                !this.contact.credit_balance ||
                this.contact.credit_balance.balance <
                    this.reward.required_credits
            ) {
                return;
            }

            const discountType = getDiscountType(this.reward);
            this.discountType = this.parseDiscountType(discountType);

            if (
                discountType === 'basket_amount' ||
                discountType === 'basket_percentage'
            ) {
                return;
            }

            let matchingCartItems;

            if (
                !this.reward.reward?.storekit_plu?.length &&
                !this.reward?.storekit_plu?.length &&
                !this.reward?.attributes?.storekit_plu?.length
            ) {
                if (
                    this.discountType.type ===
                    'product_percent_modifiers_single'
                ) {
                    this.products = this.venueProducts;
                }

                if (
                    this.discountType.type ===
                    'category_percent_modifiers_multi'
                ) {
                    this.products = this.categories;
                }
            } else {
                const productIds = this.reward?.attributes
                    ? this.reward.attributes.storekit_plu
                    : this.reward.reward
                      ? this.reward.reward.storekit_plu
                      : this.reward.storekit_plu;

                if (
                    this.discountType.type ===
                        'category_percent_modifiers_multi' ||
                    this.discountType.type ===
                        'category_percent_modifiers_single' ||
                    this.discountType.type === 'category_percent_base_single'
                ) {
                    const { categoryProperty, cartItemProperty } =
                        this.getCategoryAndCartItemProperties();

                    this.products = this.categories.filter(category =>
                        productIds.includes(
                            category[categoryProperty]?.toString()
                        )
                    );

                    matchingCartItems = this.products.filter(category =>
                        this.cartItems.some(
                            cartItem =>
                                category[categoryProperty] ===
                                cartItem[cartItemProperty]
                        )
                    );
                } else if (
                    this.discountType.type === 'modifier_percent_base_single'
                ) {
                    const modifiers = this.cartItems.reduce((acc, carItem) => {
                        const matchingModifiers = carItem.modifiers.filter(
                            modifier => productIds.includes(modifier.plu)
                        );
                        const uniqueModifiers = matchingModifiers.filter(
                            modifier =>
                                !acc.some(
                                    accModifier =>
                                        accModifier.plu === modifier.plu
                                )
                        );

                        return acc.concat(uniqueModifiers);
                    }, []);

                    this.products = modifiers;
                    matchingCartItems = [];
                } else {
                    this.products = this.venueProducts.filter(product =>
                        productIds.includes(product.plu)
                    );

                    matchingCartItems = this.cartItems.filter(cartItem =>
                        this.products.some(
                            product => product.plu === cartItem.plu
                        )
                    );
                }
            }

            if (!this.products[0]?.id) {
                if (this.discountType.prefix === 'modifier') {
                    this.products.push({
                        id: null,
                        name: `No available modifier for this reward`
                    });
                    this.isModifierInCart = false;
                } else {
                    this.products.push({
                        id: null,
                        name: `No available ${
                            this.discountType.prefix === 'category'
                                ? 'categories'
                                : 'products'
                        } for this reward`
                    });
                }
            }

            if (matchingCartItems.length === 1) {
                this.selectedProduct = matchingCartItems[0]?.id;
                this.claim();
            } else {
                this.selectedProduct = this.products[0]?.id;
            }
        },
        async claim() {
            this.$store.commit('cart/CLEAR_PIGGY_DISCOUNT');

            if (
                this.reward['has_been_collected'] === undefined &&
                this.reward.pre_redeemable
            ) {
                const payload = {
                    accountId: this.accountId,
                    rewardId: this.reward.uuid,
                    isPending: false,
                    piggyShopId: this.piggySettings.piggyShopId,
                    contactId: this.contact.uuid
                };

                const { data } = await this.$axios.post(
                    '/piggy/claim-rewards',
                    payload
                );

                this.$notify({
                    group: 'PiggyRewards',
                    title: `Congratulations! Your reward has been claimed, which you can use until ${this.$dayjs(
                        data.expires_at
                    ).format('dddd, MMMM D, YYYY h:mm A')}.`
                });
                this.back();
            } else {
                this.$store.commit(
                    'cart/SET_PIGGY_REWARD_SELECTED',
                    this.reward
                );
                this.$notify({
                    group: 'PiggyRewards',
                    title: 'Congratulations! Your reward has been successfully claimed and automatically added to your cart.'
                });
                this.applyDiscount();
                this.removeDiscount();
                this.back();
            }

            this.$modal.hide('piggy-rewards-modal');
        },
        findItemById(productId) {
            return this.cartItems.find(product => product.id === productId);
        },
        applyDiscount() {
            if (
                this.discountType.type === 'basket_amount' ||
                this.discountType.type === 'basket_percentage'
            ) {
                this.applyBasketDiscount(this.discountType.type);
                return;
            }

            if (this.discountType.type === 'modifier_percent_base_single') {
                this.modifierPercentDiscount();
                return;
            }

            const product = this.findItemById(this.selectedProduct);
            const discountValue = getDiscountValue(this.reward);
            const productsToDiscount = this.getProductsToDiscount(product);

            productsToDiscount.forEach(product => {
                const { productPrice } = this.calculateProductPrice(
                    product,
                    product.quantity
                );
                const discount = this.calculateDiscount(
                    productPrice,
                    discountValue
                );

                this.setPiggyProductDiscount({
                    id: product.id,
                    value: discount,
                    modifierIds: product.modifiers.map(modifier => modifier.id)
                });
            });

            this.cartTotalWithDiscount();
        },
        getProductsToDiscount(product) {
            const { categoryProperty, cartItemProperty } =
                this.getCategoryAndCartItemProperties();

            if (this.discountType.prefix === 'category') {
                this.categoryPosId = this.categories.find(
                    category => category.id === this.selectedProduct
                )?.[categoryProperty];

                return this.cartItems.filter(
                    cartItem =>
                        cartItem[cartItemProperty] === this.categoryPosId
                );
            } else {
                return [product];
            }
        },
        calculateProductPrice(product, quantity) {
            const isMulti = this.discountType.type.includes('multi');
            const isModifiers = this.discountType.type.includes('modifiers');
            const basePrice = product.price * (isMulti ? quantity : 1);
            const modifiersPrice = isModifiers
                ? isMulti
                    ? this.sumModifiersPrice(product) * quantity
                    : this.sumModifiersPrice(product)
                : 0;
            const productPrice = basePrice + modifiersPrice;

            return { productPrice };
        },
        calculateDiscount(productPrice, discountValue) {
            const discountType = this.discountType.type;

            if (
                discountType.includes('product_percent') ||
                discountType.includes('category_percent')
            ) {
                return Math.min(
                    productPrice * (discountValue / 100),
                    productPrice
                );
            }

            if (discountType.includes('product_amount')) {
                return Math.min(discountValue * 100, productPrice);
            }

            return 0;
        },
        sumModifiersPrice(product) {
            if (product.modifiers.length) {
                return product.modifiers.reduce(
                    (total, modifier) => total + modifier.price,
                    0
                );
            }

            return 0;
        },
        getCategoryAndCartItemProperties() {
            let categoryProperty;
            let cartItemProperty;

            switch (this.posProvider) {
                case 'deliverect':
                    categoryProperty = 'deliverectId';
                    cartItemProperty = 'categoryPosId';
                    break;
                case 'ikentoo':
                    categoryProperty = 'posId';
                    cartItemProperty = 'categoryPosId';
                    break;
                default:
                    categoryProperty = 'id';
                    cartItemProperty = 'categoryId';
                    break;
            }

            return { categoryProperty, cartItemProperty };
        },
        applyBasketDiscount(discountType) {
            const discountValue = getDiscountValue(this.reward);

            if (discountType === 'basket_amount') {
                this.setPiggyProductDiscount({
                    id: 'basket_amount',
                    value: Math.min(discountValue * 100, this.cartSubtotal),
                    modifierIds: []
                });
            }

            if (discountType === 'basket_percentage') {
                this.setPiggyProductDiscount({
                    id: 'basket_percentage',
                    value: this.cartSubtotal * (discountValue / 100),
                    modifierIds: []
                });
            }

            this.cartTotalWithDiscount();
        },
        modifierPercentDiscount() {
            const product = this.cartItems.find(
                cartItem =>
                    cartItem.modifiers &&
                    cartItem.modifiers.some(
                        modifier => modifier.id === this.selectedProduct
                    )
            );
            const modifier = product.modifiers.find(
                modifier => modifier.id === this.selectedProduct
            );
            const discountValue = getDiscountValue(this.reward);

            this.setPiggyProductDiscount({
                id: product.id,
                value: Math.min(
                    modifier.price * (discountValue / 100),
                    modifier.price
                ),
                modifierIds: [modifier.id]
            });

            this.cartTotalWithDiscount();
        }
    }
};
</script>
