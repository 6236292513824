<template>
    <div class="flex items-center text-subheading">
        <!--        <img-->
        <!--            class="mt-[2px] w-12 sm:w-14"-->
        <!--            src="https://ucarecdn.com/5c4d2f0a-2a66-40eb-9d65-04a639149c6e/-/format/auto/"-->
        <!--            alt="Piggy"-->
        <!--        />-->
        <span
            v-if="!isCalculate && isSushidogCatering"
            class="text-sm opacity-70"
        >
            You will earn one stamp for orders over £250
        </span>
        <span v-else-if="!isCalculate" class="text-sm opacity-70">
            {{
                $t('checkout.piggyEarnText', {
                    value: estimatedCredits,
                    creditName: piggyCreditName
                })
            }}
        </span>
        <span v-else class="flex text-sm opacity-70">
            calculating
            <span id="blink">.</span>
            <span id="blink">.</span>
            <span id="blink">.</span>
        </span>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'PiggyText',
    props: {
        isCalculate: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState({
            estimatedCredits: state =>
                state.customer.piggy.piggyEstimatedCredits,
            creditUnitName: state =>
                state.venue.venue.piggySettings.creditUnitName,
            venueId: state => state.venue.venue.id
        }),
        piggyCreditName() {
            return this.creditUnitName || 'points';
        },
        isSushidogCatering() {
            const sushidogIds = [8709, 7187, 7571, 7182, 7572, 9017, 9315];
            return sushidogIds.includes(this.venueId);
        }
    }
};
</script>

<style scoped>
#blink {
    animation: 1s blink infinite;
}
#blink:nth-child(2) {
    animation-delay: 250ms;
}
#blink:nth-child(3) {
    animation-delay: 500ms;
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
</style>
