const state = {
    giftCardReference: null,
    giftCardEnabled: true,
    giftCardBalance: null,
    giftCardApplied: false
};

const mutations = {
    setGiftCardReference(state, value) {
        state.giftCardReference = value;
    },
    setGiftCardEnabled(state, value) {
        state.giftCardEnabled = value;
    },
    setGiftCardBalance(state, value) {
        console.log('setting balance', value);
        state.giftCardBalance = value;
    },
    setGiftCardApplied(state, value) {
        state.giftCardApplied = value;
    }
};

const getters = {
    giftCardReference(state) {
        return state.giftCardReference;
    },
    giftCardEnabled(state) {
        return state.giftCardEnabled;
    },
    giftCardBalance(state) {
        return state.giftCardBalance;
    },
    giftCardApplied(state) {
        return state.giftCardApplied;
    }
};

const actions = {
    resetGiftCardStoreValues({ commit }) {
        console.log('resetting gift card');
        commit('setGiftCardReference', null);
        commit('setGiftCardEnabled', false);
        commit('setGiftCardBalance', null);
        commit('setGiftCardApplied', false);
    },
    removeGiftCardftCard({ commit, dispatch }) {
        console.log('removing gift card');
        commit('setGiftCardReference', null);
        commit('setGiftCardBalance', null);
        commit('setGiftCardApplied', false);
        dispatch('cart/cartTotalWithDiscount', null, { root: true });
    },
    async isGiftCardEnabled({ commit, rootState }, venueId) {
        try {
            const accountId = rootState.venue.venue.accountId;

            await this.$axios.get(
                `toggle/account/${accountId}/venue/${venueId}/status`
            );

            commit('setGiftCardEnabled', true);
        } catch (error) {
            commit('setGiftCardEnabled', true);

            if (error.response && error.response.data) {
                return error.response.data;
            }
        }
    },
    async checkGiftCardBalance({ commit }, payload) {
        try {
            const { venueId, giftCardReference } = payload;

            const { data } = await this.$axios.get(
                `/venues/${venueId}/giftpro/voucher-code/${giftCardReference}`
            );

            console.log(data.remainingValue);

            let balance = null;

            if (data.remainingValue) {
                balance = data.remainingValue * 100;
            }

            console.log('setting balance', balance);

            commit('setGiftCardReference', giftCardReference);
            commit('setGiftCardBalance', balance);
        } catch (error) {
            console.log('error');
            commit('setGiftCardBalance', null);

            if (error.response && error.response.data) {
                return error.response.data;
            }
        }
    },
    async redeemGiftCard({ commit, dispatch }, payload) {
        try {
            const { venueId, giftCardReference, amount, billId } = payload;

            await this.$axios.post(`/giftpro/redeem`, {
                venueId,
                voucherCode: giftCardReference,
                billId,
                amount
            });

            commit('setGiftCardApplied', true);
            dispatch('resetGiftCardStoreValues');
        } catch (error) {
            commit('setGiftCardApplied', false);

            if (error.response && error.response.data) {
                return error.response.data;
            }
            dispatch('resetGiftCardStoreValues');
        }
    }
};

export default {
    namespaced: true,
    getters,
    mutations,
    state,
    actions
};
