export default class CalculationMethodsEnum {
    static get ALL_INCLUDED() {
        return 'all_included';
    }

    static get JUST_FOOD() {
        return 'just_food';
    }

    static get options() {
        return {
            ALL_INCLUDED: this.ALL_INCLUDED,
            JUST_FOOD: this.JUST_FOOD,
        };
    }
}
