import isLocalStorageAvailable from '@/helpers/isLocalStorageAvailable';

// Track the current request's AbortController
let currentAbortController = null;

const state = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    companyName: '',
    notes: '',
    policyAgreed: false,
    lang: '',
    piggy: {
        loyaltyCheckboxState: false,
        piggyEstimatedCredits: 0,
        rewardSelected: {}
    },
    como: {
        transactionId: null,
        openTime: null,
        discountSum: 0,
        redeemAssets: null,
        deals: []
    },
    auth: false,
    identifierType: 'email',
    allergenDisabled: null
};

const getters = {
    policyAgreed: state => accountId => {
        if (!isLocalStorageAvailable()) {
            return state.policyAgreed;
        }

        const skOptInApproval = localStorage.getItem(`sk_optin_${accountId}`);
        const existingApproval = skOptInApproval && skOptInApproval === 'true';

        return existingApproval || state.policyAgreed;
    },
    lang: state => {
        return state.lang;
    },
    auth: (state, getters, rootState) => {
        const accountId = rootState.venue.venue.accountId;
        const auth = state.auth;

        if (!isLocalStorageAvailable() || !accountId) {
            return auth;
        }

        const skAuthApproval = localStorage.getItem(`sk_auth_${accountId}`);
        const existingAuth = skAuthApproval && skAuthApproval === 'true';

        return existingAuth || auth;
    },
    allergenDisabled: (state, rootState) => {
        const venueId = rootState.venue?.venue?.id;
        if (!isLocalStorageAvailable()) {
            return state.allergenDisabled;
        }

        const allergenDisabledValue = localStorage.getItem(
            `sk_allergen_disabled_${venueId}`
        );

        if (allergenDisabledValue === null) {
            return state.allergenDisabled;
        }

        return allergenDisabledValue === 'true';
    }
};

const mutations = {
    setFirstName(state, value) {
        state.firstName = value;
    },
    setLastName(state, value) {
        state.lastName = value;
    },
    setEmail(state, value) {
        state.email = value;
    },
    setPhone(state, value) {
        state.phone = value;
    },
    setCompanyName(state, value) {
        state.companyName = value;
    },
    setCustomer(state, value) {
        state = value;
    },
    setPolicyAgreed(state, value) {
        state.policyAgreed = value;
    },
    setNotes(state, value) {
        state.notes = value;
    },
    setLang(state, value) {
        state.lang = value;
    },
    SET_LOYALTY_CHECKBOX_STATE(state, value) {
        state.piggy.loyaltyCheckboxState = value;
    },
    SET_ESTIMATED_CREDITS(state, credits) {
        state.piggy.piggyEstimatedCredits = credits;
    },
    SET_AUTH(state, value) {
        state.auth = value;
    },
    SET_COMO_DATA(state, value) {
        state.como.openTime = value.openTime;
        state.como.transactionId = value.transactionId;
    },
    SET_COMO_DISCOUNT_SUM(state, value) {
        state.como.discountSum = value;
    },
    SET_COMO_DEALS(state, value) {
        state.como.deals = value;
    },
    SET_COMO_REWARD(state, value) {
        state.como.redeemAssets = value;
    },
    CLEAR_COMO_REWARDS(state) {
        state.como.deals = [];
        state.como.redeemAssets = null;
        state.como.discountSum = 0;
    },
    CLEAR_COMO_DATA(state) {
        state.como.transactionId = null;
        state.como.openTime = null;
    },
    SET_IDENTIFIER_TYPE(state, value) {
        state.identifierType = value;
    },
    SET_ALLERGEN_DISABLED(state, value) {
        state.allergenDisabled = value;
    }
};

const actions = {
    setPolicyAgreed({ commit, getters }, { accountId, value }) {
        if (accountId && isLocalStorageAvailable()) {
            localStorage.setItem(
                `sk_optin_${accountId}`,
                getters.policyAgreed(accountId)
            );

            return;
        }

        commit('setPolicyAgreed', value);
    },
    setAuth({ commit }, { accountId, value }) {
        if (accountId && isLocalStorageAvailable()) {
            localStorage.setItem(`sk_auth_${accountId}`, value);
        }

        // Update the Vuex state to ensure reactivity
        commit('SET_AUTH', value);
    },
    async calculateEstimatedCredits({ commit }, params) {
        // Cancel any existing request
        if (currentAbortController) {
            currentAbortController.abort();
        }

        // Create new AbortController for this request
        currentAbortController = new AbortController();
        const { signal } = currentAbortController;

        try {
            const { data } = await this.$axios.get(`/piggy/calculate-credits`, {
                params,
                signal
            });

            commit('SET_ESTIMATED_CREDITS', data);
        } catch (error) {
            // Ignore AbortError, but throw other errors
            if (error.name !== 'AbortError') {
                throw new Error(`API ${error}`);
            }
        } finally {
            // Always clear the controller reference after completion
            currentAbortController = null;
        }
    },
    setAllergenDisabled({ commit }, { venueId, value }) {
        if (venueId && isLocalStorageAvailable()) {
            localStorage.setItem(`sk_allergen_disabled_${venueId}`, value);
        }

        // Update the Vuex state to ensure reactivity
        commit('SET_ALLERGEN_DISABLED', value);
    },
    clearRewardsAndDiscounts({ commit }) {
        commit('CLEAR_COMO_DATA');
        commit('CLEAR_COMO_REWARDS');
        commit('cart/RESET_ITEM_DISCOUNT', null, { root: true });
        commit('cart/CLEAR_PIGGY_DISCOUNT', null, { root: true });
        commit('cart/SET_PIGGY_REWARD_SELECTED', {}, { root: true });
    }
};

export default {
    namespaced: true,
    getters,
    mutations,
    state,
    actions
};
