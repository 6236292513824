<template>
    <modal
        name="venue-notice-before-checkout-modal"
        height="auto"
        width="575"
        :maxWidth="575"
        :adaptive="true"
        :scrollable="true"
        :clickToClose="false"
        :class="{ 'mobile-item-modal': pivotY === 1 }"
        :pivotY="pivotY"
        :reset="true"
    >
        <div
            class="item-modal-content relative flex flex-col w-full bg-primary"
        >
            <div class="close-modal absolute z-50 right-0 top-0">
                <button
                    @click="hideModal()"
                    class="no-highlight p-3 text-muted"
                >
                    <close-icon />
                </button>
            </div>

            <div
                class="item-modal-details flex-auto p-6 pb-0 overflow-y-auto sm:p-8 sm:pb-2"
            >
                <div v-if="notice" class="text-pretty whitespace-pre-line">
                    <h4
                        class="font-heading pb-2 pl-3 text-heading text-lg font-bold"
                    >
                        {{ notice.title }}
                    </h4>
                    <p
                        class="p-3 text-sm md:text-base break-words"
                        :class="noticeClasses"
                        v-html="notice.body"
                    />
                </div>
            </div>
            <div
                class="flex flex-col-reverse items-start justify-between p-6 pb-20 sm:pb-8 sm:p-8 md:flex-row"
            >
                <button
                    @click="$emit('confirm')"
                    class="no-highlight flex-auto p-3.5 px-12 w-full text-center text-button text-sm font-bold bg-button font-button rounded-sm shadow select-none md:mb-0 md:w-auto"
                >
                    {{ $t('venueNoticeBeforeCheckoutModal.continue') }}
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import isMobile from '@/helpers/isMobileDevice';
import { mapState } from 'vuex';
import CloseIcon from '../icons/CloseIcon';

export default {
    name: 'venue-notice-before-checkout-modal',
    components: { CloseIcon },
    computed: {
        ...mapState('venue', {
            notices: state => state.venue.venueNotices
        }),
        notice() {
            if (!this.notices || !this.notices.length) {
                return [];
            }

            const notice = this.notices.filter(
                notice => notice['showInModalBeforeCheckout']
            )[0];

            if (notice) {
                this.$emit('showModalBeforeCheckout');
            }

            return notice;
        },
        pivotY() {
            return isMobile() ? 1 : 0.5;
        },
        noticeClasses() {
            if (!this.notice) return '';
            const typeClasses = {
                0: 'text-blue-700 bg-blue-50 sm:rounded-md sm:shadow-inner',
                1: 'text-green-700 bg-green-50 sm:rounded-md sm:shadow-inner',
                2: 'text-yellow-700 bg-yellow-50 sm:rounded-md sm:shadow-inner',
                3: 'text-muted bg-secondary'
            };
            return `${typeClasses[this.notice.type] || ''}`;
        }
    },
    methods: {
        hideModal() {
            this.$modal.hide('venue-notice-before-checkout-modal');
        }
    }
};
</script>
