<template>
    <div v-if="hasNotices">
        <div
            v-for="notice in filteredNotices"
            :key="notice.id"
            :class="getNoticeClasses(notice.type)"
        >
            <h3 v-if="notice.title" :class="getTitleClasses(notice.type)">
                {{ notice.title }}
            </h3>
            <div
                v-if="notice.body"
                class="text-pretty whitespace-pre-line overflow-hidden"
            >
                <p :class="getBodyClasses(notice.type)" v-html="notice.body" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

const LOCATION_MAP = {
    menu: 'showInMenu',
    basket: 'showInBasket',
    orderStatus: 'showInOrderStatus'
};

const TYPE_STYLES = {
    0: {
        container: 'bg-blue-50',
        title: 'text-blue-800',
        body: 'text-blue-700'
    },
    1: {
        container: 'bg-green-50',
        title: 'text-green-800',
        body: 'text-green-700'
    },
    2: {
        container: 'bg-yellow-50',
        title: 'text-yellow-800',
        body: 'text-yellow-700'
    },
    3: {
        container: 'bg-secondary',
        title: 'text-heading',
        body: 'text-muted'
    }
};

export default {
    name: 'StoreNotice',
    props: {
        location: {
            type: String,
            required: true,
            validator: value => Object.keys(LOCATION_MAP).includes(value)
        }
    },
    computed: {
        ...mapState('venue', {
            notices: state => state.venue.venueNotices
        }),

        filteredNotices() {
            const locationKey = LOCATION_MAP[this.location];
            return this.notices.filter(notice => notice[locationKey]);
        },

        hasNotices() {
            return this.filteredNotices.length > 0;
        }
    },

    methods: {
        getNoticeClasses(type) {
            const baseClasses =
                'px-3 py-3 break-words shadow-none sm:mb-4 sm:px-4 sm:rounded-md sm:shadow-inner';
            return `${baseClasses} ${TYPE_STYLES[type]?.container || ''}`;
        },

        getTitleClasses(type) {
            return [
                'mb-1 text-sm font-medium leading-5',
                TYPE_STYLES[type]?.title
            ];
        },

        getBodyClasses(type) {
            return [
                'm-auto text-sm leading-5 sm:text-xs',
                TYPE_STYLES[type]?.body
            ];
        }
    }
};
</script>
