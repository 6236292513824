<template>
    <div class="mb-4" id="customer-details">
        <div v-if="isEdit">
            <div class="relative flex mb-4">
                <div
                    v-if="userInformationSettings.firstName"
                    class="relative"
                    :class="
                        userInformationSettings.lastName
                            ? 'w-1/2 md:pr-2'
                            : 'w-full'
                    "
                >
                    <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        v-if="v.firstName"
                        v-model.trim="firstName"
                        autocomplete="given-name"
                        style="height: 50px"
                        :class="{
                            'form-error': v.firstName.$error,
                            'shadow-sm': !v.firstName.$error
                        }"
                        class="email-input block pt-3 px-4 w-full text-sm bg-secondary border rounded"
                        required
                        :disabled="disableAll"
                    />
                    <label
                        :for="firstName"
                        class="email-input-label absolute pt-1 px-4 rounded pointer-events-none select-none"
                    >
                        {{ $t('customerDetails.firstName') }}
                    </label>
                </div>
                <div
                    v-if="userInformationSettings.lastName"
                    class="relative"
                    :class="
                        userInformationSettings.firstName
                            ? 'w-1/2 pl-2'
                            : 'w-full'
                    "
                >
                    <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        autocomplete="family-name"
                        v-if="v.lastName"
                        v-model.trim="lastName"
                        style="height: 50px"
                        :class="{
                            'form-error': v.lastName.$error,
                            'shadow-sm': !v.lastName.$error
                        }"
                        class="email-input block pt-3 px-4 w-full text-sm bg-secondary border rounded"
                        required
                        :disabled="disableAll"
                    />
                    <label
                        for="lastName"
                        class="email-input-label absolute pt-1 px-4 rounded pointer-events-none select-none"
                    >
                        {{ $t('customerDetails.lastName') }}
                    </label>
                </div>
            </div>
            <div
                v-if="userInformationSettings.email !== 'disabled' || isBillPay"
                class="relative mb-4"
            >
                <input
                    type="email"
                    name="email"
                    autocomplete="email"
                    v-model.trim="email"
                    id="email"
                    style="height: 50px"
                    :class="{
                        'form-error': v.email.$error,
                        'shadow-sm': !v.email.$error,
                        'opacity-50': isEmailLocked
                    }"
                    class="email-input block pt-3 px-4 w-full text-sm bg-secondary border rounded"
                    required
                    :disabled="disableAll || isEmailLocked"
                />
                <label
                    for="email"
                    class="email-input-label absolute pt-1 px-4 rounded pointer-events-none select-none"
                >
                    {{
                        userInformationSettings.email === 'required' ||
                        isBillPay
                            ? $t('customerDetails.emailAddress')
                            : 'Email (optional, enter to receive a receipt)'
                    }}
                </label>
                <loyalty-auth-lock :is-locked="isEmailLocked" />
            </div>
            <!--  @TODO remove fallback for phone settings -->
            <div
                v-if="
                    (v.phone && userInformationSettings.phone === 'enabled') ||
                    userInformationSettings.phone === true
                "
                class="relative mb-4"
            >
                <input
                    type="tel"
                    name="phone"
                    autocomplete="tel"
                    v-model.trim="phone"
                    id="phone"
                    style="height: 50px"
                    :class="{
                        'form-error': v.phone.$error,
                        'shadow-sm': !v.phone.$error,
                        'opacity-50': isPhoneLocked
                    }"
                    class="email-input block pt-3 px-4 w-full text-sm bg-secondary border rounded"
                    required
                    :disabled="disableAll || isPhoneLocked"
                />
                <label
                    for="phone"
                    class="email-input-label absolute pt-1 px-4 rounded pointer-events-none select-none"
                >
                    {{ $t('customerDetails.phoneNumber') }}
                </label>
                <loyalty-auth-lock :is-locked="isPhoneLocked" />
            </div>
            <div
                v-if="v.phone && userInformationSettings.phone === 'validated'"
            >
                <span class="text-sm leading-5 text-gray-500">
                    {{ $t('customerDetails.phoneNumber') }}
                </span>

                <div class="relative mb-4">
                    <phone-input
                        id="validated-phone"
                        v-model="phone"
                        :error="v.phone.$error"
                        :is-phone-locked="isPhoneLocked"
                        @error="phoneValidation"
                    />
                    <loyalty-auth-lock :is-locked="isPhoneLocked" />
                </div>
            </div>
            <div
                v-if="
                    v.companyName &&
                    userInformationSettings.companyName !== 'disabled'
                "
                class="relative mb-4"
            >
                <input
                    type="text"
                    name="companyName"
                    autocomplete="none"
                    v-model.trim="companyName"
                    id="companyName"
                    style="height: 50px"
                    :class="{
                        'form-error': v.companyName.$error,
                        'shadow-sm': !v.companyName.$error
                    }"
                    class="email-input block pt-3 px-4 w-full text-sm bg-secondary border rounded"
                    required
                    :disabled="disableAll"
                />
                <label
                    for="companyName"
                    class="email-input-label absolute pt-1 px-4 rounded pointer-events-none select-none"
                >
                    {{ $t('customerDetails.companyName') }}
                </label>
            </div>
        </div>
        <div
            v-else-if="isUserDataCollected"
            @click="isEdit = true"
            class="relative p-3 border rounded shadow-sm cursor-pointer"
        >
            <div class="">
                <span class="text-sm font-medium text-subheading block leading">
                    <span v-show="userInformationSettings.firstName">
                        {{ firstName }}
                    </span>
                    <span v-show="userInformationSettings.lastName">
                        {{ lastName }}
                    </span>
                </span>
                <span
                    v-show="
                        userInformationSettings.email !== 'disabled' ||
                        isBillPay
                    "
                    class="text-sm text-muted block leading"
                >
                    {{ email }}
                </span>
                <span
                    v-show="userInformationSettings.companyName !== 'disabled'"
                    class="text-sm text-muted block leading"
                >
                    {{ companyName }}
                </span>
            </div>
            <div class="top-0 right-0 px-4 py-2.5 absolute">
                <span class="text-subheading font-medium text-sm">
                    <dropdown-icon />
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DropdownIcon from '../icons/DropdownIcon';
import LoyaltyAuthLock from '@/components/formElements/LoyaltyAuthLock';
import PhoneInput from '@/components/formElements/PhoneInput.vue';

export default {
    name: 'CustomerDetails',
    components: { DropdownIcon, PhoneInput, LoyaltyAuthLock },
    props: {
        v: {
            type: Object,
            required: true,
            default: () => {}
        },
        disableAll: {
            type: Boolean,
            required: true,
            default: false
        },
        isBillPay: {
            type: Boolean,
            required: false,
            default: false
        },
        isLoyaltyAuth: {
            type: Boolean,
            required: false,
            default: false
        },
        loyaltyIdentifierType: {
            type: String,
            required: false,
            default: 'email'
        }
    },
    data() {
        return {
            isEdit: true
        };
    },
    mounted() {
        const isValid = !this.v.$invalid;
        const isEmailDisabled =
            this.userInformationSettings.email === 'disabled' &&
            !this.isBillPay;
        const isEmailOptional =
            this.userInformationSettings.email !== 'required' &&
            !this.isBillPay;
        const isEmailEmpty = !this.email;

        if (isEmailDisabled) {
            if (isValid) {
                this.isEdit = false;
            }
            // For non-disabled email, check optional/empty status
        } else if (isValid && (!isEmailOptional || !isEmailEmpty)) {
            this.isEdit = false;
        }
    },
    computed: {
        ...mapGetters({
            userInformationSettings: 'venue/userInformationSettings'
        }),
        isUserDataCollected() {
            return (
                this.userInformationSettings?.email !== 'disabled' ||
                this.userInformationSettings?.phone === 'enabled' ||
                this.userInformationSettings?.phone === 'validated' ||
                this.userInformationSettings?.firstName ||
                this.userInformationSettings?.lastName ||
                this.userInformationSettings?.companyName !== 'disabled'
            );
        },
        firstName: {
            get() {
                return this.$store.state.customer.firstName;
            },
            set(value) {
                this.$store.commit('customer/setFirstName', value);

                if (this.userInformationSettings.firstName) {
                    this.v.firstName.$touch();
                }
            }
        },
        lastName: {
            get() {
                return this.$store.state.customer.lastName;
            },
            set(value) {
                this.$store.commit('customer/setLastName', value);

                if (this.userInformationSettings.lastName) {
                    this.v.lastName.$touch();
                }
            }
        },
        email: {
            get() {
                return this.$store.state.customer.email;
            },
            set(value) {
                const val = value.toLowerCase();
                this.$store.commit('customer/setEmail', val);

                if (
                    this.userInformationSettings.email !== 'disabled' ||
                    this.isBillPay
                ) {
                    this.v.email.$touch();
                }
            }
        },
        phone: {
            get() {
                return this.$store.state.customer.phone;
            },
            set(value) {
                this.$store.commit('customer/setPhone', value);

                if (
                    //    @TODO remove phone number fallback
                    this.userInformationSettings?.phone === true ||
                    this.userInformationSettings.phone === 'enabled' ||
                    this.userInformationSettings.phone === 'validated'
                ) {
                    this.v.phone.$touch();
                }
            }
        },
        companyName: {
            get() {
                return this.$store.state.customer.companyName;
            },
            set(value) {
                this.$store.commit('customer/setCompanyName', value);

                if (this.userInformationSettings.companyName !== 'disabled') {
                    this.v.companyName.$touch();
                }
            }
        },
        isEmailLocked() {
            return this.isLoyaltyAuth && this.loyaltyIdentifierType === 'email';
        },
        isPhoneLocked() {
            return this.isLoyaltyAuth && this.loyaltyIdentifierType === 'phone';
        }
    },
    methods: {
        phoneValidation(value) {
            if (
                this.userInformationSettings?.phone === true ||
                this.userInformationSettings?.phone === 'enabled' ||
                this.userInformationSettings?.phone === 'validated'
            ) {
                this.$emit('phoneValidation', value);
            }
        }
    }
};
</script>

<style src="@/assets/css/customer-details.css"></style>
