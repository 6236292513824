<template>
    <div class="recommendations-container flex-col w-full overflow-scroll">
        <h2
            class="font-heading pb-2 pt-8 text-heading text-sm font-bold"
            v-if="
                (showTitle && recommendedItemsWithDetails.length > 0) ||
                isLoading
            "
        >
            Suggested for you...
        </h2>
        <div
            class="flex flex-nowrap overflow-x-scroll snap-x snap-mandatory gap-4"
        >
            <template v-if="isLoading">
                <div
                    v-for="n in 4"
                    :key="n"
                    class="relative flex w-full flex-row bg-white border-1 border border-gray-300 pr-1.5 py-1.5 rounded-md items-center self-stretch min-w-[280px] justify-between outline-none cursor-pointer select-none"
                >
                    <vue-content-loading
                        :width="280"
                        :height="76"
                        :speed="2"
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                    >
                        <!-- Image placeholder (64x64 with margin) -->
                        <rect
                            x="12"
                            y="6"
                            rx="2"
                            ry="2"
                            width="64"
                            height="64"
                        />

                        <!-- Title placeholder (2 lines) -->
                        <rect
                            x="88"
                            y="8"
                            rx="2"
                            ry="2"
                            width="120"
                            height="12"
                        />
                        <rect
                            x="88"
                            y="24"
                            rx="2"
                            ry="2"
                            width="80"
                            height="12"
                        />

                        <!-- Price and Quick Add placeholder -->
                        <rect
                            x="88"
                            y="48"
                            rx="2"
                            ry="2"
                            width="40"
                            height="14"
                        />
                        <rect
                            x="140"
                            y="48"
                            rx="4"
                            ry="4"
                            width="70"
                            height="14"
                        />
                    </vue-content-loading>
                </div>
            </template>
            <menu-item-cross-sell
                v-for="(item, index) in recommendedItemsWithDetails"
                :key="`${item.id}-${index}`"
                :item="item"
                :isAvailable="isItemAvailable(item)"
                :navigate-to-basket="true"
                :largeImages="largeImages"
                :quick-add="true"
                @click.native="showItemModal(item)"
                @showItemModal="$emit('showItemModal', item)"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getRecommendations } from '@/services/recommendation';
import MenuItemCrossSell from '@/components/MenuItemCrossSell';
import VueContentLoading from 'vue-content-loading';
import { debounce } from '@/helpers/debounce';

export default {
    name: 'MenuRecommendations',
    components: {
        MenuItemCrossSell,
        VueContentLoading
    },
    props: {
        location: {
            type: String,
            default: 'menu',
            validator: value => ['menu', 'modal', 'basket'].includes(value)
        },
        currentItem: {
            type: Object,
            default: null
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        largeImages: {
            type: Boolean,
            default: false
        },
        instanceId: {
            type: String,
            required: true
        },
        navigateToBasket: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            recommendedItems: [],
            isLoading: false,
            error: null,
            lastLoadedConfig: null
        };
    },
    computed: {
        ...mapState({
            userEmail: state => state.customer.email,
            cartItems: state => state.cart.items
        }),
        ...mapGetters({
            getProductById: 'cart/getProductById'
        }),
        isModal() {
            return this.location === 'modal';
        },
        recommendedItemsWithDetails() {
            return this.recommendedItems
                ? this.recommendedItems
                      .map(itemId => {
                          const found = this.getProductById(itemId);
                          return found;
                      })
                      .filter(item => item)
                : [];
        },
        titleText() {
            switch (this.location) {
                case 'modal':
                    return 'Similar items you might like...';
                case 'basket':
                    return 'Frequently bought together...';
                default:
                    return 'Suggested for you...';
            }
        },
        // Create a unique configuration identifier
        currentConfig() {
            return JSON.stringify({
                location: this.location,
                currentItemId: this.currentItem?.id,
                instanceId: this.instanceId
            });
        }
    },
    methods: {
        isItemAvailable(item) {
            if (item.snooze) {
                return false;
            }

            const cartItem = this.$store.state.cart.items.find(
                x => x.id === item.id
            );
            let inventory = item.inventory;

            if (cartItem) {
                inventory -= cartItem.quantity;
            }

            if (!item.isAvailable && !item.trackInventory) {
                return false;
            } else if (item.isAvailable && !item.trackInventory) {
                return true;
            }

            return !!(item.trackInventory && inventory > 0);
        },
        async loadRecommendations() {
            if (this.isLoading) return;

            this.isLoading = true;
            this.error = null;

            // if (this.lastLoadedConfig === this.currentConfig) {
            //     return;
            // }

            // map location to recommendation type
            let itemIds;
            let recommendationType = this.location;
            if (this.location === 'menu' || this.location === 'status') {
                recommendationType = 'suggested';
            } else if (this.location === 'modal') {
                (itemIds = [this.currentItem?.id]),
                    (recommendationType = 'item');
            } else if (this.location === 'basket') {
                itemIds = this.cartItems.map(item => item.id);
                recommendationType = 'basket';
                if (itemIds.length === 0) {
                    this.isLoading = false;
                    return;
                }
            }

            try {
                const recommendedIds = await getRecommendations({
                    itemIds,
                    venueId: this.$store.state.venue.venue.id,
                    userId: this.userEmail,
                    recommendationType
                });
                this.recommendedItems = recommendedIds;
                this.lastLoadedConfig = this.currentConfig;
            } catch (error) {
                this.error = error;
                console.error('Failed to load recommendations:', error);
            } finally {
                this.isLoading = false;
            }
        },
        async showItemModal(item) {
            if (this.navigateToBasket) {
                await this.$router.push({ name: 'menu' });
            }
            this.$modal.show('item-modal', { item: item });
        }
    },
    async mounted() {
        await this.loadRecommendations();
    },
    watch: {
        currentItem: {
            handler: 'loadRecommendations',
            deep: true
        },
        cartItems: {
            handler: debounce(async function () {
                await this.loadRecommendations();
            }, 500),
            deep: true
        },
        location: {
            handler: 'loadRecommendations',
            deep: true
        }
    }
};
</script>

<style scoped>
.recommendations-container {
    margin-bottom: 1.5rem;
    position: relative;
}

/* Hide scrollbar for clean look */
.overflow-x-scroll {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    -webkit-overflow-scrolling: touch;
}

.overflow-x-scroll::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}
</style>
