<template>
    <div class="checkout-basket">
        <div class="basket-summary-wrap w-full h-full rounded-sm">
            <div class="basket-summary flex flex-col bg-secondary rounded-sm">
                <h3
                    class="m-4 py-4 text-heading font-heading text-base font-bold"
                    v-show="!mobile"
                >
                    {{ $t('checkout.basket') }}
                </h3>
                <div class="flex flex-col items-start justify-start">
                    <div class="basket-contents__header" />
                    <div class="no-scrollbar overflow-y self-stretch">
                        <div class="basket-contents__items">
                            <ul
                                :class="{ 'px-4': !mobile }"
                                class="basket-contents__items-list pb-5 list-none"
                            >
                                <li
                                    class="basket-item text-subheading flex flex-auto flex-row items-start justify-between"
                                    v-for="(item, index) in cartItems"
                                    :key="`${item.id}-${index}`"
                                >
                                    <span class="mr-3">
                                        <div
                                            class="relative inline-flex flex-row items-center justify-between"
                                        >
                                            <div
                                                class="w-6 text-center text-price"
                                            >
                                                {{ item.quantity }}x
                                            </div>
                                        </div>
                                    </span>
                                    <span
                                        class="item flex flex-auto flex-wrap justify-start mr-3 text-left"
                                    >
                                        <span
                                            class="name flex-auto self-center"
                                        >
                                            {{ item.name }}
                                        </span>
                                        <span
                                            v-if="item.modifiers"
                                            class="variants grow shrink-0 text-gray-600 text-sm"
                                            style="flex: 1 0 100%"
                                        >
                                            <span
                                                v-for="(
                                                    modifier, index
                                                ) in item.modifiers"
                                                :key="`${item.id}-${modifier.id}-${index}`"
                                                class="text-gray-400"
                                            >
                                                {{ modifier.name
                                                }}{{
                                                    index !==
                                                    item.modifiers.length - 1
                                                        ? ','
                                                        : ''
                                                }}
                                            </span>
                                        </span>
                                        <item-line-notes
                                            v-if="item.notes?.length > 0"
                                            :notes="item.notes"
                                        />
                                    </span>
                                    <span class="price text-right">
                                        {{
                                            ((countItemPrice(item) / 100) *
                                                item.quantity)
                                                | customCurrency
                                        }}
                                        <span
                                            class="text-sm text-gray-400"
                                            v-if="
                                                showDiscountValue(
                                                    item.id,
                                                    item.modifiers
                                                )
                                            "
                                        >
                                            <br />
                                            {{
                                                `-${
                                                    showDiscountValue(
                                                        item.id,
                                                        item.modifiers
                                                    ) / 100
                                                }` | customCurrency
                                            }}
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <hr
                        class="border-1 mx-auto w-4/5 border-gray-300 box-border"
                    />
                    <div class="basket-contents__totals mt-4 w-full">
                        <ul
                            :class="{ 'px-5': !mobile }"
                            class="pb-2 md:pb-5 text-subheading list-none"
                        >
                            <li
                                class="flex flex-row items-center justify-between mt-2"
                            >
                                <span>
                                    {{ $t('checkout.subtotal') }}
                                </span>
                                <span class="text-price">
                                    {{ (cartSubtotal / 100) | customCurrency }}
                                </span>
                            </li>
                        </ul>
                        <ul
                            v-if="giftCardBalance > 0"
                            class="pb-5 -mt-5 px-5 text-subheading list-none"
                        >
                            <li
                                class="flex flex-row items-center justify-between mt-2"
                            >
                                <span>
                                    BAO coin payment
                                    <span class="underline text-price"
                                        >Remove</span
                                    ></span
                                >
                                <span class="text-green-700">
                                    -{{
                                        ((giftCardBalance > cartSubtotal
                                            ? cartSubtotal
                                            : giftCardBalance) /
                                            100)
                                            | customCurrency
                                    }}
                                </span>
                            </li>
                        </ul>
                        <ul
                            v-if="loyaltyDiscount > 0"
                            :class="{ 'px-5': !mobile }"
                            class="pb-5 -mt-5 text-subheading list-none"
                        >
                            <li
                                class="flex flex-row items-center justify-between mt-2"
                            >
                                <span>
                                    {{ $t('checkout.loyaltyDiscount') }}
                                </span>
                                <span class="text-price">
                                    -{{ (loyaltyDiscount / 100) | customCurrency }}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div
                        class="basket-contents__footer md:border-t py-4 w-full rounded-b-sm"
                    >
                        <ul
                            :class="{ 'px-5': !mobile }"
                            class="basket-totals list-none"
                        >
                            <li
                                class="flex flex-auto flex-row justify-between text-subheading"
                                v-if="
                                    fulfillmentMethod ===
                                    FulfillmentMethodsEnum.DELIVERY
                                "
                            >
                                <span class="total">
                                    {{ $t('checkout.deliveryFee') }}
                                </span>
                                <span
                                    class="price"
                                    :class="{
                                        'flex flex-1 justify-end line-through':
                                            hasDeliveryDiscount
                                    }"
                                >
                                    {{ (deliveryFee / 100) | customCurrency }}
                                </span>
                                <span
                                    class="ml-1 flex items-center text-sm"
                                    v-if="hasDeliveryDiscount"
                                >
                                    {{ formattedDeliveryFee }}
                                </span>
                            </li>
                            <li
                                v-if="serviceCharge > 0"
                                class="flex flex-auto flex-row justify-between text-subheading"
                            >
                                <span class="total">
                                    {{
                                        serviceChargeLabel ||
                                        $t('checkout.serviceCharge')
                                    }}
                                </span>
                                <span class="price">
                                    {{ (serviceCharge / 100) | customCurrency }}
                                </span>
                            </li>
                            <li
                                v-if="discountCodeDetails"
                                class="text-subheading flex flex-auto flex-row justify-between"
                            >
                                <span class="total">
                                    {{ $t('checkout.discountCode') }}
                                </span>
                                <span
                                    v-if="
                                        discountCodeDetails.minimumAmount <=
                                            cartSubtotal &&
                                        discountCodeDetails.type ===
                                            DiscountTypesEnum.FIXED
                                    "
                                    class="price"
                                    >{{
                                        discountCodeDetails
                                            | discountCode
                                            | customCurrency
                                    }}</span
                                >
                                <span
                                    v-else-if="
                                        discountCodeDetails.minimumAmount <=
                                            cartSubtotal &&
                                        discountCodeDetails.type ===
                                            DiscountTypesEnum.PERCENT
                                    "
                                    class="price"
                                >
                                    {{ discountCodeDetails | discountCode }}
                                </span>
                                <span
                                    v-else-if="
                                        discountCodeDetails.type ===
                                        DiscountTypesEnum.BUY_ONE_GET_ONE_FREE
                                    "
                                    class="price"
                                    >{{
                                        `${totalAmountDiscounted / 100}`
                                            | customCurrency
                                    }}</span
                                >
                                <span
                                    v-else-if="hasDeliveryDiscount"
                                    class="price"
                                    >{{
                                        `${totalAmountDiscounted / 100}`
                                            | customCurrency
                                    }}</span
                                >
                                <span v-else class="price text-red-600">
                                    {{ $t('checkout.spend') }}
                                    {{
                                        ((discountCodeDetails.minimumAmount -
                                            cartSubtotal) /
                                            100)
                                            | customCurrency
                                    }}
                                    {{ $t('checkout.more') }}
                                </span>
                            </li>
                            <li
                                v-if="tip > 0"
                                class="flex flex-auto flex-row justify-between text-subheading"
                            >
                                <span class="total">
                                    {{ tipLabel || $t('checkout.tip') }}
                                </span>
                                <span class="price">
                                    {{ (tip / 100) | customCurrency }}
                                </span>
                            </li>
                            <li
                                class="flex flex-auto flex-row justify-between text-heading font-heading font-bold"
                            >
                                <span class="total"
                                    >{{ $t('checkout.total') }}
                                </span>
                                <span class="price">
                                    {{ (totalWithDiscount / 100) | customCurrency }}
                                </span>
                            </li>
                            <li
                                v-if="isCateringMethod"
                                class="flex justify-end items-center"
                            >
                                <span
                                    @click="openCalculationMethodModal()"
                                    class="price-per-person text-xs cursor-pointer"
                                >
                                    {{ (pricePerPerson / 100) | customCurrency }} /
                                    {{ $t('basket.person') }}
                                </span>
                            </li>
                            <piggy-text
                                v-if="showEstimatedCredits"
                                :is-calculate="isCalculate"
                            />
                            <button
                                v-if="
                                    !mobile &&
                                    piggySettings?.enabled &&
                                    piggySettings.enabledShowRewards
                                "
                                class="text-sm text-subheading"
                                @click="$modal.show('piggy-rewards-modal')"
                                >view rewards</button
                            >
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { isMatchingProduct } from '@/util/piggyHelper';
import { FulfillmentMethodsEnum, DiscountTypesEnum } from '@/enums';

export default {
    name: 'CheckoutBasket',
    props: {
        isCalculate: {
            type: Boolean,
            required: true,
            default: false
        },
        mobile: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            isLoading: true,
            FulfillmentMethodsEnum,
            DiscountTypesEnum
        };
    },
    computed: {
        ...mapState({
            cartItems: state => state.cart.items,
            venueId: state => state.venue.venue.id,
            deliveryFee: state =>
                state.cart.deliveryFee || state.venue.venue.deliveryFee || 0,
            serviceCharge: state => state.venue.venue.serviceCharge,
            serviceChargeLabel: state =>
                state.venue.venue.serviceChargeLabel || '',
            tipLabel: state => state.venue.venue.tipLabel || '',
            piggySettings: state => state.venue.venue.piggySettings,
            piggyProductDiscount: state => state.cart.piggyProductDiscount,
            totalPiggyDiscount: state => state.cart.totalPiggyDiscount,
            fulfillmentMethod: state => state.cart.fulfillmentMethod,
            discountedDeliveryFee: state => state.cart.discountedDeliveryFee,
            comoDiscountSum: state => state.customer.como.discountSum
        }),
        ...mapGetters({
            tip: 'cart/tip',
            cartSubtotal: 'cart/cartSubtotal',
            discountCodeDetails: 'discount/details',
            totalWithDiscount: 'cart/cartTotalWithDiscount',
            totalAmountDiscounted: 'cart/totalAmountDiscounted',
            giftCardBalance: 'giftCard/giftCardBalance',
            pricePerPerson: 'cart/getCalculatedPricePerPerson'
        }),
        ...mapActions({
            removeGiftCardftCard: 'giftCard/removeGiftCardftCard'
        }),
        hasDeliveryDiscount() {
            return (
                this.fulfillmentMethod === FulfillmentMethodsEnum.DELIVERY &&
                this.discountCodeDetails?.minimumAmount <= this.cartSubtotal &&
                this.discountedDeliveryFee !== this.deliveryFee
            );
        },
        showEstimatedCredits() {
            return !!(
                this.piggySettings?.enabled &&
                !this.piggySettings.loyaltyOptIn.enabledLoyalty
            );
        },
        isCateringMethod() {
            return FulfillmentMethodsEnum.isCateringMethod(
                this.fulfillmentMethod
            );
        },
        formattedDeliveryFee() {
            return this.discountedDeliveryFee === 0
                ? this.$t('basket.free')
                : this.$options.filters.customCurrency(
                      this.discountedDeliveryFee / 100
                  );
        },
        loyaltyDiscount() {
            return this.totalPiggyDiscount || this.comoDiscountSum;
        }
    },
    methods: {
        countItemPrice(item) {
            let price = item.price;

            if (item.modifiers) {
                item.modifiers.map(modifier => {
                    price += modifier.price;
                });
            }

            return price;
        },
        showDiscountValue(id, modifiers) {
            const modifierIds = modifiers.map(modifier => modifier.id);
            const discountValue = this.piggyProductDiscount.find(product =>
                isMatchingProduct(product, id, modifierIds)
            );

            return discountValue?.value;
        },
        openCalculationMethodModal() {
            this.$modal.show('calculation-method-modal');
        }
    }
};
</script>
