<template>
    <div
        v-if="isLocked"
        class="absolute top-2 right-2 cursor-pointer z-10"
        @click="$modal.show('loyalty-logout-modal')"
    >
        <padlock-icon />
    </div>
</template>
<script>
import PadlockIcon from '@/components/icons/PadlockIcon.vue';

export default {
    name: 'LoyaltyAuthLock',
    components: { PadlockIcon },
    props: {
        isLocked: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>
