import Vue from 'vue';
import VueCurrencyFilter from 'vue-currency-filter';
import currencyMap from './../util/currency';
import router from './../router';
import isLocalStorageAvailable from '@/helpers/isLocalStorageAvailable';
import { FulfillmentMethodsEnum, TrackingEventsEnum } from '@/enums';
import { i18n } from '@/plugins/i18n';
import { enablePlugins } from '@/services/analytics/enablePlugins';
import Analytics from 'analytics';
import {
    jitsu,
    klaviyo,
    facebookPixel,
    googleTagManager,
    intercom
} from '@/services/analytics';
import { kebabCase } from 'lodash-es';
import isMobile from '@/helpers/isMobileDevice';

const state = {
    isLoading: 0,
    venue: {
        id: '',
        logo: '',
        name: '',
        address: '',
        phoneNumber: '',
        acceptsDelivery: true,
        acceptsPickup: true,
        acceptsPreOrders: false,
        acceptsInStore: false,
        deliveryFee: 0,
        serviceCharge: 0,
        tipLabel: '',
        primaryColour: '',
        headerImage: '',
        description: '',
        tableDescriptor: '',
        tableScreenImage: '',
        enableOrderNotes: true,
        enableItemNotes: false,
        venueHours: [],
        visualMenu: false,
        activePaymentSystem: '',
        optInMessage: '',
        minInStore: 0,
        menuNotice: '',
        acceptsPat: null,
        metaTitle: '',
        metaDescription: '',
        showCurrencySymbol: true,
        categoryNavImages: 'NONE',
        modalImageStyle: 'medium',
        comoEnabled: false,
        imageSize: null,
        comoUserIdentifier: null,
        cartImages: 'disabled'
    },
    // items: [],
    categories: [],
    tableAreas: null
};

const mutations = {
    setDeliveryFeeFromZone(state, fee) {
        state.venue.deliveryFee = fee;
    },
    SAVE_VENUE(state, payload) {
        state.venue = payload;
    },
    SAVE_MENU_CATEGORIES(state, payload) {
        state.categories = payload;
    },
    SAVE_TABLES(state, payload) {
        state.tableAreas = payload;
    },
    FETCH_START(state) {
        state.isLoading++;
    },
    FETCH_END(state) {
        state.isLoading--;
    },
    DISABLE_ORDER_NOTES(state) {
        state.venue.enableOrderNotes = false;
    },
    SET_VISUAL_MENU(state, payload) {
        state.venue.visualMenu = payload;
    },
    clearAutoAppliedDiscountCodes(state) {
        state.venue.autoAppliedDiscountCode = [];
    },
    SET_VENUE_VALUE(state, { key, value }) {
        if (typeof state.venue[key] === 'object' && state.venue[key] !== null) {
            state.venue = {
                ...state.venue,
                [key]: {
                    ...state.venue[key],
                    ...value
                }
            };
        } else {
            state.venue[key] = value;
        }
    }
};

const getters = {
    isLoading(state) {
        return state.isLoading;
    },
    pickupSlots(state) {
        return state.venue.pickupSlots;
    },
    findTableById: state => id => {
        if (!state.tableAreas) {
            return null;
        }

        return state.tableAreas
            .reduce((arr, item) => arr.concat(item.tables), [])
            .find(sub => sub.id === id);
    },
    tableCount(state) {
        let count = 0;
        for (const area of state.tableAreas) {
            count += area.tables.length || 0;
        }
        return count;
    },
    autoAppliedDiscountCode({ venue }, getters, { cart }) {
        return (
            ((venue.autoAppliedDiscountCode || false)[0] || false)
                .fulfillmentMethods || []
        ).includes(cart.fulfillmentMethod)
            ? venue.autoAppliedDiscountCode[0]
            : null;
    },
    venueCoordinates(state) {
        // @TODO Venue address coordinates are stored incorrectly :(
        return [
            state.venue.address.lat || null,
            state.venue.address.lng || null
        ];
    },
    venueValue: state => value => {
        return state.venue[value];
    },
    selectedTable(state) {
        if (!isLocalStorageAvailable()) {
            return null;
        }

        const key = `selected-table-venue-${state.venue.id}`;
        const item = localStorage.getItem(key);

        if (!item) {
            return null;
        }

        const { tableId, expiry } = JSON.parse(item);

        if (new Date().getTime() > expiry) {
            localStorage.removeItem(key);

            return null;
        }

        return tableId;
    },
    selectedArea(state, getters, rootState) {
        const area = state.tableAreas?.find(area =>
            area.tables.find(sub => sub.id === rootState.cart.selectedTableId)
        );

        return area;
    },
    tipVariant(state) {
        return state.venue.acceptTips;
    },
    mandatoryTipSettings(state) {
        return state.venue.mandatoryTipSettings;
    },
    prepTime(state, getters, rootState) {
        let prepTimeByMethod;

        if (
            [
                FulfillmentMethodsEnum.IN_STORE,
                FulfillmentMethodsEnum.DELIVERY
            ].includes(rootState.cart.fulfillmentMethod)
        ) {
            prepTimeByMethod = Math.abs(
                state.venue.prepTime + rootState.cart.deliveryTime
            );
        } else {
            prepTimeByMethod = Math.abs(state.venue.collectionPrepTime);
        }

        if (prepTimeByMethod < 120) {
            return `${prepTimeByMethod} - ${prepTimeByMethod + 10} ${i18n.t(
                'timeUnits.minutes'
            )}`;
        } else if (prepTimeByMethod < 1440) {
            return `${Math.floor(prepTimeByMethod / 60)} ${i18n.t(
                'timeUnits.hours'
            )}`;
        }

        return `${Math.floor(prepTimeByMethod / 60 / 24)} ${i18n.t(
            'timeUnits.days'
        )}`;
    },
    smartReviewsEnabled(state) {
        return !!state.venue.reviewLinks.length;
    },
    userInformationSettings(state, getters, rootState) {
        const checkoutSettings = state.venue.checkoutSettings?.find(
            ({ fulfillmentMethod }) =>
                fulfillmentMethod === rootState.cart.fulfillmentMethod
        );

        if (checkoutSettings) {
            return {
                email: checkoutSettings.email,
                phone: checkoutSettings.phone,
                firstName: checkoutSettings.firstName,
                lastName: checkoutSettings.lastName,
                companyName: checkoutSettings.companyName
            };
        }

        return {
            email: 'required',
            phone: 'enabled',
            firstName: true,
            lastName: true,
            companyName: 'disabled'
        };
    },
    cutlerySetting(state, getters, rootState) {
        const checkoutSettings = state.venue.checkoutSettings?.find(
            ({ fulfillmentMethod }) =>
                fulfillmentMethod === rootState.cart.fulfillmentMethod
        );

        return checkoutSettings ? checkoutSettings.cutlery : false;
    },
    products(state) {
        return (
            state.categories?.flatMap(category => category.products ?? []) ?? []
        );
    },
    categoryExists: state => id => {
        return state.categories.some(category => category.id === id);
    }
};

const actions = {
    async getVenue({ dispatch, commit, rootGetters }, venueSlug) {
        if (venueSlug === '404') {
            await router.replace({ name: '404' });
            return false;
        }

        commit('FETCH_START');

        const isStoreDesigner = rootGetters['helpers/isStoreDesigner'];

        try {
            const {
                data: { venue }
            } = await this.$axiosRetry.get(`/venues/${venueSlug}`, {
                timeout: 8000,
                'axios-retry': {
                    retryCondition: error =>
                        error.response ? error.response.status !== 404 : true
                }
            });

            commit('SAVE_VENUE', venue);

            // Update Growthbook attributes with venue context
            if (Vue.prototype.$growthbook) {
                await Vue.prototype.$growthbook.updateAttributes({
                    venueId: venue.id,
                    accountId: venue.accountId,
                    venueSlug: venue.slug
                });
            }

            commit('deliveryAddress/setCountry', venue.address.country, {
                root: true
            });

            if (venue.acceptsInStore) {
                dispatch('getTables', venue.id);
                commit(
                    'cart/setFulfillmentMethod',
                    FulfillmentMethodsEnum.IN_STORE,
                    { root: true }
                );
            }

            if (venue.menu[0] && venue.menu[0].id) {
                dispatch('getMenuCategories', venue.menu[0].id);
            }

            if (venue.fonts) {
                for (const [key] of Object.entries(venue.fonts)) {
                    await mountCustomFont(
                        venue.fonts[key].name,
                        venue.fonts[key].url,
                        `--${key}-font`
                    );
                }
            }

            if (venue.branding) {
                let styles = venue.branding;

                for (const style in venue.branding) {
                    if (cssVariableKeys.includes(style)) {
                        setCustomVariable(style, styles[style]);

                        continue;
                    }

                    if (style === 'buttonFont') {
                        const val = venue.fonts[styles[style]].name;

                        setCustomVariable(style, val);
                    }
                }
            }

            if (venue?.noIndex || venue?.acceptsPat) {
                const noIndexTag = document.createElement('meta');
                noIndexTag.name = 'robots';
                noIndexTag.content = 'noindex';

                document.head.appendChild(noIndexTag);
            }

            if (venue?.faviconImage) {
                const favicon = document.getElementById('favicon');
                favicon.href = `${venue.faviconImage}-/resize/32x32/`;

                const appleFavicon = document.getElementById('apple-favicon');
                appleFavicon.href = `${venue.faviconImage}-/resize/180x180/`;
            }

            if (!venue.enableOrderNotes) {
                commit('DISABLE_ORDER_NOTES');
            }

            const {
                symbol,
                thousandsSeparator,
                fractionSeparator,
                symbolPosition
            } = currencyMap(venue.currencyCode);

            const baseConfig = {
                thousandsSeparator,
                fractionCount: 2,
                fractionSeparator,
                symbolPosition,
                symbolSpacing: false
            };

            const filtersConfig = [
                {
                    name: 'currency',
                    symbol,
                    thousandsSeparator,
                    fractionCount: 2,
                    fractionSeparator,
                    symbolPosition,
                    symbolSpacing: false
                },
                {
                    name: 'customCurrency',
                    ...baseConfig,
                    symbol: venue.showCurrencySymbol ? symbol : '',
                    avoidEmptyDecimals: venue.showCurrencySymbol
                        ? undefined
                        : ''
                }
            ];

            if (isStoreDesigner) {
                filtersConfig.push({
                    ...baseConfig,
                    name: 'currencyWithSymbol',
                    symbol,
                    avoidEmptyDecimals: undefined
                });
                filtersConfig.push({
                    ...baseConfig,
                    name: 'currencyWithoutSymbol',
                    symbol: '',
                    avoidEmptyDecimals: ''
                });
            }

            Vue.use(VueCurrencyFilter, filtersConfig);

            const plugins = [];

            if (venue.googleTagManagerId) {
                const venueGoogleTagManager = Object.assign(
                    {},
                    googleTagManager({
                        containerId: venue.googleTagManagerId,
                        enabled: false
                    }),
                    {
                        name: 'google-tag-venue'
                    }
                );

                plugins.push(venueGoogleTagManager);

                venueGoogleTagManager.updateConsent({
                    config: venueGoogleTagManager.config,
                    settings: {
                        ad_storage: 'granted',
                        analytics_storage: 'granted',
                        functionality_storage: 'granted',
                        personalization_storage: 'granted',
                        security_storage: 'granted',
                        ad_user_data: 'granted',
                        ad_personalization: 'granted'
                    }
                });
            }

            if (venue.klaviyoApiKey) {
                plugins.push(
                    klaviyo({ apiKey: venue.klaviyoApiKey, enabled: false })
                );
            }

            if (import.meta.env.VITE_JITSU_CLIENT_KEY) {
                plugins.push(
                    jitsu({
                        accountId: venue.accountId,
                        venueId: venue.id,
                        slug: venue.slug,
                        enabled: false
                    })
                );
            }

            if (venue.facebookPixelId) {
                plugins.push(
                    facebookPixel({
                        apiKey: venue.facebookPixelId,
                        enabled: false
                    })
                );
            }

            const sushidogIds = [8709, 7187, 7571, 7182, 7572, 9017, 9315];
            if (sushidogIds.includes(venue.id)) {
                plugins.push(
                    intercom({
                        appId: 'glazwf8n'
                    })
                );
            }

            if (!isStoreDesigner) {
                Vue.prototype.$analytics = Analytics({
                    app: 'storekit',
                    debug: import.meta.env.DEV,
                    plugins
                });

                if (Vue.prototype.$analytics) {
                    Vue.prototype.$analytics.track(
                        TrackingEventsEnum.ACTIVE_ON_SITE,
                        {
                            accountId: venue.accountId,
                            id: venue.id,
                            slug: venue.slug
                        }
                    );
                }

                dispatch('enablePlugins');
                await dispatch('setupOpenReplay');

                if (!isMobile() && sushidogIds.includes(venue.id)) {
                    console.log('Showing Intercom');
                    Vue.prototype.$analytics.plugins.intercom.show();
                }
            }

            // router.afterEach(() => {
            //     if (Vue.prototype.$analytics) {
            //         Vue.prototype.$analytics.page();
            //     }
            // });

            // Apply theme if theme parameter is present
            const themeParam = router?.currentRoute?.query?.theme;
            if (themeParam) {
                try {
                    const { data } = await this.$axiosRetry.get(
                        `/theme-preview/${themeParam}`
                    );
                    for (const [key, value] of Object.entries(data.theme)) {
                        await dispatch('setVenueValue', { key, value });
                    }
                } catch (error) {
                    console.error('Failed to load theme:', error);
                }
            }

            commit('FETCH_END');
        } catch (e) {
            if (e.response) {
                // client received an error response (5xx, 4xx)
                if (e.response.status === 404) {
                    router.replace({ name: '404' });
                }
                throw new Error(`API Response ${e}`);
            } else if (e.request) {
                // client never received a response, or request never left
                throw new Error(`API Request ${e}`);
            } else {
                // anything else
                throw new Error(`Unknown API ${e}`);
            }
        }
    },
    async checkMenuVersion({ state, dispatch }) {
        try {
            return true;
            // const menu = state.venue.menu;
            //
            // if (menu.length && menu[0].deliverectId) {
            //     const { data: version } = await this.$axiosRetry.get(
            //         `/menus/${menu[0].id}/version`
            //     );
            //
            //     if (version !== menu[0].version) {
            //         dispatch('cart/emptyCart', {}, { root: true });
            //         dispatch('getMenuCategories', state.venue.menu[0].id);
            //         state.venue.menu[0].version = version;
            //
            //         return false;
            //     }
            // }
            //
            // return true;
        } catch (error) {
            return false;
        }
    },
    async getMenuCategories({ commit, rootGetters }, id) {
        commit('FETCH_START');

        const isStoreDesigner = rootGetters['helpers/isStoreDesigner'];

        try {
            if (Vue.prototype.$growthbook?.isOn('fetch_menu_v2')) {
                const { data } = await this.$axiosRetry.get(
                    `v2/categories/${id}?showAll=false`,
                    {
                        timeout: 8000
                    }
                );

                const categories = data.categories.map(category => ({
                    ...category,
                    products: category.products.map(product => ({
                        ...product,
                        categoryPosId: category.deliverectId,
                        modifierGroups:
                            product?.modifierGroups?.map(({ id, index }) => {
                                const fullGroup =
                                    data.modifierGroups?.[id] || {};

                                return { ...fullGroup, index };
                            }) || []
                    }))
                }));

                commit('SAVE_MENU_CATEGORIES', categories);
            } else {
                const showAll = isStoreDesigner ? true : false;
                const { data } = await this.$axiosRetry.get(
                    `/categories/${id}?showAll=${showAll}`,
                    {
                        timeout: 8000
                    }
                );

                const categories = data.categories.map(category => ({
                    ...category,
                    products: category.products.map(product => ({
                        ...product,
                        categoryPosId: category.deliverectId
                    }))
                }));

                commit('SAVE_MENU_CATEGORIES', categories);
            }

            if (Vue.prototype.$analytics) {
                await Vue.prototype.$analytics.track(
                    TrackingEventsEnum.MENU_LOADED,
                    {
                        menuId: id
                    }
                );
            }
        } catch (e) {
            throw new Error(`API ${e}`);
        } finally {
            commit('FETCH_END');
        }
    },
    async getTables({ dispatch, commit }, venueId) {
        commit('FETCH_START');

        try {
            const { data } = await this.$axiosRetry.get(
                `/venues/${venueId}/tables?showAll=false`,
                {
                    timeout: 8000
                }
            );

            commit('SAVE_TABLES', data);
            setTimeout(() => {
                dispatch('checkIfAreaAndTableIsAvailable', venueId);
            }, 500);
        } catch (e) {
            throw new Error(`API ${e}`);
        } finally {
            commit('FETCH_END');
        }
    },
    loading({ commit }, value) {
        value ? commit('FETCH_START') : commit('FETCH_END');
    },
    async checkIfAreaAndTableIsAvailable({ dispatch, state, commit }, venueId) {
        if (!isLocalStorageAvailable()) {
            return;
        }

        const key = `selected-table-venue-${venueId}`;
        const item = localStorage.getItem(key);

        if (item) {
            const { tableId } = JSON.parse(item);
            let selectedTable;

            const selectedArea = state.tableAreas.find(area => {
                selectedTable = area.tables.find(table => table.id === tableId);

                return selectedTable;
            });

            if (!selectedTable) {
                localStorage.removeItem(key);
                await commit('cart/setTable', null, {
                    root: true
                });
            }

            if (
                (selectedArea && !selectedArea.active) ||
                (selectedTable && !selectedTable.active)
            ) {
                await dispatch('triggerTableClosedModal');
            }
        }
        await dispatch('triggerTableSelectionModal');
    },
    triggerTableSelectionModal({ state, rootState }) {
        if (
            [
                'orderStatus',
                'Privacy',
                'checkout',
                'orderReview',
                'LandingPage',
                'billStatus'
            ].includes(router.currentRoute.name)
        ) {
            return false;
        }

        if (
            !state.venue.visualMenu &&
            !rootState.cart.selectedTableId &&
            state.venue.acceptsInStore
        ) {
            this._vm.$modal.show('table-selection-modal');
        }
    },
    triggerFulfillmentMethodModal({ state, commit, rootGetters }) {
        if (state.venue.visualMenu) {
            return false;
        }

        if (
            state.venue &&
            state.venue.acceptsDelivery === true &&
            state.venue.acceptsPickup === true
        ) {
            if (rootGetters['cart/fulfillmentMethod'] == null) {
                this._vm.$modal.show('fulfillment-method-modal');
            }
        } else if (
            state.venue.acceptsDelivery &&
            state.venue.deliveryZones &&
            state.venue.deliveryZones.length > 0
        ) {
            commit(
                'cart/setFulfillmentMethod',
                FulfillmentMethodsEnum.DELIVERY,
                { root: true }
            );

            this._vm.$modal.show('fulfillment-method-modal');
        } else if (state.venue.acceptsDelivery === true) {
            commit(
                'cart/setFulfillmentMethod',
                FulfillmentMethodsEnum.DELIVERY,
                { root: true }
            );
        } else {
            commit('cart/setFulfillmentMethod', FulfillmentMethodsEnum.PICKUP, {
                root: true
            });
        }
    },
    triggerCateringFulfillmentMethodModal({ state, commit, rootGetters }) {
        if (state.venue.visualMenu) {
            return false;
        }

        if (
            state.venue &&
            state.venue.acceptsDelivery === true &&
            state.venue.acceptsPickup === true
        ) {
            if (rootGetters['cart/fulfillmentMethod'] == null) {
                this._vm.$modal.show('catering-fulfillment-method-modal');
            }
        } else if (
            state.venue.acceptsDelivery &&
            state.venue.deliveryZones &&
            state.venue.deliveryZones.length > 0
        ) {
            commit(
                'cart/setFulfillmentMethod',
                FulfillmentMethodsEnum.CATERING_DELIVERY,
                { root: true }
            );

            this._vm.$modal.show('catering-fulfillment-method-modal');
        } else if (state.venue.acceptsDelivery === true) {
            commit(
                'cart/setFulfillmentMethod',
                FulfillmentMethodsEnum.CATERING_DELIVERY,
                { root: true }
            );
        } else {
            commit(
                'cart/setFulfillmentMethod',
                FulfillmentMethodsEnum.CATERING_PICKUP,
                {
                    root: true
                }
            );
        }
    },
    triggerTableClosedModal() {
        this._vm.$modal.show('custom-message-modal', {
            text: 'tableClosed'
        });
    },
    clearAutoAppliedDiscountCodes({ commit }) {
        commit('clearAutoAppliedDiscountCodes');
    },
    enablePlugins({ state }) {
        const pluginData = {
            googleTagManagerId: state.venue?.googleTagManagerId,
            klaviyoApiKey: state.venue?.klaviyoApiKey,
            facebookPixelId: state.venue?.facebookPixelId
        };
        enablePlugins(pluginData, Vue.prototype.$analytics);
    },
    async setVenueValue({ commit, rootGetters, getters }, { key, value }) {
        const fontKeys = ['bodyFont', 'headingFont', 'accentFont'];
        const allowedKeys = [
            'logo',
            'enabled',
            'logoNav',
            'ageGated',
            'buttonFont',
            'menuNotice',
            'mapStyleUrl',
            'busyMessage',
            'description',
            'largeImages',
            'headerImage',
            'faviconImage',
            'primaryColour',
            'modalImageStyle',
            'tableScreenImage',
            'orderInstructions',
            'showCurrencySymbol',
            'showInventoryQuantity',
            'showTableSelectionModal',
            ...fontKeys,
            ...cssVariableKeys
        ];

        if (!allowedKeys.includes(key)) {
            return;
        }

        if (key === 'buttonFont') {
            const isStoreDesigner = rootGetters['helpers/isStoreDesigner'];

            if (value) {
                const val = getters.venueValue('fonts')[value].name;

                setCustomVariable(key, val, isStoreDesigner);
            }

            value = { buttonFont: value };
            key = 'branding';
        }

        if (fontKeys.includes(key)) {
            const cssVariable = `--${kebabCase(key)}`;
            const isValue = value?.name && value?.url;
            const isButtonFont =
                getters.venueValue('branding').buttonFont ===
                key.replace('Font', '');

            if (isValue) {
                await mountCustomFont(value.name, value.url, cssVariable);

                if (isButtonFont) {
                    document.documentElement.style.setProperty(
                        '--button-font',
                        value.name
                    );
                }
            } else {
                document.documentElement.style.removeProperty(cssVariable);

                if (isButtonFont) {
                    document.documentElement.style.removeProperty(
                        '--button-font'
                    );

                    commit('SET_VENUE_VALUE', {
                        key: 'branding',
                        value: { buttonFont: null }
                    });
                }
            }

            const fontKey = key.replace('Font', '');
            value = { [fontKey]: value };
            key = 'fonts';
        }

        if (cssVariableKeys.includes(key)) {
            const isStoreDesigner = rootGetters['helpers/isStoreDesigner'];

            setCustomVariable(key, value, isStoreDesigner);
        }

        if (key === 'showCurrencySymbol') {
            const filterName = value
                ? 'currencyWithSymbol'
                : 'currencyWithoutSymbol';

            const currencyFilterFunction = Vue.filter(filterName);

            Vue.filter('customCurrency', v => currencyFilterFunction(v));
        }

        if (key === 'mapStyleUrl') {
            key = 'mapStyle';
            value = { mapStyleUrl: value };
        }

        commit('SET_VENUE_VALUE', { key, value });
    },
    async setupOpenReplay({ state }) {
        // Check if OpenReplay is already initialized
        if (window.openReplayTrackerInitialized) {
            console.log('OpenReplay tracker is already initialized');
            return;
        }

        // Sushidog
        if (state.venue.accountId === '487aa959-b7e6-430d-a1a5-edc9a09ab249') {
            try {
                // Dynamically import OpenReplay
                const { default: Tracker } = await import(
                    '@openreplay/tracker'
                );

                // Initialize the tracker
                const tracker = new Tracker({
                    projectKey: '1BWsxwFDyso39FRoEipy'
                });
                await tracker.start({
                    obscureText: true,
                    network: {
                        capturePayload: true
                    }
                });

                // Mark as initialized
                window.openReplayTrackerInitialized = true;
                console.log('OpenReplay tracker initialized');
            } catch (error) {
                console.error(
                    'Failed to initialize OpenReplay tracker:',
                    error
                );
            }
        }
    }
};

async function mountCustomFont(family, source, variable) {
    try {
        const font = new FontFace(family, `url(${source})`, {
            display: 'swap'
        });
        await font.load();
        document.fonts.add(font);

        document.documentElement.style.setProperty(variable, `'${family}'`);
    } catch (e) {
        return false;
    }
}

function setCustomVariable(key, value, isStoreDesigner = false) {
    if (!key || (!isStoreDesigner && !value)) {
        return;
    }

    const root = document.documentElement;

    root.style.setProperty(`--${kebabCase(key)}`, value);
}

const cssVariableKeys = [
    'colorBgPage',
    'colorBgButton',
    'menuItemWidth',
    'colorBgPrimary',
    'colorTextPrice',
    'colorTextMuted',
    'borderMenuItem',
    'colorTextAccent',
    'colorTextButton',
    'colorBgSecondary',
    'colorBgBrandPage',
    'colorTextHeading',
    'colorTextPrimary',
    'boxShadowMenuItem',
    'colorTextItemBody',
    'colorBgPageDarker',
    'bodyTextTransform',
    'lineClampMenuItem',
    'borderSelectedItem',
    'colorTextSecondary',
    'colorBgBrandBanner',
    'colorTextSubheading',
    'borderButtonPrimary',
    'headingTextTransform',
    'colorHighlightBorder',
    'borderBottomMenuItem',
    'colorTextBrandBanner',
    'colorSecondaryActions',
    'colorBgModalOverlay',
    'colorBgSecondaryTransparent',
    'menuListItemFlexDirection',
    'menuListItemJustify',
    'menuListGridColsDesktop',
    'menuListGridColsMobile',
    'colorBgModifierCard',
    'colorBgSelectedModifierCard',
    'boxShadowModifierCard'
];

export default {
    namespaced: true,
    getters,
    mutations,
    state,
    actions
};
