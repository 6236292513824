<template>
    <div class="min-h-screen">
        <div
            v-if="isLoading"
            class="loader relative flex flex-col items-center justify-center m-auto w-20 min-h-screen"
        >
            <loading class="mb-10" />
        </div>
        <div v-else class="flex flex-col">
            <public-nav v-once v-if="!hasNewHeader" />
            <router-view />
            <app-footer v-once />
            <custom-message-modal />
            <unavailable-categories-modal />
        </div>
        <catering-fulfillment-method-modal
            v-if="hasCateringFulfillmentMethodModal"
        />
        <fulfillment-method-modal />
        <age-verification-modal />
        <table-selection-modal />
        <item-unavailable-modal />
        <offer-unavailable-modal />
        <calculation-method-modal />
        <mandatory-tip-modal />
        <applying-discount-modal
            :status="discountAppliedStatus"
            :details="discountDetails"
        />
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex';
import PublicNav from '@/components/PublicNav';
import AppFooter from '@/components/AppFooter';
import FulfillmentMethodModal from '@/components/modals/FulfillmentMethodModal';
import ageVerificationModal from '@/components/modals/ageVerificationModal';
import TableSelectionModal from '@/components/modals/TableSelectionModal';
import CustomMessageModal from '@/components/modals/CustomMessageModal';
import ItemUnavailableModal from '@/components/modals/ItemUnavailableModal';
import UnavailableCategoriesModal from '@/components/modals/UnavailableCategoriesModal';
import switchToLangOrCloserLang from '@/services/switchToLangOrCloserLang';
import { FulfillmentMethodsEnum } from '@/enums';
import MandatoryTipModal from '@/components/modals/MandatoryTipModal';
import Loading from '@/components/icons/Loading';
import ApplyingDiscountModal from '../components/modals/ApplyingDiscountModal.vue';
import OfferUnavailableModal from '@/components/modals/OfferUnavailableModal';
import CalculationMethodModal from '@/components/modals/CalculationMethodModal';
import Vue from 'vue';
import CateringFulfillmentMethodModal from '@/components/modals/CateringFulfillmentMethodModal.vue';
const baoCss = () => import('@/assets/css/bao.css');

export default {
    name: 'PublicRouteContainer',
    head() {
        return {
            // if no subcomponents specify a head.title, this title will be used
            title: 'Order Online',
            // all titles will be injected into this template
            titleTemplate: () => this.$store.state.venue.venue.name + ' | %s',
            meta: [
                {
                    name: 'theme-color',
                    content: () => this.$store.state.venue.venue.primaryColour
                },
                {
                    key: 'description',
                    name: 'description',
                    content: () => this.metaDescription
                }
            ],
            link: [
                {
                    rel: 'canonical',
                    href: `https://${this.domain}/${this.slug}/menu`
                }
            ]
        };
    },
    components: {
        CalculationMethodModal,
        PublicNav,
        AppFooter,
        FulfillmentMethodModal,
        CateringFulfillmentMethodModal,
        ageVerificationModal,
        CustomMessageModal,
        TableSelectionModal,
        ItemUnavailableModal,
        OfferUnavailableModal,
        MandatoryTipModal,
        UnavailableCategoriesModal,
        Loading,
        ApplyingDiscountModal
    },
    data() {
        return {
            isLoading: true,
            domain: import.meta.env.VITE_WHITELABEL_DOMAIN,
            FulfillmentMethodsEnum
        };
    },
    async created() {
        try {
            await this.initializeStoreDesigner();
            await this.loadVenueData();
            await this.setupLanguage();
            if (
                this.$store.state.venue.venue.accountId ===
                '5f4e9ddc-3431-43f5-9de0-7aa356564afc'
            ) {
                await baoCss();
            }
            if (this.customerEmail && Vue.prototype.$analytics) {
                Vue.prototype.$analytics.identify(this.customerEmail);
            }
        } catch (e) {
            throw new Error(e);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapMutations('cart', ['setFulfillmentMethod']),
        ...mapActions({
            triggerFulfillmentMethodModal:
                'venue/triggerFulfillmentMethodModal',
            triggerCateringFulfillmentMethodModal:
                'venue/triggerCateringFulfillmentMethodModal'
        }),
        async loadVenueData() {
            try {
                await this.$store.dispatch(
                    'venue/getVenue',
                    this.$route.params.venueSlug
                );
                this.triggerModal();
            } catch (error) {
                console.error('Failed to load venue:', error);
                throw error;
            }
        },
        async initializeStoreDesigner() {
            const { storeDesigner, theme } = this.$route.query;
            if (storeDesigner === 'true' || theme) {
                await this.$store.dispatch('helpers/enableStoreDesigner');
            }
        },
        isExcludedRoute() {
            return [
                'orderStatus',
                'Privacy',
                'checkout',
                'orderReview',
                'LandingPage',
                'billStatus'
            ].includes(this.$route.name);
        },
        triggerModal() {
            if (
                !this.isExcludedRoute() &&
                this.$store.state.venue &&
                this.$store.state.venue.venue &&
                !this.$store.state.venue.venue.acceptsInStore
            ) {
                if (this.hasCateringFulfillmentMethodModal) {
                    return this.triggerCateringFulfillmentMethodModal();
                } else {
                    this.triggerFulfillmentMethodModal();
                }
            }
        },

        async setupLanguage() {
            await switchToLangOrCloserLang.handle(
                this.$store.state.venue.venue.lang
            );
            if (this.$growthbook.isOn('browser_lang')) {
                await switchToLangOrCloserLang.loadBrowserLang();
            }
        }
    },
    computed: {
        ...mapGetters({
            forceRefresh: 'helpers/forceRefresh',
            discountAppliedStatus: 'discount/status',
            discountDetails: 'discount/details'
        }),
        ...mapState('venue', {
            slug: state => state.venue.slug,
            visualMenu: state => state.venue.visualMenu,
            acceptsPat: state => state.venue.acceptsPat
        }),
        ...mapState('customer', {
            customerEmail: state => state.email
        }),
        metaDescription() {
            const description =
                this.$store.state.venue.venue.metaDescription ||
                this.$store.state.venue.venue.description;

            return description.slice(0, 157);
        },
        hasNewHeader() {
            return this.acceptsPat || this.$growthbook.isOn('new_header');
        },
        hasCateringFulfillmentMethodModal() {
            if (
                this.$store.state.venue.venue.id === 9133 ||
                this.$store.state.venue.venue.id === 9469
            ) {
                return true;
            }
            return this.$growthbook.isOn('catering_fulfillment_method_modal');
        }
    }
};
</script>

<style src="@/assets/css/loading-animation_scoped.css" scoped></style>
