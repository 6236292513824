import Vue from 'vue';
import Analytics from 'analytics';
import { googleTagManager } from '@/services/analytics';
import router from '../router';

const state = {
    details: {
        brandColour: '',
        description: '',
        headerImage: '',
        logo: '',
        name: '',
        slug: '',
        styles: {},
        venues: []
    }
};

const mutations = {
    SAVE_BRAND(state, brand) {
        state.details = brand;
    }
};

const getters = {
    details(state) {
        return state.details;
    },
    styles(state) {
        return state.details.styles;
    }
};

const actions = {
    async getBrandDetails({ dispatch, commit }, slug) {
        try {
            const { data } = await this.$axios.get(`/brands/${slug}`);

            commit('SAVE_BRAND', data.brand);

            if (data.brand.id === 'b60dc75a-2452-4406-bc4f-e46cd1b0e4cf') {
                dispatch('setupAnalytics', data.brand);
            }
        } catch (error) {
            if (
                error.response &&
                error.response.status &&
                error.response.status === 404
            ) {
                await router.replace({ name: '404' });
            }

            throw new Error(`API Response ${error}`);
        }
    },

    setupAnalytics(brand) {
        try {
            const plugins = [];

            const googleTagManagerPlugin = Object.assign(
                {},
                googleTagManager({
                    containerId: 'GTM-WFC9GVR7',
                    enabled: true
                }),
                {
                    name: 'google-tag-group'
                }
            );

            plugins.push(googleTagManagerPlugin);

            // Setup the analytics using the Vue instance for easier access
            Vue.prototype.$analytics = Analytics({
                app: 'storekit',
                debug: import.meta.env.DEV,
                plugins
            });

            // Track the event if analytics was successfully initialized
            if (Vue.prototype.$analytics) {
                Vue.prototype.$analytics.track(
                    'ACTIVE_ON_SITE', // Replace with your actual tracking event enum if needed
                    {
                        accountId: brand.accountId,
                        brandId: brand.id,
                        slug: brand.slug
                    }
                );
            }
        } catch (error) {
            console.error('Error setting up analytics', error);
        }
    }
};

export default {
    namespaced: true,
    getters,
    mutations,
    state,
    actions
};
