<template>
    <div :class="{ relative: isStoreDesigner }">
        <venue-header-new v-if="hasNewHeader" />
        <VenueHeader v-else />
        <MenuStickyHeader
            ref="menuStickyHeader"
            :showBasket="showBasket"
            @search="updateSearchQuery"
            :has-new-header="hasNewHeader"
        />

        <main
            v-if="!isLoading && categories"
            class="menu-page"
            :class="{
                'pt-12': hasNewHeader,
                'visual-menu': visualMenu,
                'happy-endings': isHappyEndings
            }"
        >
            <div
                class="menu-page__menu-wrapper flew-row container-lg flex flex-wrap items-start justify-start px-2 py-3 md:py-6 lg:px-3"
            >
                <div
                    id="busyMessage"
                    class="store-notice break-words bg-red-50 px-3 py-3 shadow-none sm:mb-4 sm:rounded-sm sm:px-4 sm:shadow-inner"
                    v-if="!enabled"
                >
                    <div class="flex">
                        <p class="text-sm font-medium leading-5 text-red-800">
                            {{ busyMessage }}
                        </p>
                    </div>
                </div>

                <div
                    v-if="allergenDisabled"
                    @click="$modal.show('allergen-modal')"
                    id="allergenClosed"
                    class="store-notice cursor-pointer break-words px-3 py-3 shadow-none sm:mb-4 sm:rounded-sm sm:px-4 sm:shadow-inner"
                >
                    <div class="">
                        <p class="text-sm font-medium leading-5 text-red-800">
                            Online ordering is disabled due to your allergy,
                            please order with a team member
                        </p>
                        <p class="text-gray-600 mt-1 text-xs"
                            >Click here to change your answer</p
                        >
                    </div>
                </div>

                <StoreNotice
                    v-if="!search && notices && notices.length"
                    location="menu"
                    class="store-notice"
                />

                <MenuOffers
                    v-if="!search && offers && offers.length"
                    :offers="offers"
                    @showItemModalById="showItemModalById"
                />

                <dietary-filters
                    v-if="hasCateringFulfillmentMethodModal"
                    :showIcons="true"
                    size="large"
                    class="border-button-primary bg-secondary menu-page__menu-category pt-2 rounded-md"
                >
                    <h6 class="text-sm text-primary mb-1">Dietary filters</h6>
                </dietary-filters>

                <!--                <menu-recommendations-->
                <!--                    location="menu"-->
                <!--                    :instance-id="'menu'"-->
                <!--                    :current-item="null"-->
                <!--                    class="mt-8 px-4 menu-page__menu-category"-->
                <!--                    @showItemModal="showItemModal"-->
                <!--                />-->

                <div
                    class="menu-page__menu-category"
                    :id="'category-' + category.id"
                    v-for="(category, index) in isSearchEnabled
                        ? categoriesSearch
                        : categories"
                    :key="category.id"
                >
                    <div
                        v-if="
                            accountId === '5f4e9ddc-3431-43f5-9de0-7aa356564afc'
                        "
                        class="bg-white px-5 pb-5 pt-10"
                    >
                        <h3
                            class="font-heading text-xl leading-none font-bold text-category-heading"
                            >{{ category.name }}</h3
                        >
                    </div>
                    <img
                        v-else-if="category.image && !hasCategoryHeaderImages"
                        class="select-none"
                        :class="{
                            'mb-2 mt-4': largeImages,
                            '-mb-2': !largeImages
                        }"
                        :src="`${category.image}-/format/auto/-/resize/1500x/`"
                        :alt="category.name"
                    />

                    <div
                        v-else
                        class="menu-category__heading flex items-end justify-between pb-2 pt-12"
                    >
                        <h2
                            class="font-heading text-2xl font-bold text-category-heading"
                        >
                            {{ category.name }}
                        </h2>
                        <span
                            v-if="!!displayCategoryDiscount(category.id)"
                            class="inline-flex mb-0.5 flex-shrink-0 items-center rounded-md bg-button font-button px-2.5 py-0.5 text-sm font-medium text-white"
                        >
                            {{ displayCategoryDiscount(category.id) }}
                        </span>
                    </div>
                    <p
                        class="pb-2 text-sm text-secondary whitespace-pre-line"
                        v-if="!category.image && category.description"
                    >
                        {{ category.description }}
                    </p>
                    <ul
                        class="menu-page__items grid lg:gap-x-3 list-none pt-2"
                        v-bind="
                            isStoreDesigner ? { key: showCurrencySymbol } : {}
                        "
                    >
                        <menu-item
                            v-for="(item, itemIndex) in orderMenuItems(
                                category.products
                            )"
                            :category-index="index"
                            :item-index="itemIndex"
                            :key="`${item.id}${category.id}${itemIndex}`"
                            :isAvailable="isInStock(item)"
                            @click.native="showItemModal(item)"
                            :item="item"
                            :large-images="largeImages"
                            :quick-add="hasQuickAddToCart"
                        ></menu-item>
                    </ul>
                </div>

                <div
                    v-if="categories.length === 0"
                    class="menu-page__menu-category px-2 lg:px-3"
                >
                    <h1 class="pb-2 pt-24 text-lg font-bold text-gray-900">
                        {{ $t('menu.nothingOnMenu') }}
                    </h1>
                </div>
                <div
                    v-if="search && categoriesSearch.length === 0"
                    class="menu-page__menu-category px-2 text-center lg:px-3"
                    @click="resetSearchQuery"
                >
                    <h1
                        class="mb-4 mt-12 cursor-pointer font-heading text-lg font-bold text-heading sm:text-xl"
                    >
                        {{ $t('menu.noMoreResults') }}
                    </h1>
                    <span
                        class="cursor-pointer font-heading text-lg text-heading underline"
                    >
                        {{ $t('menu.noMoreResultsReset') }}
                    </span>
                </div>
                <menu-notice
                    id="menuNotice"
                    v-bind="isStoreDesigner ? { key: menuNotice } : {}"
                />
            </div>
        </main>

        <main v-else class="menu-page" :class="{ 'pt-12': hasNewHeader }">
            <div
                class="menu-page__menu-wrapper flew-row container-lg flex flex-wrap items-start justify-start px-2 py-3 md:py-6 lg:px-3"
            >
                <MenuOffersSkeleton v-if="offers && offers.length > 0" />
                <div
                    class="menu-page__menu-category pt-7 sm:pt-10"
                    v-for="(n, index) in 2"
                    :key="index"
                >
                    <menu-category-skeleton class="mb-3 px-4 sm:mb-0 sm:px-0" />
                    <ul
                        class="menu-page__items grid lg:gap-x-3 list-none sm:pt-2"
                    >
                        <div
                            ref="menuItemSkeleton"
                            class="menu-page__item relative mb-4 block select-none rounded-sm bg-secondary px-0 py-0 outline-none lg:py-2"
                            v-for="(n, index) in 5"
                            :key="index"
                        >
                            <menu-item-skeleton />
                        </div>
                    </ul>
                </div>
            </div>
        </main>

        <cross-sell-modal
            v-if="categories"
            :products="
                categories
                    .filter(c => c.id === crossSellCategory)
                    .map(c => c.products)
                    .flat()
            "
        />
        <venue-notice-confirmation-modal
            @confirm="onConfirmModalBeforeCheckout"
            @showModalBeforeCheckout="showModalBeforeCheckout = true"
        />
        <item-modal />
        <rewards-modal />
        <menu-summary-modal
            :categories="categories"
            @scroll="scrollToElement"
        />
        <allergen-modal />

        <!--        <div-->
        <!--            v-if="isStoreDesigner"-->
        <!--            class="fixed top-0 left-0 bg-transparent h-full w-full z-50"-->
        <!--        />-->
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { getRWGToken } from '@/helpers/rwgTokenHelper';
import { orderBy } from '@/util/sort';
import { DiscountTypesEnum, TrackingEventsEnum } from '@/enums';
import Fuse from 'fuse.js';

import VenueHeader from '../components/VenueHeader.vue';
import MenuStickyHeader from '../components/MenuStickyHeader.vue';
import MenuItem from '../components/MenuItem.vue';
import StoreNotice from '../components/StoreNotice.vue';
import MenuNotice from '../components/MenuNotice.vue';
import MenuItemSkeleton from '../components/MenuItemSkeleton';
import MenuCategorySkeleton from '../components/MenuCategorySkeleton';
import VenueNoticeConfirmationModal from '@/components/modals/VenueNoticeBeforeCheckoutModal';
import ItemModal from '../components/modals/ItemModal';
import VenueHeaderNew from '../components/VenueHeaderNew';
import CrossSellModal from '../components/modals/CrossSellModal';
import MenuOffersSkeleton from '@/components/MenuOffersSkeleton';
import MenuOffers from '@/components/MenuOffers';
import RewardsModal from '@/components/modals/RewardsModal';
import DietaryFilters from '@/components/DietaryFilters.vue';
import MenuRecommendations from '@/components/Recommendations.vue';
import MenuSummaryModal from '@/components/modals/MenuSummaryModal';
import isMobile from '@/helpers/isMobileDevice';
import AllergenModal from '@/components/modals/AllergenModal';
import isLocalStorageAvailable from '@/helpers/isLocalStorageAvailable';

export default {
    name: 'MenuView',
    head() {
        return {
            title: 'Menu',
            titleTemplate: this.metaTitle || this.name + ' | %s',
            meta: [
                { name: 'twitter:card', content: 'summary_large_image' },
                { name: 'twitter:title', content: this.metaTitle || this.name },
                {
                    name: 'twitter:description',
                    content: this.metaDescription || this.description
                },
                {
                    name: 'twitter:image',
                    content: this.headerImage || this.logo
                },
                { property: 'og:title', content: this.metaTitle || this.name },
                {
                    property: 'og:description',
                    content: this.metaDescription || this.description
                },
                { property: 'og:type', content: 'website' },
                { property: 'og:image', content: this.headerImage || this.logo }
            ]
        };
    },
    beforeRouteEnter(to, from, next) {
        if (from.name === 'orderStatus' && to.params.venueSlug) {
            next(vm =>
                vm.$store.dispatch('venue/getVenue', to.params.venueSlug)
            );
        }

        next();
    },
    beforeRouteUpdate(to, from, next) {
        if (from.hash === '#product') {
            this.$modal.hide('item-modal');
        }

        next();
    },
    beforeRouteLeave(to, from, next) {
        if (!this.showModalBeforeCheckout || to.name !== 'checkout') {
            next();
        }

        if (from.name !== 'basket' && to.name === 'checkout') {
            this.$modal.show('venue-notice-before-checkout-modal');
        }
    },
    components: {
        MenuOffers,
        MenuOffersSkeleton,
        VenueHeader,
        MenuItem,
        MenuRecommendations,
        MenuStickyHeader,
        ItemModal,
        StoreNotice,
        MenuNotice,
        MenuItemSkeleton,
        MenuCategorySkeleton,
        VenueNoticeConfirmationModal,
        VenueHeaderNew,
        CrossSellModal,
        RewardsModal,
        DietaryFilters,
        MenuSummaryModal,
        AllergenModal
    },
    computed: {
        ...mapState('venue', {
            categories: state => state.categories,
            accountId: state => state.venue.accountId,
            isLoading: state => state.isLoading,
            enabled: state => state.venue.enabled,
            busyMessage: state => state.venue.busyMessage,
            name: state => state.venue.name,
            description: state => state.venue.description,
            metaTitle: state => state.venue.metaTitle,
            metaDescription: state => state.venue.metaDescription,
            headerImage: state => state.venue.headerImage,
            logo: state => state.venue.logo,
            visualMenu: state => state.venue.visualMenu,
            venueId: state => state.venue.id,
            autoAppliedDiscountCode: state =>
                state.venue.autoAppliedDiscountCode,
            isInStore: state => state.venue.acceptsInStore,
            largeImages: state => state.venue.largeImages,
            offers: state => state.venue.offers,
            notices: state => state.venue.venueNotices,
            crossSellCategory: state => state.venue.crossSellCategory,
            showTableSelectionModal: state =>
                state.venue.showTableSelectionModal,
            showCurrencySymbol: state => state.venue.showCurrencySymbol,
            menuNotice: state => state.venue.menuNotice
        }),
        ...mapState({
            cartItems: state => state.cart.items,
            acceptsPat: state => state.venue.venue.acceptsPat,
            selectedTableId: state => state.cart.selectedTableId
        }),
        ...mapGetters({
            fulfillmentMethod: 'cart/fulfillmentMethod',
            activeFilterTags: 'helpers/activeFilterTags',
            isStoreDesigner: 'helpers/isStoreDesigner',
            billId: 'bill/id',
            allergenDisabled: 'customer/allergenDisabled'
        }),
        hasNewHeader() {
            return this.acceptsPat || this.$growthbook.isOn('new_header');
        },
        hasRecommendationsEnabled() {
            return this.$growthbook.isOn('shaped_recs');
        },
        hasQuickAddToCart() {
            return this.$growthbook.isOn('quick_add_to_cart');
        },
        shouldShowTableSelectionModal() {
            return this.showTableSelectionModal || !this.$route.query.tableId;
        },
        fuseOptions() {
            return {
                keys: [
                    {
                        name: 'name',
                        weight: 3
                    },

                    {
                        name: 'categoryName',
                        weight: 2
                    },
                    {
                        name: 'description'
                    }
                ]
            };
        },
        products() {
            return this.categories.flatMap(category =>
                category.products.map(product => ({
                    ...product,
                    categoryId: category.id,
                    categoryName: category.name,
                    categoryPosId: category.deliverectId || category.posId
                }))
            );
        },
        isSearchEnabled() {
            return this.products && this.products.length > 20;
        },
        hasCateringFulfillmentMethodModal() {
            if (
                this.$store.state.venue.venue.id === 9133 ||
                this.$store.state.venue.venue.id === 9469
            ) {
                return true;
            }
            return this.$growthbook.isOn('catering_fulfillment_method_modal');
        },
        hasCategoryHeaderImages() {
            return this.$growthbook.isOn('category_header_images');
        },
        isHappyEndings() {
            return this.accountId === 'b1d47448-139d-4955-afa1-72e199880113';
        },
        isMobile() {
            return isMobile();
        },
        hasMenuSummaryModal() {
            return this.$growthbook.isOn('menu_summary_modal');
        }
    },
    watch: {
        categories() {
            if (this.$route.name === 'basket' && this.$route.params.products) {
                this.prefillCart(this.$route.params.products);
                this.$router.replace({
                    name: 'menu',
                    query: this.$route.query
                });
            }
        },
        '$route.name': {
            handler(value) {
                this.menuLocation = value === 'menu';
            },
            deep: true,
            immediate: true
        },
        products() {
            this.initFuse();
        },
        selectedTableId() {
            if (this.acceptsPat && !this.billId && this.selectedTableId) {
                this.updateAndFetchBill({
                    venueId: this.venueId,
                    tableId: this.selectedTableId
                });
            }
        }
    },
    data() {
        return {
            showBasket: false,
            menuLocation: false,
            search: '',
            categoriesSearch: [],
            showModalBeforeCheckout: false,
            fuse: null,
            fuseSearchTimeout: null,
            hasCheckedAllergens: false
        };
    },
    async mounted() {
        if (this.isStoreDesigner) {
            this.enableMessageReceive();
            this.sendMessage({ type: 'syncSettings' });
        }

        if (this.isMobile && this.hasMenuSummaryModal) {
            this.$modal.show('menu-summary-modal');
        }

        if (
            !this.$route.query.redirectResult &&
            this.shouldShowTableSelectionModal
        ) {
            this.triggerTableSelectionModal();
        }

        if (
            !this.$store.state.cart.fulfillmentMethod &&
            !this.$store.state.venue.venue.acceptsInStore
        ) {
            if (this.hasCateringFulfillmentMethodModal) {
                return this.triggerCateringFulfillmentMethodModal();
            }

            this.triggerFulfillmentMethodModal();
        }

        this.setContactInformation();

        getRWGToken(this.$store.state.venue.venue.slug);
        this.initFuse();

        // Check for allergen_menu_disable flag
        if (this.$growthbook.isOn('allergen_menu_disable')) {
            const venueId = this.$store.state.venue.venue.id;
            let allergenResponse = null;

            // // Check localStorage if available
            // if (isLocalStorageAvailable()) {
            //     allergenResponse = localStorage.getItem(
            //         `sk_allergen_disabled_${venueId}`
            //     );
            // } else {
            // If localStorage is not available, use Vuex state
            allergenResponse = this.$store.state.customer.allergenDisabled;
            // }

            // Only show modal if user hasn't responded yet (response is null)
            if (allergenResponse === null) {
                this.$modal.show('allergen-modal');
            }
        }

        if (this.acceptsPat && !this.billId && this.selectedTableId) {
            this.updateAndFetchBill({
                venueId: this.venueId,
                tableId: this.selectedTableId
            });
        }
    },
    created() {
        if (this.$route.params.showBasket) {
            this.showBasket = this.$route.params.showBasket;
        }

        this.categoriesSearch = this.categories;
    },
    beforeDestroy() {
        if (this.isStoreDesigner) {
            this.disableMessageReceive();
        }
    },
    methods: {
        ...mapActions({
            triggerFulfillmentMethodModal:
                'venue/triggerFulfillmentMethodModal',
            triggerCateringFulfillmentMethodModal:
                'venue/triggerCateringFulfillmentMethodModal',
            triggerTableSelectionModal: 'venue/triggerTableSelectionModal',
            prefillCart: 'cart/prefillCart',
            updateAndFetchBill: 'bill/updateAndFetchBill',
            enableMessageReceive: 'storeDesigner/enableMessageReceive',
            disableMessageReceive: 'storeDesigner/disableMessageReceive',
            sendMessage: 'storeDesigner/sendMessage'
        }),
        ...mapMutations('customer', [
            'setFirstName',
            'setLastName',
            'setEmail',
            'setPhone'
        ]),
        ...mapMutations('deliveryAddress', ['setPostCode', 'setCity']),
        onConfirmModalBeforeCheckout() {
            this.$modal.hide('venue-notice-before-checkout-modal');
            this.showModalBeforeCheckout = false;
            this.$router.push({ name: 'checkout' });
        },
        orderMenuItems(val) {
            return this.search ? val : orderBy(val, 'index');
        },
        showItemModal(item) {
            if (this.isInStock(item)) {
                this.$modal.show('item-modal', { item: item });
            }
        },
        displayCategoryDiscount(categoryId) {
            if (!this.autoAppliedDiscountCode) {
                return;
            }

            const discountCode = this.autoAppliedDiscountCode.find(code =>
                code.categories?.includes(categoryId)
            );
            if (!discountCode || !this.isCategoryDiscountValid(discountCode))
                return;

            return this.$t('menu.categoryDiscountOff', {
                value: `${discountCode.codeAmount / 10}%`
            });
        },
        isCategoryDiscountValid(discountCode) {
            // @TODO show fixed amount & BOGO discounts
            const isTypePercent =
                discountCode.type === DiscountTypesEnum.PERCENT;
            const isWithinUsageLimit =
                discountCode.usageLimit === 0 ||
                discountCode.usageCount < discountCode.usageLimit;
            const isTimeValid =
                this._isDiscountAvailableAtThisTime(discountCode);

            return isTypePercent && isWithinUsageLimit && isTimeValid;
        },
        _isDiscountAvailableAtThisTime(discountCode) {
            const { startTime, endTime } = discountCode;
            if (!startTime || !endTime) return true;

            const currentTime = this.$dayjs();
            const dateFormat = 'HH:mm:ss';
            const startMoment = this.$dayjs(startTime, dateFormat);
            const endMoment = this.$dayjs(endTime, dateFormat);

            return (
                currentTime.isAfter(startMoment) &&
                currentTime.isBefore(endMoment)
            );
        },
        isInStock(item) {
            if (item.snooze) return false;
            if (item.isAvailable && !item.trackInventory) return true;
            if (!item.isAvailable && !item.trackInventory) return false;

            if (item.trackInventory) {
                const cartItem = this.cartItems.find(x => x.id === item.id);
                if (cartItem) {
                    return item.inventory - cartItem.quantity > 0;
                } else {
                    return item.inventory > 0;
                }
            }
            return false;
        },
        updateSearchQuery(value) {
            this.search = value;
            this.fuseSearch();
        },
        searchResults(results) {
            let categories = [];
            const categoryIds = [];

            for (const result of results) {
                const category = this.categories.find(
                    ({ id }) => id === result.item.categoryId
                );

                if (!categoryIds.includes(result.item.categoryId)) {
                    categories.push({ ...category, products: [result.item] });
                } else {
                    const existingCategory = categories.find(
                        ({ id }) => id === result.item.categoryId
                    );

                    if (existingCategory) {
                        existingCategory.products.push(result.item);
                    }
                }

                categoryIds.push(result.item.categoryId);
            }

            if (this.activeFilterTags?.length > 0) {
                categories = categories
                    .map(category => ({
                        ...category,
                        products: category.products.filter(product =>
                            product.tags?.some(tag =>
                                this.activeFilterTags.includes(tag)
                            )
                        )
                    }))
                    .filter(category => category.products.length > 0);
            }

            this.categoriesSearch = categories;
        },
        resetSearchQuery() {
            this.$refs.menuStickyHeader.cancelSearch();
            this.search = ' ';
        },
        showItemModalById(itemId) {
            const item = this.categories
                .flatMap(category => category.products)
                .find(product => product.id === itemId);

            if (!item) {
                return;
            }

            if (!this.isInStock(item)) {
                return this.$modal.show('offer-unavailable-modal');
            }

            this.$modal.show('item-modal', { item });
        },
        setContactInformation() {
            const {
                firstName,
                lastName,
                email,
                phone,
                postCode,
                ...remainingParams
            } = this.$route.query;

            if (!firstName && !lastName && !email && !phone && !postCode) {
                return;
            }

            const setValue = (value, setter) => {
                if (value) {
                    setter(value);
                }
            };

            setValue(firstName, this.setFirstName);
            setValue(lastName, this.setLastName);
            setValue(email, this.setEmail);
            setValue(phone, this.setPhone);
            setValue(postCode, this.setPostCode);

            this.$router.replace({ query: remainingParams });
        },
        initFuse() {
            if (!this.isSearchEnabled) {
                return;
            }

            this.fuse = new Fuse(this.products, this.fuseOptions);
            this.fuseSearch();
        },
        fuseSearch() {
            let results;
            const query = this.search.trim();

            if (this.fuseSearchTimeout) {
                clearTimeout(this.fuseSearchTimeout);
            }

            if (query === '') {
                results = this.products.map((item, refIndex) => {
                    return { item, refIndex };
                });
            } else {
                results = this.fuse.search(query);

                this.fuseSearchTimeout = setTimeout(() => {
                    if (this.$analytics) {
                        this.$analytics.track(
                            TrackingEventsEnum.PRODUCTS_SEARCHED,
                            {
                                query: query,
                                resultCount: results?.length || 0
                            }
                        );
                    }
                }, 1000);
            }

            this.searchResults(results);
        },
        scrollToElement(id) {
            const element = document.getElementById(id);

            if (!element || !element.offsetTop) {
                return;
            }

            window.scrollTo({
                top: element.offsetTop - 25,
                behavior: 'smooth'
            });
        }
    }
};
</script>

<style src="@/assets/css/menu_scoped.css" scoped></style>
