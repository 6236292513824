<template>
    <div ref="wrapper">
        <vue-content-loading
            :width="wrapperWidth"
            :height="116"
            :speed="2"
            class="mb-3"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
        >
            <rect x="2.5%" y="14" rx="3" ry="3" width="43" height="30" />
            <rect x="65" y="26" rx="3" ry="3" width="30%" height="10" />
            <rect x="2.5%" y="60" rx="3" ry="3" width="95%" height="40" />
        </vue-content-loading>

        <vue-content-loading
            :width="wrapperWidth"
            :height="56"
            :speed="2"
            class="mb-3"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
        >
            <rect x="2.5%" y="11" rx="3" ry="3" width="43" height="30" />
            <rect x="65" y="22" rx="3" ry="3" width="30%" height="10" />

            <rect
                :x="wrapperWidth - 100"
                y="17"
                rx="3"
                ry="3"
                width="25"
                height="17"
            />
            <rect
                :x="wrapperWidth - 70"
                y="17"
                rx="3"
                ry="3"
                width="25"
                height="17"
            />
            <rect
                :x="wrapperWidth - 40"
                y="17"
                rx="3"
                ry="3"
                width="25"
                height="17"
            />
        </vue-content-loading>

        <vue-content-loading
            :width="wrapperWidth"
            :height="56"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
        >
            <rect x="2.5%" y="11" rx="3" ry="3" width="43" height="30" />
            <rect x="65" y="22" rx="3" ry="3" width="30%" height="10" />

            <rect
                :x="wrapperWidth - 100"
                y="17"
                rx="3"
                ry="3"
                width="25"
                height="17"
            />
            <rect
                :x="wrapperWidth - 70"
                y="17"
                rx="3"
                ry="3"
                width="25"
                height="17"
            />
            <rect
                :x="wrapperWidth - 40"
                y="17"
                rx="3"
                ry="3"
                width="25"
                height="17"
            />
        </vue-content-loading>
    </div>
</template>

<script>
import VueContentLoading from 'vue-content-loading';

export default {
    data() {
        return {
            wrapperWidth: 285
        };
    },
    components: {
        VueContentLoading
    },
    mounted() {
        if (this.$refs.wrapper && this.$refs.wrapper.offsetWidth) {
            this.wrapperWidth = this.$refs.wrapper.offsetWidth;
        }
    }
};
</script>
