<template>
    <modal
        name="applying-discount-modal"
        height="auto"
        width="450"
        :maxWidth="450"
        :adaptive="true"
        :scrollable="true"
        :clickToClose="false"
        :reset="true"
    >
        <div class="item-modal-content relative flex flex-col w-full">
            <div class="item-modal-details text-center flex-auto overflow-y-auto p-8">
                <div>
                    <h4
                        class="text-lg font-bold pb-1 mb-5"
                    >
                        {{ status ? successMessage : failedMessage }}
                    </h4>
                  <div v-if="details">
                    <span class="uppercase font-semibold tracking-wide text-base text-primary py-2 px-3 border-2 rounded-sm border-dashed">

                    {{ details.code }}
                    </span>
                  </div>

                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'applying-discount-modal',
    props: {
        status: {
            type: Boolean,
            default: false,
            required: true
        },
        details: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            successMessage: 'Discount code applied',
            failedMessage: 'Sorry, that discount code isn\'t valid'
        };
    },

    methods: {
        hideDiscountModal() {
            this.$modal.hide('applying-discount-modal');
        }
    }
};
</script>
