export default ({ error, modal, dispatch }) => {
    switch (error.response?.data?.message) {
        case 'venue_closed':
            modal.show('custom-message-modal', {
                text: 'notAcceptingOrders'
            });

            break;
        case 'time_slot_expired':
        case 'slot_quantity_limit_exceeded':
        case 'slot_item_quantity_limit_exceeded':
        case 'slot_monetary_limit_exceeded':
            dispatch('setOrderCurrentCheckoutError', true);

            modal.show('custom-message-modal', {
                text: 'timeSlotExpired'
            });

            dispatch('fetchPickupSlots');

            break;

        case 'table_closed':
        case 'area_closed':
            modal.show('custom-message-modal', {
                text: 'tableClosed'
            });

            break;
        case 'sold_out':
            dispatch('setOrderCurrentCheckoutError', true);

            dispatch('setSoldOutItems', {
                items: error.response.data.data,
                redirect: true
            });

            break;
        case 'unavailable_categories':
            modal.show('unavailable-categories-modal', {
                unavailableItemsNames: error.response.data.data
            });

            break;
        case 'bill_closed':
            modal.show('custom-message-modal', {
                text: 'billClosed'
            });

            break;
        case 'min_delivery_value_error':
        case 'max_delivery_value_error':
        case 'min_pickup_value_error':
        case 'max_pickup_value_error':
        case 'min_in_store_value_error':
        case 'max_in_store_value_error':
            modal.show('custom-message-modal', {
                text: 'orderAmountError'
            });

            break;
        case 'bad_total':
            modal.show('custom-message-modal', {
                text: 'badTotal'
            });

            break;
        case 'discount_unavailable_for_method':
            modal.show('custom-message-modal', {
                text: 'discountUnavailableForMethod'
            });

            break;
        case 'discount_user_limit_exceeded':
            modal.show('custom-message-modal', {
                text: 'discountUserLimitExceeded'
            });

            break;
        case 'invalid_discount':
            modal.show('custom-message-modal', {
                text: 'invalidDiscount'
            });

            break;
        case 'delivery_too_far':
            modal.show('custom-message-modal', {
                text: 'deliveryTooFar'
            });
            break;
        case 'gift_card_error':
            modal.show('custom-message-modal', {
                text: 'giftCardError'
            });
            break;
        case 'piggy_voucher_error':
        case 'como_error':
            modal.show('custom-message-modal', {
                text: 'piggyVoucherError'
            });
            break;
        default:
            // modal.show('custom-message-modal', {
            //     text: 'genericError'
            // });
            break;
    }

    return true;
};
