<template>
    <modal
        height="auto"
        name="mandatory-tip-modal"
        width="525"
        :maxWidth="575"
        :adaptive="true"
        :scrollable="true"
        :clickToClose="true"
        :reset="true"
        transition="pop-out"
        :class="{ 'mobile-item-modal': pivotY === 1 }"
        :pivotY="pivotY"
    >
        <div
            v-if="mandatoryTipSettings"
            class="item-modal-content relative flex w-full"
        >
            <div
                class="p-6 sm:p-10 text-center bg-secondary shadow-xl overflow-auto transition-all w-full"
            >
                <h1 class="text-xl font-bold text-primary">{{ mandatoryTipSettings.modal.header }}</h1>
                <div class="mt-4 text-base text-secondary">
                    {{ mainText }}
                </div>

                <div class="flex flex-col items-center mt-8">
                    <button
                        class="no-highlight flex-auto p-3 px-10 mb-3 w-full text-center text-secondaryactions text-sm font-bold font-button border border-transparent rounded-sm select-none md:w-auto"
                        @click="hideModalAndSetTip(0)"
                    >
                        {{ $t('mandatoryTipModal.noPay') }}
                    </button>
                    <button
                        class="no-highlight flex-auto  p-3.5 px-12 w-full text-center text-button text-sm font-bold bg-button font-button rounded-sm shadow select-none md:mb-0 md:ml-6 md:w-auto"
                        @click="hideModalAndSetTip(mandatoryTipSettings.value)"
                    >
                        {{ $t('mandatoryTipModal.pay') }}
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import {
    mapGetters,
    mapState,
    mapActions
} from 'vuex';
import isMobile from "@/helpers/isMobileDevice";

export default {
    name: 'mandatory-tip-modal',
    data() {
      return {
        mandatoryTipSettings: {"value":1250, "modal":{"header": "Please pay our service charge"}}
      }
    },
    computed: {
        ...mapState({
          venueId: state => state.venue.venue.id,
        }),
        ...mapGetters({
            // mandatoryTipSettings: 'venue/mandatoryTipSettings',
            selectedTip: 'cart/selectedTip'
        }),
        pivotY() {
          return isMobile() ? 1 : 0.5;
        },
        mainText() {
          if (this.venueId === 6169) {
            return '100% of all service charge is given to our staff'
          }
          else return '100% of all tips, service charges and gratuities go to our staff, who in the current climate rely on these more than ever. Thank you for your support.'
        }
    },
    methods: {
        ...mapActions({
            cartTotalWithDiscount: 'cart/cartTotalWithDiscount',
            setTip: 'cart/setTip',
            initTipValue: 'cart/initTipValue'
        }),
        hideModalAndSetTip(value) {
            this.$modal.hide('mandatory-tip-modal');

            if (value === 0) {
                this.setTip(value);
            } else {
                this.initTipValue();
                this.setTip(this.selectedTip || value);
            }

            this.cartTotalWithDiscount();
        }
    }
};
</script>
