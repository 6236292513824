import { TrackingEventsEnum } from "@/enums";

function klaviyoAnalyticsPlugin(config) {
    return {
        name: 'klaviyo',
        config,
        initialize: ({ config }) => {
            const { apiKey } = config;

            if (!apiKey) {
                return
            }

            if (!window.klaviyo) {
                const script = document.createElement('script');
                script.async = true
                script.src = `//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${apiKey}`;
                script.type = "text/javascript"
                document.body.appendChild(script);
            }
        },
        page: () => {},
        track: ({ payload }) => {
            const { event, properties } = payload;

            switch (event) {
                case TrackingEventsEnum.VIEWED_PRODUCT:
                    window.klaviyo.track('Viewed Product', {
                        ProductId: properties.id,
                        ProductName: properties.name,
                        ProductPrice: properties.price,
                        ProductImage: properties.image
                    });

                    break;
                case TrackingEventsEnum.ADD_TO_CART:
                    window.klaviyo.track('Added to Cart', {
                        ProductId: properties.id,
                        ProductName: properties.name,
                        ProductPrice: properties.price,
                        ProductImage: properties.image
                    });

                    break;

                case TrackingEventsEnum.ACTIVE_ON_SITE:
                    window.klaviyo.track('Active on Site', {
                        AccountId: properties.accountId,
                        VenueId: properties.id,
                        VenueSlug: properties.slug
                    });

                    break;
                case TrackingEventsEnum.STARTED_CHECKOUT:
                    window.klaviyo.track('Started Checkout', {
                        Currency: properties.currency,
                        Value: properties.value,
                    });

                    break;
                default:
                    break;
            }
        },
        identify: ({ payload }) => {
            const { userId } = payload;

            window.klaviyo.identify({'$email': userId});
        },
        loaded: () => {
            return  !!window.klaviyo
        }
    }
}

export default klaviyoAnalyticsPlugin
