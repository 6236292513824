import { DiscountTypesEnum, FulfillmentMethodsEnum } from '@/enums';
import { getDiscountType, getDiscountValue } from '@/util/piggyHelper';

const SPECIAL_MERCHANTS = {
    BYRON: '8f0feebd-26b8-4201-9279-26e013a0c4be',
    BB_PIZZA: 'a942e0c3-498f-4118-b2da-fe5a8e86baf1',
    MISS_M: '1be67dbd-0917-42a7-bc7e-121bc25af27a'
};

export const calculateItemTotal = item => {
    const { price, quantity = 1, modifiers = [] } = item;
    const baseTotal = price * quantity;
    const modifiersTotal = modifiers.reduce(
        (sum, { price: modPrice = 0, quantity: modQuantity = 1 }) =>
            sum + modPrice * modQuantity * quantity,
        0
    );

    return baseTotal + modifiersTotal;
};

export const findMatchingCartItem = (items, product, modifiers) => {
    return items.find(
        item =>
            item.id === product.id &&
            item.notes === product.notes &&
            item.modifiers?.length === modifiers.length &&
            item.modifiers.every(mod => modifiers.some(m => m.id === mod.id))
    );
};

export const getDiscountTypes = discount => ({
    isCategoryDiscount:
        Array.isArray(discount.categories) && discount.categories.length,
    isProductDiscount:
        Array.isArray(discount.products) && discount.products.length
});

export const isProductInDiscountCategories = (
    product,
    discount,
    categories
) => {
    if (!Array.isArray(discount.categories) || !Array.isArray(categories)) {
        return false;
    }

    for (const category of categories) {
        if (discount.categories.includes(category.id)) {
            const productFound = category.products.find(
                categoryProduct => categoryProduct.id === product.id
            );

            if (productFound) {
                return true;
            }
        }
    }

    return false;
};

export const calculateAndSplitPrices = ({
    discount,
    items,
    isCategoryDiscount,
    excludeModifiers = false,
    categories
}) => {
    let otherPricesSum = 0;
    let pricesToBeDiscounted = [];

    const shouldExcludeModifiers =
        discount.accountId === SPECIAL_MERCHANTS.BB_PIZZA ||
        discount.id === SPECIAL_MERCHANTS.BYRON ||
        discount.id === SPECIAL_MERCHANTS.MISS_M;

    excludeModifiers = excludeModifiers || shouldExcludeModifiers;

    for (const cartProduct of items) {
        let basePrice = cartProduct.price;
        let totalPrice = basePrice;

        if (cartProduct.modifiers) {
            cartProduct.modifiers.forEach(modifier => {
                totalPrice += modifier.price;
            });
        }

        let productFound = false;

        if (isCategoryDiscount) {
            productFound = isProductInDiscountCategories(
                cartProduct,
                discount,
                categories
            );
        } else if (discount.products?.includes(cartProduct.id)) {
            productFound = true;
        }

        if (productFound) {
            const priceToConsider = excludeModifiers ? basePrice : totalPrice;

            for (let i = 1; i <= cartProduct.quantity; i++) {
                pricesToBeDiscounted.push(priceToConsider);
            }

            if (excludeModifiers && cartProduct.modifiers) {
                cartProduct.modifiers.forEach(modifier => {
                    otherPricesSum += modifier.price * cartProduct.quantity;
                });
            }
        } else {
            otherPricesSum += totalPrice * cartProduct.quantity;
        }
    }

    return { pricesToBeDiscounted, otherPricesSum };
};

export const calculatePercentDiscount = async ({
    discount,
    state,
    subtotal,
    deliveryFee,
    categories
}) => {
    const { isCategoryDiscount, isProductDiscount } =
        getDiscountTypes(discount);

    if (isCategoryDiscount || isProductDiscount) {
        const { pricesToBeDiscounted, otherPricesSum } =
            calculateAndSplitPrices({
                discount,
                items: state.items,
                isCategoryDiscount,
                categories
            });

        const discountedPricesSum = pricesToBeDiscounted.reduce(
            (sum, price) => sum + price,
            0
        );
        let discountValue = Math.round(
            discountedPricesSum * (discount.codeAmount / 1000)
        );

        if (discount.maxDiscountAmount) {
            discountValue = Math.min(discountValue, discount.maxDiscountAmount);
        }

        discountValue = Math.max(0, discountValue);

        // Calculate delivery fee separately, respecting excludeDelivery flag
        const deliveryFeeWithDiscount =
            state.fulfillmentMethod === FulfillmentMethodsEnum.DELIVERY
                ? discount.excludeDelivery
                    ? deliveryFee // If excludeDelivery is true, return original fee
                    : Math.round(
                          (deliveryFee * (100 - discount.codeAmount / 10)) / 100
                      )
                : 0; // Only return 0 if it's not delivery

        return {
            subtotalWithDiscount:
                otherPricesSum + discountedPricesSum - discountValue,
            deliveryFeeWithDiscount:
                state.fulfillmentMethod === FulfillmentMethodsEnum.DELIVERY
                    ? deliveryFeeWithDiscount
                    : 0
        };
    }

    let discountValue = Math.round((subtotal * discount.codeAmount) / 1000);

    if (discount.maxDiscountAmount) {
        discountValue = Math.min(discountValue, discount.maxDiscountAmount);
    }

    discountValue = Math.max(0, discountValue);

    const deliveryFeeWithDiscount =
        state.fulfillmentMethod === FulfillmentMethodsEnum.DELIVERY
            ? discount.excludeDelivery
                ? deliveryFee // If excludeDelivery is true, return original fee
                : Math.round(
                      (deliveryFee * (100 - discount.codeAmount / 10)) / 100
                  )
            : 0; // Only return 0 if it's not delivery

    return {
        subtotalWithDiscount: subtotal - discountValue,
        deliveryFeeWithDiscount:
            state.fulfillmentMethod === FulfillmentMethodsEnum.DELIVERY
                ? deliveryFeeWithDiscount
                : 0
    };
};

export const calculateFixedDiscount = async ({
    discount,
    state,
    subtotal,
    deliveryFee,
    categories
}) => {
    const { isCategoryDiscount, isProductDiscount } =
        getDiscountTypes(discount);

    if (isCategoryDiscount || isProductDiscount) {
        const { pricesToBeDiscounted, otherPricesSum } =
            calculateAndSplitPrices({
                discount,
                items: state.items,
                isCategoryDiscount,
                categories
            });

        const count = pricesToBeDiscounted.length;
        let discountedPricesSum = 0;

        if (count > 0) {
            const totalDiscountableProducts =
                isProductDiscount && !isCategoryDiscount && count > 0
                    ? count
                    : 1;

            discountedPricesSum = Math.max(
                0,
                pricesToBeDiscounted.reduce((sum, price) => sum + price, 0) -
                    discount.codeAmount * totalDiscountableProducts
            );
        }

        const cartSubtotalWithDiscount = otherPricesSum + discountedPricesSum;

        return {
            subtotalWithDiscount: cartSubtotalWithDiscount,
            deliveryFeeWithDiscount:
                state.fulfillmentMethod === FulfillmentMethodsEnum.DELIVERY
                    ? cartSubtotalWithDiscount <= 0
                        ? Math.max(0, deliveryFee + cartSubtotalWithDiscount)
                        : deliveryFee
                    : 0
        };
    }

    const cartSubtotalWithDiscount = Math.max(
        0,
        subtotal - discount.codeAmount
    );

    return {
        subtotalWithDiscount: cartSubtotalWithDiscount,
        deliveryFeeWithDiscount:
            state.fulfillmentMethod === FulfillmentMethodsEnum.DELIVERY
                ? cartSubtotalWithDiscount <= 0
                    ? Math.max(0, deliveryFee + cartSubtotalWithDiscount)
                    : deliveryFee
                : 0
    };
};

export const calculateBOGODiscount = async ({
    discount,
    state,
    subtotal,
    deliveryFee,
    categories
}) => {
    const { isCategoryDiscount, isProductDiscount } =
        getDiscountTypes(discount);

    if (!isCategoryDiscount && !isProductDiscount) {
        return {
            subtotalWithDiscount: subtotal,
            deliveryFeeWithDiscount: deliveryFee
        };
    }

    // Split prices into those eligible for discount and others
    const { pricesToBeDiscounted, otherPricesSum } = calculateAndSplitPrices({
        discount,
        items: state.items,
        isCategoryDiscount,
        categories
    });

    // Create new array and sort prices highest to lowest
    // This ensures most expensive items are paid for
    const sortedPrices = [...pricesToBeDiscounted].sort((a, b) => b - a);

    // Calculate how many items should be full price
    // Ceil ensures odd numbers of items have more full price than free
    const half = Math.ceil(sortedPrices.length / 2);

    // Split into paid and free items without mutating original array
    const fullPrices = sortedPrices.slice(0, half); // Items to pay for
    const discountedPrices = sortedPrices.slice(half); // Items to get free

    // Calculate total of items to pay for
    const fullPricesSum = fullPrices.reduce((sum, price) => sum + price, 0);

    // Calculate potential discount (total of free items)
    let discountedPricesSum = discountedPrices.reduce(
        (sum, price) => sum + price,
        0
    );

    // First calculate the percentage-based discount
    discountedPricesSum = Math.max(
        0,
        Math.round(
            (discountedPricesSum * (100 - discount.codeAmount / 10)) / 100
        )
    );

    // Apply maximum discount limit if specified
    if (discount.maxDiscountAmount) {
        // The original sum of discounted items before applying discount
        const originalDiscountedSum = discountedPrices.reduce(
            (sum, price) => sum + price,
            0
        );
        const appliedDiscount = originalDiscountedSum - discountedPricesSum;
        if (appliedDiscount > discount.maxDiscountAmount) {
            discountedPricesSum =
                originalDiscountedSum - discount.maxDiscountAmount;
        }
    }

    return {
        subtotalWithDiscount:
            otherPricesSum + fullPricesSum + discountedPricesSum,
        deliveryFeeWithDiscount:
            state.fulfillmentMethod === FulfillmentMethodsEnum.DELIVERY
                ? deliveryFee
                : 0
    };
};

export const calculateFreeDeliveryDiscount = async ({ subtotal }) => {
    return {
        subtotalWithDiscount: Math.max(0, subtotal),
        deliveryFeeWithDiscount: 0
    };
};

export const calculateDiscount = async ({ discount, ...rest }) => {
    switch (discount.type) {
        case DiscountTypesEnum.PERCENT:
            return calculatePercentDiscount({ discount, ...rest });
        case DiscountTypesEnum.FIXED:
            return calculateFixedDiscount({ discount, ...rest });
        case DiscountTypesEnum.BUY_ONE_GET_ONE_FREE:
            return calculateBOGODiscount({ discount, ...rest });
        case DiscountTypesEnum.FREE_DELIVERY:
            return calculateFreeDeliveryDiscount({ discount, ...rest });
        default:
            return {
                subtotalWithDiscount: rest.subtotal,
                deliveryFeeWithDiscount: rest.deliveryFee
            };
    }
};

export const calculatePiggyDiscount = async ({
    piggyProductDiscount,
    piggyRewardSelected,
    fulfillmentMethod,
    subtotalWithDiscount,
    deliveryFeeWithDiscount,
    deliveryFee,
    piggySettings
}) => {
    const discountType = getDiscountType(piggyRewardSelected);
    const discountValue = getDiscountValue(piggyRewardSelected);

    let totalPiggyDiscount = Math.ceil(
        piggyProductDiscount.reduce(
            (total, product) => total + product.value,
            0
        )
    );

    subtotalWithDiscount -= Math.min(totalPiggyDiscount, subtotalWithDiscount);

    if (
        fulfillmentMethod === FulfillmentMethodsEnum.DELIVERY &&
        piggySettings?.includeDeliveryCosts &&
        deliveryFeeWithDiscount > 0
    ) {
        if (discountType.includes('amount')) {
            const diff = discountValue * 100 - totalPiggyDiscount;
            if (diff > 0) {
                const maxAmountDiscount = Math.min(
                    diff,
                    deliveryFeeWithDiscount
                );
                deliveryFeeWithDiscount -= maxAmountDiscount;
                totalPiggyDiscount += maxAmountDiscount;
            }
        }

        if (discountType.includes('percent')) {
            const calculatedPercentDiscount = Math.min(
                deliveryFee * (discountValue / 100),
                deliveryFeeWithDiscount
            );
            deliveryFeeWithDiscount -= calculatedPercentDiscount;
            totalPiggyDiscount += calculatedPercentDiscount;
        }
    }

    return {
        subtotalWithDiscount,
        deliveryFeeWithDiscount,
        totalPiggyDiscount
    };
};

export default {
    calculateItemTotal,
    calculateDiscount,
    calculatePiggyDiscount
};
