<template>
    <div class="relative flex flex-col">
        <div class="sticky top-0 z-50 flex h-0 justify-end" v-if="isOpenReward">
            <div class="mr-2 mt-2">
                <button
                    @click="back"
                    class="no-highlight text-primary bg-secondary rounded-full p-1.5 shadow-md"
                >
                    <svg
                        class="h-6 w-6"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                </button>
            </div>
        </div>
        <div v-if="!isOpenReward" class="max-h-[695px] overflow-auto">
            <div v-if="isLoading">
                <vue-content-loading
                    v-for="index in 3"
                    :key="index"
                    :height="48"
                    :speed="2"
                    class="mb-3"
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                >
                    <rect
                        x="2.5%"
                        y="10"
                        rx="3"
                        ry="3"
                        width="95%"
                        height="40"
                    />
                </vue-content-loading>
            </div>
            <ul
                :class="{
                    'horizontal-list no-scrollbar py-4 px-3':
                        listDirection === 'horizontal'
                }"
                v-else
            >
                <li
                    v-for="voucher in vouchers"
                    :key="voucher.uuid"
                    class="cursor-pointer border-b border-dashed p-4"
                    :class="{
                        'last:border-b-0': listDirection !== 'horizontal'
                    }"
                    @click="onRewardSelected(voucher)"
                >
                    <div
                        class="transition-scale flex w-full flex-row duration-300 sm:hover:scale-[101%]"
                    >
                        <img
                            v-if="voucher.reward?.media?.value"
                            :src="`https://71f65abd77a8d66ef2e1.ucr.io/-/resize/208x/${voucher.reward.media.value}`"
                            class="h-[104px] w-[104px] shrink-0 rounded-md object-cover"
                            alt="reward"
                        />
                        <div class="flex flex-col pl-2 pr-4 py-2">
                            <p
                                class="mb-4 font-heading text-heading text-sm font-bold"
                                >{{ voucher.name }}</p
                            >
                            <p
                                v-if="voucher.expiration_date"
                                class="text-xs text-subheading mb-1.5"
                                >Use before:
                                {{
                                    $dayjs(voucher.expiration_date).format(
                                        'Do MMM'
                                    )
                                }}</p
                            >
                            <span
                                class="border-button-primary w-fit text-button bg-button font-button rounded-md p-2 text-xs font-bold shadow"
                                >Redeem</span
                            >
                        </div>
                    </div>
                </li>
                <li
                    v-for="collectableReward in collectableRewards"
                    :key="collectableReward.id"
                    class="cursor-pointer border-b border-dashed p-4"
                    :class="{
                        'last:border-b-0': listDirection !== 'horizontal'
                    }"
                    @click="onRewardSelected(collectableReward)"
                >
                    <div
                        class="transition-scale flex w-full flex-row duration-300 sm:hover:scale-[101%]"
                    >
                        <img
                            v-if="collectableReward.reward?.media?.value"
                            :src="`https://71f65abd77a8d66ef2e1.ucr.io/-/resize/208x/${collectableReward.reward.media.value}`"
                            class="h-[104px] w-[104px] shrink-0 rounded-md object-cover"
                            alt="reward"
                        />
                        <div class="flex flex-col px-4 py-2">
                            <p class="text-sm"
                                >Pick up before:
                                {{
                                    $dayjs(collectableReward.expires_at).format(
                                        'dddd, MMMM D, YYYY h:mm A'
                                    )
                                }}</p
                            >
                            <p class="mb-4 font-heading text-base font-bold"
                                >{{ collectableReward.title }}
                                {{
                                    `(-${collectableReward.reward.cost_price}%)`
                                }}</p
                            >
                            <span
                                class="border-button-primary w-fit text-button bg-button font-button rounded-md p-2 text-xs font-bold shadow"
                                >Ready for pick up</span
                            >
                        </div>
                    </div>
                </li>

                <li
                    v-for="reward in rewards"
                    :key="reward.id"
                    class="cursor-pointer p-4"
                    :class="{
                        'border-b border-dashed last:border-b-0':
                            listDirection === 'vertical'
                    }"
                    @click="onRewardSelected(reward)"
                >
                    <div
                        class="transition-scale flex w-full flex-row duration-300 sm:hover:scale-[101%]"
                    >
                        <img
                            v-if="reward.media?.value"
                            :src="`https://71f65abd77a8d66ef2e1.ucr.io/-/resize/208x/${reward.media?.value}`"
                            class="h-[104px] w-[104px] shrink-0 rounded-md object-cover"
                            alt="reward"
                        />
                        <div class="flex flex-col px-4 py-2">
                            <p
                                class="mb-4 text-heading font-heading text-sm font-bold"
                                >{{ reward.title }}</p
                            >
                            <p
                                v-if="reward.required_credits"
                                class="mb-1.5 text-xs text-subheading"
                                >{{ reward.required_credits }} credits</p
                            >
                            <span
                                v-if="
                                    isCreditBalanceInsufficient(
                                        reward.required_credits
                                    )
                                "
                                class="border-button-primary opacity-75 w-fit text-button bg-button font-button rounded-md p-2 text-xs font-bold shadow"
                                >Not enough {{ piggyCreditName }}</span
                            >
                            <span
                                v-if="
                                    contact.credit_balance &&
                                    !isCreditBalanceInsufficient(
                                        reward.required_credits
                                    )
                                "
                                class="border-button-primary w-fit text-button bg-button font-button rounded-md p-2 text-xs font-bold shadow"
                                >Redeem</span
                            >
                            <span
                                v-if="
                                    !contact.credit_balance &&
                                    !isCreditBalanceInsufficient(
                                        reward.required_credits
                                    )
                                "
                                class="border-button-primary w-fit text-button bg-button font-button rounded-md p-2 text-xs font-bold shadow"
                                >Sign up to claim</span
                            >
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div
            v-if="noRewardsFound"
            class="flex h-56 items-center justify-center text-xl font-bold"
        >
            No rewards available
        </div>
        <div v-if="isOpenReward" class="flex flex-col">
            <piggy-reward-selected
                :reward="selectedReward"
                :contact="contact"
                @back="back"
            />
        </div>
    </div>
</template>

<script>
import VueContentLoading from 'vue-content-loading';
import { mapGetters, mapState, mapActions } from 'vuex';
import PiggyRewardSelected from '@/components/PiggyRewardSelected';

export default {
    name: 'PiggyRewardsList',
    components: {
        VueContentLoading,
        PiggyRewardSelected
    },
    data() {
        return {
            isOpenReward: false,
            selectedReward: {},
            rewards: [],
            collectableRewards: [],
            vouchers: [],
            contact: {},
            isLoading: false,
            noRewardsFound: false
        };
    },
    props: {
        listDirection: {
            type: String,
            default: 'vertical',
            validator: function (value) {
                return ['vertical', 'horizontal'].indexOf(value) !== -1;
            }
        },
        isExternalRewardModal: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        rewardTitle(title) {
            this.$emit('update-title', title);
        }
    },
    created() {
        this.getRewards();
    },
    computed: {
        ...mapState({
            email: state => state.customer.email,
            piggySettings: state => state.venue.venue.piggySettings
        }),
        ...mapGetters({
            venueValue: 'venue/venueValue'
        }),
        rewardTitle() {
            return `${this.selectedReward.title || 'Rewards'}`;
        },

        accountId() {
            return this.venueValue('accountId');
        },
        venueId() {
            return this.venueValue('id');
        },

        piggyCreditName() {
            return this.piggySettings.creditUnitName || 'points';
        }
    },
    methods: {
        ...mapActions({
            setPiggyProductDiscount: 'cart/SET_PIGGY_PRODUCT_DISCOUNT',
            cartTotalWithDiscount: 'cart/cartTotalWithDiscount',
            removeDiscount: 'cart/removeDiscount'
        }),
        back() {
            this.isOpenReward = false;
            this.selectedReward = {};
        },
        parseDiscountType(type) {
            const prefix = type.split('_')[0];

            return {
                prefix,
                type
            };
        },
        onRewardSelected(reward) {
            if (
                !this.contact.credit_balance ||
                this.contact.credit_balance.balance < reward.required_credits
            ) {
                return;
            }

            this.selectedReward = reward;

            if (this.isExternalRewardModal) {
                this.$modal.show('piggy-reward-selected-modal', {
                    reward,
                    contact: this.contact
                });
            } else {
                this.isOpenReward = true;
            }
        },
        async getRewards() {
            this.isLoading = true;
            this.noRewardsFound = false;

            try {
                const params = {
                    email: this.email,
                    piggyShopId: this.piggySettings.piggyShopId,
                    accountId: this.accountId,
                    venueId: this.venueId
                };

                const { data } = await this.$axios.get('/piggy/rewards', {
                    params
                });

                if (!data.contact) {
                    this.rewards = data;
                } else {
                    this.rewards = data.rewards;
                    this.collectableRewards = data.collectableRewards;
                    this.vouchers = data.vouchers;
                    this.contact = data.contact;
                }
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;

                if (!this.rewards.length && !this.vouchers.length) {
                    this.noRewardsFound = true;
                }
            }
        },
        isCreditBalanceInsufficient(requiredCredits) {
            return this.contact?.credit_balance?.balance < requiredCredits;
        }
    }
};
</script>

<style>
.horizontal-list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    gap: 0.5rem;
}

.horizontal-list li {
    min-width: 210px;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    padding: 0.5rem;
    flex-shrink: 0;
}
</style>
