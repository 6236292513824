export default class CategoryNavImagesEnum {
    static get DESKTOP() {
        return 'DESKTOP';
    }

    static get DESKTOP_MOBILE() {
        return 'DESKTOP_MOBILE';
    }

    static get NONE() {
        return 'NONE';
    }

    static get options() {
        return {
            DESKTOP: this.DESKTOP,
            DESKTOP_MOBILE: this.DESKTOP_MOBILE,
            NONE: this.NONE
        };
    }
}
