<template>
    <div class="py-2.5 flex">
        <label class="text-price text-sm p-1 mr-2" for="guest-count">
            Number of diners:
        </label>
        <input
            type="number"
            id="guest-count"
            class="border-color p-1 focus:outline-none border rounded-sm text-primary bg-secondary bg border-gray-200 shadow-sm text-xs w-14"
            v-model="noOfDiners"
            min="1"
            @input="updateGuestCount"
            @blur="validateGuestCount"
        />
    </div>
</template>

<script>
export default {
    name: 'GuestCountInput',
    data() {
        return {
            noOfDiners: 1
        };
    },
    methods: {
        updateGuestCount() {
            this.$store.commit(
                'cart/SET_NUMBER_OF_DINERS',
                Math.max(this.noOfDiners, 1)
            );
        },
        validateGuestCount() {
            if (this.noOfDiners < 1) {
                this.noOfDiners = 1;
            }
        }
    }
};
</script>

<style scoped>
.border-color:focus {
    border-color: var(--color-highlight-border);
    border-width: 1.5px;
}
</style>
