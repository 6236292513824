<template>
    <div>
        <span id="blink">.</span>
        <span id="blink">.</span>
        <span id="blink">.</span>
    </div>
</template>

<script>
export default {
    name: 'LoadingDots'
};
</script>

<style scoped>
#blink {
    animation: 1s blink infinite;
}
#blink:nth-child(2) {
    animation-delay: 250ms;
}
#blink:nth-child(3) {
    animation-delay: 500ms;
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
</style>
