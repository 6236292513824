import Vue from 'vue';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

const locales = {
    fr: () => import('dayjs/locale/fr'),
    it: () => import('dayjs/locale/it'),
    es: () => import('dayjs/locale/es')
};

const loadLocale = async lang => {
    const locale = lang.split('-')[0];

    if (locales[locale]) {
        try {
            const module = await locales[locale]();

            dayjs.locale(module.default);
        } catch (error) {
            /* eslint-disable-next-line */
            console.error(`Failed to load locale: ${locale}`, error);
        }
    }
};

Object.defineProperties(Vue.prototype, {
    $dayjs: {
        get() {
            return dayjs;
        }
    }
});

Vue.filter('dayjs', (value, format) => {
    let data = Array.isArray(value) ? dayjs(...value) : dayjs.utc(value).local();

    return data.isValid() ? data.format(format) : '';
});

export { loadLocale };
