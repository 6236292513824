<template>
    <div
        class="relative shrink-0 self-center ml-3 sm:ml-4 mr-auto overflow-hidden bg-cover bg-center rounded-sm"
        :class="imageClasses"
    >
        <BlurHash
            v-if="item.imageHash"
            :hash="item.imageHash"
            :isLoaded="isLoaded"
        />
        <img
            :loading="eagerLoad ? 'eager' : 'lazy'"
            :decoding="eagerLoad ? 'sync' : 'async'"
            :width="imageSize"
            :height="imageSize"
            class="menu-page__item-image block w-full h-full rounded-r-sm object-cover"
            :class="{ 'rounded-l-sm': imageSize !== ImageSizeEnum.LG }"
            :src="item.image + imageResizeSuffix"
            :srcset="srcSet"
            :alt="item.name"
            @load="onLoad"
        />
    </div>
</template>

<script>
import BlurHash from './BlurHash.vue';
import { ImageSizeEnum } from '@/enums';

export default {
    name: 'ItemImage',
    components: { BlurHash },
    props: {
        item: {
            type: Object,
            required: true
        },
        largeImages: {
            type: Boolean,
            required: true
        },
        eagerLoad: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            isLoaded: false,
            ImageSizeEnum
        };
    },
    computed: {
        imageSize() {
            const size = ImageSizeEnum.getFromLegacyFlags(
                this.largeImages,
                this.isBao
            );
            switch (size) {
                case ImageSizeEnum.LG:
                    return 144;
                case ImageSizeEnum.SM:
                    return 96;
                case ImageSizeEnum.XL:
                    return 240;
                default:
                    return 112; // MD
            }
        },
        imageClasses() {
            const size = ImageSizeEnum.getFromLegacyFlags(
                this.largeImages,
                this.isBao
            );
            return {
                'w-36 h-36': size === ImageSizeEnum.LG,
                'w-28 h-28': size === ImageSizeEnum.MD,
                '!w-24 !h-24': size === ImageSizeEnum.SM,
                '!w-full !h-60 rounded-t-md': size === ImageSizeEnum.XL
            };
        },
        isBao() {
            return (
                this.$store.state.venue?.venue?.accountId ===
                '5f4e9ddc-3431-43f5-9de0-7aa356564afc'
            );
        },
        imageResizeSuffix() {
            return this.largeImages
                ? '-/resize/144x/-/format/auto/'
                : '-/resize/112x/-/format/auto/';
        },
        srcSet() {
            const base = this.item.image;
            const resize1x = this.imageSize;
            const resize2x = this.imageSize * 2;
            const resize3x = this.imageSize * 3;
            return `${base}-/resize/${resize1x}x/-/format/auto/ 1x, ${base}-/resize/${resize2x}x/-/format/auto/ 2x, ${base}-/resize/${resize3x}x/-/format/auto/ 3x`;
        }
    },
    methods: {
        onLoad() {
            this.isLoaded = true;
        }
    }
};
</script>
