<template>
    <li
        class="relative flex snap-start flex-row bg-white border-1 border border-gray-300 pr-1.5 py-1.5 rounded-md items-center self-stretch justify-between bg-secondary outline-none sm:hover:shadow-lg cursor-pointer select-none transition-shadow duration-500 border-bottom-menu-item border-menu-item"
        :class="{
            'item-in-cart': isInCart
        }"
    >
        <div
            class="relative shrink-0 self-center w-16 h-16 ml-2 mr-auto bg-cover bg-center rounded-md"
        >
            <img
                v-if="item.image"
                class="block w-full h-full rounded-md object-cover"
                :src="`${item.image}-/format/auto/-/resize/120x/`"
                height="60"
                width="60"
                :alt="item.name"
            />
        </div>
        <!--        <ItemImage-->
        <!--            v-if="item.image"-->
        <!--            :item="item"-->
        <!--            :largeImages="largeImages"-->
        <!--            :eager-load="shouldEagerLoad"-->
        <!--        />-->
        <div class="relative grow mb-auto px-1">
            <h3
                class="font-heading block text-sm text-gray-800 leading-tight font-semibold break-word"
                v-snip="2"
            >
                {{ item.name }}
            </h3>
            <!--            <div class="pb-2 leading-none md:leading-tight" v-if="isAvailable">-->
            <!--                <span-->
            <!--                    class="menu-page__item-desc block mt-1 text-muted text-sm leading-5 xl:mt-2 break-word"-->
            <!--                    v-snip="1"-->
            <!--                >-->
            <!--                    {{ item.description }}-->
            <!--                </span>-->
            <!--            </div>-->

            <div class="flex items-baseline justify-between">
                <span
                    class="flex mr-2 mt-1 text-price text-sm leading-relaxed md:text-base xl:mt-2"
                >
                    <span
                        v-if="isInCart && !item.compareAtPrice"
                        class="pr-1 font-medium"
                        style="color: #abadad"
                    >
                        {{ quantityInCart }}x
                    </span>

                    <span v-if="item.price || !item.modifierGroups.length">
                        <span v-if="item.compareAtPrice" class="text-gray-500">
                            <s class="mr-1 text-gray-500">
                                {{
                                    (item.compareAtPrice / 100) | customCurrency
                                }}
                            </s>

                            <span
                                v-if="isInCart"
                                class="font-medium"
                                style="color: #abadad"
                            >
                                {{ quantityInCart }}x
                            </span>

                            {{ (item.price / 100) | customCurrency }}
                        </span>

                        <span v-else>
                            {{ (item.price / 100) | customCurrency }}
                        </span>
                    </span>

                    <button
                        v-if="quickAdd"
                        @click="quickAddToCartWithDelay($event)"
                        id="quick-add-to-cart"
                        class="text-sm px-2 mx-2 rounded py-0 bg-button text-button flex justify-center items-center gap-2 transition-colors ease-in-out"
                    >
                        <plus-icon class="w-2.5 h-2.5 pointer-events-none" />
                        <span class="pointer-events-none font-medium">
                            {{ $t('menuItem.quickAdd') }}
                        </span>
                    </button>
                </span>
            </div>
        </div>
    </li>
</template>

<script>
import ItemImage from './MenuItemListItemImage';
import MenuItemTags from './MenuItemTags';
import { mapActions, mapGetters } from 'vuex';
import PlusIcon from './icons/Plus';

export default {
    name: 'MenuItemCrossSell',
    components: {
        PlusIcon,
        ItemImage,
        MenuItemTags
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        isAvailable: {
            type: Boolean,
            required: true
        },
        largeImages: {
            type: Boolean,
            required: true
        },
        categoryIndex: {
            type: Number,
            required: false
        },
        itemIndex: {
            type: Number,
            required: false
        },
        quickAdd: {
            type: Boolean,
            default: false
        },
        navigateToBasket: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isInCart() {
            return this.$store.getters['cart/cartItemById'](this.item.id);
        },
        quantityInCart() {
            if (!this.isInCart) {
                return false;
            }
            return this.$store.getters['cart/cartItemQuantity'](this.item.id);
        },
        isBao() {
            return (
                this.$store.state.venue?.venue?.accountId ===
                '5f4e9ddc-3431-43f5-9de0-7aa356564afc'
            );
        },
        isBundobust() {
            return (
                this.$store.state.venue?.venue?.accountId ===
                'e98a0850-c90b-42ff-8118-014d4132ae5c'
            );
        },
        hasModifierRequired() {
            return !!this.item.modifierGroups.find(
                modifierGroup => modifierGroup.min !== 0
            );
        },
        hasAlcoholTag() {
            return !!this.item.tags?.includes(1);
        },
        requiresAgeVerification() {
            return (
                this.hasAlcoholTag && !this.$store.state.cart.ageOver18Confirmed
            );
        },
        shouldEagerLoad() {
            return this.categoryIndex === 0 && this.itemIndex <= 8;
        }
    },
    methods: {
        ...mapActions({
            addProductToCart: 'cart/addProductToCart',
            cartTotalWithDiscount: 'cart/cartTotalWithDiscount'
        }),
        async checkAvailability() {
            try {
                const { data: inventory } = await this.$axios.get(
                    `/menus-items/${this.item.id}/inventory`
                );

                this.item.inventory = inventory;

                return inventory - 1 >= 0;
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async quickAddToCartWithDelay($event) {
            if (this.hasModifierRequired || this.requiresAgeVerification) {
                this.$emit('showItemModal');

                return;
            } else {
                $event.stopPropagation();
            }

            this.item.notes = '';

            if (this.item.trackInventory && !this.isInCart) {
                const isAvailable = await this.checkAvailability();

                if (!isAvailable) {
                    return;
                }
            }

            setTimeout(
                () => {
                    this.addProductToCart([
                        this.item,
                        1,
                        [],
                        'basket_ai_cross_sell_quick_add'
                    ]);

                    if ('vibrate' in navigator) {
                        window.navigator.vibrate(200);
                    }

                    this.cartTotalWithDiscount();

                    this.$notify({
                        group: 'PiggyRewards',
                        title: `${this.item.name} added to your basket`
                    });

                    if (this.navigateToBasket) {
                        this.$router.push({
                            name: 'menu',
                            params: { showBasket: true }
                        });
                    }
                },
                this.item.trackInventory ? 0 : 150
            );
        }
    }
};
</script>

<style scoped>
.bao {
    font-size: 13px !important;
}
</style>
<style src="@/assets/css/menu-item.css"></style>
