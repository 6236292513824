export default {
    ar: 'ar',
    'zh-CN': 'zh-CN',
    'zh-TW': 'zh-TW',
    'hr-HR': 'hr-HR',
    'cs-CZ': 'cs-CZ',
    'da-DK': 'da-DK',
    'nl-NL': 'nl-NL',
    'en-US': 'en-US',
    'fi-FI': 'fi-FI',
    'fr-FR': 'fr-FR',
    'de-DE': 'de-DE',
    'el-GR': 'el-GR',
    'hu-HU': 'hu-HU',
    'it-IT': 'it-IT',
    'ja-JP': 'ja-JP',
    'ko-KR': 'ko-KR',
    'no-NO': 'no-NO',
    'pl-PL': 'pl-PL',
    'pt-BR': 'pt-BR',
    'ro-RO': 'ro-RO',
    'ru-RU': 'ru-RU',
    'sk-SK': 'sk-SK',
    'sl-SI': 'sl-SI',
    'es-ES': 'es-ES',
    'sv-SE': 'sv-SE'
};
