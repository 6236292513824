<template>
    <li
        class="menu-page__item my-0 relative sm:mb-4 flex items-center self-stretch bg-secondary rounded-sm outline-none sm:hover:shadow-lg cursor-pointer select-none transition-shadow duration-500 border-bottom-menu-item border-menu-item"
        :class="{
            'pointer-events-none opacity-50': !isAvailable,
            'item-in-cart': isInCart,
            'mb-3 mx-3 sm:mx-0 !rounded-sm active:outline-none active:ring active:ring-slate-100':
                largeImages,
            'px-4 py-2.5 sm:py-4': !largeImages,
            'px-5 pt-4': isBao
        }"
        :aria-disabled="!isAvailable"
    >
        <div
            class="grow mb-auto"
            :class="{ 'px-4 py-2.5 sm:py-4': largeImages }"
        >
            <h3
                class="menu-page__item-name font-heading text-base text-heading font-semibold"
            >
                {{ item.name }}
            </h3>
            <div class="pb-2 leading-none md:leading-tight" v-if="isAvailable">
                <span
                    class="menu-page__item-desc block mt-1 text-muted text-sm leading-5 xl:mt-2 break-word"
                    :class="{ bao: isBao }"
                    v-snip="2"
                >
                    {{ item.description }}
                </span>
            </div>
            <p
                class="menu-page__item-desc mt-1 text-gray-600 text-sm leading-tight xl:mt-2"
                v-else
            >
                {{ $t('menuItem.soldOut') }}
            </p>

            <div class="flex items-baseline justify-between">
                <span
                    class="flex mr-2 mt-1 text-price text-sm leading-relaxed md:text-base xl:mt-2"
                >
                    <span
                        v-if="isInCart && !item.compareAtPrice"
                        class="pr-1 font-medium"
                        style="color: #abadad"
                    >
                        {{ quantityInCart }}x
                    </span>

                    <span v-if="item.price || !item.modifierGroups.length">
                        <span v-if="item.compareAtPrice">
                            <s class="mr-1 text-price">
                                {{
                                    (item.compareAtPrice / 100) | customCurrency
                                }}
                            </s>

                            <span
                                v-if="isInCart"
                                class="font-medium"
                                style="color: #abadad"
                            >
                                {{ quantityInCart }}x
                            </span>

                            {{ (item.price / 100) | customCurrency }}
                        </span>

                        <span v-else>
                            {{ (item.price / 100) | customCurrency }}
                        </span>
                    </span>

                    <span
                        v-if="item.popular && item.isAvailable"
                        :class="{ 'font-bold': isBao }"
                        class="flex items-center ml-2 text-orange-400 text-xs"
                    >
                        <svg
                            v-if="!isBao"
                            class="mr-0.5 w-4 h-4"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                            ></path>
                        </svg>
                        <img
                            v-if="isBao"
                            src="https://ucarecdn.com/d5b9f033-267e-44f0-a68c-5b328bb00e47/-/format/auto/-/resize/48x/"
                            alt=""
                            class="w-4 h-4 mr-1"
                        />
                        {{ isBao ? 'Our Fav' : $t('menuItem.popular') }}
                    </span>
                </span>
                <menu-item-tags :tags="item.tags" />
            </div>
        </div>

        <ItemImage
            v-if="item.image"
            :item="item"
            :largeImages="largeImages"
            :eager-load="shouldEagerLoad"
        />

        <quick-add-context
            v-if="!allergenDisabled"
            :item="item"
            class="absolute bottom-1 right-3 scale-[0.8]"
            :is-expandable="!hasModifierRequired && !requiresAgeVerification"
            @increment="quickAddToCartWithDelay($event)"
            @decrement="removeProductFromCart"
        />
    </li>
</template>

<script>
import ItemImage from './MenuItemListItemImage';
import MenuItemTags from './MenuItemTags';
import { mapState, mapActions, mapGetters } from 'vuex';
import PlusIcon from './icons/Plus';
import QuickAddContext from './QuickAddContext';
import { showNotificationWithCounter } from '@/utils/notificationCounter';

export default {
    name: 'MenuItem',
    components: {
        ItemImage,
        MenuItemTags,
        QuickAddContext
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        isAvailable: {
            type: Boolean,
            required: true
        },
        largeImages: {
            type: Boolean,
            required: true
        },
        categoryIndex: {
            type: Number,
            required: false
        },
        itemIndex: {
            type: Number,
            required: false
        },
        quickAdd: {
            type: Boolean,
            default: false
        },
        source: {
            type: String,
            default: 'quick_add'
        }
    },
    computed: {
        ...mapState({
            cartItems: state => state.cart.items
        }),
        ...mapGetters({
            allergenDisabled: 'customer/allergenDisabled'
        }),
        isInCart() {
            return this.$store.getters['cart/cartItemById'](this.item.id);
        },
        quantityInCart() {
            if (!this.isInCart) {
                return false;
            }
            return this.$store.getters['cart/cartItemQuantity'](this.item.id);
        },
        isBao() {
            return (
                this.$store.state.venue?.venue?.accountId ===
                '5f4e9ddc-3431-43f5-9de0-7aa356564afc'
            );
        },
        hasModifierRequired() {
            return !!this.item.modifierGroups.find(
                modifierGroup => modifierGroup.min !== 0
            );
        },
        hasAlcoholTag() {
            return !!this.item.tags?.includes(1);
        },
        requiresAgeVerification() {
            return (
                this.$store.state.venue?.venue?.ageGated &&
                this.hasAlcoholTag &&
                !this.$store.state.cart.ageOver18Confirmed
            );
        },
        shouldEagerLoad() {
            return this.categoryIndex === 0 && this.itemIndex <= 8;
        }
    },
    methods: {
        ...mapActions({
            addProductToCart: 'cart/addProductToCart',
            cartTotalWithDiscount: 'cart/cartTotalWithDiscount',
            updatePiggyDiscount: 'cart/updatePiggyDiscount'
        }),
        async checkAvailability() {
            try {
                const { data: inventory } = await this.$axios.get(
                    `/menus-items/${this.item.id}/inventory`
                );

                this.item.inventory = inventory;

                return inventory - 1 >= 0;
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async quickAddToCartWithDelay($event) {
            if (this.hasModifierRequired || this.requiresAgeVerification) {
                this.$emit('showItemModal');

                return;
            } else {
                $event.stopPropagation();
            }

            this.item.notes = '';

            if (this.item.trackInventory && !this.isInCart) {
                const isAvailable = await this.checkAvailability();

                if (!isAvailable) {
                    return;
                }
            }

            setTimeout(
                () => {
                    this.addProductToCart([this.item, 1, [], this.source]);

                    if ('vibrate' in navigator) {
                        window.navigator.vibrate(200);
                    }

                    this.cartTotalWithDiscount();
                    this.showAddedToCartNotification();
                },
                this.item.trackInventory ? 0 : 0
            );
        },
        showAddedToCartNotification() {
            // Use the reusable utility to show notification with counter
            showNotificationWithCounter(this, this.item.id, this.item.name);
        },
        removeProductFromCart() {
            const product = [{ ...this.item, notes: '' }, -1, [], this.source];

            this.addProductToCart(product);
            this.removePiggyProductDiscount(product);

            if (!this.cartItems.length) {
                this.$store.commit('customer/CLEAR_COMO_REWARDS');
            }
            this.cartTotalWithDiscount();
        },
        removePiggyProductDiscount(product) {
            if (product[0].quantity === 0) {
                this.$store.commit('cart/REMOVE_PIGGY_PRODUCT_DISCOUNT', {
                    id: product[0].id,
                    modifierIds: []
                });

                if (!this.piggyProductDiscount?.length) {
                    this.$store.commit('cart/SET_PIGGY_REWARD_SELECTED', {});
                }
            }

            this.updatePiggyDiscount({
                productId: product[0].id,
                quantity: product[0].quantity
            });
        }
    }
};
</script>

<style scoped>
.bao {
    font-size: 13px !important;
}
</style>
<style src="@/assets/css/menu-item.css"></style>
