<template>
    <div class="relative">
        <span class="inline-block mb-2 text-itembody text-sm font-black">
            {{ $t('preOrder.orderingFor') }}
        </span>
        <div class="relative flex flex-col sm:flex-row">
            <div class="relative mb-2 w-full sm:mb-4 sm:w-1/2 md:mb-0 md:pr-2">
                <div class="rounded-md shadow-sm">
                    <select-input
                        :data="availablePreorderDays"
                        v-model="displayablePickupDate"
                        :placeholder="$t('preOrder.selectADay')"
                        dates
                        :disableAll="disableAll"
                        :error="v.pickupDate.$error"
                        :size="size"
                    />
                </div>
            </div>
            <div
                v-if="!displayablePickupDate"
                class="relative w-full sm:ml-2 sm:w-1/2 md:pl-2"
            >
                <div class="rounded-md shadow-sm">
                    <select
                        v-model="displayablePickupTimeSlot"
                        id="noDaySelected"
                        :class="{
                            'ring ring-red-200 border-red-300':
                                v.pickupTimeSlot.$error
                        }"
                        class="form-select block py-3 w-full text-sm bg-gray-50 transition duration-150 ease-in-out sm:leading-5 border-gray-200 rounded"
                        disabled
                    >
                        <option value="" selected>
                            {{
                                pickupSlotTimesLoading
                                    ? $t('preOrder.loading')
                                    : $t('preOrder.selectDayFirst')
                            }}
                        </option>
                    </select>
                </div>
            </div>
            <div
                v-else-if="pickupSlotTimes && pickupSlotTimes.length > 0"
                class="relative w-full sm:ml-2 sm:w-1/2 md:pl-2"
            >
                <div class="rounded-md shadow-sm">
                    <select-input
                        :placeholder="
                            pickupSlotTimesLoading
                                ? $t('preOrder.loading')
                                : $t('preOrder.selectTimeSlot')
                        "
                        v-model="displayablePickupTimeSlot"
                        :data="pickupSlotTimes"
                        :disableAll="disableAll"
                        :error="v.pickupTimeSlot.$error"
                        :size="size"
                    />
                </div>
            </div>

            <div v-else class="relative w-full sm:ml-2 sm:w-1/2 md:pl-2">
                <div class="rounded-md shadow-sm">
                    <select
                        v-model="displayablePickupTimeSlot"
                        id="noSlots"
                        :class="{
                            'ring ring-red-200 border-red-300':
                                v.pickupTimeSlot.$error
                        }"
                        style="height: 47px"
                        class="form-select block w-full text-sm font-medium bg-gray-50 transition duration-150 ease-in-out sm:leading-5 border-gray-200 rounded"
                        disabled
                    >
                        <option value="" selected>
                            {{
                                pickupSlotTimesLoading
                                    ? $t('preOrder.loading')
                                    : $t('preOrder.noSlotsLeftToday')
                            }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SelectInput from './Select';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    name: 'PreOrder',
    components: { SelectInput },
    props: {
        v: {
            type: Object,
            required: true,
            default: () => ({})
        },
        blockAutoDatePreFill: {
            type: Boolean,
            required: true,
            default: false
        },
        venueId: {
            type: Number,
            required: true,
            default: 0
        },
        availablePreorderDays: {
            type: Array,
            required: true,
            default: () => []
        },
        disableAll: {
            type: Boolean,
            required: true,
            default: false
        },
        fulfillmentMethod: {
            type: String,
            required: true
        },
        size: {
            type: String,
            default: 'medium'
        }
    },
    computed: {
        ...mapState('cart', {
            fetchPickupSlotsKey: state => state.fetchPickupSlotsKey
        }),
        ...mapGetters({
            orderTotal: 'cart/cartTotalWithDiscount',
            cartQuantity: 'cart/cartQuantity',
            pickupDate: 'cart/getPickupDate',
            pickupTimeSlot: 'cart/getPickupTimeSlot'
        })
    },
    async mounted() {
        if (this.pickupDate?.length > 0) {
            this.displayablePickupDate = this.pickupDate;

            if (!this.pickupSlotTimes?.length) {
                this.pickupSlotTimes = await this.checkTimeSlotAccessibility();
            }
        } else {
            if (!this.blockAutoDatePreFill) {
                this.displayablePickupDate = this.availablePreorderDays[0];
            }
        }

        // if (this.pickupTimeSlot?.length > 0) {
        //     this.displayablePickupTimeSlot = this.pickupTimeSlot;
        // }
    },
    watch: {
        async pickupDate(value) {
            if (!value) {
                return;
            }

            this.pickupSlotTimesLoading = true;

            this.pickupSlotTimes = await this.checkTimeSlotAccessibility();

            this.displayablePickupTimeSlot = null;
            await this.setPreOrderDateTimeSlot(null);
            await this.setPickupTimeSlot(null);

            // if (this.pickupSlotTimes?.length) {
            //     this.displayablePickupTimeSlot = this.pickupSlotTimes[0];
            // }

            this.pickupSlotTimesLoading = false;
        },
        displayablePickupTimeSlot(value) {
            if (!value) {
                return;
            }

            this.setPickupTimeSlot(value);
            this.setPreOrderDateTime();
        },
        displayablePickupDate(value) {
            if (!value) {
                return;
            }

            this.setPickupDate(this.$dayjs(value).format('YYYY-MM-DD'));
        },
        async fetchPickupSlotsKey() {
            this.pickupSlotTimesLoading = true;
            this.pickupSlotTimes = await this.checkTimeSlotAccessibility();
            this.pickupTimeSlot = null;
            await this.setPreOrderDateTimeSlot(null);
            this.pickupSlotTimesLoading = false;
        }
    },
    data() {
        return {
            pickupSlotTimes: [],
            pickupSlotTimesLoading: false,
            displayablePickupTimeSlot: '',
            displayablePickupDate: ''
        };
    },
    methods: {
        ...mapActions({
            setPickupDate: 'cart/setPickupDate',
            setPickupTimeSlot: 'cart/setPickupTimeSlot',
            setPreOrderDateTimeSlot: 'cart/setPreOrderDateTimeSlot'
        }),
        async checkTimeSlotAccessibility() {
            const INVALID_DATE = 'Invalid date';
            const venueId = this.venueId;
            const requestedDay = this.$dayjs(this.displayablePickupDate).format(
                'YYYY-MM-DD'
            );

            if (requestedDay === INVALID_DATE) {
                return;
            }

            try {
                const params = {
                    venueId,
                    requestedDay,
                    fulfillmentMethod: this.fulfillmentMethod,
                    orderTotal: this.orderTotal || 0,
                    orderTotalItems: this.cartQuantity
                };

                const { data } = await this.$axiosRetry.get(
                    '/checkouts/slots',
                    { params }
                );

                return data;
            } catch (error) {
                throw new Error(
                    `Error while checking time slots accessibility: ${error}`
                );
            }
        },
        setPreOrderDateTime() {
            if (!this.displayablePickupTimeSlot) {
                this.setPreOrderDateTimeSlot(null);
                return;
            }

            if (this.displayablePickupTimeSlot === 'ASAP') {
                this.setPreOrderDateTimeSlot('ASAP');

                return;
            }

            const timeComponent = this.displayablePickupTimeSlot
                ? this.displayablePickupTimeSlot.substring(0, 8)
                : 0;

            const time = this.$dayjs(timeComponent, 'HH:mm a');

            // Check if parsing was successful
            if (!time.isValid()) {
                console.error('Invalid time format:', timeComponent);
                return;
            }

            let preOrderDate = this.$dayjs(this.pickupDate)
                .hour(time.hour())
                .minute(time.minute())
                .second(0)
                .millisecond(0);

            // const startDayOfOrderOffset = this.$dayjs(preOrderDate)
            //     .startOf('day')
            //     .utcOffset();
            // const endDayOfOrderOffset = this.$dayjs(preOrderDate)
            //     .endOf('day')
            //     .utcOffset();
            // const preOrderDateOffset = this.$dayjs(preOrderDate).utcOffset();
            //
            // if (
            //     startDayOfOrderOffset !== endDayOfOrderOffset &&
            //     preOrderDateOffset === endDayOfOrderOffset
            // ) {
            //     const diff = endDayOfOrderOffset - startDayOfOrderOffset;
            //
            //     preOrderDate =
            //         diff > 0
            //             ? preOrderDate.subtract(Math.abs(diff), 'minutes')
            //             : preOrderDate.add(Math.abs(diff), 'minutes');
            // }

            const result = preOrderDate.format();

            this.setPreOrderDateTimeSlot(result);
        }
    }
};
</script>
