<template>
    <div class="mb-4" id="delivery-address">
        <div
            v-if="displayAddAddressButton"
            @click="$modal.show('delivery-address-modal')"
            class="relative items-center justify-between flex border rounded shadow-sm p-3 cursor-pointer"
            :class="{
                'ring ring-red-200 border-red-300': v.$error
            }"
        >
            <div class="flex items-center">
                <delivery-icon class="text-muted" />
                <span class="ml-4 text-muted text-xs font-medium uppercase">
                    {{ $t('deliveryAddress.addDeliveryAddress') }}
                </span>
            </div>
            <dropdown-icon class="-rotate-90 text-muted" />
        </div>
        <div
            v-else
            @click="$modal.show('delivery-address-modal')"
            class="relative border rounded shadow-sm"
            :class="{
                'ring ring-red-200 border-red-300': v.$error
            }"
        >
            <static-map
                class="flex h-16 h-24 overflow-hidden content-center"
                v-if="showMap"
                :coordinates="coordinates"
                :show-interactive-map="false"
                :zoom="16"
            />

            <div class="relative">
                <div class="p-3">
                    <span class="mb-1 text-muted text-xs font-medium uppercase">
                        {{ $t('deliveryAddress.deliverTo') }}
                    </span>
                    <span class="leading block text-gray-500 text-sm">
                        {{ street }}, {{ buildingNumber }} {{ postCode }}
                    </span>
                </div>
                <div class="absolute right-0 top-0 px-4 py-2.5 cursor-pointer">
                    <span class="text-subheading text-sm font-semibold">
                        {{ $t('deliveryAddress.change') }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import StaticMap from '@/components/elements/StaticMap';
import DeliveryIcon from '@/components/icons/DeliveryIcon';
import DropdownIcon from '@/components/icons/DropdownIcon';

export default {
    name: 'DeliveryAddress',
    components: { DropdownIcon, DeliveryIcon, StaticMap },
    props: {
        v: {
            type: Object,
            required: true,
            default: () => ({})
        },
        disableAll: {
            type: Boolean,
            required: true,
            default: false
        },
        requiredFields: {
            type: Array,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            deliveryAddress: 'deliveryAddress/address'
        }),
        coordinates: {
            get() {
                return this.deliveryAddress.coordinates;
            }
        },
        showMap() {
            return !!(this.coordinates.lat && this.coordinates.lng);
        },
        street: {
            get() {
                return this.deliveryAddress.street;
            }
        },
        buildingNumber: {
            get() {
                return this.deliveryAddress.buildingNumber;
            }
        },
        postCode: {
            get() {
                return this.deliveryAddress.postCode;
            }
        },
        displayAddAddressButton() {
            return this.requiredFields.some(
                field =>
                    (field === 'street' && !this.street) ||
                    (field === 'postCode' && !this.postCode)
            );
        }
    }
};
</script>
