<template>
    <div v-if="!defaultServiceChargeEnabled">
        <span class="inline-block mb-3 text-itembody text-sm font-black">
            {{ tipLabel || $t('tipSelection.leaveATip') }}
        </span>
        <div v-if="tipVariant === 'smart'"
            class="calc-width flex flex-row overflow-auto md:max-w-sm lg:max-w-[550px]"
        >
            <button
                v-for="(tip, index) in availableTips"
                :key="index"
                class="flex flex-col grow	md:grow-0 items-center justify-center mr-2 px-4 py-4 text-center border border rounded shadow-sm cursor-pointer transition duration-300 ease-in-out sm:mr-4"
                :class="{
                    'shadow-sm selected-tip': selectedTip === tip
                }"
                style="min-width: 75px"
                :disabled="disableAll"
                @click="selectTip(tip)"
            >
                <span v-if="tip === 0" class="mt-1 text-subheading font-medium text-sm">
                    {{
                        $t('tipSelection.notNow') 
                    }}
                </span>
                <span v-else class="mt-1 text-subheading font-medium text-sm">
                    {{
                        tip | currency
                    }}
                </span>
            </button>
        </div>
        <div v-else
            class="calc-width flex flex-row overflow-auto md:max-w-sm lg:max-w-[550px]"
        >
            <button
                v-for="(tip, index) in availableTips"
                :key="index"
                class="flex flex-col grow	md:grow-0 items-center justify-center mr-2 px-4 py-2 text-center border border rounded shadow-sm cursor-pointer transition duration-300 ease-in-out sm:mr-4"
                :class="{
                    'shadow-sm selected-tip': selectedTip === tip
                }"
                style="min-width: 75px"
                :disabled="disableAll"
                @click="selectTip(tip)"
            >
                <span class="text-subheading text-xs font-medium leading-tight">
                    {{
                        tip === 0 ? $t('tipSelection.notNow') : tip / 100 + '%'
                    }}
                </span>

                <span v-if="tip !== 0" class="mt-1 text-subheading font-bold text-sm">
                    {{ (tipAmount(tip) / 100) | currency }}
                </span>
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';

export default {
    name: 'TipSelection',
    props: {
        disableAll: {
            type: Boolean,
            required: true,
            default: false
        },
        venueId: {
            type: Number,
            required: true,
            default: null
        }
    },
    computed: {
        ...mapGetters({
            cartSubtotal: 'cart/cartSubtotal',
            availableTips: 'cart/availableTips',
            selectedTip: 'cart/selectedTip',
            tipVariant: 'venue/tipVariant'
        }),
        ...mapState('venue', {
            tipLabel: state => state.venue.tipLabel || '',
            defaultServiceChargeEnabled: state => state.venue.defaultServiceChargeEnabled
        })
    },
    beforeMount() {
        this.initTipValue();
    },
    methods: {
        ...mapMutations({
            setTip: 'cart/setTip'
        }),
        ...mapActions({
            cartTotalWithDiscount: 'cart/cartTotalWithDiscount',
            initTipValue: 'cart/initTipValue'
        }),
        tipAmount(tip) {
            return Math.floor((this.cartSubtotal * tip) / 10000);
        },
        selectTip(tip) {
            this.setTip(tip);
            this.cartTotalWithDiscount();
        },
        showMandatoryTipModal() {
            this.$modal.show('mandatory-tip-modal');
        }
    }
};
</script>

<style>
.selected-tip {
    border-color: var(--color-highlight-border);
}

.calc-width {
    width: calc(100vw - 30px);
}
</style>
