<template>
    <modal
        name="unavailable-categories-modal"
        height="auto"
        width="575"
        :maxWidth="575"
        :adaptive="true"
        :scrollable="true"
        :clickToClose="true"
        :reset="true"
        @before-open="beforeOpen"
    >
        <div
            class="item-modal-content relative flex flex-col w-full text-center"
        >
            <div class="item-modal-details flex-auto p-8 overflow-y-auto">
                <div>
                    <h4 class="mb-2 pb-1 text-gray-800 text-xl font-bold">
                        {{
                            $t('unavailableCategoriesModal.notAcceptingOrders')
                        }}
                    </h4>

                    <div
                        v-for="(unavailableItemsName,
                        index) in unavailableItemsNames"
                        :key="`${unavailableItemsName}-${index}`"
                        class="m-1 p-1"
                    >
                        <b>
                            {{ unavailableItemsName }}
                        </b>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'unavailable-categories-modal',
    data() {
        return {
            unavailableItemsNames: {}
        };
    },
    methods: {
        hideFulfillmentModal() {
            this.$modal.hide('unavailable-categories-modal');
        },
        beforeOpen({ params }) {
            this.unavailableItemsNames = params.unavailableItemsNames;
        }
    }
};
</script>
