import { axios } from '@/plugins/axios';
import isLocalStorageAvailable from '@/helpers/isLocalStorageAvailable';

export function getRWGToken(slug) {
    if (!isLocalStorageAvailable()) {
        return;
    }

    const rwgToken = rwgTokenFromUrl();

    if (rwgToken) {
        setRWGToken(decodeURIComponent(rwgToken), slug);

        return;
    }
    const rwgKey = `rwg_token_${slug}`;

    const item = localStorage.getItem(rwgKey);

    if (!item) {
        return;
    }

    const { expiry } = JSON.parse(item);
    const today = new Date().toISOString().split('T')[0];

    if (today > expiry) {
        localStorage.removeItem(rwgKey);
    }
}

function rwgTokenFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);

    return urlParams.get('rwg_token');
}

function setRWGToken(token, slug) {
    const THIRTY_DAYS_IN_MILLISECONDS = 30 * 24 * 60 * 60 * 1000;
    const expiryDate = new Date(Date.now() + THIRTY_DAYS_IN_MILLISECONDS)
        .toISOString()
        .split('T')[0];

    localStorage.setItem(
        `rwg_token_${slug}`,
        JSON.stringify({ token, expiry: expiryDate })
    );
}

export async function sendConversionData(slug) {
    if (!isLocalStorageAvailable()) {
        return;
    }

    const item = localStorage.getItem(`rwg_token_${slug}`);

    if (!item) {
        return;
    }

    const { token } = JSON.parse(item);

    try {
        await axios.post(import.meta.env.VITE_GOOGLE_CONVERSION_TRACK_URL, {
            conversion_partner_id: import.meta.env.VITE_GOOGLE_PARTNER_ID,
            rwg_token: token
        });
    } catch (error) {
        /* eslint-disable-next-line */
        console.error('Error sending conversion tracking data:', error);
    }
}
