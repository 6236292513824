import PublicRouteContainer from '@/components/PublicRouteContainer';
import Menu from '@/views/Menu';
import Checkout from '@/views/Checkout';
const OrderStatus = () => import('@/views/OrderStatus');
const OrderReview = () => import('@/views/OrderReview');
const Brand = () => import('@/views/Brand');
const Privacy = () => import('@/views/Privacy');
const NotFound = () => import('@/views/NotFound');
const LandingPage = () => import('@/views/LandingPage');
const BillStatus = () => import('@/views/BillStatus');
const DiscountCode = () => import('@/views/DiscountCode');

const StoreSelection = () => import('@/views/StoreSelectionView');

const routes = [
    {
        path: '/b/:brandSlug',
        component: Brand
    },
    {
        path: '/stores/:brandSlug',
        component: StoreSelection
    },
    {
        path: '/:venueSlug/',
        component: PublicRouteContainer,
        children: [
            {
                path: '',
                redirect: 'menu'
            },
            {
                name: 'menu',
                path: 'menu',
                component: Menu,
                props: true
            },
            {
                name: 'discountCode',
                path: 'menu/discount/:code',
                component: DiscountCode
            },
            {
                name: 'checkout',
                path: 'checkout/pay',
                component: Checkout
            },
            {
                name: 'checkoutPaymentResult',
                path: 'checkout/pay/:orderId/result',
                component: Checkout
            },
            {
                name: 'orderStatus',
                path: 'orders/:id/status',
                component: OrderStatus
            },
            {
                name: 'orderReview',
                path: 'orders/:id/review',
                component: OrderReview
            },
            {
                name: 'billStatus',
                path: 'bill/:billPaymentId',
                component: BillStatus
            },
            {
                name: 'Privacy',
                path: 'privacy',
                component: Privacy
            },
            {
                name: 'LandingPage',
                path: 'table/:tableId',
                component: LandingPage
            },
            {
                name: 'basket',
                path: 'basket/:products',
                component: Menu
            }
        ]
    },
    {
        path: '/404',
        alias: '*',
        name: '404',
        component: NotFound
    }
];

export default routes;
