<template>
    <div id="app" class="font-body bg-primary" @click="resetRefreshPageTimer">
        <router-view />
        <notification-groups />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import NotificationGroups from '@/components/notifications/NotificationGroups';

export default {
    name: 'App',

    components: {
        NotificationGroups
    },

    computed: {
        ...mapGetters({
            forceRefresh: 'helpers/forceRefresh',
            isStoreDesigner: 'helpers/isStoreDesigner'
        })
    },

    data() {
        return {
            prevClientHeight: null,
            updateViewportTimer: null,
            refreshPageTimer: null
        };
    },
    mounted() {
        this.addEventListeners();
        const { documentElement } = document;
        const { clientHeight } = documentElement;
        this.updateViewportHeight(documentElement, clientHeight);
    },

    methods: {
        ...mapActions({
            setForceRefresh: 'helpers/setForceRefresh'
        }),
        addEventListeners() {
            const { documentElement } = document;
            const viewportHandler = this.setViewportProperty(documentElement);

            window.addEventListener('resize', viewportHandler);
            window.addEventListener('orientationchange', viewportHandler);

            if (!this.isStoreDesigner) {
                window.addEventListener('keypress', this.resetRefreshPageTimer);
            }
        },
        setViewportProperty(documentElement) {
            return () => {
                const { clientHeight } = documentElement;

                if (clientHeight !== this.prevClientHeight) {
                    requestAnimationFrame(() =>
                        this.debounce(
                            () =>
                                this.updateViewportHeight(
                                    documentElement,
                                    clientHeight
                                ),
                            'updateViewport',
                            32
                        )
                    );
                }
            };
        },
        updateViewportHeight(documentElement, clientHeight) {
            documentElement.style.setProperty(
                '--vh',
                clientHeight * 0.01 + 'px'
            );

            this.prevClientHeight = clientHeight;
        },
        resetRefreshPageTimer() {
            if (this.isStoreDesigner) {
                return;
            }
            const oneHour = 3600000;

            return this.debounce(
                () => this.refreshPage(),
                'refreshPage',
                oneHour
            );
        },
        debounce(functionHandler, event, timeout) {
            switch (event) {
                case 'refreshPage':
                    clearTimeout(this.refreshPageTimer);

                    this.refreshPageTimer = setTimeout(() => {
                        functionHandler.call();
                    }, timeout);

                    break;
                case 'updateViewport':
                    clearTimeout(this.updateViewportTimer);

                    this.updateViewportTimer = setTimeout(() => {
                        functionHandler.call();
                    }, timeout);

                    break;
                default:
                    break;
            }
        },
        refreshPage() {
            this.setForceRefresh(true);

            window.open(self.location, '_top');
        }
    }
};
</script>

<style src="@/assets/css/app.css"></style>
