<template>
    <modal
        ref="deliveryAddressModal"
        name="delivery-address-modal"
        height="auto"
        width="575"
        :maxWidth="575"
        :adaptive="true"
        :scrollable="true"
        :clickToClose="true"
        :pivotY="pivotY"
        :reset="true"
        transition="pop-out"
        :class="{ 'mobile-item-modal': pivotY === 1 }"
    >
        <div class="item-modal-content relative flex flex-col w-full">
            <div class="item-modal-body">
                <div class="item-modal-details flex-auto p-6 pb-1">
                    <div class="relative block w-full pb-3 sm:hidden">
                        <h4
                            class="font-heading text-xl font-bold text-subheading"
                        >
                            {{ $t('deliveryAddress.addDeliveryAddress') }}
                        </h4>
                        <div class="absolute right-0 top-0">
                            <button
                                type="button"
                                aria-label="Hide delivery address"
                                class="text-primary transition duration-150 ease-in-out hover:text-gray-500 focus:text-gray-500 focus:outline-none"
                                @click="close"
                            >
                                <close-icon />
                            </button>
                        </div>
                    </div>

                    <div>
                        <div v-if="hasAutocomplete" class="relative w-full">
                            <ideal-postcodes-autocomplete
                                v-if="hasIdealPostcodesAutocomplete"
                            />
                            <autocomplete v-else />
                        </div>
                        <div class="relative mb-4 mt-4">
                            <div class="relative w-full">
                                <input
                                    v-model="street"
                                    type="text"
                                    name="street"
                                    id="street"
                                    autocomplete="shipping address-line1"
                                    style="height: 50px"
                                    :class="{
                                        'ring ring-red-200 border-red-300':
                                            $v.street.$error,
                                        'shadow-sm': !$v.street.$error
                                    }"
                                    class="email-input block pt-3 px-4 w-full text-sm bg-secondary border rounded"
                                    required
                                    :disabled="disableAll"
                                />
                                <label
                                    for="street"
                                    class="email-input-label absolute pt-1 px-4 rounded pointer-events-none select-none"
                                >
                                    {{ $t('deliveryAddress.streetAddress') }}
                                </label>
                            </div>
                        </div>
                        <div class="relative mb-4 mt-4 w-full">
                            <input
                                v-model="buildingNumber"
                                type="text"
                                name="buildingNumber"
                                id="buildingNumber"
                                ref="buildingNumber"
                                autocomplete="shipping address-line2"
                                style="height: 50px"
                                class="email-input block pt-3 px-4 w-full text-sm bg-secondary border rounded"
                                :class="{
                                    'ring ring-red-200 border-red-300':
                                        $v.buildingNumber.$error,
                                    'shadow-sm': !$v.buildingNumber.$error
                                }"
                                required
                                :disabled="disableAll"
                            />
                            <label
                                for="buildingNumber"
                                class="email-input-label absolute pt-1 px-4 rounded pointer-events-none select-none"
                            >
                                {{ $t('deliveryAddress.buildingNumberOrName') }}
                            </label>
                        </div>

                        <div class="flex">
                            <div class="relative mb-4 w-full">
                                <input
                                    v-model="city"
                                    type="text"
                                    name="email"
                                    autocomplete="shipping locality"
                                    id="city"
                                    style="height: 50px"
                                    :class="{
                                        'ring ring-red-200 border-red-300':
                                            $v.city.$error,
                                        'shadow-sm': !$v.city.$error
                                    }"
                                    class="email-input block pt-3 px-4 w-full text-sm bg-secondary border rounded"
                                    required
                                    :disabled="disableAll"
                                />
                                <label
                                    for="city"
                                    class="email-input-label absolute pt-1 px-4 rounded pointer-events-none select-none"
                                >
                                    {{ $t('deliveryAddress.city') }}
                                </label>
                            </div>
                            <div class="relative mb-4 ml-2 w-full lg:w-2/5">
                                <input
                                    type="text"
                                    name="postCode"
                                    autocomplete="shipping postal-code"
                                    :value="deliveryAddress.postCode"
                                    @input="
                                        deliveryAddress.postCode =
                                            $event.target.value.toUpperCase()
                                    "
                                    id="postCode"
                                    style="height: 50px"
                                    :class="{
                                        'ring ring-red-200 border-red-300':
                                            $v.postCode.$error,
                                        'shadow-sm': !$v.postCode.$error
                                    }"
                                    class="email-input block pt-3 px-4 w-full text-sm bg-secondary border rounded"
                                    required
                                    :disabled="disableAll"
                                />
                                <label
                                    for="postCode"
                                    class="email-input-label absolute pt-1 px-4 rounded pointer-events-none select-none"
                                >
                                    {{ $t('deliveryAddress.postCode') }}
                                </label>
                            </div>
                        </div>

                        <div v-if="!notesDisabled" class="relative mb-4">
                            <textarea
                                v-model.trim="notes"
                                type="text"
                                name="notes"
                                id="notes"
                                class="email-input block pt-7 px-4 w-full text-sm bg-secondary border rounded shadow-sm"
                                style="height: 100px"
                                :class="{
                                    'ring ring-red-200 border-red-300':
                                        $v.notes.$error
                                }"
                                required
                            >
                            </textarea>
                            <label
                                for="notes"
                                class="email-input-label absolute pt-2 px-4 rounded pointer-events-none select-none"
                            >
                                {{ $t('deliveryAddress.notes') }}
                            </label>
                        </div>
                    </div>
                </div>
                <div
                    class="item-modal-footer flex flex-col-reverse justify-center p-6 md:flex-row"
                >
                    <base-button
                        v-if="!isMobile"
                        class="p-3 px-10 sm:block md:w-auto"
                        size="sm"
                        variant="secondaryActions"
                        :is-border-transparent="true"
                        :button-text="$t('deliveryAddressModal.close')"
                        @clicked="close"
                    />

                    <base-button
                        class="px-12 py-4 sm:mb-3 md:mb-0 md:ml-6 md:w-auto"
                        size="sm"
                        :button-text="$t('deliveryAddressModal.confirm')"
                        @clicked="update"
                    />
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import isMobile from '@/helpers/isMobileDevice';
import CloseIcon from '@/components/icons/CloseIcon';
import { mapActions, mapGetters, mapState } from 'vuex';
import { maxLength, requiredIf } from 'vuelidate/lib/validators';
import Autocomplete from '@/components/formElements/Autocomplete';
import addressRequirements from '@/util/addressRequirements.json';
// import IdealPostcodesAutocomplete from "@/components/formElements/IdealPostcodesAutocomplete";

const IdealPostcodesAutocomplete = () =>
    import('@/components/formElements/IdealPostcodesAutocomplete');

export default {
    name: 'DeliveryAddressModal',
    components: {
        IdealPostcodesAutocomplete,
        Autocomplete,
        CloseIcon
    },
    data() {
        return {
            isEdit: true,
            disableAll: false
        };
    },
    validations: {
        buildingNumber: {
            maxLength: maxLength(100)
        },
        street: {
            required: requiredIf(function () {
                return !(!this.hasField('street') && this.hasAutocomplete);
            }),
            maxLength: maxLength(100)
        },
        city: {
            required: requiredIf(function () {
                return !(!this.hasField('city') && this.hasAutocomplete);
            }),
            maxLength: maxLength(100)
        },
        postCode: {
            required: requiredIf(function () {
                return this.hasField('postCode');
            }),
            maxLength: maxLength(100)
        },
        notes: {
            maxLength: maxLength(200)
        }
    },
    watch: {
        showMap() {
            this.resetModalHeight();
        }
    },
    methods: {
        ...mapActions({
            setAddress: 'deliveryAddress/setAddress',
            setStreet: 'deliveryAddress/setStreet',
            setBuildingNumber: 'deliveryAddress/setBuildingNumber',
            setCity: 'deliveryAddress/setCity',
            setPostCode: 'deliveryAddress/setPostCode',
            setCountry: 'deliveryAddress/setCountry',
            setNotes: 'deliveryAddress/setNotes'
        }),
        update() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }

            this.close();
        },
        close() {
            this.$modal.hide('delivery-address-modal');
        },
        hasField(fieldName) {
            const country = this.deliveryAddress.country;
            const countryFields =
                addressRequirements.countries[country]?.fields;

            if (!countryFields) {
                return addressRequirements.default.fields.includes(fieldName);
            }

            return countryFields.includes(fieldName);
        },
        resetModalHeight() {
            if (
                this.hasIdealPostcodesAutocomplete ||
                !this.showMap ||
                !this.$refs.deliveryAddressModal.visible
            ) {
                return;
            }

            setTimeout(() => {
                const windowHeight = window.innerHeight;
                const modalRefs = this.$refs.deliveryAddressModal.$refs.modal;

                let diff = Math.floor(windowHeight - modalRefs.clientHeight);

                diff = isMobile ? diff : diff / 2;

                modalRefs.style.setProperty('top', `${diff}px`);
            }, 300);
        }
    },
    computed: {
        ...mapState('venue', {
            venueId: state => state.venue.id
        }),
        ...mapGetters({
            deliveryAddress: 'deliveryAddress/address'
        }),
        pivotY() {
            return isMobile() ? 1 : 0.5;
        },
        isMobile() {
            return isMobile();
        },
        coordinates: {
            get() {
                return this.deliveryAddress.coordinates;
            }
        },
        showMap() {
            return !!(this.coordinates.lat && this.coordinates.lng);
        },
        street: {
            get() {
                return this.deliveryAddress.street;
            },
            set(value) {
                this.setStreet(value);
                this.$v.street.$touch();
            }
        },
        buildingNumber: {
            get() {
                return this.deliveryAddress.buildingNumber;
            },
            set(value) {
                this.setBuildingNumber(value);
                this.$v.buildingNumber.$touch();
            }
        },
        city: {
            get() {
                return this.deliveryAddress.city;
            },
            set(value) {
                this.setCity(value);
                this.$v.city.$touch();
            }
        },
        postCode: {
            get() {
                return this.deliveryAddress.postCode;
            },
            set(value) {
                this.setPostCode(value);
                this.$v.postCode.$touch();
            }
        },
        country: {
            get() {
                return this.deliveryAddress.country;
            },
            set(value) {
                this.setCountry(value);
            }
        },
        notes: {
            get() {
                return this.deliveryAddress.notes;
            },
            set(value) {
                this.setNotes(value);
                this.$v.notes.$touch();
            }
        },
        hasAutocomplete() {
            return this.$growthbook.isOn('address_autocomplete');
        },
        hasIdealPostcodesAutocomplete() {
            return this.$growthbook.isOn('ideal_postcodes_autocomplete');
        },
        notesDisabled() {
            const manjarosIds = [
                '6259',
                '6536',
                '6825',
                '6828',
                '7309',
                '7427',
                '7540',
                '7559',
                '7700',
                '7828',
                '7978',
                '8143',
                '8276',
                '8288',
                '8289',
                '8319',
                '8327',
                '8328',
                '8537',
                '8700',
                '8941',
                '9027',
                // Jeezburger
                '2877',
                // Balti Night
                '9250'
            ];
            return manjarosIds.includes(this.venueId.toString());
        }
    }
};
</script>
