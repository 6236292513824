import { axios } from '@/plugins/axios';

let currentAbortController = null;

export const getComoRewards = async payload => {
    if (currentAbortController) {
        currentAbortController.abort();
    }

    currentAbortController = new AbortController();

    const { signal } = currentAbortController;

    try {
        return await axios.post('/como/rewards', payload, { signal });
    } catch (error) {
        if (!axios.isCancel(error)) {
            throw new Error(`API ${error}`);
        }
    } finally {
        currentAbortController = null;
    }
};

export const calculateComoRewards = payload => {
    return axios.post('/como/calculate', payload);
};
export const formatRewards = reward => {
    return {
        deals: (reward.deals || []).map(deal => ({
            key: deal.key,
            name: deal.name,
            appliedAmount: deal.benefits[0].sum
        })),
        redeemAssets: reward.redeemAssets?.[0]?.redeemable
            ? [
                  {
                      key: reward.redeemAssets[0].key,
                      appliedAmount: reward.redeemAssets[0].benefits[0].sum
                  }
              ]
            : []
    };
};

export const loginWithTemporaryToken = async payload => {
    const { data } = await axios.post('/como/token-login', payload);

    return data;
};
