<template>
    <div>
        <div
            class="flex relative items-center justify-center size-10 rounded-full bg-white text-gray-900 text-2xl transition-transform transform hover:scale-110"
            v-click-outside="{ handler: () => collapse() }"
            v-touch:tap.stop="expand"
            @mouseleave="isExpanded = false"
            @mousemove.stop="collapse(true)"
            v-on="
                isExpandable
                    ? { click: $event => $event.stopPropagation() }
                    : {}
            "
        >
            <button
                class="z-10 w-10 h-8 bg-white relative rounded-full"
                @mouseenter.stop="expand"
                @mousemove.stop="isExpanded ? collapse(true) : expand()"
            >
                <div
                    class="overflow-hidden absolute size-full ease-in-out inset-y-0 right-0 transition-transform duration-200"
                    :class="!isQuantity ? 'scale-100' : 'scale-0'"
                    @click="increment"
                >
                    <plus-icon class="size-8 mx-auto" />
                </div>
                <div
                    class="overflow-hidden pointer-events-none text-lg flex justify-center items-center absolute size-full ease-in-out inset-y-0 right-0 transition-transform duration-200"
                    :class="isQuantity ? 'scale-100' : 'scale-0'"
                >
                    {{ quantityInCart }}
                </div>
            </button>

            <div
                class="flex overflow-hidden absolute inset-y-0 right-0 shadow-md items-center bg-white text-gray-900 h-10 pr-10 rounded-full transform transition-all ease-in-out duration-200"
                :class="isExpandable && isExpanded ? 'w-28' : 'w-10'"
            >
                <button
                    class="w-10 h-8 relative flex items-center justify-center text-xl"
                    @click.stop="decrement"
                >
                    <div
                        class="absolute left-0 w-10 bg-white text-2xl overflow-hidden transition-height duration-200"
                        :class="quantityInCart > 1 ? 'h-full' : 'h-0'"
                    >
                        <minus-icon class="size-8 mx-auto" />
                    </div>
                    <div
                        class="absolute bg-white left-0 w-10 flex items-center overflow-hidden transition-height duration-200 justify-center fill-white"
                        :class="quantityInCart > 1 ? 'h-0' : 'h-full'"
                    >
                        <bin-icon
                            :class="quantityInCart === 0 ? 'opacity-20' : ''"
                        />
                    </div>
                </button>

                <span
                    class="flex-1 text-center text-lg"
                    :class="
                        isExpandable && isExpanded ? 'opacity-100' : 'opacity-0'
                    "
                >
                    {{ quantityInCart }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import BinIcon from './icons/BinIcon';
import PlusIcon from './icons/Plus';
import MinusIcon from './icons/Minus';
import vClickOutside from 'v-click-outside';

export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        isExpandable: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        BinIcon,
        PlusIcon,
        MinusIcon
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    data() {
        return {
            isExpanded: false,
            timer: null
        };
    },
    computed: {
        quantityInCart() {
            return this.$store.getters['cart/cartItemQuantity'](this.item.id);
        },
        isQuantity() {
            return this.quantityInCart > 0 && !this.isExpanded;
        }
    },
    beforeDestroy() {
        clearTimeout(this.timer);
        this.timer = null;
    },
    methods: {
        increment($event) {
            if (!this.isExpandable) {
                return;
            }

            this.collapse(true);
            $event.stopPropagation();
            this.$emit('increment', $event);
        },
        decrement() {
            this.collapse(this.quantityInCart !== 1);

            this.$emit('decrement');
        },
        expand() {
            if (this.isExpandable) {
                this.isExpanded = true;

                this.collapse(true);
            }
        },
        collapse(isDelayed = false) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }

            if (!this.isExpanded) {
                return;
            }

            const time = isDelayed ? 5000 : 0;

            this.timer = setTimeout(() => {
                this.isExpanded = false;
            }, time);
        }
    }
};
</script>
