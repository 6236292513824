<template>
    <div>
        <div class="bg-primary border-t border-b">
            <div
                class="container-lg flex flex-row items-center justify-between px-2 sm:px-5 py-4 text-price text-sm"
            >
                <div
                    class="flex cursor-pointer justify-center select-none px-2 py-1 hover:bg-gray-50 rounded-md"
                    @click="$modal.show('language-switcher-modal')"
                >
                    <language-icon class="mr-1" />
                    {{ language.name }}
                </div>

                <router-link :to="{ name: 'Privacy' }" class="text-sm pr-1">
                    {{ $t('appFooter.termsAndPrivacy') }}
                </router-link>
            </div>
        </div>
        <div class="py-6">
            <powered-by />
        </div>
        <language-switcher-modal />
    </div>
</template>

<script>
import PoweredBy from './common/PoweredBy';
import { languages } from '@/util/languages';
import LanguageIcon from './icons/LanguageIcon';
import LanguageSwitcherModal from './modals/LanguageSwitcherModal';

export default {
    name: 'AppFooter',
    components: {
        PoweredBy,
        LanguageIcon,
        LanguageSwitcherModal
    },
    data() {
        return { brandName: import.meta.env.VITE_BRANDING_NAME };
    },
    computed: {
        language() {
            return languages.find(item =>
                item.code.startsWith(this.$store.state.customer.lang)
            );
        }
    }
};
</script>
