<template>
    <modal
        name="age-verification-modal"
        height="auto"
        width="575"
        :maxWidth="575"
        :adaptive="true"
        :scrollable="true"
        :clickToClose="false"
        :pivotY="pivotY"
        :reset="true"
    >
        <div class="item-modal-content relative flex flex-col w-full">
            <div class="item-modal-details flex-auto p-8 overflow-y-auto">
                <div>
                    <h4 class="pb-1 text-gray-800 text-xl font-bold">
                        {{ $t('ageVerificationModal.mustBeOver18') }}
                    </h4>
                    <p class="text-gray-600 leading-relaxed"> </p>
                </div>
            </div>
            <div
                class="item-modal-footer flex flex-col-reverse items-start justify-between p-10 md:flex-row"
            >
                <button
                    @click="underAge()"
                    class="flex-auto p-3 px-10 w-full text-center text-red-900 text-sm font-bold rounded-sm md:w-1/2"
                >
                    {{ $t('ageVerificationModal.under18') }}
                </button>
                <button
                    @click="confirmAgeOver18()"
                    class="flex-auto mb-3 p-3 px-12 w-full text-center text-white text-sm font-bold bg-button font-button rounded-sm md:mb-0 md:ml-3 md:w-1/2"
                >
                    {{ $t('ageVerificationModal.over18') }}
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapMutations } from 'vuex';
import isMobile from '@/helpers/isMobileDevice';

export default {
    name: 'age-verification-modal',
    data() {
        return {
            isLoading: false
        };
    },
    computed: {
        pivotY() {
            return isMobile() ? 1 : 0.5;
        }
    },
    methods: {
        ...mapMutations({
            setAgeOver18Confirmed: 'cart/SET_AGE_OVER_18_CONFIRMED'
        }),
        confirmAgeOver18() {
            this.setAgeOver18Confirmed(true);
            this.$modal.hide('age-verification-modal');
        },
        underAge() {
            this.$modal.hide('item-modal');
            this.$modal.hide('age-verification-modal');
        }
    }
};
</script>
