<template>
    <modal
        height="auto"
        name="piggy-reward-selected-modal"
        width="525"
        :maxWidth="575"
        :adaptive="true"
        :scrollable="true"
        :clickToClose="true"
        :reset="true"
        transition="pop-out"
        :pivotY="pivotY"
        @before-open="beforeOpen"
    >
        <h4
            class="text-heading border-b border-solid border-gray-200 px-4 py-2 font-heading text-lg font-bold"
        >
            {{ reward.title }}
        </h4>
        <div class="absolute right-0 top-0 pr-4 pt-2">
            <button
                type="button"
                aria-label="Hide basket"
                class="text-primary focus:outline-none transition duration-150 ease-in-out hover:text-gray-500 focus:text-gray-500"
                @click="closeModal"
            >
                <close-icon />
            </button>
        </div>
        <piggy-reward-selected
            :reward="reward"
            :contact="contact"
            @back="closeModal"
        />
    </modal>
</template>

<script>
import isMobile from '@/helpers/isMobileDevice';
import PiggyRewardSelected from '@/components/PiggyRewardSelected';
import CloseIcon from '@/components/icons/CloseIcon';

export default {
    components: { CloseIcon, PiggyRewardSelected },
    name: 'piggy-reward-selected-modal',
    data() {
        return {
            reward: {},
            contact: {}
        };
    },
    computed: {
        pivotY() {
            return isMobile() ? 1 : 0.5;
        }
    },
    methods: {
        beforeOpen({ params }) {
            this.reward = params.reward;
            this.contact = params.contact;
        },
        closeModal() {
            this.$modal.hide('piggy-reward-selected-modal');
        }
    }
};
</script>
