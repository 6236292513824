<template>
    <div ref="wrapper">
        <vue-content-loading
            :width="wrapperWidth"
            :height="74"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
        >
            <rect x="0" y="18" rx="3" ry="3" width="30%" height="13" />
            <rect x="0" y="53" rx="3" ry="3" width="50%" height="10" />
        </vue-content-loading>
    </div>
</template>

<script>
import VueContentLoading from 'vue-content-loading';

export default {
    name: 'MenuCategorySkeleton',
    data() {
        return {
            wrapperWidth: 285
        };
    },
    components: {
        VueContentLoading
    },
    mounted() {
        if (this.$refs.wrapper && this.$refs.wrapper.offsetWidth) {
            this.wrapperWidth = this.$refs.wrapper.offsetWidth;
        }
    }
};
</script>
