import Vue from 'vue';

const state = Vue.observable({
    storeDesignerDevice:
        typeof window !== 'undefined' && window.storeDesigner
            ? window.storeDesignerDevice
            : null
});

if (typeof window !== 'undefined') {
    Object.defineProperty(window, 'storeDesignerDevice', {
        set(value) {
            state.storeDesignerDevice = value;
        },
        get() {
            return state.storeDesignerDevice;
        },
        configurable: true
    });
}

const checkIfMobileDevice = () => {
    if (typeof window === 'undefined' || typeof navigator === 'undefined') {
        return false;
    }

    if (window.storeDesigner) {
        return state.storeDesignerDevice === 'mobile';
    }

    return (
        /Mobi|iP(hone|od|ad)|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        ) ||
        navigator.userAgentData?.mobile ||
        'ontouchstart' in document.documentElement ||
        window.matchMedia('(pointer:coarse)').matches
    );
};

const isMobile = () => checkIfMobileDevice();

export default isMobile;
