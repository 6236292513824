import {
    GrowthBook,
    BrowserCookieStickyBucketService
} from '@growthbook/growthbook';
import Cookies from 'js-cookie';
import TrackingEventsEnum from '@/enums/modules/TrackingEventsEnum';

export default {
    install(Vue) {
        // Initialize GrowthBook with user attributes
        const growthbook = new GrowthBook({
            clientKey: import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY,
            apiHost: import.meta.env.VITE_GROWTHBOOK_API_HOST,
            enableDevMode: !import.meta.env.PROD,
            attributes: {
                email: null,
                venueId: null,
                venueSlug: null,
                accountId: null
            },
            stickyBucketService: new BrowserCookieStickyBucketService({
                jsCookie: Cookies
            }),
            trackingCallback: (experiment, result) => {
                if (Vue.prototype.$analytics) {
                    Vue.prototype.$analytics.track(
                        TrackingEventsEnum.VIEWED_EXPERIMENT,
                        {
                            experimentKey: experiment.key,
                            variationId: result.variationId,
                            // Include current user context if available
                            venue_id: growthbook.getAttributes()?.venueId,
                            account_id: growthbook.getAttributes()?.accountId,
                            venue_slug: growthbook.getAttributes()?.venueSlug
                        }
                    );
                }
            }
        });

        // Initialize Growthbook and load features
        growthbook
            .init({
                timeout: 10000,
                streaming: false
            })
            .catch(err => {
                console.error('Failed to load Growthbook features:', err);
            });

        // Add methods to update attributes
        growthbook.updateAttributes = attrs => {
            growthbook.setAttributes({
                ...growthbook.getAttributes(),
                ...attrs
            });
        };

        // Attach to Vue prototype
        Vue.prototype.$growthbook = growthbook;
    }
};
