<template>
    <input
        :id="`phone-input-${id}`"
        type="tel"
        :required="required"
        v-model="input"
        class="email-input w-full py-3 pl-12 text-sm bg-secondary border rounded shadow-sm"
        ref="inputElement"
        :class="{
            'form-error': error || telInputError,
            'shadow-sm': error || telInputError,
            'opacity-50': isPhoneLocked
        }"
        :disabled="isPhoneLocked"
    />
</template>
<script>
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

export default {
    name: 'PhoneInput',
    props: {
        id: {
            type: String,
            required: true
        },
        required: {
            type: Boolean,
            default: false
        },
        value: {
            type: [String, Number]
        },
        error: {
            type: Boolean,
            default: false
        },
        isPhoneLocked: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            telInput: null,
            telInputError: false
        };
    },
    mounted() {
        const input = document.querySelector(`#phone-input-${this.id}`);

        this.telInput = intlTelInput(input, {
            strictMode: true,
            validationNumberTypes: null, // Replacing deprecated validationNumberType
            countryOrder: [
                this.$store.state.venue.venue.address.country,
                'IE',
                'FR',
                'ES',
                'US'
            ],
            initialCountry: this.$store.state.venue.venue.address.country,
            loadUtils: () => import('intl-tel-input/build/js/utils.js') // Using promise-based utils loading
        });
    },
    computed: {
        input: {
            get: function () {
                return this.value;
            },
            set: async function (value) {
                this.telInputError = !this.telInput.isValidNumber();

                this.$emit('error', this.telInputError);
                this.$emit('input', this.telInput.getNumber());
            }
        }
    }
};
</script>

<style>
.iti {
    width: 100%;
}
</style>
