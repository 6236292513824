<template>
    <modal
        ref="fulfillmentModal"
        name="fulfillment-method-modal"
        height="auto"
        width="575"
        :maxWidth="575"
        :adaptive="true"
        :scrollable="true"
        :clickToClose="false"
        :pivotY="pivotY"
        :reset="true"
        @opened="resetModalHeight"
    >
        <div
            class="item-modal-content bg-primary relative flex flex-col w-full"
        >
            <div
                class="item-modal-details flex-auto p-6 overflow-y-auto sm:p-8"
            >
                <div v-if="!showPostcode">
                    <h4
                        class="pb-1 text-heading font-heading text-xl font-bold"
                    >
                        {{ $t('fulfillmentMethodModal.chooseDeliveryMethod') }}
                    </h4>
                </div>
                <div v-else>
                    <h4
                        class="pb-1 text-heading font-heading text-xl font-bold"
                    >
                        {{ $t('fulfillmentMethodModal.deliveryAddress') }}
                    </h4>
                    <p
                        class="text-subheading leading-relaxed"
                        v-if="hasDeliveryZones && venueMaxDistance"
                    >
                        {{
                            $t('fulfillmentMethodModal.deliveryMaxMiles', {
                                value: Number.isInteger(venueMaxDistance)
                                    ? venueMaxDistance.toString()
                                    : venueMaxDistance.toFixed(1)
                            })
                        }}
                    </p>
                    <div
                        class="relative my-4 w-full"
                        :class="{ spinner: isLoading }"
                    >
                        <autocomplete
                            v-if="hasAutocomplete"
                            @onChange="resetDistance"
                        />
                        <input
                            v-else
                            type="text"
                            name="postCode"
                            autocomplete="shipping postal-code"
                            :value="postCode"
                            @input="
                                postCode = $event.target.value
                                    .toUpperCase()
                                    .trim()
                            "
                            @change="resetDistance"
                            id="postCode"
                            style="height: 50px"
                            class="postcode-input block w-full rounded border px-4 pt-3 text-sm text-gray-800 shadow-sm"
                            required
                        />
                        <label
                            v-if="!hasAutocomplete"
                            for="postCode"
                            class="sk-form-label postcode-input-label pointer-events-none absolute select-none rounded px-4 pt-1"
                        >
                            {{ $t('fulfillmentMethodModal.enterPostCode') }}
                        </label>

                        <span
                            class="block -mb-3 mt-3 text-red-800 text-xs font-bold"
                            v-if="hasError"
                            v-html="errorMessage"
                        >
                        </span>
                    </div>
                </div>
            </div>
            <div
                class="item-modal-footer flex flex-col-reverse items-start justify-between p-10 md:flex-row"
                v-if="!showPostcode"
            >
                <button
                    v-if="$store.state.venue.venue.acceptsPickup"
                    @click="
                        setFulfillmentMethodWithDelay(
                            FulfillmentMethodsEnum.PICKUP
                        )
                    "
                    :class="{ spinner: isLoading }"
                    class="no-highlight flex-auto p-3 px-10 w-full text-center text-primary text-sm font-bold border border-button-primary font-button rounded-sm focus:opacity-75 active:opacity-75 select-none md:w-auto"
                    aria-label="Select pickup fulfillment method"
                    tabindex="0"
                >
                    {{ $t('fulfillmentMethodModal.pickup') }}
                </button>
                <button
                    v-if="hasDeliveryZones && !isDeliveryOnly"
                    @click="showPostcode = !showPostcode"
                    class="no-highlight flex-auto mb-3 p-3 px-12 w-full text-center text-button text-sm font-bold bg-button border border-button-primary font-button rounded-sm focus:opacity-75 active:opacity-75 select-none md:mb-0 md:w-auto"
                    :class="{
                        'md:ml-6': $store.state.venue.venue.acceptsPickup
                    }"
                    aria-label="Select delivery fulfillment method"
                    tabindex="0"
                >
                    {{ $t('fulfillmentMethodModal.delivery') }}
                </button>
                <button
                    v-else-if="
                        $store.state.venue.venue.acceptsDelivery &&
                        !isDeliveryOnly
                    "
                    @click="
                        setFulfillmentMethodWithDelay(
                            FulfillmentMethodsEnum.DELIVERY
                        )
                    "
                    class="no-highlight flex-auto mb-3 p-3 px-12 w-full text-center text-button text-sm font-bold bg-button border border-button-primary font-button rounded-sm focus:opacity-75 active:opacity-75 select-none md:mb-0 md:ml-6 md:w-auto"
                >
                    {{ $t('fulfillmentMethodModal.delivery') }}
                </button>
            </div>
            <div v-else>
                <div
                    class="item-modal-footer flex flex-col-reverse p-10 md:flex-row"
                    :class="[
                        isDeliveryOnly ? 'justify-center' : 'justify-between'
                    ]"
                >
                    <button
                        v-if="!isDeliveryOnly"
                        @click="showPostcode = !showPostcode"
                        class="no-highlight flex-auto p-3 px-10 w-full text-center text-primary text-sm font-bold border border-button-primary font-button rounded-sm select-none md:w-auto"
                    >
                        {{ $t('fulfillmentMethodModal.goBack') }}
                    </button>

                    <button
                        :disabled="distanceIsTooFar"
                        @click="getDistance()"
                        class="no-highlight flex-auto mb-3 p-3 px-12 w-full text-center text-button text-sm font-bold bg-button border border-button-primary font-button rounded-sm select-none md:mb-0 md:w-auto"
                        :class="{
                            'disabled-button': distanceIsTooFar,
                            'md:ml-6': !isDeliveryOnly
                        }"
                    >
                        {{ $t('fulfillmentMethodModal.saveAddress') }}
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import isMobile from '@/helpers/isMobileDevice';
import { FulfillmentMethodsEnum } from '@/enums';
import { helpers, requiredIf } from 'vuelidate/lib/validators';
import Autocomplete from '@/components/formElements/Autocomplete';
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';

// https://ideal-postcodes.co.uk/guides/postcode-validation#simpleregularexpression
const postcodeRegex = helpers.regex(
    'alpha',
    /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i
);
export default {
    name: 'fulfillment-method-modal',
    components: { Autocomplete },
    data() {
        return {
            isLoading: false,
            showPostcode: false,
            distance: null,
            distanceIsTooFar: false,
            errorMessage: null,
            hasError: false,
            FulfillmentMethodsEnum
        };
    },
    validations() {
        return {
            postCode: {
                required: requiredIf(function () {
                    return this.country === 'GB';
                }),
                ...(this.country === 'GB' && !this.hasAutocomplete
                    ? { postcodeRegex }
                    : false)
            }
        };
    },
    computed: {
        ...mapState('venue', {
            country: state => state.venue.address.country
        }),
        ...mapGetters({
            deliveryAddress: 'deliveryAddress/address',
            formattedAddress: 'deliveryAddress/formattedAddress',
            venueValue: 'venue/venueValue',
            fulfillmentMethod: 'cart/fulfillmentMethod'
        }),
        pivotY() {
            return isMobile() ? 1 : 0.5;
        },
        venueId() {
            return this.venueValue('id');
        },
        venuePostcode() {
            if (!this.$store.state.venue.venue.address) {
                return false;
            }

            return this.$store.state.venue.venue.address.postCode;
        },
        hasDeliveryZones() {
            return !!(
                this.$store.state.venue.venue.deliveryZones &&
                this.$store.state.venue.venue.deliveryZones.length > 0
            );
        },
        deliveryZones() {
            if (!this.hasDeliveryZones) {
                return null;
            }

            return this.$store.state.venue.venue.deliveryZones;
        },
        venueMaxDistance() {
            if (!this.hasDeliveryZones) {
                return null;
            }

            return Math.max.apply(
                Math,
                this.$store.state.venue.venue.deliveryZones.map(
                    zone => zone.radius
                )
            );
        },
        isDeliveryOnly() {
            return (
                this.$store.state.venue.venue.acceptsDelivery &&
                !this.$store.state.venue.venue.acceptsPickup
            );
        },
        postCode: {
            get() {
                return this.deliveryAddress.postCode;
            },
            set(value) {
                this.setPostCode(value);
            }
        },
        coordinates: {
            get() {
                return this.deliveryAddress.coordinates;
            }
        },
        showMap() {
            return !!(this.coordinates.lat && this.coordinates.lng);
        },
        hasAutocomplete() {
            if (this.country !== 'GB') {
                return true;
            }
            return this.$growthbook.isOn('address_autocomplete');
        },
        hasDeliveryZonesV2() {
            return this.$growthbook.isOn('delivery_zones_v2');
        }
    },
    watch: {
        isDeliveryOnly(value) {
            this.showPostcode = value;
        },
        showMap() {
            this.resetModalHeight();
        }
    },
    methods: {
        ...mapActions({
            setPostCode: 'deliveryAddress/setPostCode'
        }),
        resetModalHeight() {
            if (!this.showMap || !this.$refs.fulfillmentModal.visible) {
                return;
            }

            setTimeout(() => {
                const windowHeight = window.innerHeight;
                const modalRefs = this.$refs.fulfillmentModal.$refs.modal;

                let diff = Math.floor(windowHeight - modalRefs.clientHeight);

                diff = isMobile ? diff : diff / 2;

                modalRefs.style.setProperty('top', `${diff}px`);
            }, 300);
        },
        ...mapMutations({
            setFulfillmentMethod: 'cart/setFulfillmentMethod',
            resetPickupAndPreOrderDates: 'cart/RESET_PICKUP_AND_PRE_ORDER_DATES'
        }),
        setFulfillmentMethodWithDelay(value) {
            this.isLoading = true;

            if (value === FulfillmentMethodsEnum.PICKUP) {
                this.$store.commit('deliveryAddress/clearAddress');
            }

            setTimeout(() => {
                this.$store.commit('cart/setDeliveryTime', 0);
                this.setFulfillmentMethod(value);
                this.resetPickupAndPreOrderDates();
                this.isLoading = false;
                this.hideFulfillmentModal();
            }, 350);
        },
        hideFulfillmentModal() {
            this.$modal.hide('fulfillment-method-modal');
        },
        resetDistance() {
            this.hasError = false;
            this.errorMessage = null;
            this.distanceIsTooFar = false;
        },
        async geoDistanceV2(params) {
            const { data } = await this.$axios.get(
                `/venues/${this.venueId}/delivery-fee`,
                {
                    params
                }
            );

            if (!data || data === 'ZERO_RESULTS') {
                this.distanceIsTooFar = true;
                this.hasError = true;
                this.errorMessage = this.$t(
                    'fulfillmentMethodModal.errors.outOfRange'
                );

                // @TODO Manjaros
                if (
                    this.venueId === 7700 ||
                    this.venueId === 7427 ||
                    this.venueId === 6825
                ) {
                    this.errorMessage = `It seems we do not deliver to your area, to confirm please call the store <a href="tel:+441642284151">01642 284151</a>`;
                }

                return;
            }

            this.distanceIsTooFar = false;
            this.setFulfillmentMethod(FulfillmentMethodsEnum.DELIVERY);
            this.resetPickupAndPreOrderDates();

            this.$store.commit('cart/setDeliveryTime', parseInt(data.duration));
            this.$store.commit('cart/setDeliveryFeeFromZone', data.deliveryFee);
            this.$store.commit(
                'cart/SET_ZONE_DELIVERY_MIN_COST',
                data.minDeliveryAmount
            );

            this.hideFulfillmentModal();
        },
        async getDistance() {
            this.$v.postCode.$touch();

            if (this.$v.$invalid) {
                this.errorMessage = this.$t(
                    'fulfillmentMethodModal.errors.notValidPostcode'
                );
                this.hasError = true;

                return false;
            }

            this.isLoading = true;

            let params = {
                origins: `${this.$store.state.venue.venue.address.street}, ${this.$store.state.venue.venue.address.city}, ${this.venuePostcode}, ${this.$store.state.venue.venue.address.country}`,
                destinations: this.hasAutocomplete
                    ? this.formattedAddress
                    : `${this.postCode}, ${this.country}`,
                units: 'imperial',
                departure_time: 'now',
                hasAutocomplete: this.hasAutocomplete,
                postCode: this.postCode,
                country: this.country
            };

            if (
                this.deliveryAddress.coordinates &&
                this.deliveryAddress.coordinates.lat &&
                this.deliveryAddress.coordinates.lng
            ) {
                params.destinations = `${this.coordinates.lat},${this.coordinates.lng}`;
            }

            try {
                if (this.hasDeliveryZonesV2) {
                    await this.geoDistanceV2(params);
                } else {
                    const { data, status } = await this.$axios.get(
                        `/geo/distance`,
                        {
                            params
                        }
                    );

                    if (status !== 200) {
                        this.distanceIsTooFar = true;
                        this.hasError = true;
                        this.errorMessage = this.$t(
                            'fulfillmentMethodModal.errors.outOfRange'
                        );

                        return;
                    }

                    if (
                        data.status === 'INVALID_REQUEST' ||
                        (data.rows &&
                            data.rows.length &&
                            data.rows[0].elements &&
                            data.rows[0].elements.length &&
                            data.rows[0].elements[0].status !== 'OK')
                    ) {
                        this.errorMessage = this.$t(
                            'fulfillmentMethodModal.errors.notValidPostcode'
                        );
                        this.hasError = true;

                        return;
                    }

                    const distance =
                        data.rows[0]?.elements[0]?.distance?.value || 0;
                    const duration =
                        data.rows[0]?.elements[0]?.duration?.value || 0;

                    if (distance * 0.000621371 > this.venueMaxDistance) {
                        this.distanceIsTooFar = true;
                        this.hasError = true;
                        this.errorMessage = this.$t(
                            'fulfillmentMethodModal.errors.outOfRange'
                        );

                        // @TODO Manjaros
                        if (
                            this.venueId === 7700 ||
                            this.venueId === 7427 ||
                            this.venueId === 6825
                        ) {
                            this.errorMessage = `It seems we do not deliver to your area, to confirm please call the store <a href="tel:+441642284151">01642 284151</a>`;
                        }

                        return;
                    }

                    this.distanceIsTooFar = false;
                    this.setFulfillmentMethod(FulfillmentMethodsEnum.DELIVERY);
                    this.resetPickupAndPreOrderDates();

                    this.$store.commit(
                        'cart/setDeliveryTime',
                        parseInt(duration / 60)
                    );

                    if (this.hasDeliveryZones) {
                        const { deliveryFee } = this.deliveryZones.find(
                            zone => {
                                return zone.radius > distance * 0.000621371;
                            }
                        );

                        this.$store.commit(
                            'cart/setDeliveryFeeFromZone',
                            deliveryFee
                        );
                    }

                    this.hideFulfillmentModal();
                }
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>

<style>
.v--modal-overlay[data-modal='fulfillment-method-modal'] {
    background: rgba(0, 0, 0, 0.5);
}
</style>

<style src="@/assets/css/fulfillment-method-modal_scoped.css" scoped></style>
