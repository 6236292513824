<template>
    <canvas 
        v-if="!isLoaded"
        ref="canvas"
        class="absolute top-0 left-0 right-0 bottom-0 w-full h-full rounded-sm"
        width="32"
        height="32"
    />
</template>

<script>
import { decodeBlurHash } from 'fast-blurhash';

export default { 
    name: 'BlurHash',
    props: { 
        hash: {
            type: String,
            required: true,
            default: 'UNJ=_sK*0}I.}@KOV[Rj5SR*NHV[,?NHS1n+'
        },
        isLoaded: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        const pixels = decodeBlurHash(this.hash, 32, 32);
        const imageData = new ImageData(pixels, 32, 32);
        const context = this.$refs.canvas.getContext('2d');

        context.putImageData(imageData, 0, 0);
    }
}
</script>
