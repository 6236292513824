<template>
    <div class="py-3">
        <span class="inline-block mb-3 text-itembody text-sm font-black">
            {{ $t('checkout.paymentLabel') || 'Payment' }}
        </span>
        <adyen-payment-options-skeleton-loading v-if="!adyenDropInLoaded" />
        <div class="payment" ref="adyenPaymentComponent"></div>
        <cash-payment-option
            class="mt-2"
            v-if="cashEnabledInStore && adyenDropInLoaded"
            @checkout="$emit('cash-checkout')"
            :errors="errors"
            :is-loading="isLoading"
            @is-validated="isValidated"
        />
        <footer
            v-if="!cashEnabledInStore && !isBillPayment"
            class="fixed-footer footer-blurred fixed bottom-0 left-0 flex flex-wrap items-center p-3 w-full sm:pb-5"
        >
            <div
                class="footer-checkout-pay container-lg flex mx-auto w-full max-w-6xl md:px-3 lg:px-6 xl:px-8"
            >
                <div
                    v-if="
                        !submitButtonClicked && isInstantPaymentMethodSelected
                    "
                    class="flex w-full"
                >
                    <div class="flex flex-col justify-center mr-10">
                        <p class="text-muted text-sm">{{
                            $t('checkout.total')
                        }}</p>
                        <span class="text-secondary text-lg font-bold">{{
                            (actualPaymentAmount / 100) | currency
                        }}</span>
                    </div>
                    <apple-pay-button
                        v-if="selectedPaymentMethod === 'applepay'"
                        buttonstyle="black"
                        type="pay"
                        :locale="currentSupportedLang"
                        @click="payButton"
                    />
                    <button
                        v-else
                        @click="payButton"
                        :title="$t('checkout.order')"
                        :disabled="submitButtonClicked"
                        :class="{
                            'branded-checkout-button klarna-payment-button':
                                selectedPaymentMethod === 'klarna',
                            'branded-checkout-button google-pay-button':
                                selectedPaymentMethod === 'googlepay'
                        }"
                        class="inline-flex items-center justify-center p-3 w-full text-white font-bold rounded-sm rounded-sm shadow-sm select-none"
                    >
                    </button>
                </div>
                <button
                    v-else-if="selectedPaymentMethod === 'scheme'"
                    id="payButton"
                    @click.stop.prevent="payButton"
                    :disabled="
                        submitButtonClicked || additionalDetailsInProgress
                    "
                    :class="{
                        'spinner spinner-black':
                            submitButtonClicked || additionalDetailsInProgress
                    }"
                    class="inline-flex items-center justify-between p-3.5 w-full text-button text-sm font-bold bg-button font-button rounded-sm shadow-sm select-none md:text-base"
                >
                    Place {{ $t('checkout.order') }}
                    <span class="pr-1">
                        {{ (actualPaymentAmount / 100) | currency }}
                    </span>
                </button>
                <div v-else class="spinner spinner-black w-full">
                    <vue-content-loading
                        :height="48"
                        :speed="2"
                        class="mb-3"
                        primaryColor="#f3f3f3"
                        secondaryColor="#ecebeb"
                    >
                        <rect
                            x="2.5%"
                            y="10"
                            rx="3"
                            ry="3"
                            width="95%"
                            height="40"
                        />
                    </vue-content-loading>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import { AdyenCheckout, ApplePay, Card, Dropin } from '@adyen/adyen-web';
import VueContentLoading from 'vue-content-loading';
import { mapActions, mapGetters, mapState } from 'vuex';
import router from '@/router';
import isLocalStorageAvailable from '@/helpers/isLocalStorageAvailable.js';
import AdyenPaymentOptionsSkeletonLoading from '@/components/AdyenPaymentOptionsSkeletonLoading';
import CashPaymentOption from '@/components/payments/CashPaymentOption';
import { PaymentMethodsEnum } from '@/enums';
import adyenSupportedLang from '@/util/adyenSupportedLang';
import ably from '@/helpers/ably';
import '@adyen/adyen-web/styles/adyen.css';
import { getGatewayMerchantId } from '@/services/adyen';

export default {
    name: 'AdyenCheckout',
    props: {
        checkoutObject: {
            type: Object,
            required: true,
            default: () => ({})
        },
        redirectResult: {
            type: String,
            required: false,
            default: ''
        },
        errors: {
            type: [String, Boolean],
            required: true,
            default: ''
        },
        isLoading: {
            type: Boolean,
            required: true
        },
        isBillPayment: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    watch: {
        async totalWithDiscount() {
            this.updateAdyenCheckout();
        }
    },
    components: {
        AdyenPaymentOptionsSkeletonLoading,
        CashPaymentOption,
        VueContentLoading
    },
    computed: {
        ...mapState('venue', {
            venueId: state => state.venue.id,
            name: state => state.venue.name,
            phoneNumber: state => state.venue.phoneNumber,
            currencyCode: state => state.venue.currencyCode,
            cashEnabledInStore: state => state.venue.cashEnabledInStore,
            venueLang: state => state.venue.lang,
            countryCode: state => state.venue.address.country
        }),
        ...mapState('customer', {
            customerName: state => state.firstName + ' ' + state.lastName,
            customerEmail: state => state.email
        }),
        ...mapGetters({
            totalWithDiscount: 'cart/cartTotalWithDiscount',
            orderId: 'cart/orderId',
            orderCurrentCheckoutError: 'cart/getOrderCurrentCheckoutError'
        }),
        currentSupportedLang() {
            return (
                adyenSupportedLang[this.$store.state.customer.lang] ||
                adyenSupportedLang[this.venueLang] ||
                'en-US'
            );
        },
        actualPaymentAmount() {
            let amount = String(this.totalWithDiscount);

            if (this.isBillPayment) {
                amount = (
                    this.checkoutObject.total + (this.checkoutObject.tip || 0)
                ).toString();

                if (!amount) {
                    amount = '1';
                }
            }
            return amount;
        },
        requiredApplePayContactFields() {
            return this.$growthbook.isOn('required_apple_pay_shipping_details')
                ? ['name', 'email']
                : [];
        },
        paymentMethodsConfiguration() {
            const amount = {
                value: String(this.totalWithDiscount),
                currency: this.currencyCode
            };

            if (this.isBillPayment) {
                amount.value = (
                    this.checkoutObject.total + (this.checkoutObject.tip || 0)
                ).toString();

                if (!amount.value) {
                    amount.value = '1';
                }
            }
            return {
                card: {
                    hasHolderName: true,
                    holderNameRequired: true,
                    showStoredPaymentMethods: true,
                    billingAddressRequired: this.totalWithDiscount > 10000,
                    billingAddressMode:
                        this.totalWithDiscount > 15000 ? 'full' : 'partial',
                    brands: ['mc', 'visa', 'amex', 'maestro', 'maestrouk'],
                    name: 'Credit or debit card',
                    data: this.customerName.trim()
                        ? {
                              holderName: this.customerName.trim()
                          }
                        : {},
                    onBrand: event => (this.isAmex = event.brand === 'amex')
                },
                applepay: {
                    amount,
                    countryCode: this.countryCode,
                    buttonType: 'plain',
                    buttonColor: 'black',
                    configuration: {
                        merchantName: this.name,
                        merchantId: '000000000200411'
                    },
                    supportedNetworks: [
                        'amex',
                        'masterCard',
                        'visa',
                        'maestro'
                    ],
                    requiredShippingContactFields:
                        this.requiredApplePayContactFields,
                    onClick: (resolve, reject) => {
                        this.googleAndApplePayHandler(resolve, reject);
                    },
                    onAuthorized: async ({ authorizedEvent }, actions) => {
                        const AMEX_CARD = 'AmEx';
                        this.isAmex =
                            authorizedEvent.payment?.token?.paymentMethod
                                .network === AMEX_CARD;

                        actions.resolve();
                    }
                },
                paywithgoogle: {
                    environment: import.meta.env.PROD ? 'live' : 'test',
                    countryCode: this.countryCode,
                    amount,
                    configuration: {
                        gatewayMerchantId: getGatewayMerchantId(
                            this.currencyCode
                        ),
                        merchantId: import.meta.env.VITE_GOOGLE_PAY_ID,
                        merchantName: this.name
                    },
                    shippingOptionRequired: false,
                    shippingAddressRequired: false,
                    emailRequired: false,
                    billingAddressRequired: true,
                    buttonType: 'plain',
                    buttonColor: 'black',
                    onClick: (resolve, reject) => {
                        this.googleAndApplePayHandler(resolve, reject);

                        this.setOrderCurrentCheckoutError(false);
                    },
                    onAuthorized: ({ authorizedEvent }, actions) => {
                        const AMEX_CARD = 'AMEX';
                        this.isAmex =
                            authorizedEvent?.paymentMethodData?.info
                                ?.cardNetwork === AMEX_CARD;

                        actions.resolve();
                    }
                }
            };
        },
        adyenCheckoutConfiguration() {
            const amount = {
                value: String(this.totalWithDiscount),
                currency: this.currencyCode
            };

            if (this.isBillPayment) {
                amount.value = (
                    this.checkoutObject.total + (this.checkoutObject.tip || 0)
                ).toString();

                if (!amount.value) {
                    amount.value = '1';
                }
            }

            return {
                paymentMethodsResponse: { paymentMethods: this.paymentMethods },
                clientKey: import.meta.env.VITE_ADYEN_CLIENT_KEY,
                locale: this.currentSupportedLang,
                environment: import.meta.env.PROD ? 'live' : 'test',
                showPayButton: this.cashEnabledInStore || this.isBillPayment,
                amount,
                countryCode: this.countryCode,
                paymentMethodsConfiguration: this.paymentMethodsConfiguration,
                onSubmit: async (state, component, actions) => {
                    try {
                        if (
                            state.data.paymentMethod.type === 'paywithgoogle' &&
                            this.orderCurrentCheckoutError
                        ) {
                            this.adyenNotify(
                                8000,
                                'Error',
                                'Order updated, please close Google pay window'
                            );

                            actions.reject();
                            return;
                        }

                        if (state.isValid && (await this.isValidated())) {
                            await this.disableAllFields(true);

                            if (
                                !this.paymentInProgress &&
                                !this.additionalDetailsInProgress
                            ) {
                                const { action, resultCode } =
                                    await this.createPayment(
                                        state,
                                        component,
                                        amount
                                    );

                                if (!resultCode) {
                                    actions.reject();
                                }

                                actions.resolve({
                                    resultCode,
                                    action
                                });
                            }
                        } else {
                            component.setStatus('error', {
                                message: 'Please enter all required details'
                            });

                            setTimeout(() => {
                                component.setStatus('ready');
                            }, 4000);

                            actions.reject();
                        }
                    } catch (error) {
                        console.error('onSubmit', error);

                        actions.reject();
                    }
                },
                onAdditionalDetails: async (state, component, actions) => {
                    try {
                        component.setStatus('loading');

                        setTimeout(() => {
                            this.submitButtonClicked = false;
                        }, 6000);

                        const { resultCode } =
                            await this.submitAdditionalDetails(
                                state,
                                component
                            );

                        if (!resultCode) {
                            actions.reject();
                        }

                        actions.resolve({ resultCode });
                    } catch (error) {
                        console.error('onAdditionalDetails', error);
                        actions.reject();
                    }
                },
                onError: error => {
                    if (error?.name === 'CANCEL') {
                        this.adyenNotify(
                            8000,
                            this.$t('adyen.refusal.cancelled.reason'),
                            this.$t('adyen.refusal.cancelled.description')
                        );

                        return;
                    }

                    throw error;
                }
            };
        },
        isInstantPaymentMethodSelected() {
            return ['applepay', 'googlepay', 'klarna', 'ideal'].includes(
                this.selectedPaymentMethod
            );
        }
    },
    data() {
        return {
            adyenCheckout: null,
            paymentMethods: null,
            adyenDropInLoaded: false,
            adyenInstance: null,
            selectedPaymentMethod: null,
            submitButtonClicked: false,
            paymentInProgress: false,
            additionalDetailsInProgress: false,
            isAmex: false
        };
    },
    async mounted() {
        await this.disableAllFields(true);
        await this.setPaymentType(PaymentMethodsEnum.ADYEN);
        await this.setupAdyen(this.redirectResult);
        await this.disableAllFields(false);
    },
    methods: {
        ...mapActions({
            checkout: 'cart/checkout',
            setPaymentType: 'cart/setPaymentType',
            emptyCart: 'cart/emptyCart',
            checkoutEnd: 'cart/checkoutEnd',
            trackOrder: 'cart/trackOrder',
            setOrderCurrentCheckoutError: 'cart/setOrderCurrentCheckoutError'
        }),
        payButton() {
            if (!this.submitButtonClicked) {
                this.submitButtonClicked = true;
                this.adyenInstance.submit();

                setTimeout(() => {
                    this.submitButtonClicked = false;
                }, 8000);
            } else {
                return false;
            }
        },
        subscribeToOrder(orderId) {
            this.channel = `order-${orderId}`;
            const channel = ably.channels.get(this.channel);

            channel.subscribe(`edit-order-${orderId}`, async () => {
                //  @TODO handle status change
                const venueId = this.venueId;
                const { data: order } = await this.$axiosRetry.get(
                    `/orders/${orderId}`,
                    {
                        params: { venueId }
                    }
                );

                if (!order.isDraft) {
                    await router.replace({
                        name: 'orderStatus',
                        params: { id: orderId }
                    });
                }
            });
        },
        subscribeToBillPayment(billPaymentId) {
            this.channel = `bill-payment-id-${billPaymentId}`;
            const channel = ably.channels.get(this.channel);

            channel.subscribe(
                `bill-payment-status-update-${billPaymentId}`,
                async ({ data: { type } }) => {
                    if (type === 'payment-success') {
                        await this.$router.push({
                            name: 'billStatus',
                            params: { billPaymentId }
                        });
                    }
                }
            );
        },
        async getPaymentMethods(value) {
            const params = {
                shopperLocale: 'en-US',
                value,
                currency: this.currencyCode,
                venueId: this.venueId,
                countryCode: this.countryCode
            };

            try {
                const { data } = await this.$axiosRetry.get(
                    '/adyen/payments/methods',
                    {
                        params,
                        timeout: 10000
                    }
                );

                return data;
            } catch (error) {
                throw new Error(error);
            }
        },
        async setupAdyen(redirectResult) {
            let amount = this.totalWithDiscount;

            if (this.isBillPayment) {
                amount = (
                    this.checkoutObject.total + (this.checkoutObject.tip || 0)
                ).toString();

                if (!amount) {
                    amount = '1';
                }
            }

            this.paymentMethods = await this.getPaymentMethods(String(amount));

            this.adyenCheckout = await AdyenCheckout(
                this.adyenCheckoutConfiguration
            );

            const componentMap = {
                klarna: async () => (await import('@adyen/adyen-web')).Klarna,
                paywithgoogle: async () =>
                    (await import('@adyen/adyen-web')).GooglePay,
                twint: async () => (await import('@adyen/adyen-web')).Twint
            };

            const methodsTypes = this.paymentMethods.map(({ type }) => type);
            const paymentMethodComponents = [Card, ApplePay];

            try {
                const components = await Promise.all(
                    methodsTypes
                        .filter(type => componentMap[type])
                        .map(type => componentMap[type]())
                );

                paymentMethodComponents.push(...components);
            } catch (error) {
                console.error('Error loading adyen components:', error);
            }

            if (this.$refs.adyenPaymentComponent) {
                this.adyenInstance = new Dropin(this.adyenCheckout, {
                    paymentMethodComponents,
                    paymentMethodsConfiguration:
                        this.paymentMethodsConfiguration,
                    onReady: () => (this.adyenDropInLoaded = true),
                    onSelect: component =>
                        this.switchSelectedPaymentMethod(component)
                }).mount(this.$refs.adyenPaymentComponent);

                if (redirectResult) {
                    const data = { details: { redirectResult } };

                    this.submitAdditionalDetails(
                        { data },
                        this.adyenCheckout.components[0]
                    );
                }
            }
        },
        async googleAndApplePayHandler(resolve, reject) {
            if (await this.isValidated()) {
                resolve();
            }

            reject(new Error('Validation error'));
        },
        async createPayment(state, component, amount) {
            const { type } = state.data.paymentMethod;

            this.paymentInProgress = true;

            if (isLocalStorageAvailable()) {
                localStorage.setItem('selectedPaymentMethod', type);
            }

            try {
                const payload = {
                    stateData: state.data,
                    isAmex: this.isAmex,
                    venueId: this.venueId,
                    amount,
                    fulfillmentMethod: this.checkoutObject.fulfillmentMethod,
                    originUrl: window.location.origin || 'order.storekit.com'
                };

                if (this.isBillPayment) {
                    const { data: payment } = await this.$axios.post(
                        '/bills/pay',
                        {
                            venueId: this.checkoutObject.venueId,
                            tableId: this.checkoutObject.tableId,
                            total: this.checkoutObject.total,
                            partial: this.checkoutObject.partial,
                            tip: this.checkoutObject.tip,
                            paymentType: 'Adyen',
                            billId: this.checkoutObject.billId,
                            splitDetails: this.checkoutObject.splitDetails,
                            customer: this.checkoutObject.customer
                        }
                    );

                    if (isLocalStorageAvailable()) {
                        localStorage.setItem(
                            'billPaymentAmount',
                            payment.total
                        );
                    }

                    payload.billPaymentId = payment.id;
                    this.paymentId = payment.id;
                } else {
                    await this.checkout(this.checkoutObject);

                    payload.orderId = this.orderId;
                }

                const { data } = await this.$axiosRetry.post(
                    '/adyen/payments/create',
                    payload
                );

                if (data?.action?.type === 'threeDS2') {
                    // expect onAdditionalDetails to be called
                    this.additionalDetailsInProgress = true;
                }

                this.handleServerResponse(data, component, type);

                return data;
            } catch (error) {
                this.handleServerResponse({}, component, type);

                throw new Error(error);
            } finally {
                this.paymentInProgress = false;
            }
        },
        async submitAdditionalDetails({ data: { details } }, component) {
            const type = isLocalStorageAvailable()
                ? localStorage.getItem('selectedPaymentMethod')
                : 'scheme';

            try {
                const payload = {
                    details
                };

                const { data } = await this.$axiosRetry.post(
                    '/adyen/payments/additional-details',
                    payload
                );

                this.handleServerResponse(data, component, type);

                return data;
            } catch (error) {
                this.handleServerResponse({}, component, type);

                throw new Error(error);
            } finally {
                this.additionalDetailsInProgress = false;
            }
        },
        async handleServerResponse(res, component, type) {
            const {
                orderId = this.orderId,
                amount,
                action,
                resultCode,
                refusalReasonCode
            } = res;

            if (action) {
                component.handleAction(action);
            } else {
                let message = `Sorry, something went wrong`;
                let timeout = 8000;

                if (resultCode !== 'Authorised') {
                    await this.disableAllFields(false);
                }

                const refusalReasons = {
                    2: {
                        reason: this.$t('adyen.refusal.refused.reason'),
                        description: this.$t(
                            'adyen.refusal.refused.description'
                        )
                    },
                    5: {
                        reason: this.$t('adyen.refusal.blockedCard.reason'),
                        description: this.$t(
                            'adyen.refusal.blockedCard.description'
                        )
                    },
                    6: {
                        reason: this.$t('adyen.refusal.expiredCard.reason'),
                        description: this.$t(
                            'adyen.refusal.expiredCard.description'
                        )
                    },
                    7: {
                        reason: this.$t('adyen.refusal.invalidAmount.reason'),
                        description: this.$t(
                            'adyen.refusal.invalidAmount.description'
                        )
                    },
                    8: {
                        reason: this.$t(
                            'adyen.refusal.invalidCardNumber.reason'
                        ),
                        description: this.$t(
                            'adyen.refusal.invalidCardNumber.description'
                        )
                    },
                    9: {
                        reason: this.$t(
                            'adyen.refusal.issuerUnavailable.reason'
                        ),
                        description: this.$t(
                            'adyen.refusal.issuerUnavailable.description'
                        )
                    },
                    10: {
                        reason: this.$t('adyen.refusal.notSupported.reason'),
                        description: this.$t(
                            'adyen.refusal.notSupported.description'
                        )
                    },
                    11: {
                        reason: this.$t(
                            'adyen.refusal.3DNotAuthenticated.reason'
                        ),
                        description: this.$t(
                            'adyen.refusal.3DNotAuthenticated.description'
                        )
                    },
                    12: {
                        reason: this.$t(
                            'adyen.refusal.notEnoughBalance.reason'
                        ),
                        description: this.$t(
                            'adyen.refusal.notEnoughBalance.description'
                        )
                    },
                    15: {
                        reason: this.$t('adyen.refusal.cancelled.reason'),
                        description: this.$t(
                            'adyen.refusal.cancelled.description'
                        )
                    },
                    16: {
                        reason: this.$t(
                            'adyen.refusal.shopperCancelled.reason'
                        ),
                        description: this.$t(
                            'adyen.refusal.shopperCancelled.description'
                        )
                    },
                    24: {
                        reason: this.$t('adyen.refusal.CVCDeclined.reason'),
                        description: this.$t(
                            'adyen.refusal.CVCDeclined.description'
                        )
                    }
                };

                if (
                    resultCode === 'Refused' &&
                    Object.keys(refusalReasons).includes(refusalReasonCode)
                ) {
                    component.setStatus('error', {
                        message: refusalReasons[refusalReasonCode].reason
                    });

                    this.adyenNotify(
                        timeout,
                        refusalReasons[refusalReasonCode].reason,
                        refusalReasons[refusalReasonCode].description
                    );

                    setTimeout(() => {
                        component.setStatus('ready');
                    }, timeout);
                } else {
                    switch (resultCode) {
                        case 'Authorised':
                            message = 'Payment successful!';
                            timeout = 300;

                            component.setStatus('success', {
                                message
                            });

                            if (type !== 'scheme') {
                                this.adyenNotify(timeout, 'Success', message);
                            }

                            if (orderId) {
                                await this.trackOrder({
                                    orderId,
                                    orderTotal: amount ? amount.value : 0
                                });

                                await this.checkoutEnd(orderId);
                            } else {
                                await this.$router.push({
                                    name: 'billStatus',
                                    params: { billPaymentId: this.paymentId }
                                });
                            }

                            break;
                        case 'Pending':
                        case 'Received':
                            message = `Waiting for payment information`;

                            component.setStatus('loading', {
                                message
                            });

                            if (type !== 'scheme') {
                                this.adyenNotify(timeout, 'Loading', message);
                            }

                            if (orderId) {
                                this.subscribeToOrder(orderId);
                            }

                            if (this.paymentId) {
                                this.subscribeToBillPayment(this.paymentId);
                            }

                            break;
                        case 'Refused':
                        case 'Cancelled':
                        case 'Error':
                            message = `Sorry, your payment was declined. Please try again or use another payment method.`;

                            component.setStatus('error', {
                                message
                            });

                            this.adyenNotify(
                                timeout,
                                'Payment was declined',
                                message
                            );

                            setTimeout(() => {
                                component.setStatus('ready');
                            }, timeout);

                            break;
                        default:
                            component.setStatus('error', {
                                message
                            });

                            if (type !== 'scheme') {
                                this.adyenNotify(timeout, 'Error', message);
                            }

                            setTimeout(() => {
                                component.setStatus('ready');
                            }, timeout);

                            if (resultCode) {
                                const error = new Error(
                                    `Adyen payment failed with result code: "${resultCode}"`
                                );
                                error.resultCode = resultCode || null;
                                error.orderId = this.orderId || null;
                                error.paymentId = this.paymentId || null;

                                throw error;
                            }

                            if (this.orderId || this.paymentId) {
                                throw new Error(
                                    `No adyen result code found for order ${this.orderId} / payment ${this.paymentId}`
                                );
                            }
                    }
                }
            }
        },
        async isValidated() {
            await this.$emit('is-validated');

            return !this.errors;
        },
        updateAdyenCheckout() {
            if (this.adyenCheckout) {
                this.adyenCheckout.update(this.adyenCheckoutConfiguration);
            }
        },
        adyenNotify(duration, title, text) {
            this.$notify({
                group: 'Adyen',
                duration,
                title,
                text
            });
        },
        disableAllFields(value) {
            this.$emit('disable-all-fields', value);
        },
        switchSelectedPaymentMethod(component) {
            const type = component.props.type;

            switch (type) {
                case 'applepay':
                    this.selectedPaymentMethod = 'applepay';
                    break;
                case 'paywithgoogle':
                    this.selectedPaymentMethod = 'googlepay';
                    break;
                case 'scheme':
                    this.selectedPaymentMethod = 'scheme';
                    break;
                case 'klarna':
                    this.selectedPaymentMethod = 'klarna';
                    break;
                default:
                    this.selectedPaymentMethod = 'scheme';
            }
        }
    }
};
</script>
<style src="@/assets/css/adyen-checkout_custom.css"></style>
