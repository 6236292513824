import { TrackingEventsEnum } from '@/enums';

export const config = {
    containerId: null,
    dataLayerName: 'dataLayer',
    dataLayer: undefined,
    preview: undefined,
    auth: undefined,
    execution: 'async'
};

let initializedDataLayerName;

function googleTagManager(pluginConfig = {}) {
    const defaultScriptSrc = 'https://www.googletagmanager.com/gtm.js';

    return {
        name: 'google-tag-manager',
        config: {
            ...config,
            ...pluginConfig
        },
        initialize: ({ config }) => {
            const {
                containerId,
                dataLayerName,
                customScriptSrc,
                preview,
                auth,
                execution
            } = config;
            if (!containerId) {
                throw new Error('No google tag manager containerId defined');
            }
            if (preview && !auth) {
                throw new Error(
                    'When enabling preview mode, both preview and auth parameters must be defined'
                );
            }

            const scriptSrc = customScriptSrc || defaultScriptSrc;

            if (!scriptLoaded(containerId, scriptSrc)) {
                /* eslint-disable */
                (function (w, d, s, l, i) {
                    // Initialize dataLayer and gtag
                    w[l] = w[l] || [];
                    function gtag() {
                        w[l].push(arguments);
                    }
                    w.gtag = gtag;

                    // Set initial consent settings
                    gtag('consent', 'default', {
                        ad_storage: 'granted',
                        analytics_storage: 'granted',
                        functionality_storage: 'granted',
                        personalization_storage: 'granted',
                        security_storage: 'granted',
                        ad_user_data: 'granted',
                        ad_personalization: 'granted'
                    });

                    // Standard GTM initialization
                    w[l].push({
                        'gtm.start': new Date().getTime(),
                        event: 'gtm.js'
                    });

                    var f = d.getElementsByTagName(s)[0],
                        j = d.createElement(s),
                        dl = l != 'dataLayer' ? '&l=' + l : '',
                        p = preview
                            ? '&gtm_preview=' +
                              preview +
                              '&gtm_auth=' +
                              auth +
                              '&gtm_cookies_win=x'
                            : '';
                    if (execution) {
                        j[execution] = true;
                    }
                    j.src = `${scriptSrc}?id=` + i + dl + p;
                    f.parentNode.insertBefore(j, f);
                })(window, document, 'script', dataLayerName, containerId);
                /* eslint-enable */
                initializedDataLayerName = dataLayerName;
                config.dataLayer = window[dataLayerName];
            }
        },
        updateConsent: ({ config, settings }) => {
            if (config.dataLayer) {
                console.log('consent', 'update', settings);
                config.dataLayer.push(['consent', 'update', settings]);
            }
        },
        page: ({ payload, config }) => {
            if (typeof config.dataLayer === 'undefined') {
                return;
            }

            config.dataLayer.push(payload.properties);
        },
        track: ({ payload, config }) => {
            const { event, properties } = payload;

            if (typeof config.dataLayer === 'undefined') {
                return;
            }

            switch (event) {
                case TrackingEventsEnum.ADD_TO_CART:
                    config.dataLayer.push({
                        event: 'add_to_cart',
                        // @TODO handle modifier price
                        value: (properties.price * properties.quantity) / 100,
                        currency: properties.currency,
                        items: [
                            {
                                item_name: properties.name,
                                item_id: properties.plu,
                                price: properties.price / 100,
                                quantity: properties.quantity
                            }
                        ]
                    });

                    break;
                case TrackingEventsEnum.ORDER_COMPLETED:
                    config.dataLayer.push({
                        event: 'purchase',
                        transaction_id: properties.orderId,
                        value: properties.total,
                        currency: properties.currency,
                        // shipping: properties.deliveryFee / 100,
                        items: Array.isArray(properties.order?.items)
                            ? mapOrderItemsToGTMFormat(
                                  properties.order.items,
                                  properties.venueSlug
                              )
                            : []
                    });

                    break;
                case TrackingEventsEnum.STARTED_CHECKOUT:
                    config.dataLayer.push({
                        event: 'begin_checkout',
                        value: properties.value,
                        currency: properties.currency,
                        items: Array.isArray(properties.items)
                            ? mapOrderItemsToGTMFormat(
                                  properties.items,
                                  properties.venueSlug
                              )
                            : []
                    });

                    break;
                case TrackingEventsEnum.ACTIVE_ON_SITE:
                    config.dataLayer.push({
                        event: 'active_on_site',
                        userId: properties.userId,
                        sessionId: properties.sessionId,
                        pagePath: properties.pagePath
                    });
                    break;
                case TrackingEventsEnum.VIEWED_PRODUCT:
                    config.dataLayer.push({
                        event: 'view_item',
                        currency: properties.currency,
                        value: properties.price / 100,
                        items: [
                            {
                                item_id:
                                    properties.plu ||
                                    properties.sku ||
                                    properties.id,
                                item_name: properties.name
                            }
                        ]
                    });
                    break;
                default:
                    break;
            }
        },
        loaded: () => {
            const hasDataLayer =
                !!initializedDataLayerName &&
                !!(
                    window[initializedDataLayerName] &&
                    Array.prototype.push !==
                        window[initializedDataLayerName].push
                );
            return (
                scriptLoaded(
                    pluginConfig.containerId,
                    pluginConfig.customScriptSrc || defaultScriptSrc
                ) && hasDataLayer
            );
        }
    };
}

export default googleTagManager;

function mapOrderItemsToGTMFormat(orderItems, venueSlug) {
    return orderItems.map(item => ({
        item_name: item.name,
        affiliation: venueSlug,
        item_id: item.plu || item.sku || item.posId || item.id,
        price: item.price / 100,
        quantity: item.quantity,
        category:
            item.categories && item.categories.length > 0
                ? item.categories[0].name
                : 'Unknown',
        item_variant: item.modifiers.map(modifier => modifier.name).join(', ')
    }));
}

const regexCache = {};

function scriptLoaded(containerId, scriptSrc) {
    let regex = regexCache[containerId];
    if (!regex) {
        const scriptSrcEscaped = scriptSrc
            .replace(/^https?:\/\//, '')
            .replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

        regex = new RegExp(scriptSrcEscaped + '.*[?&]id=' + containerId);
        regexCache[containerId] = regex;
    }
    const scripts = document.querySelectorAll('script[src]');
    return !!Object.keys(scripts).filter(key =>
        (scripts[key].src || '').match(regex)
    ).length;
}
