<template>
    <modal
        name="confirm-item-removal-modal"
        height="auto"
        width="575"
        :maxWidth="575"
        :adaptive="true"
        :scrollable="true"
        :clickToClose="false"
        :pivotY="pivotY"
        :reset="true"
        transition="pop-out"
        :class="{ 'mobile-item-modal': pivotY === 1 }"
        @before-open="beforeOpen"
    >
        <div class="item-modal-content relative flex flex-col w-full">
            <div class="item-modal-body">
                <div class="item-modal-details flex-auto p-6 overflow-y-auto">
                    <div>
                        <h4
                            class="font-heading text-xl font-bold text-subheading"
                        >
                            {{ $t('basket.confirmRemoveItemText') }}
                        </h4>
                        <p class="text-gray-600 leading-relaxed"> </p>
                    </div>
                </div>
                <div
                    class="flex flex-col items-center justify-between p-6 pb-20 sm:pb-6 md:flex-row"
                >
                    <base-button
                        class="p-3 px-10 mb-3 sm:block sm:mb-0 md:w-auto"
                        size="sm"
                        variant="secondaryActions"
                        :button-text="$t('basket.cancel')"
                        :is-border-transparent="true"
                        @clicked="hide"
                    />
                    <base-button
                        class="px-12 py-3.5 sm:mb-3 md:mb-0 md:ml-6 md:w-auto"
                        size="sm"
                        :button-text="$t('basket.yes')"
                        @clicked="confirmRemoval"
                    />
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import isMobile from '@/helpers/isMobileDevice';

export default {
    name: 'confirm-item-removal-modal',
    data() {
        return {
            product: null
        };
    },
    beforeOpen() {
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    computed: {
        pivotY() {
            return isMobile() ? 1 : 0.5;
        }
    },
    methods: {
        beforeOpen({ params }) {
            this.product = params.product;
        },
        confirmRemoval() {
            this.$emit('confirm-item-removal', this.product);
            this.hide();
        },
        hide() {
            this.$modal.hide('confirm-item-removal-modal');
        }
    }
};
</script>
