<template>
    <div class="flex w-full">
        <curved-arrow-icon class="max-h-3 max-w-3 rotate-180 transform-gpu mr-1" />
        <p class="break-word w-full text-muted pb-1 text-xs">
            {{ notes }}
        </p>
    </div>
</template>

<script>
import CurvedArrowIcon from '@/components/icons/CurvedArrowIcon';

export default {
    name: 'ItemLineNotes',
    props: {
        notes: {
            type: String,
            required: true
        }
    },
    components: {
        CurvedArrowIcon
    }
};
</script>
