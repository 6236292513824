<template>
  <div class="mb-4 relative sm:hidden">
    <div class="relative venue-map-container -mx-3 sm:mx-0 -mb-10 z-0">
      <img v-show="mapLoaded" :src="staticMapUrl" @load="onMapLoad">
      <div v-show="!mapLoaded" class=" w-full"  style="background: linear-gradient(
                        150deg,
                        rgba(255, 255, 255, 1) 0%,
                        rgba(244, 244, 244, 1) 55%,
                        rgba(255, 255, 255, 1) 100%
                    );height: 195px;
                "></div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: 'VenuePickupLocation',
  data() {
    return {
      accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
      mapLoaded: false
    }
  },
  computed: {
    ...mapState({
      logo: state => state.venue.venue.logo,
    }),
    ...mapGetters({
      venueCoordinates: 'venue/venueCoordinates',
    }),
    staticMapUrl() {

      const baseUrl =
          'https://api.mapbox.com/styles/v1/benlarceysk/ckg6vzzxv4zrw19qhc3xscs5n/static/';
      const long = this.venueCoordinates[0];
      const lat = this.venueCoordinates[1];
      let customMarkerUrl;
      if(this.logo) {
        const logoUrl = `${this.logo}-/format/png/-/resize/100x/-/border_radius/50p/`;
        customMarkerUrl = encodeURIComponent(logoUrl);
      }
      return `${baseUrl}${customMarkerUrl ? 'url-' + customMarkerUrl : 'pin-s' }(${long},${lat})/${long},${lat},15,0,0/600x300@2x?access_token=${this.accessToken}&attribution=false&logo=false`;

    }

  },
  methods: {
    onMapLoad() {
      this.mapLoaded = true;
    }
  }
}
</script>

<style>

.venue-map-container::before {
  content: '';
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.venue-map-container::before {
  background: linear-gradient(180deg, transparent, var(--color-bg-secondary));
  border-radius: 2px 2px 0 0;
  height: 80px;
  bottom: -1px;
}
</style>