const state = {
    observer: {}
};

const getters = {
    observer(state) {
        return state.observer;
    }
};
const mutations = {
    SET_OBSERVER(state, { key, observer }) {
        state.observer[key] = observer;
    }
};
const actions = {
    enableMessageReceive({ dispatch }) {
        window.addEventListener('message', event =>
            dispatch('onMessageReceived', event)
        );
    },
    disableMessageReceive({ dispatch }) {
        window.removeEventListener('message', event =>
            dispatch('onMessageReceived', event)
        );
    },
    sendMessage(ctx, message) {
        // eslint-disable-next-line no-undef
        const NODE_ENV = process.env.NODE_ENV;

        const source =
            NODE_ENV === 'development'
                ? 'http://localhost:8080'
                : 'https://dashboard.storekit.com';

        window.parent.postMessage(message, source);
    },
    onMessageReceived({ dispatch }, event) {
        // eslint-disable-next-line no-undef
        const NODE_ENV = process.env.NODE_ENV;

        const allowedDomain =
            NODE_ENV === 'development'
                ? 'http://localhost:8080'
                : 'https://dashboard.storekit.com';

        if (event.origin !== allowedDomain) {
            return;
        }

        const { type } = event.data;

        switch (type) {
            case 'settingChange':
                dispatch('applySetting', event);
                break;
            case 'toggleModal':
                dispatch('toggleModal', event);
                break;
            case 'openModal':
                dispatch('openModal', event);
                break;
            case 'closeModal':
                dispatch('closeModal', event);
                break;
            case 'scroll':
                dispatch('scrollTo', event);
                break;
            case 'device':
                dispatch('setDevice', event);
                break;
            default:
                break;
        }
    },
    toggleModal(ctx, event) {
        const { value } = event.data;

        if (typeof value !== 'string') {
            return;
        }

        this._vm.$modal.toggle(value);
    },
    openModal(ctx, event) {
        const { value, item } = event.data;

        if (typeof value !== 'string') {
            return;
        }

        this._vm.$modal.show(value, { item });
    },
    closeModal(ctx, event) {
        const { value } = event.data;

        if (typeof value !== 'string') {
            return;
        }

        this._vm.$modal.hide(value);
    },
    applySetting({ dispatch }, event) {
        const { value, key } = event.data;

        if (value === undefined || !key || typeof key !== 'string') {
            return;
        }

        dispatch('venue/setVenueValue', { value, key }, { root: true });
    },
    scrollTo({ commit, getters }, event) {
        const { value } = event.data;

        if (typeof value !== 'string') {
            return;
        }

        const element = document.getElementById(value);

        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }

        if (!element && !getters.observer[value]) {
            const observer = new MutationObserver(() => {
                const element = document.getElementById(value);

                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                    getters.observer[value].disconnect();
                    commit('SET_OBSERVER', { key: value, observer: null });
                }
            });

            commit('SET_OBSERVER', { key: value, observer });

            getters.observer[value].observe(document.body, {
                childList: true,
                subtree: true
            });
        }
    },
    setDevice(ctx, event) {
        const { value } = event.data;

        if (typeof value !== 'string') {
            return;
        }

        window.storeDesignerDevice = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
