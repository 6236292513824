const state = {
    details: null,
    applied: false
};

const mutations = {
    DETAILS(state, payload) {
        state.details = payload;
    },

    APPLIED(state, status) {
        state.applied = status;
    }
};

const getters = {
    details(state) {
        return state.details;
    },

    status(state) {
        return state.applied;
    }
};

const actions = {
    setDiscountCode({ commit }, { code }) {
        commit('DETAILS', code);
    },

    async checkDiscountCode(
        { commit },
        { venueId, code, date, timeSlot, fulfillmentMethod, email }
    ) {
        try {
            const { data } = await this.$axios.get('/discount-codes/check', {
                params: { venueId, code, date, timeSlot, fulfillmentMethod, email }
            });

            commit('DETAILS', data);
        } catch (error) {
            commit('DETAILS', null);

            if (error.response && error.response.data) {
                return error.response.data;
            }
        }
    },

    setAppliedStatus({ commit }, status) {
        commit('APPLIED', status);
    }
};

export default {
    namespaced: true,
    getters,
    mutations,
    state,
    actions
};
