/**
 * Utility for tracking and displaying notifications with counters for repeated actions
 */

const recentlyAddedItems = {};
const NOTIFICATION_TIMEOUT = 3000; // 3 seconds window for considering "quick succession"

/**
 * Show a notification with counter for repeated actions
 * @param {Object} vue - Vue instance with $notify method
 * @param {String} itemId - Unique identifier for the item
 * @param {String} itemName - Name of the item to display in notification
 * @param {String} group - Notification group name
 * @param {String} message - Message template (optional)
 */
export function showNotificationWithCounter(vue, itemId, itemName, group = 'PiggyRewards', message = null) {
    const currentTime = Date.now();
    
    if (recentlyAddedItems[itemId]) {
        vue.$notify.close(`item-${itemId}`);
    }
    
    if (recentlyAddedItems[itemId] && 
        currentTime - recentlyAddedItems[itemId].timestamp < NOTIFICATION_TIMEOUT) {
        recentlyAddedItems[itemId].count++;
        recentlyAddedItems[itemId].timestamp = currentTime;
        
        vue.$notify({
            group,
            title: message || `${recentlyAddedItems[itemId].count}x ${itemName} added to your basket`,
            duration: 5000,
            id: `item-${itemId}` // Use consistent ID for this item
        });
    } else {
        recentlyAddedItems[itemId] = {
            count: 1,
            timestamp: currentTime
        };
        
        vue.$notify({
            group,
            title: message || `${itemName} added to your basket`,
            duration: 5000,
            id: `item-${itemId}` // Use consistent ID for this item
        });
    }
    
    setTimeout(() => {
        delete recentlyAddedItems[itemId];
    }, NOTIFICATION_TIMEOUT);
}

/**
 * Get the current count for an item
 * @param {String} itemId - Unique identifier for the item
 * @returns {Number} - Current count or 0 if not found
 */
export function getItemCount(itemId) {
    return recentlyAddedItems[itemId]?.count || 0;
}

/**
 * Reset the counter for an item
 * @param {String} itemId - Unique identifier for the item
 */
export function resetItemCounter(itemId) {
    delete recentlyAddedItems[itemId];
}
