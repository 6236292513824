import Vue from 'vue';

/**
 * Service for fetching menu item recommendations
 * Provides methods to get personalized and contextual menu item suggestions
 */

/**
 * Get recommended menu items based on various parameters
 * @param {Object} params
 * @param {string} [params.itemId] - Current item ID to get recommendations for
 * @param {Array} [params.basketItems] - Current items in basket
 * @param {string} [params.userId] - User email for personalized recommendations
 * @returns {Promise<Array>} Array of recommended menu items
 */
export async function getRecommendations({
    itemId,
    itemIds = [],
    userId,
    venueId,
    recommendationType
}) {
    try {
        // Build request body parameters, excluding undefined values

        const allItemIds = [...itemIds];
        if (itemId) {
            allItemIds.push(itemId);
        }

        // Build request body parameters
        const requestBody = {
            userId,
            itemIds: allItemIds,
            venueId: String(venueId),
            limit: 10,
            recommendationType
        };

        const { data } = await Vue.prototype.$axios.post(
            '/ml/suggestions',
            requestBody
        );

        // console.log(data);
        //
        // if (!data || !Array.isArray(data)) {
        //     console.error('Invalid recommendations response format');
        //     return [];
        // }

        data.ids = data.ids.map(id => parseInt(id));

        return data.ids;
    } catch (error) {
        console.error(
            'Error fetching recommendations:',
            error?.response?.data || error
        );
        return [];
    }
}
