<template>
    <modal
        id="interactivemap-modal"
        name="interactivemap-modal"
        height="auto"
        width="575"
        transition="pop-out"
        :adaptive="true"
        :scrollable="true"
        :clickToClose="true"
        :pivotY="pivotY"
        class="bg-primary"
        :reset="true"
        @opened="createMap"
    >
        <div class="bg-primary px-4 py-4">
            <span class="text-md text-muted">
                Drag the pin to your building entrance
            </span>

            <div class="relative">
                <div
                    ref="map"
                    class="mx-auto mt-1 h-[400px] rounded-md"
                    id="map"
                />
                <div
                    v-if="distanceExceeded"
                    class="warning-box fade-in absolute bottom-4 left-1/2 flex w-11/12 -translate-x-1/2 transform flex-col rounded-md bg-black p-3 text-sm text-white"
                >
                    <h4 class="mb-1 text-base font-bold md:text-xl">{{
                        $t('interactiveMap.warningTitle')
                    }}</h4>
                    <span class="text-xs md:text-base">{{ warningText }}</span>
                    <span
                        @click="resetLocation"
                        class="cursor-pointer text-[10px] font-bold md:mt-2 md:text-sm"
                        >{{ $t('interactiveMap.resetLocation') }}</span
                    >
                </div>
            </div>

            <img
                id="pin"
                class="pin pointer-events-none absolute"
                src="https://ucarecdn.com/4d683df2-ac9f-45ef-9bae-d30a12e16ad9/icons8location48.png"
                alt="pin"
            />
            <div id="pin-shadow" />

            <button
                class="text-button bg-button font-button mb-3 mt-3 w-full flex-auto select-none rounded-sm p-3 px-12 text-center text-sm font-bold md:mb-0"
                :class="{
                    'white spinner': isLoading,
                    'opacity-80': isOver1000m
                }"
                @click="confirmPin"
                :disabled="isOver1000m"
            >
                <span v-if="!isLoading"> Confirm location </span>
                <span class="inline-block"></span>
            </button>
        </div>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import isMobile from '@/helpers/isMobileDevice';
import haversineDistanceBetweenPoints from '@/util/haversineHelper';

export default {
    name: 'InteractiveMap',
    data() {
        return {
            isLoading: false,
            API_KEY: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
            map: null,
            distanceExceeded: false,
            warningText: '',
            isOver1000m: false
        };
    },
    computed: {
        ...mapGetters({
            deliveryAddress: 'deliveryAddress/address'
        }),
        coordinates: {
            get() {
                return this.deliveryAddress.coordinates;
            },
            set(value) {
                this.setCoordinates(value);
            }
        },
        pivotY() {
            return isMobile() ? 1 : 0.5;
        }
    },
    methods: {
        ...mapActions({
            setCoordinates: 'deliveryAddress/setCoordinates',
            setUserConfirmedCoordinates:
                'deliveryAddress/setUserConfirmedCoordinates'
        }),
        createMap() {
            this.distanceExceeded = false;

            const myLatlng = new window.google.maps.LatLng(
                this.coordinates.lat,
                this.coordinates.lng
            );

            this.map = new window.google.maps.Map(
                document.getElementById('map'),
                {
                    center: myLatlng,
                    zoom: 18,
                    maxZoom: 22,
                    minZoom: 10,
                    disableDefaultUI: true,
                    gestureHandling: 'greedy',
                    zoomControl: true,
                    zoomControlOptions: {
                        position: window.google.maps.ControlPosition.TOP_RIGHT
                    },
                    styles: [
                        {
                            featureType: 'poi.business',
                            stylers: [{ visibility: 'off' }]
                        }
                    ]
                }
            );

            const pin = document.getElementById('pin');
            const pinShadow = document.getElementById('pin-shadow');

            this.map.addListener('dragstart', () => {
                pin.classList.add('larger');
                pinShadow.classList.add('larger');

                this.distanceExceeded = false;
            });

            this.map.addListener('dragend', () => {
                setTimeout(() => {
                    pin.classList.remove('larger');
                    pinShadow.classList.remove('larger');
                    const distance = haversineDistanceBetweenPoints(
                        this.coordinates.lat,
                        this.coordinates.lng,
                        this.map.getCenter().lat(),
                        this.map.getCenter().lng()
                    );

                    this.distanceExceeded = distance > 100;
                    this.isOver1000m = distance > 1000;

                    if (this.distanceExceeded) {
                        this.warningText = this.isOver1000m
                            ? this.$t('interactiveMap.over1000Text')
                            : this.$t('interactiveMap.over100Text');
                    } else {
                        this.warningText = '';
                    }
                }, 100);
            });
        },
        async confirmPin() {
            this.isLoading = true;
            const position = this.map.getCenter();

            this.coordinates = {
                lat: position.lat(),
                lng: position.lng()
            };

            this.setUserConfirmedCoordinates(true);

            setTimeout(() => {
                this.isLoading = false;
                this.$modal.hide('interactivemap-modal');
            }, 300);
        },
        resetLocation() {
            this.map.setCenter({
                lat: this.coordinates.lat,
                lng: this.coordinates.lng
            });
            this.distanceExceeded = false;
            this.isOver1000m = false;
        }
    }
};
</script>

<style scoped>
@keyframes pin-drop-top {
    0% {
        top: -100px;
        opacity: 0;
    }

    100% {
        top: calc(50% - 54px);
        opacity: 1;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#pin {
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    animation: pin-drop-top 0.5s ease forwards;
    transition: transform 0.3s ease-in-out;
}

#pin.larger {
    transform: translate(-50%, -50%) scale(1.1);
}

#pin-shadow.larger {
    width: 16px;
    height: 5px;
}

#pin-shadow {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 14px;
    height: 4px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0.8;
    z-index: 1;
    transition: width 0.5s height 0.5s;
}

.fade-in {
    animation: fade-in 0.7s forwards;
}
</style>
