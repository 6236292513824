const state = {
    mapMatching: []
};

const mutations = {
    setMapMatching(state, payload) {
        state.mapMatching = payload;
    },
    clearMapMatching(state) {
        state.mapMatching = [];
    }
};

const getters = {
    mapMatching(state) {
        return state.mapMatching;
    }
};

const actions = {
    async performMapMatching({ commit }, locations) {
        let matchedCoordinates = null

        const radiuses = Array(locations.length).fill(25).join(';')
        const coordinatesToMatch = locations.join(';')

        try {
            const { data } = await this.$axios.get(`https://api.mapbox.com/matching/v5/mapbox/driving/${coordinatesToMatch}`, {
                params: {
                    geometries: 'geojson',
                    steps: false,
                    overview: 'full',
                    access_token: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
                    radiuses
                }
            });

            matchedCoordinates = data?.matchings.flatMap(match => match.geometry?.coordinates) || [];

            commit('setMapMatching', matchedCoordinates);
        } catch (e) {
            throw new Error(`API ${e}`);
        }
    },
};

export default {
    namespaced: true,
    getters,
    mutations,
    state,
    actions
};
