<template>
    <modal
        name="table-selection-modal"
        ref="tableSelectModal"
        height="100%"
        width="100%"
        transition="pop-out"
        class="table-selection-modal"
        :maxWidth="575"
        :maxHeight="isMobile ? 1200 : 800"
        :adaptive="true"
        :focus-trap="true"
        :clickToClose="false"
        :pivotY="pivotY"
        :reset="true"
        @beforeOpen="beforeOpen"
        :style="cssVars"
    >
        <div
            class="item-modal-content relative flex flex-col w-full h-full bg-primary"
            style="background-size: cover; background-position: 50%"
            :style="{ backgroundImage: tableScreenImage }"
        >
            <div
                class="item-modal-details flex-auto mx-auto p-8 w-full max-w-md text-center overflow-y-auto overscroll-contain"
            >
                <div v-if="!bg && logo" class="mx-auto py-1 w-full">
                    <img
                        :src="logo + '-/resize/300x/-/format/auto/'"
                        :alt="name + ' Logo'"
                        class="mx-auto w-auto h-20"
                    />
                </div>
                <div v-else-if="bg" class="h-28"> </div>
                <div class="mx-auto py-3 max-w-sm">
                    <h4
                        v-if="!bg && !logo"
                        class="break-anywhere pb-6 text-subheading text-2xl font-extrabold leading-tight"
                    >
                        {{ name }}
                    </h4>
                    <p class="text-white text-sm" v-html="intro" />
                </div>
                <div v-if="!areTableAreasLoading">
                    <div
                        class="mx-auto py-4 max-w-xs"
                        v-if="!showTableSelection"
                    >
                        <div
                            class="flex flex-row my-3 w-auto bg-white border border-gray-200 rounded shadow-sm"
                        >
                            <div
                                class="px-2 py-2 w-1/2 rounded-l flex items-center justify-center bg-button text-button"
                            >
                                <span
                                    class="tracking-relaxed text-white font-bold"
                                    >{{ tableDescriptor }}</span
                                >
                            </div>
                            <div
                                class="px-2 py-2 w-1/2 flex items-center justify-center"
                            >
                                <span
                                    v-if="selectedTableId"
                                    class="tracking-relaxed font-bold"
                                    :style="{
                                        color: brandColour || '#161e2e'
                                    }"
                                >
                                    {{ selectedTableName }}
                                </span>
                            </div>
                        </div>
                        <span
                            v-if="venueId !== 4386 && venueId !== 4286"
                            class="py-4 text-muted text-sm cursor-pointer"
                            @click="openSelect"
                        >
                            {{
                                $t('tableSelectionModal.notYour', {
                                    value: tableDescriptor.toLowerCase()
                                })
                            }}
                            <span class="text-blue-500 underline">
                                {{ $t('tableSelectionModal.clickHere') }}
                            </span>
                        </span>
                    </div>
                    <div
                        v-show="showTableSelection"
                        class="mx-auto py-4 max-w-xs mt-3"
                        @click="scrollWindow"
                    >
                        <table-selection-dropdown
                            ref="dropdown"
                            :payload="tableAreas"
                            :value="selectedTableId"
                            @selected="setTableSelection"
                            :searchable="isSearchable"
                            :placeholder="
                                $t('tableSelectionModal.selectTable', {
                                    value: tableDescriptor.toLowerCase()
                                })
                            "
                        >
                        </table-selection-dropdown>
                    </div>
                </div>
                <div v-else-if="isLoading">
                    <div class="tables-spinner mx-auto py-4 max-w-xs">
                        <div class="spinner"></div>
                    </div>
                </div>
                <div v-else-if="!isLoading && tableAreas && !tableAreas.length">
                    <div
                        class="mx-auto px-3 py-2 max-w-xs font-bold bg-red-200 rounded-sm shadow-sm"
                    >
                        <span class="text-red-900 text-sm">
                            {{ $t('tableSelectionModal.noTablesYet') }}
                        </span>
                    </div>
                </div>
            </div>
            <div
                :class="{ 'fixed bottom-0 left-0': isMobile }"
                class="table-cta item-modal-footer z-50 flex flex-col justify-between pb-4 px-6 py-6 w-full bg-secondary"
                v-if="!tableSelectionDropdownOpen"
            >
                <button
                    v-if="!selectedTableId"
                    @click="openSelect"
                    :class="{ 'spinner white': isLoading }"
                    class="no-highlight border-button-primary bg-button font-button text-button flex-auto mb-3 mx-auto py-4 px-12 w-full max-w-sm text-center text-sm font-bold bg-blue-900 rounded-sm outline-none shadow focus:ring sm:text-base md:mb-0 md:w-auto"
                >
                    {{
                        $t('tableSelectionModal.selectTable', {
                            value: tableDescriptor.toLowerCase()
                        })
                    }}
                </button>
                <button
                    v-else
                    @click="setFulfillmentMethodWithDelay(false)"
                    :class="{ 'spinner white': isLoading }"
                    class="no-highlight flex-auto mb-3 mx-auto py-4 px-12 w-full max-w-sm text-center text-button text-sm font-bold bg-button font-button rounded-sm outline-none border-button-primary shadow sm:text-base"
                >
                    <span v-show="!isLoading">
                        {{ $t('tableSelectionModal.startOrder') }}
                    </span>
                    <span class="inline-block"></span>
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';
import isMobile from '@/helpers/isMobileDevice';
import { FulfillmentMethodsEnum } from '@/enums';
import TableSelectionDropdown from '@/components/TableSelectionDropdown';

export default {
    name: 'table-selection-modal',
    components: {
        TableSelectionDropdown
    },
    data() {
        return {
            isLoading: false,
            areTableAreasLoading: true,
            hasError: false,
            showTableSelection: true,
            tableSelectionDropdownOpen: false,
            selected: { name: null, id: null },
            FulfillmentMethodsEnum
        };
    },
    computed: {
        ...mapState('venue', {
            venueId: state => state.venue.id,
            logo: state => state.venue.logo,
            name: state => state.venue.name,
            tableDescriptor: state => state.venue.tableDescriptor,
            colour: state => state.venue.primaryColour,
            tableAreas: state => state.tableAreas,
            bg: state => state.venue.tableScreenImage,
            intro: state => state.venue.orderInstructions,
            isInStore: state => state.venue.acceptsInStore
        }),
        ...mapState('cart', {
            selectedTableId: state => state.selectedTableId
        }),
        ...mapGetters({
            findTableById: 'venue/findTableById',
            tableCount: 'venue/tableCount',
            selectedTable: 'venue/selectedTable',
            isStoreDesigner: 'helpers/isStoreDesigner'
        }),
        selectedTableName() {
            const table = this.findTableById(this.selectedTableId);

            if (!table) {
                this.openSelect();
                return;
            }

            return table.name;
        },
        isMobile() {
            return isMobile();
        },
        pivotY() {
            return isMobile() ? 1 : 0.5;
        },
        brandColour() {
            return this.colour || '#161e2e';
        },
        areTableAreasFetched() {
            return this.$store.state.venue &&
                this.$store.state.venue.tableAreas &&
                this.$store.state.venue.tableAreas.length
                ? true
                : false;
        },
        cssVars() {
            return {
                '--brand-color': this.brandColour
            };
        },
        tableScreenImage() {
            return this.bg
                ? 'url(' + this.bg + '-/format/auto/-/resize/1500x/' + ')'
                : '#fff';
        },
        isSearchable() {
            return this.tableCount > 25;
        }
    },
    watch: {
        areTableAreasFetched() {
            if (this.areTableAreasFetched) {
                this.areTableAreasLoading = false;

                const table = this.findTableById(this.$route.query.tableId);

                if (table) {
                    this.setTable({
                        table: table.id,
                        venueId: this.venueId,
                        isQrCode: true
                    });
                    this.showTableSelection = false;
                }
            }
        },
        selectedTable(value) {
            if (value && this.isInStore) {
                this.setTable({
                    table: value,
                    venueId: this.venueId,
                    isQrCode: false
                });

                this.showTableSelection = false;
                this.setFulfillmentMethodWithDelay(true);
            }
        }
    },
    methods: {
        ...mapMutations({ setFulfillmentMethod: 'cart/setFulfillmentMethod' }),
        ...mapActions({ setTable: 'cart/setTable' }),
        openSelect() {
            this.showTableSelection = true;

            if (this.$refs.dropdown) {
                this.$refs.dropdown.showOptions();
            } else {
                this.activateDropdownWithDelay();
            }
        },
        activateDropdownWithDelay(timeout = 100) {
            if (timeout === 500) {
                return;
            }

            setTimeout(() => {
                if (this.$refs.dropdown) {
                    this.$refs.dropdown.showOptions();
                } else {
                    this.activateDropdownWithDelay(timeout + 100);
                }
            }, timeout);
        },
        setTableSelection(val) {
            if (!val?.id) {
                return;
            }

            this.setTable({
                table: val.id,
                venueId: this.venueId,
                isQrCode: false
            });

            this.showTableSelection = false;
            this.tableSelectionDropdownOpen = false;

            if ('vibrate' in navigator && !this.isStoreDesigner) {
                window.navigator.vibrate([20, 10, 30]);
            }

            document.activeElement.blur();
        },
        setFulfillmentMethodWithDelay(alreadySelectedTable) {
            this.isLoading = true;

            setTimeout(() => {
                if ('vibrate' in navigator && !alreadySelectedTable) {
                    window.navigator.vibrate([25, 50, 75]);
                }

                this.isLoading = false;
                this.hideTableSelectionModal();
                window.scrollTo(0, 0);
            }, 200);
        },
        hideTableSelectionModal() {
            this.$modal.hide('table-selection-modal');
        },
        beforeOpen() {
            // https://github.com/euvl/vue-js-modal/issues/529#issuecomment-600733392
            // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
            let vh = window.innerHeight * 0.01;
            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        },
        scrollWindow() {
            if (window.innerWidth <= 600) {
                setTimeout(() => {
                    window.scrollBy({
                        top: 100,
                        behavior: 'smooth'
                    });
                }, 100);
            }
        }
    }
};
</script>

<style src="@/assets/scss/table-selection-modal.scss" lang="scss"></style>
<style src="@/assets/css/table-selection-modal_scoped.css" scoped></style>
