const state = {
    forceRefresh: false,
    storeDesigner: false,
    activeFilterTags: [],
    searchExpandedLock: false
};

const getters = {
    forceRefresh(state) {
        return state.forceRefresh;
    },

    activeFilterTags(state) {
        return state.activeFilterTags;
    },
    searchExpandedLock(state) {
        return state.searchExpandedLock;
    },
    isStoreDesigner(state) {
        return state.storeDesigner;
    }
};
const mutations = {
    setForceRefresh(state, bool) {
        state.forceRefresh = bool;
    },

    SET_STORE_DESIGNER(state, bool) {
        state.storeDesigner = bool;
    },
    toggleActiveFilterTags(state, value) {
        if (value === null) {
            state.activeFilterTags = [];

            return;
        }

        const index = state.activeFilterTags.indexOf(value);

        if (index === -1) {
            state.searchExpandedLock = true;
            state.activeFilterTags.push(value);
        } else {
            if (state.activeFilterTags.length === 1) {
                state.searchExpandedLock = false;
            }

            state.activeFilterTags.splice(index, 1);
        }
    }
};
const actions = {
    setForceRefresh({ commit }, bool) {
        commit('setForceRefresh', bool);
    },
    setActiveFilterTags({ commit }, value) {
        commit('toggleActiveFilterTags', value);
    },
    enableStoreDesigner({ commit }) {
        window.storeDesigner = true;

        commit('SET_STORE_DESIGNER', true);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
