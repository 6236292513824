function isLocalStorageAvailable() {
    const testValue = 'test';

    try {
        localStorage.setItem('testValue', testValue);
        localStorage.removeItem('testValue');
    } catch (error) {
        return false;
    }

    return true;
}

export default isLocalStorageAvailable;
