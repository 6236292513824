<template>
    <div v-if="giftCardEnabled" class="relative flex pt-3 sm:pt-5 px-1 md:px-3">
        <div class="w-full">
            <div>
                <div
                    @click="toggleShowGiftCard"
                    style="height: 50px"
                    class="email-input flex items-center justify-between p-2 pl-0 rounded cursor-pointer"
                >
                    <span class="text-itembody text-sm font-black select-none">
                        {{ $t('giftCardBox.addGiftCardReference') }}
                    </span>
                    <span class="text-itembody font-medium text-sm">
                        <dropdown-icon
                            class="transition duration-300"
                            :class="{ 'rotate-180': showGiftCard }"
                        />
                    </span>
                </div>
            </div>
            <div v-if="showGiftCard" class="relative flex mt-1">
                <div class="relative w-2/3 md:pr-2">
                    <input
                        type="text"
                        name="gift-card-code"
                        v-model="giftCardReference"
                        id="gift-card-code"
                        autocomplete="gift-card-code"
                        style="height: 50px"
                        :class="{
                            'shadow-outline-red border-red-300': giftCardError
                        }"
                        class="email-input disabled:opacity-50 block pt-3 px-4 w-full text-sm bg-secondary border rounded shadow-sm"
                        required
                        :disabled="this.giftCardApplied"
                        @blur="
                            giftCardError = null;
                            giftCardErrorMessage = null;
                        "
                        @keyup.enter.prevent="giftCardReference"
                    />
                    <label
                        for="gift-card-code"
                        class="email-input-label absolute pt-1 px-4 rounded pointer-events-none select-none"
                    >
                        {{ $t('giftCardBox.giftCard') }}
                    </label>
                </div>

                <div class="relative pl-2 w-1/3">
                    <button
                        v-if="!giftCardApplied"
                        @click="applyGiftCard"
                        :class="{
                            spinner: giftCardIsLoading
                        }"
                        class="p-1 py-3 w-full h-full text-button font-bold bg-button font-button border border-transparent rounded-md select-none"
                        :disabled="giftCardIsLoading"
                    >
                        Check
                        <!--                        {{ $t('giftCardBox.submit') }}-->
                    </button>
                    <button
                        v-else="giftCardApplied"
                        @click="redeemGiftCardBillPay"
                        :class="{
                            spinner: giftCardIsLoading
                        }"
                        class="p-1 py-3 w-full h-full text-button font-bold bg-button font-button border border-transparent rounded-md select-none"
                        :disabled="giftCardIsLoading"
                    >
                        Apply
                        <!--                        {{ $t('giftCardBox.submit') }}-->
                    </button>
                    <!--                    <button-->
                    <!--                        v-else-->
                    <!--                        @click="removeGiftCard"-->
                    <!--                        :class="{-->
                    <!--                            spinner: giftCardIsLoading-->
                    <!--                        }"-->
                    <!--                        class="p-1 py-3 w-full h-full text-button font-bold bg-button border border-transparent rounded-md select-none"-->
                    <!--                        :disabled="giftCardIsLoading"-->
                    <!--                    >-->
                    <!--                        {{ $t('giftCardBox.remove') }}-->
                    <!--                    </button>-->
                </div>
            </div>
            <div
                v-if="giftCardBalance"
                class="bg-green-50 ring-1 ring-green-100 px-2 py-1 mt-3"
            >
                <span class="font-medium text-green-800 text-sm select-none">
                    You currently have {{ (giftCardBalance / 100) | currency }}
                    balance on your BAO code. Click apply to use it.

                    <!--                  This purchase will leave you with-->
                    <!--                    {{-->
                    <!--                        ((giftCardBalance - cartSubtotal > 0-->
                    <!--                            ? giftCardBalance - cartSubtotal-->
                    <!--                            : 0) /-->
                    <!--                            100)-->
                    <!--                            | currency-->
                    <!--                    }}-->
                    <!--                    remaining-->
                </span>
            </div>
            <span
                v-show="giftCardErrorMessage"
                class="py-2 text-red-600 text-xs"
            >
                {{ giftCardErrorMessage }}
            </span>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import DropdownIcon from '../icons/DropdownIcon';

export default {
    name: 'GiftCardBox',
    components: { DropdownIcon },
    props: {
        disableAll: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data() {
        return {
            giftCardIsLoading: false,
            giftCardReference: null,
            giftCardErrorMessage: null,
            giftCardError: null,
            showGiftCard: false
        };
    },

    computed: {
        ...mapGetters({
            giftCardEnabled: 'giftCard/giftCardEnabled',
            cartTotal: 'cart/cartTotal',
            giftCardBalance: 'giftCard/giftCardBalance',
            getCartTotalWithDiscount: 'cart/cartTotalWithDiscount',
            giftCardPaymentAmount: 'cart/giftCardPaymentAmount',
            cartSubtotal: 'cart/cartSubtotal'
        }),
        ...mapState({
            venueId: state => state.venue.venue.id,
            giftCardEnabled: state => state.giftCard.giftCardEnabled,
            giftCardApplied: state => state.giftCard.giftCardApplied,
            giftCardPaymentAmount: state => state.cart.giftCardPaymentAmount
        })
    },

    async mounted() {
        await this.isGiftCardEnabled(this.venueId);
    },
    methods: {
        ...mapActions({
            isGiftCardEnabled: 'giftCard/isGiftCardEnabled',
            cartTotalWithDiscount: 'cart/cartTotalWithDiscount',
            getGiftCardBalance: 'giftCard/checkGiftCardBalance',
            setTotalWithDiscount: 'cart/setTotalWithDiscount',
            setGiftCardApplied: 'giftCard/setGiftCardApplied',
            resetGiftCardStoreValues: 'giftCard/resetGiftCardStoreValues',
            removeGiftCardftCard: 'giftCard/removeGiftCardftCard',
            redeemGiftCard: 'giftCard/redeemGiftCard'
        }),
        toggleShowGiftCard() {
            this.showGiftCard = !this.showGiftCard;
        },
        async applyGiftCard() {
            if (!this.giftCardReference) {
                return;
            }
            await this.checkGiftCardBalance();

            let newCartTotal = null;
            let giftCardPaymentAmount = null;

            if (!this.giftCardBalance) {
                return;
            }

            if (this.giftCardBalance > 0) {
                this.$store.commit('giftCard/setGiftCardApplied', true);
            }

            if (
                this.giftCardBalance > 0 &&
                this.getCartTotalWithDiscount > this.giftCardBalance
            ) {
                newCartTotal =
                    this.getCartTotalWithDiscount - this.giftCardBalance;
                giftCardPaymentAmount = this.giftCardBalance;
            } else if (
                this.giftCardBalance > 0 &&
                this.getCartTotalWithDiscount < this.giftCardBalance
            ) {
                newCartTotal = 0;
                giftCardPaymentAmount = this.getCartTotalWithDiscount;
            }

            this.$store.commit(
                'cart/setGiftCardPaymentAmount',
                giftCardPaymentAmount
            );
            this.$store.commit('cart/setTotalWithDiscount', newCartTotal);
        },
        async removeGiftCard() {
            console.log('clciedk');
            this.giftCardReference = null;
            await this.removeGiftCardftCard();
        },
        async checkGiftCardBalance() {
            this.giftCardIsLoading = true;

            this.giftCardError = false;
            this.giftCardErrorMessage = null;

            try {
                const payload = {
                    giftCardReference: this.giftCardReference,
                    venueId: this.venueId
                };
                await this.getGiftCardBalance(payload);

                if (this.giftCardBalance === null) {
                    this.giftCardError = true;
                    this.giftCardErrorMessage = this.$t(
                        'giftCardBox.errors.codeNotValid'
                    );
                }

                // if (this.giftCardBalance == 0) {
                //     this.giftCardError = true;
                //     this.giftCardErrorMessage = this.$t(
                //         'giftCardBox.errors.balanceZero'
                //     );
                // }
            } catch (error) {
                if (error.response && error.response.data) {
                    return error.response.data;
                }
            } finally {
                this.giftCardIsLoading = false;
            }
        },
        async redeemGiftCardBillPay() {
            this.giftCardIsLoading = true;

            this.giftCardError = false;
            this.giftCardErrorMessage = null;

            try {
                const payload = {
                    giftCardReference: this.giftCardReference,
                    venueId: this.venueId,
                    amount: this.giftCardBalance,
                    billId: this.$store.state.bill.id
                };
                await this.redeemGiftCard(payload);

                if (this.giftCardBalance === null) {
                    this.giftCardError = true;
                    this.giftCardErrorMessage = this.$t(
                        'giftCardBox.errors.codeNotValid'
                    );
                }

                // if (this.giftCardBalance == 0) {
                //     this.giftCardError = true;
                //     this.giftCardErrorMessage = this.$t(
                //         'giftCardBox.errors.balanceZero'
                //     );
                // }
            } catch (error) {
                if (error.response && error.response.data) {
                    return error.response.data;
                }
            } finally {
                this.giftCardIsLoading = false;
            }
        }
    },
    beforeDestroy() {
        this.resetGiftCardStoreValues();
    }
};
</script>

<style scoped>
.spinner {
    color: white !important;
    opacity: 0.9;
}

.spinner:after {
    left: auto;
    right: 15px;
}
</style>
