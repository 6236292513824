<template>
    <div>
        <img
            id="static-map"
            class="w-full h-full object-cover rounded-sm border"
            alt="Static Map"
            :src="mapUrl"
        />

        <button
            v-if="showInteractiveMap"
            class="edit-address-location text-primary border-button-primary mb-3 mt-3 flex w-full flex-auto select-none items-center justify-center rounded-sm bg-transparent p-3 px-12 text-center text-sm font-bold md:mb-0"
            @click="edit"
        >
            <svg
                class="mr-3 h-5 w-5"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                ></path>
            </svg>
            Edit entrance location
        </button>

        <interactive-map v-if="showInteractiveMap" />
    </div>
</template>

<script>
import InteractiveMap from '@/components/modals/InteractiveMap';

export default {
    name: 'StaticMap',
    components: { InteractiveMap },
    props: {
        coordinates: {
            type: Object,
            required: true
        },
        showInteractiveMap: {
            type: Boolean,
            default: true
        },
        zoom: {
            type: Number,
            default: 18
        }
    },
    data() {
        return {
            API_KEY: import.meta.env.VITE_GOOGLE_MAPS_API_KEY
        };
    },
    computed: {
        mapUrl() {
            if (!this.coordinates.lat || !this.coordinates.lng) {
                return;
            }

            const style = `feature:poi.business|element:labels|visibility:off`;
            const size = '650x200';
            const marker =
                'https://ucarecdn.com/4d683df2-ac9f-45ef-9bae-d30a12e16ad9/icons8location48.png';
            const center = `${this.coordinates.lat},${this.coordinates.lng}`;

            return `https://maps.googleapis.com/maps/api/staticmap?key=${
                this.API_KEY
            }&style=${style}&size=${size}&zoom=${this.zoom}&markers=icon:${marker}|${center}`;
        }
    },
    methods: {
        edit() {
            this.$modal.show('interactivemap-modal');
        }
    }
};
</script>

<style>
.edit-address-location {
    border-color: var(--color-bg-button);
    border-color: var(--color-highlight-border);
}
</style>
