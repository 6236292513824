<template>
    <div>
        <div
            v-if="!$store.state.customer.notes"
            @click="$modal.show('order-notes-modal')"
        >
            <div class="flex items-center py-2.5 text-heading cursor-pointer">
                <notes-icon class="inline-block sm:hidden w-6 h-6 mr-2" />
                <span
                    class="text-sm font-medium text-itembody sm:font-black"
                    :class="{ 'text-red-500': this.v && this.v.$error }"
                >
                    {{ $t('orderNotes.addOrderNotes') }}
                </span>
            </div>
        </div>
        <div v-if="$store.state.customer.notes">
            <div
                class="flex py-1 text-sm cursor-pointer"
                @click="$modal.show('order-notes-modal')"
            >
                <span class="text-base sm:text-sm sm:font-black text-subheading">
                    {{ $t('orderNotes.orderNotesOrRequests') }}
                </span>
                <span
                    class="ml-auto flex items-center ml-2 text-gray-400 hover:text-gray-500 duration-300 ease-in-out"
                >
                    {{ $t('orderNotes.edit') }}
                </span>
            </div>
          <div class="flex">
            <curved-arrow-icon
                class="max-h-3 max-w-3 rotate-180 transform-gpu mr-1 text-subheading"
            />
            <p
                v-snip="1"
                class="break-word w-full text-muted pb-1 text-xs">

                {{ $store.state.customer.notes }}
            </p>
          </div>
        </div>
    </div>
</template>

<script>
import NotesIcon from '../icons/NotesIcon';
import CurvedArrowIcon from '../icons/CurvedArrowIcon';

export default {
    name: 'OrderNotes',
    components: {
        NotesIcon,
        CurvedArrowIcon
    },
    props: {
        v: {
            type: Object,
            required: false,
            default: () => {}
        }
    }
};
</script>
