/**
 * Threshold values for filtering allergen tags
 * @type {{min: number, max: number}}
 */
export const ALLERGENS_THRESHOLD = { min: 10, max: 200000 };

/**
 * Tags that should be excluded from allergen filtering
 * @type {number[]}
 */
export const SPEC_FREE_TAGS = [1101, 1128, 1129];

/**
 * Filters an array of tags to only include regular allergen tags, excluding special "free" tags
 * @param {number[]} tags - Array of tag IDs
 * @returns {number[]} Array of filtered allergen tags
 */
export const filterAllergens = tags => {
    return (
        tags?.filter(
            tag =>
                tag > ALLERGENS_THRESHOLD.min &&
                tag < ALLERGENS_THRESHOLD.max &&
                !SPEC_FREE_TAGS.includes(tag)
        ) || []
    );
};

/**
 * Filters an array of tags to only include possible allergen tags and extracts their base allergen IDs
 * @param {number[]} tags - Array of tag IDs
 * @returns {number[]} Array of base allergen IDs extracted from possible allergen tags
 */
export const filterPossibleAllergens = tags => {
    return (
        tags
            ?.filter(tag => tag > ALLERGENS_THRESHOLD.max)
            .map(tag => +tag.toString().substring(3)) || []
    );
};

/**
 * Extracts all tags from selected modifiers in modifier groups
 * @param {Array<{
 *   selectedOptions?: number[],
 *   selectedOption?: number,
 *   products: Array<{
 *     id: number,
 *     tags: number[]
 *   }>
 * }>} modifierGroups - Array of modifier groups with selected options
 * @returns {number[]} Array of all tags from selected modifiers
 */
export const getTagsFromModifiers = modifierGroups => {
    return modifierGroups.flatMap(group => {
        if (group.selectedOptions) {
            return group.selectedOptions.flatMap(
                id => group.products.find(modifier => modifier.id === id).tags
            );
        }

        if (group.selectedOption) {
            return group.products.find(
                modifier => modifier.id === +group.selectedOption
            ).tags;
        }

        return [];
    });
};

/**
 * Translates tag IDs to their corresponding display names
 * @param {number[]} tags - Array of tag IDs to translate
 * @param {Object.<string, string>} translations - Object mapping tag IDs to display names
 * @returns {(string|boolean)[]} Array of translated tag names or false for unknown tags
 */
export const translateTags = (tags, translations) => {
    return tags.map(tag => (translations[tag] ? translations[tag] : false));
};

/**
 * Process an item and its modifiers to extract allergen information
 * @param {Object} item - The menu item
 * @param {Array} modifierGroups - Selected modifier groups
 * @param {Object} translations - Allergen translations
 * @returns {Object} Allergen information
 */
export const processItemAllergens = (
    item,
    modifierGroups = [],
    translations = {}
) => {
    if (!item?.tags) {
        return {
            allergens: [],
            possibleAllergens: [],
            raw: { allergenTags: [], possibleAllergenTags: [] }
        };
    }

    const modifierTags = getTagsFromModifiers(modifierGroups);
    const allTags = [...item.tags, ...modifierTags];

    const allergenTags = filterAllergens(allTags);
    const possibleAllergenTags = filterPossibleAllergens(allTags);

    return {
        allergens: translateTags(allergenTags, translations),
        possibleAllergens: translateTags(possibleAllergenTags, translations)
    };
};
