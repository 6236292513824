<template>
    <div v-click-outside="onClickOutside">
        <div class="relative">
            <button
                type="button"
                class="form-select block w-full py-3 rounded border-gray-200 sm:text-sm sm:leading-5 duration-150 ease-in-out focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                aria-haspopup="listbox"
                aria-expanded="true"
                aria-labelledby="listbox-label"
                @click="showOptions = !showOptions"
                :class="{
                    'ring ring-red-200 border-red-300': error
                }"
                :disabled="disableAll"
            >
                <span
                    class="flex items-center text-gray-700 text-sm font-medium"
                >
                    <span v-if="value && dates" class="block">
                       {{ selectedDate }}
                    </span>
                    <span v-else-if="!value" class="block truncate">
                        {{ placeholder }}
                    </span>
                    <span v-else-if="value === 'ASAP'" class="block truncate">
                        {{ $t('select.asSoonAsPossible') }}
                    </span>
                    <span v-else class="block truncate">
                        {{ value }}
                    </span>
                </span>
                <span
                    class="absolute inset-y-0 right-0 flex items-center ml-3 pr-2 pointer-events-none"
                >
                    <svg
                        class="w-5 h-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                    ></svg>
                </span>
            </button>
            <transition
                enter-active-class="transition ease-out duration-100"
                enter-class="opacity-0 scale-95"
                enter-to-class="opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-class="opacity-100 scale-100"
                leave-to-class="opacity-0 scale-95"
            >
                <ul
                    v-if="showOptions"
                    class="absolute mt-1 py-1 w-full  text-base bg-white rounded-md focus:outline-none shadow-lg overflow-auto ring-1 ring-black ring-opacity-5 sm:text-sm"
                    :class="[ isSmall ? 'max-h-36' : 'max-h-56' ]"
                    tabindex="-1"
                    role="listbox"
                    aria-labelledby="listbox-label"
                    aria-activedescendant="listbox-option-3"
                    style="z-index: 1000 !important"
                >
                    <li
                        v-for="(item, index) of data"
                        :key="index"
                        class="relative pl-3 pr-3 py-2.5 text-gray-900 hover:bg-gray-100 bg-white cursor-default select-none"
                        role="option"
                        style="z-index: 1000 !important"
                        @click.prevent="handleClick(item)"
                        :class="isSelected(item) ? 'bg-indigo-200' : ''"
                    >
                        <div class="flex items-center text-gray-700 text-sm">
                            <div
                                v-if="dates"
                                class="block flex flex-col font-normal truncate"
                            >
                                <span
                                    v-if="
                                        $dayjs(item).isSame($dayjs(), 'day')
                                    "
                                    class="text-gray-500 text-sm"
                                >
                                    {{ $t('select.today') }}
                                </span>
                                <span>
                                    {{ $dayjs(item).format('ddd D MMM ') }}
                                </span>
                            </div>
                            <span v-else-if="item === 'ASAP'"  class="block font-normal truncate">
                                {{ $t('select.asSoonAsPossible') }}
                            </span>
                            <span v-else class="block font-normal truncate">
                                {{ item }}
                            </span>
                        </div>
                    </li>
                    <li
                        v-if="!data || !data.length"
                        class="relative pl-3 pr-9 py-2 text-gray-900 bg-gray-200 bg-white cursor-default select-none"
                        role="option"
                        style="z-index: 1000 !important"
                    >
                        <div class="flex items-center">
                            <span class="block font-normal truncate">
                                {{ $t('select.noOptionsAvailable') }}
                            </span>
                        </div>
                    </li>
                </ul>
            </transition>
        </div>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import { loadLocale } from '@/plugins/dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';

export default {
    directives: {
        clickOutside: vClickOutside.directive
    },
    props: {
        value: {
            type: String,
            required: false,
            default: ''
        },
        data: {
            type: Array,
            required: true,
            default: () => []
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Select an option'
        },
        dates: {
            type: Boolean,
            required: false,
            default: false
        },
        error: {
            type: Boolean,
            required: false,
            default: false
        },
        disableAll: {
            type: Boolean,
            required: true,
            default: false
        },
        size: {
            type: String,
            default: 'medium'
        }
    },
    data() {
        return {
            showOptions: false,
        };
    },
    created() {
        this.$dayjs.extend(isToday)
        this.$dayjs.extend(isTomorrow)
    },
    mounted() {
        loadLocale(this.$i18n.locale)
    },
    computed: {
        selectedDate() {
            const date = this.$dayjs(this.value);

            if (date.isToday()) {
                return this.$t('select.today');
            }

            if (date.isTomorrow()) {
                return this.$t('select.tomorrow');
            }

            return date.format('ddd, D MMM');
        },
        isSmall() {
            return this.size === 'small'
        }
    },
    methods: {
        handleClick(item) {
            this.$emit('input', item);
            this.showOptions = false;
        },
        onClickOutside() {
            this.showOptions = false;
        },
        isSelected(item) {
            return this.value === item;
        }
    }
};
</script>
