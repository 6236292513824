<template>
    <component
        :is="offer.type === OfferLinkTypesEnum.EXTERNAL ? 'a' : 'div'"
        :href="
            offer.type === OfferLinkTypesEnum.EXTERNAL
                ? offer.value
                : undefined
        "
        :target="
            offer.type === OfferLinkTypesEnum.EXTERNAL
                ? '_blank'
                : undefined
        "
        @click="$emit('action', offer)"
        class="card rounded-md shadow-md cursor-pointer relative"
    >
        <BlurHash
            v-if="imageHash"
            :hash="imageHash"
            :isLoaded="isLoaded"
        />
        <img
            :src="imageSource"
            :alt="offer.name"
            @load="onLoad"
        />
    </component>
</template>

<script>
import isMobile from '@/helpers/isMobileDevice';
import { OfferLinkTypesEnum } from '@/enums';
import BlurHash from './BlurHash';

export default {
    name: 'MenuOfferItem',
    components: { BlurHash },
    props: {
        offer: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            OfferLinkTypesEnum,
            isLoaded: false
        };
    },
    computed: {
        imageSource() {
            return isMobile() ? this.offer.mobileImage : this.offer.desktopImage;
        },
        imageHash() {
            return isMobile() ? this.offer.mobileImageHash : this.offer.desktopImageHash;
        }
    },
    methods: {
        onLoad() {
            this.isLoaded = true;
        }
    }
};
</script>
