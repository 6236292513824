<template>
    <div class="relative flex px-1 pt-3 sm:pt-5 md:px-3">
        <div class="w-full">
            <div class="relative w-full">
                <input
                    type="text"
                    name="po-number"
                    required
                    v-model="poNumber"
                    id="po-number"
                    autocomplete="off"
                    style="height: 50px"
                    class="email-input block pt-3 px-4 w-full text-sm bg-secondary border rounded shadow-sm disabled:opacity-50"
                    :disabled="disableAll"
                />
                <label
                    for="po-number"
                    class="email-input-label absolute pt-1 px-4 rounded pointer-events-none select-none"
                >
                    {{ $t('poNumber.poNumberLabel') }}
                </label>
                <label
                    for="po-number"
                    class="text-sm text-price ml-2 mt-2 block"
                    >{{ $t('poNumber.poNumberSubLabel') }}</label
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PoNumber',
    props: {
        disableAll: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        poNumber: {
            get() {
                return this.$store.state.cart.poNumber;
            },
            set(value) {
                this.$store.commit('cart/setPoNumber', value);
            }
        }
    }
};
</script>
