<template>
    <div
        v-if="brandName === 'StoreKit'"
        role="presentation"
        class="mx-auto  mb-3 flex items-center justify-center py-16 sm:py-8"
    >
        <span class="text-sm text-primary">served with</span>
        <a :href="`${brandLink}?utm_source=internal&utm_medium=referral`" target="_blank" rel="noreferrer noopener" aria-label="Powered by storekit - sign up here">
            <store-kit class="ml-2 h-6" />
        </a>
    </div>
    <span v-else role="presentation">
        {{ $t('poweredBy.poweredBy') }}
        <a :href="`${brandLink}?utm_source=internal&utm_medium=referral`" target="_blank" rel="noreferrer noopener" :aria-label="`Powered by ${brandName} - sign up here`">
            {{ brandName }}
        </a>
    </span>
</template>

<script>
import StoreKit from '@/components/icons/StoreKit';

export default {
    name: 'PoweredBy',
    components: { StoreKit },
    data() {
        return {
            brandName: import.meta.env.VITE_BRANDING_NAME,
            brandLink: import.meta.env.VITE_BRANDING_LINK
        };
    }
};
</script>
