import { i18n } from '../plugins/i18n';
import { axios } from '@/plugins/axios';
import store from '../store';
import languages from '../util/languages';

const loadedLanguages = [import.meta.env.VITE_I18N_LOCALE];
const languagesAvailable = languages.availableLanguages;

class switchToLangOrCloserLang {
    static async handle(lang) {
        const userLanguage = lang || navigator.language;

        const langIfSupported = this.findMatchingLang(userLanguage);

        if (langIfSupported) {
            store.commit('customer/setLang', langIfSupported);

            return this._loadLanguageAsync(langIfSupported);
        }
        return;
    }

    static findMatchingLang(lang) {
        if (languagesAvailable.includes(lang)) {
            return lang;
        } else if (lang.includes('-')) {
            const userLanguageSplit = lang.split('-');

            if (languagesAvailable.includes(userLanguageSplit[0])) {
                return userLanguageSplit[0];
            }
        }

        return false;
    }

    static async loadBrowserLang() {
        const langIfSupported = this.findMatchingLang(
            window.navigator.language
        );
        if (langIfSupported) {
            await this.handle(langIfSupported);
        }
    }

    static async _setI18nLanguage(lang) {
        i18n.locale = lang;
        document.documentElement.setAttribute('lang', lang);
        axios.defaults.headers.common['Accept-Language'] =
            lang || import.meta.env.VITE_I18N_LOCALE || 'en';

        return lang;
    }

    static async _loadLanguageAsync(lang) {
        if (i18n.locale === lang) {
            return Promise.resolve(lang);
        }

        if (loadedLanguages.includes(lang)) {
            return Promise.resolve(this._setI18nLanguage(lang));
        }

        return import(
          `../lang/${lang}.json`
        ).then(msgs => {
            i18n.setLocaleMessage(lang, msgs.default);
            loadedLanguages.push(lang);

            return this._setI18nLanguage(lang);
        });
    }
}

export default switchToLangOrCloserLang;
