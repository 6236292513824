<template>
    <modal
        name="cross-sell-modal"
        height="auto"
        width="575"
        :maxWidth="575"
        :adaptive="true"
        :scrollable="true"
        :clickToClose="true"
        :pivotY="pivotY"
        :reset="true"
        transition="pop-out"
        :class="{ 'mobile-item-modal': pivotY === 1 }"
        class="modal-visible-overflow"
        @opened="opened()"
        @before-open="onBeforeOpen"
        @before-close="onBeforeClose"
    >
        <div class="w-full bg-secondary mt-20 rounded-md">
            <div class="modal-body">
                <div
                    class="sticky top-0 z-50 flex w-full bg-secondary py-3 px-4 shadow-sm rounded-t-md"
                >
                    <h2 class="font-heading text-xl font-bold text-subheading">
                        {{ $t('crossSellModal.peopleAlsoAdded') }}
                    </h2>
                    <div class="absolute right-0 top-0 pr-3 pt-3">
                        <button
                            type="button"
                            aria-label="Hide basket"
                            class="text-primary transition duration-150 ease-in-out hover:text-gray-500 focus:text-gray-500 focus:outline-none"
                            @click="continueToCheckout"
                        >
                            <close-icon />
                        </button>
                    </div>
                </div>
                <div class="relative pt-4">
                    <ul>
                        <menu-item
                            v-for="item in products"
                            :key="item.id"
                            :isAvailable="isInStock(item)"
                            :quick-add="true"
                            :source="'cross_sell_quick_add'"
                            :large-images="largeImages"
                            @click.native="showItemModal(item)"
                            :item="item"
                        />
                    </ul>
                </div>
                <div
                    class="item-modal-footer z-50 sticky bottom-0 flex footer-blurred p-3"
                >
                    <button
                        @click="continueToCheckout"
                        class="border-button-primary no-highlight w-full flex-auto select-none rounded-sm bg-button font-button px-12 py-4 text-center text-sm font-bold text-button shadow sm:mb-3 md:mb-0 md:ml-6 md:w-auto"
                    >
                        {{ $t('crossSellModal.continueToCheckout') }}
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import isMobile from '@/helpers/isMobileDevice';
import { mapActions, mapGetters, mapState } from 'vuex';
import MenuItem from '@/components/MenuItem.vue';
import CloseIcon from '@/components/icons/CloseIcon';
import { TrackingEventsEnum } from '@/enums';

export default {
    name: 'cross-sell-modal',
    components: {
        MenuItem,
        CloseIcon
    },
    props: {
        products: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            hasAddedProduct: false
        };
    },
    computed: {
        ...mapState({
            cartItems: state => state.cart.items,
            largeImages: state => state.venue.venue.largeImages
        }),
        pivotY() {
            return isMobile() ? 1 : 0.5;
        },
        isMobile() {
            return isMobile();
        }
    },
    methods: {
        ...mapActions({
            setShowMobileBasket: 'basket/setShowMobileBasket'
        }),
        showItemModal(item) {
            if (this.isInStock(item)) {
                this.$modal.show('item-modal', { item: item });
            }
        },
        continueToCheckout() {
            this.setShowMobileBasket(true);
            this.close();
        },
        isInStock(item) {
            if (item.snooze) {
                return false;
            }

            const cartItem = this.cartItems.find(x => x.id === item.id);

            let inventory = item.inventory;

            if (cartItem) {
                inventory -= cartItem.quantity;
            }

            if (!item.isAvailable && !item.trackInventory) {
                return false;
            } else if (item.isAvailable && !item.trackInventory) {
                return true;
            } else return !!(item.trackInventory && inventory > 0);
        },
        update() {
            this.close();
        },
        close() {
            this.$modal.hide('cross-sell-modal');
        },
        opened() {
            if (this.$analytics) {
                this.$analytics.track(
                    TrackingEventsEnum.CROSS_SELL_MODAL_VIEW,
                    {
                        productCount: this.products.length,
                        crossSellType: 'category'
                    }
                );
            }
        },
        onBeforeOpen() {
            document.body.classList.add('v--modal-cross-sell-block-scroll');
        },
        onBeforeClose() {
            document.body.classList.remove('v--modal-cross-sell-block-scroll');
        }
    }
};
</script>
