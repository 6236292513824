<template>
    <header
        ref="venueheader"
        v-if="$store.state.venue.venue"
        class="bg-primary"
    >
        <div
            class="venue-header container-lg flex flex-auto flex-col-reverse flex-wrap justify-between mx-auto md:flex-row md:px-3 lg:px-4 xl:px-8"
        >
            <div
                v-if="!visualMenu"
                class="venue-main flex flex-col justify-start pt-2 py-4 sm:pr-6 sm:pt-4 md:py-10"
            >
                <div class="venue-details px-5 md:px-0">
                    <div class="flex">
                        <h1
                            class="break-anywhere font-heading font-heading mt-2 text-heading text-2xl font-black leading-tight md:text-3xl"
                        >
                            {{ name }}
                        </h1>
                    </div>
                    <div class="venue-metadetails pt-1 sm:pt-2">
                        <div
                            class="flex flex-row w-full cursor-pointer md:w-1/2"
                            @click="showAllOpeningHours = !showAllOpeningHours"
                        >
                            <small
                                v-if="
                                    openUntil === END_OF_THE_DAY &&
                                    closingNextDay
                                "
                                class="mr-1 text-green-600"
                            >
                                {{ $t('venueHeader.openUntil') }}
                                {{
                                    [closingNextDay, 'HH:mm:ss']
                                        | dayjs('h:mm a')
                                }}
                            </small>
                            <small
                                v-else-if="openUntil === END_OF_THE_DAY"
                                class="mr-1 text-green-600"
                            >
                                {{ $t('venueHeader.openAllDay') }}
                            </small>
                            <small
                                v-else-if="openUntil"
                                class="mr-1 text-green-600"
                            >
                                {{ $t('venueHeader.openUntil') }}

                                {{ [openUntil, 'HH:mm:ss'] | dayjs('h:mm a') }}
                            </small>
                            <small
                                v-else-if="
                                    acceptsPreOrders && alwaysOpenForPreorders
                                "
                                class="mr-1 text-green-600"
                            >
                                {{ $t('venueHeader.openForPreOrders') }}
                            </small>
                            <small v-else class="mr-1 text-red-700">
                                {{ $t('venueHeader.closedUntil') }}
                                {{ [openTime, 'HH:mm:ss'] | dayjs('h:mm a') }}
                                <span>{{ dayOfOpening }}</span>
                            </small>

                            <div class="text-price">
                                <dropdown-icon
                                    class="w-5 h-5"
                                    :class="
                                        showAllOpeningHours
                                            ? 'rotate-180'
                                            : 'rotate-0'
                                    "
                                />
                            </div>
                        </div>

                        <venue-opening-hours
                            v-once
                            v-if="showAllOpeningHours"
                            class="inline-block my-2 pl-2"
                        />
                    </div>
                    <div class="mt-2 text-muted text-sm max-w-2xl">
                        <span
                            class="block"
                            @click="showFullDesc = !showFullDesc"
                            :v-snip="maxDescLines"
                            snipMethod="js"
                            elipsis="'.\u200A.\u200A.'"
                        >
                            {{ description }}
                        </span>
                    </div>
                    <VenueInformation v-once />
                </div>
            </div>
            <div
                class="venue-image relative flex-col justify-start md:flex md:py-10"
            >
                <div
                    v-if="headerImage"
                    class="relative hidden w-auto h-40 bg-cover bg-center sm:block sm:rounded-sm xl:ml-4"
                    :style="{
                        backgroundImage: `url(${headerImage}-/resize/1200x/-/format/auto/)`
                    }"
                >
                </div>
                <div
                    :style="[
                        visualMenu ? { height: '10rem' } : { height: '12rem' }
                    ]"
                    class="relative w-full h-40 bg-cover bg-center overflow-hidden sm:hidden sm:rounded-sm xl:ml-4"
                    v-if="headerImage && isMobile"
                >
                    <img
                        v-parallax.absY="0.2"
                        :srcset="`${headerImage}-/resize/500x/-/format/auto/ 1x,${headerImage}-/resize/1000x/-/format/auto/ 2x,${headerImage}-/resize/1500x/-/format/auto/ 3x,`"
                        :src="`${headerImage}-/resize/1200x/-/format/auto/`"
                        class="w-full h-40 object-cover"
                        :alt="`${name}-logo`"
                    />
                </div>
                <div
                    class="relative block w-auto h-14 bg-cover bg-center rounded-sm sm:hidden xl:ml-4"
                    :style="{
                        backgroundColor: primaryColour || '#292a31'
                    }"
                    v-else
                >
                </div>
                <div v-if="!visualMenu">
                    <div
                        v-if="
                            $store.state.cart.fulfillmentMethod ===
                                FulfillmentMethodsEnum.IN_STORE &&
                            $store.state.venue.venue.acceptsInStore === true
                        "
                        :class="{
                            'fulfillment-chooser-mobile-no-image': !headerImage,
                            'cursor-pointer': !isChangingTableDisabled
                        }"
                        class="instore-order-details z-50 flex justify-between p-3 focus:border border-2 border-transparent focus:border-blue-900 rounded-sm shadow sm:shadow-none md:px-0 xl:m-4"
                        @click="showTableSelectionModal"
                    >
                        <div class="flex flex-col">
                            <span class="text-subheading font-bold">
                                {{ tableDescriptor }}:
                                <span
                                    v-if="
                                        table &&
                                        $store.state.cart.selectedTableId
                                    "
                                    >{{ table.name }}</span
                                >
                            </span>
                        </div>
                        <span
                            v-if="!isChangingTableDisabled"
                            class="text-secondaryactions text-sm"
                        >
                            {{ $t('venueHeader.change') }}
                        </span>
                    </div>
                    <div
                        v-else-if="
                            $store.state.cart.fulfillmentMethod &&
                            $store.state.venue.venue.acceptsDelivery === true &&
                            $store.state.venue.venue.acceptsPickup === true
                        "
                        :class="{
                            'pt-3 fulfillment-chooser-mobile-no-image':
                                !headerImage
                        }"
                        class="fulfillment-chooser-mobile flex flex items-center justify-between my-2 px-5 text-sm bg-primary focus:border focus:border-blue-900 rounded-sm cursor-pointer sm:text-base md:pt-0 md:px-0 xl:m-4"
                        @click="showFulfillmentMethodModal"
                    >
                        <div class="flex flex-col" v-if="!acceptsPreOrders">
                            <span class="text-subheading font-bold">
                                {{ fulfillmentMethod }} in {{ prepTime }}
                            </span>
                            <span
                                class="text-gray-400 text-sm font-bold uppercase"
                                v-if="deliveryPostCode"
                                >{{ deliveryPostCode }}</span
                            >
                        </div>
                        <div class="flex flex-col" v-else>
                            <span class="text-subheading font-bold">
                                {{ $t('venueHeader.preOrderFor') }}
                                {{ fulfillmentMethod }}
                            </span>
                            <span
                                class="text-gray-400 text-sm font-bold uppercase"
                                v-if="deliveryPostCode"
                                >{{ deliveryPostCode }}</span
                            >
                        </div>
                        <span class="text-primary">
                            {{ $t('venueHeader.change') }}
                        </span>
                    </div>
                    <div
                        v-else
                        :class="{
                            'pt-3 fulfillment-chooser-mobile-no-image':
                                !headerImage
                        }"
                        class="fulfillment-chooser-mobile flex justify-between my-2 px-5 text-sm bg-primary focus:border focus:border-blue-900 rounded-sm sm:text-base md:pt-0 md:px-0 xl:m-4"
                    >
                        <div class="flex flex-col">
                            <span
                                class="text-subheading font-bold"
                                v-if="!acceptsPreOrders"
                            >
                                {{ fulfillmentMethod }} in {{ prepTime }}
                            </span>
                            <span class="text-subheading font-bold" v-else>
                                {{ $t('venueHeader.preOrderFor') }}
                                {{ fulfillmentMethod }}
                            </span>
                            <span
                                class="text-gray-400 text-sm font-bold uppercase"
                                v-if="
                                    $store.state.cart.fulfillmentMethod ===
                                        [FulfillmentMethodsEnum.DELIVERY] &&
                                    $store.state.cart.deliveryPostCode
                                "
                            >
                                {{ $store.state.cart.deliveryPostCode }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import isMobile from '@/helpers/isMobileDevice';
import VenueInformation from '@/components/VenueInformation';
import VenueOpeningHours from '@/components/VenueOpeningHours';
import { FulfillmentMethodsEnum } from '@/enums';
import DropdownIcon from '@/components/icons/DropdownIcon';
import { orderBy } from '@/util/sort';

export default {
    name: 'VenueHeader',
    components: {
        VenueInformation,
        VenueOpeningHours,
        DropdownIcon
    },
    data() {
        return {
            open: true,
            showFullDesc: false,
            START_OF_THE_DAY: '00:00:00',
            END_OF_THE_DAY: '23:59:59',
            FulfillmentMethodsEnum,
            showAllOpeningHours: false
        };
    },
    mounted() {
        if ('IntersectionObserver' in window) {
            this.createObserver();
        }
    },
    computed: {
        ...mapState('venue', {
            name: state => state.venue.name,
            id: state => state.venue.id,
            description: state => state.venue.description,
            headerImage: state => state.venue.headerImage,
            acceptsDelivery: state => state.venue.acceptsDelivery,
            acceptsPickup: state => state.venue.acceptsPickup,
            latLong: state => state.venue.latLong,
            hours: state => state.venue.venueHours,
            enabled: state => state.venue.enabled,
            visualMenu: state => state.venue.visualMenu,
            acceptsPreOrders: state => state.venue.acceptsPreOrders,
            alwaysOpenForPreorders: state => state.venue.alwaysOpenForPreorders,
            primaryColour: state => state.venue.primaryColour,
            tableDescriptor: state => state.venue.tableDescriptor
        }),
        ...mapState('deliveryAddress', {
            deliveryPostCode: state => state.deliveryAddress.postCode
        }),
        ...mapGetters('venue', ['findTableById', 'prepTime']),
        fulfillmentMethod() {
            return {
                [FulfillmentMethodsEnum.IN_STORE]: this.$t(
                    'fulfillmentMethods.InStore'
                ),
                [FulfillmentMethodsEnum.DELIVERY]: this.$t(
                    'fulfillmentMethods.Delivery'
                ),
                [FulfillmentMethodsEnum.PICKUP]: this.$t(
                    'fulfillmentMethods.Pickup'
                ),
                [FulfillmentMethodsEnum.CATERING_PICKUP]: this.$t(
                    'fulfillmentMethods.Pickup'
                ),
                [FulfillmentMethodsEnum.CATERING_DELIVERY]: this.$t(
                    'fulfillmentMethods.Delivery'
                )
            }[this.$store.state.cart.fulfillmentMethod];
        },
        isMobile() {
            return isMobile();
        },
        isInStore() {
            return (
                this.$store.state.cart.fulfillmentMethod ===
                FulfillmentMethodsEnum.IN_STORE
            );
        },
        maxDescLines() {
            return isMobile() ? (this.showFullDesc ? 8 : 3) : 8;
        },
        openUntil() {
            const currentInterval = this.currentInterval();
            if (currentInterval) {
                return currentInterval.closeTime;
            }

            return false;
        },
        closingNextDay() {
            const currentInterval = this.currentInterval();
            const upcomingInterval = this.upcomingInterval();

            if (
                (upcomingInterval.day === currentInterval.day + 1 ||
                    (upcomingInterval.day === 0 &&
                        currentInterval.day === 6)) &&
                upcomingInterval.openTime === this.START_OF_THE_DAY &&
                upcomingInterval.closeTime !== this.END_OF_THE_DAY &&
                !upcomingInterval.closingNextDay
            ) {
                return upcomingInterval.closeTime;
            }

            return false;
        },
        openTime() {
            const upcomingInterval = this.upcomingInterval();

            if (upcomingInterval) {
                return upcomingInterval.openTime;
            }

            return this.START_OF_THE_DAY;
        },

        dayOfOpening() {
            let timeNow = new Date();

            let dayOfWeek = timeNow.getDay();
            if (dayOfWeek === 0) {
                dayOfWeek = 7;
            }

            const upcomingInterval = this.upcomingInterval();

            const daysOfWeek = {
                0: this.$t('daysOfWeek.monday'),
                1: this.$t('daysOfWeek.tuesday'),
                2: this.$t('daysOfWeek.wednesday'),
                3: this.$t('daysOfWeek.thursday'),
                4: this.$t('daysOfWeek.friday'),
                5: this.$t('daysOfWeek.saturday'),
                6: this.$t('daysOfWeek.sunday')
            };

            if (upcomingInterval) {
                if (upcomingInterval.day === dayOfWeek - 1) {
                    return '';
                } else if (upcomingInterval.day === dayOfWeek) {
                    return this.$t('venueHeader.tomorrow');
                } else {
                    return daysOfWeek[upcomingInterval.day];
                }
            }
            return '';
        },
        table() {
            return this.findTableById(this.$store.state.cart.selectedTableId);
        },
        isChangingTableDisabled() {
            return this.id === 4286 || this.id === 4386;
        },
        hasCateringFulfillmentMethodModal() {
            return this.$growthbook.isOn('catering_fulfillment_method_modal');
        }
    },
    methods: {
        currentInterval() {
            if (!this.hours) {
                return false;
            }

            const currentTime = this.$dayjs();
            const dayOfWeek = (currentTime.day() || 7) - 1;

            const today = this.hours.filter(x => x.day === dayOfWeek);

            for (const interval of today) {
                const openTime = this.$dayjs(interval.openTime, 'HH:mm:ss');
                const closeTime = this.$dayjs(interval.closeTime, 'HH:mm:ss');

                if (
                    this.$dayjs(currentTime, 'HH:mm:ss').isAfter(openTime) &&
                    this.$dayjs(currentTime, 'HH:mm:ss').isBefore(closeTime)
                ) {
                    return interval;
                }
            }

            return false;
        },
        upcomingInterval() {
            if (!this.hours) {
                return false;
            }

            const currentTime = this.$dayjs();
            let dayOfWeek = currentTime.day();

            if (dayOfWeek === 0) {
                dayOfWeek = 7;
            }

            let days = [0, 1, 2, 3, 4, 5, 6];
            delete days[dayOfWeek - 1];

            const daysFromToday = days.concat(days.splice(0, dayOfWeek - 1));
            const today = this.hours.filter(x => x.day === dayOfWeek - 1);
            const sorted = orderBy(today, 'openTime');

            for (const interval of sorted) {
                let openTime = this.$dayjs(interval.openTime, 'HH:mm:ss');

                if (this.$dayjs(currentTime).isBefore(openTime)) {
                    return interval;
                }
            }

            for (const day of daysFromToday) {
                let dayIntervals = this.hours.filter(x => x.day === day);
                const sorted = orderBy(dayIntervals, 'openTime');

                for (const interval of sorted) {
                    if (interval.openTime !== interval.closeTime) {
                        return interval;
                    }
                }
            }

            return false;
        },
        showTableSelectionModal() {
            if (this.isChangingTableDisabled) {
                return;
            }

            if (!this.$route.query.redirectResult) {
                this.$modal.show('table-selection-modal');
            }
        },
        createObserver() {
            const headerParallax = document.querySelector(
                '.mobile-header-parallax'
            );
            if (!headerParallax) return;

            const calculateOpacity = (scrollTop, elementTop, height) => {
                if (scrollTop <= elementTop) return 1;

                const opacity = 1 - (scrollTop - elementTop) / height;
                return opacity >= 0 ? opacity : 0;
            };

            const observer = new IntersectionObserver(
                ([entry]) => {
                    const elementTop =
                        window.pageYOffset + entry.boundingClientRect.top;
                    const opacity = calculateOpacity(
                        document.documentElement.scrollTop,
                        elementTop,
                        entry.target.offsetHeight
                    );

                    headerParallax.style.opacity = opacity;
                },
                {
                    threshold: Array.from({ length: 21 }, (_, i) => i / 20)
                }
            );

            observer.observe(headerParallax);
        },
        showFulfillmentMethodModal() {
            if (this.hasCateringFulfillmentMethodModal) {
                return this.$modal.show('catering-fulfillment-method-modal');
            }

            this.$modal.show('fulfillment-method-modal');
        }
    }
};
</script>

<style src="@/assets/css/venue-header_scoped.css" scoped></style>
