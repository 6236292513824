<template>
    <div class="flex flex-col w-full">
        <base-button
            class="relative py-3 mb-3 bg-slate-950 bg-opacity-70"
            :disabled="countdown > 15"
            @clicked="completeConfirmationStep"
        >
            <template #slot>
                <div
                    class="absolute inset-0 z-2 bg-button transition-all duration-1000 ease-linear"
                    :style="{
                        width: `${progress}%`
                    }"
                ></div>
                <span
                    class="relative z-10"
                    :class="{
                        'text-gray-900': countdown > 15
                    }"
                >
                    {{
                        `Confirm... (00:${countdown.toString().padStart(2, '0')})`
                    }}
                </span>
            </template>
        </base-button>
        <base-button
            variant="secondaryActions"
            :is-border-transparent="true"
            button-text="Close"
            @clicked="stopCounting"
        />
    </div>
</template>

<script>
export default {
    name: 'CountdownButton',
    data() {
        return {
            countdown: 0,
            progress: 0,
            timer: null
        };
    },
    mounted() {
        this.startCountdown();
    },
    methods: {
        startCountdown() {
            this.countdown = 20;
            this.progress = 0;

            const step = 100 / this.countdown;

            this.timer = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--;
                    this.progress += step;
                } else {
                    this.stopCounting();
                }
            }, 1000);
        },
        stopCounting() {
            clearInterval(this.timer);
            this.$emit('toggle-counting');
        },
        completeConfirmationStep() {
            this.stopCounting();
            this.$emit('complete-confirmation-step');
        }
    }
};
</script>
