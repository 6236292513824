<template>
    <fieldset
        class="flex items-start pb-4 pt-2 px-1 w-full text-sm cursor-pointer"
    >
        <div class="text-price">
            <label class="container">
                {{ optInMessage }}
                <input
                    v-model="policyAgreedValue"
                    id="optInMessage"
                    class="form-checkbox w-4 h-4 transition duration-150 ease-in-out rounded border-gray-200 focus:border-blue-200 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                    type="checkbox"
                    :disabled="disableAll"
                />
                <span class="checkmark"></span>
            </label>
        </div>
    </fieldset>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'PolicyConsents',
    props: {
        venueId: {
            type: Number,
            required: true,
            default: 0
        },
        accountId: {
            type: String,
            required: true,
            default: ''
        },
        optInMessage: {
            type: String,
            required: true,
            default: ''
        },
        disableAll: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    computed: {
        ...mapGetters({
            venueValue: 'venue/venueValue',
            policyAgreed: 'customer/policyAgreed'
        }),
        policyAgreedValue: {
            get: function () {
                return this.policyAgreed(this.accountId);
            },
            set: function (value) {
                return this.setPolicyAgreed({ value });
            }
        }
    },
    methods: {
        ...mapActions({
            setPolicyAgreed: 'customer/setPolicyAgreed'
        })
    },
    mounted() {
        this.setPolicyAgreed({ value: this.venueValue('optInPreselect') });
    }
};
</script>

<style scoped>
.container {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 0px;
}

.container input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: white;
    border: 1px solid rgb(229 231 235);
    border-radius: 4px;
}

.container input:checked ~ .checkmark {
    background-color: var(--color-bg-button);
}

.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}
.container input:checked ~ .checkmark:after {
    display: block;
}

.container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 9px;
    border: solid var(--color-text-button);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
</style>
