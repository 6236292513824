<template>
    <div ref="wrapper">
        <vue-content-loading
            :width="wrapperWidth"
            :height="122"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
        >
            <rect x="18" y="18" rx="3" ry="3" width="30%" height="8" />
            <rect x="18" y="45" rx="3" ry="3" width="50%" height="8" />
            <rect x="18" y="65" rx="3" ry="3" width="40%" height="8" />
            <rect x="18" y="85" rx="3" ry="3" width="20%" height="8" />
            <rect
                :x="wrapperWidth - 128"
                y="10"
                rx="3"
                ry="3"
                width="112"
                height="100"
            />
        </vue-content-loading>
    </div>
</template>

<script>
import VueContentLoading from 'vue-content-loading';

export default {
    data() {
        return {
            wrapperWidth: 285
        };
    },
    components: {
        VueContentLoading
    },
    mounted() {
        if (this.$refs.wrapper && this.$refs.wrapper.offsetWidth) {
            this.wrapperWidth = this.$refs.wrapper.offsetWidth;
        }
    }
};
</script>
