<template>
    <div
        @click="showAuthModal"
        class="border-button-primary select-none cursor-pointer flex items-center justify-center w-fit text-button bg-button font-button rounded-md py-2 px-3 text-sm font-bold shadow"
    >
        Login to view your rewards
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'CustomerAuth',
    data() {
        return {};
    },
    computed: {
        ...mapGetters('customer', {
            auth: 'auth'
        })
    },
    methods: {
        showAuthModal() {
            this.$modal.show('customer-auth-modal');
        }
    }
};
</script>
