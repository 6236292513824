// pluginUtils.js

export function enablePlugins(pluginData, analytics) {
    if (!analytics) {
        return;
    }

    const pluginNames = [];

    if (pluginData.googleTagManagerId) {
        pluginNames.push('google-tag-venue');
    }

    if (pluginData.klaviyoApiKey) {
        pluginNames.push('klaviyo');
    }

    if (import.meta.env.VITE_JITSU_CLIENT_KEY) {
        pluginNames.push('jitsu');
    }

    if (pluginData.facebookPixelId) {
        pluginNames.push('facebookPixel');
    }

    analytics.plugins.enable(pluginNames);
}
