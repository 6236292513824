<template>
    <div class="basket pin-r absolute block w-full rounded-sm bg-primary">
        <div
            v-if="!visualMenu"
            class="basket-action select-none relative flex hidden flex-wrap justify-between bg-secondary sm:block"
            :class="[itemCount === 0 ? 'p-0 md:p-4' : 'p-4']"
        >
            <router-link
                :disabled="!canCheckout"
                :to="{ name: 'checkout' }"
                class="basket-action__button border-button-primary flex w-full justify-center rounded-sm bg-button font-button p-3 text-center text-sm font-bold text-button md:text-base"
                :class="{
                    'basket-action__button-disabled md:block': !canCheckout
                }"
            >
                <span
                    class="item-count inline-block rounded-sm md:hidden"
                    v-if="canCheckout && itemCount > 0"
                    >{{ cartQuantity }}</span
                >
                <span class="rounded-sm" v-if="isClosed">
                    {{ $t('basket.closed') }}
                </span>
                <span v-else-if="nonAlcoholicItemRequired && itemCount > 0">
                    Non alcoholic item required
                </span>
                <span v-else-if="checkoutConditions()">
                    {{ checkFulfillmentMethod() }}
                </span>
                <span v-else>
                    {{ $t('basket.nextCheckout') }}
                </span>

                <span class="total inline-block pr-1 md:hidden">{{
                    (cartTotalWithDiscount / 100) | customCurrency
                }}</span>
            </router-link>
        </div>
        <div
            v-if="!showMobileBasket && itemCount > 0 && !visualMenu"
            class="basket-action select-none basket-action__checkout relative flex flex-wrap justify-between bg-secondary sm:hidden"
            :class="[itemCount === 0 ? 'p-0 md:p-3' : 'p-3']"
        >
            <div
                class="mb-2 flex flex-auto font-bold flex-row items-center text-sm justify-between px-3 text-subheading"
                v-if="totalAmountDiscounted"
            >
                <span class="total">
                    {{
                        $t('basket.mobileDiscount', {
                            value: totalAmountDiscountedFormatted
                        })
                    }}
                </span>
            </div>
            <span
                class="basket-action__button border-button-primary flex w-full justify-center rounded-sm bg-button font-button p-3 text-center text-sm font-bold text-button md:text-base"
                @click="shouldShowCrossellOrNavigate()"
            >
                <span
                    class="item-count inline-block rounded-sm md:hidden"
                    v-if="canCheckout && itemCount > 0"
                >
                    {{ cartQuantity }}
                </span>
                <span class="rounded-sm" v-if="isClosed">
                    {{ $t('basket.closed') }}
                </span>
                <span v-else-if="nonAlcoholicItemRequired">
                    Non alcoholic item required
                </span>
                <span v-else-if="checkoutConditions()">
                    {{ checkFulfillmentMethod() }}
                </span>
                <span v-else>
                    {{ $t('basket.nextViewOrder') }}
                </span>
                <span class="total inline-block pr-1 md:hidden">
                    {{ (cartTotalWithDiscount / 100) | customCurrency }}
                </span>
            </span>
        </div>
        <div
            class="basket-action select-none relative flex flex-wrap justify-between bg-secondary sm:hidden"
            v-if="showMobileBasket && cartItems.length > 0 && !visualMenu"
            :class="[itemCount === 0 ? 'p-0 md:p-4' : 'p-4']"
        >
            <router-link
                :disabled="!canCheckout || checkOrderNotes"
                :to="{ name: 'checkout' }"
                class="basket-action__button border-button-primary bg-button font-button text-button flex w-full justify-center rounded-sm p-3 text-center text-sm font-bold md:text-base"
                :class="{
                    'basket-action__button-disabled':
                        !canCheckout || checkOrderNotes
                }"
            >
                <span
                    class="item-count inline-block rounded-sm md:hidden"
                    v-if="canCheckout && itemCount > 0"
                    >{{ cartQuantity }}</span
                >
                <span class="rounded-sm" v-if="isClosed">
                    {{ $t('basket.closed') }}
                </span>
                <span v-else-if="nonAlcoholicItemRequired">
                    Non alcoholic item required
                </span>
                <span v-else-if="checkoutConditions()">
                    {{ checkFulfillmentMethod() }}
                </span>
                <span v-else-if="checkOrderNotes">Order notes is required</span>
                <span
                    v-else-if="
                        billId ||
                        venueId === 8376 ||
                        venueId === 9708 ||
                        venueId === 9710 ||
                        venueId === 9714 ||
                        venueId === 9711 ||
                        venueId === 9713 ||
                        venueId === 9712
                    "
                    >{{ $t('basket.nextPlaceOrder') }}</span
                >
                <span v-else>
                    {{ $t('basket.nextCheckout') }}
                </span>
                <span
                    v-if="venueId !== 9711"
                    class="total inline-block pr-1 md:hidden"
                    >{{ (cartTotalWithDiscount / 100) | customCurrency }}</span
                >
                <span v-else class="w-8"></span>
            </router-link>

            <span
                class="mt-1 flex w-full w-full justify-center rounded-sm bg-secondary p-3 text-center text-sm font-bold text-secondaryactions"
                @click="setShowMobileBasket(false)"
            >
                {{ $t('basket.backToMenu') }}
            </span>
        </div>
        <div
            v-if="!visualMenu"
            class="basket-contents overflow-y-auto relative bg-primary sm:bg-secondary sm:pt-4 md:block"
            :class="{ 'mobile-active': showMobileBasket }"
        >
            <div class="flex flex-col items-start justify-start">
                <div
                    class="basket-contents__header block w-full py-3 px-4 shadow-sm sm:hidden"
                >
                    <h2 class="font-heading text-xl font-bold text-subheading">
                        {{ $t('basket.myBasket') }}
                    </h2>
                    <div class="absolute right-0 top-0 pr-3 pt-3">
                        <button
                            type="button"
                            aria-label="Hide basket"
                            class="text-primary transition duration-150 ease-in-out hover:text-gray-500 focus:text-gray-500 focus:outline-none"
                            @click="setShowMobileBasket(false)"
                        >
                            <close-icon />
                        </button>
                    </div>
                </div>
                <div
                    class="relative no-scrollbar bg-secondary self-stretch overflow-y-auto pt-4 sm:pt-0 fade-container"
                    :class="{ show: showFade }"
                >
                    <div
                        v-if="hasOverflow"
                        class="absolute z-10 bottom-3 left-1/2 transform -translate-x-1/2"
                    >
                        <base-button
                            class="flex items-center py-1 px-6"
                            button-text="View more"
                            size="sm"
                            rounded="rounded-2xl"
                            @clicked="scrollToBottom"
                        >
                            <template #slot>
                                <arrow-down-icon class="mr-2 h-3 w-3" />
                            </template>
                        </base-button>
                    </div>
                    <div
                        class="basket-contents__items"
                        ref="basketItemsContainer"
                        :class="{
                            'no-scrollbar': showMobileBasket,
                            'full-height-basket': isFullHeightMenu
                        }"
                    >
                        <ul
                            ref="basketList"
                            class="basket-contents__items-list list-none px-5 pb-4 sm:pb-5"
                        >
                            <transition-group
                                name="list"
                                tag="div"
                                v-if="cartItems.length > 0"
                                @after-leave="checkOverflow"
                            >
                                <li
                                    class="basket-item flex flex-auto flex-row items-start justify-between text-subheading"
                                    :class="{ 'items-center': acceptsPat }"
                                    v-for="(item, index) in cartItems"
                                    :key="'item-' + item.id + '-' + index"
                                >
                                    <div
                                        v-if="
                                            $store.state.venue.venue
                                                .cartImages === 'md'
                                        "
                                        class="mr-3 rounded-lg overflow-hidden flex-shrink-0"
                                    >
                                        <img
                                            v-if="item.image"
                                            :src="`${item.image}/-/format/auto/-/resize/288x/`"
                                            :alt="item.name"
                                            class="w-16 h-16"
                                        />
                                        <div v-else class="w-16 h-16"></div>
                                    </div>
                                    <span
                                        class="item mr-3 flex flex-auto flex-wrap justify-start text-left"
                                    >
                                        <span
                                            class="name flex-auto self-center"
                                        >
                                            {{ item.name }}
                                        </span>
                                        <span
                                            v-if="item.modifiers"
                                            class="variants shrink-0 grow text-sm text-gray-600"
                                            style="flex: 1 0 100%"
                                        >
                                            <span
                                                v-for="(
                                                    modifier, index
                                                ) in item.modifiers"
                                                :key="
                                                    'modifier-' +
                                                    item.id +
                                                    modifier.id +
                                                    index
                                                "
                                                class="text-price"
                                            >
                                                {{ modifier.name
                                                }}{{
                                                    index !==
                                                    item.modifiers.length - 1
                                                        ? ','
                                                        : ''
                                                }}
                                            </span>
                                        </span>
                                        <item-line-notes
                                            v-if="item.notes?.length > 0"
                                            :notes="item.notes"
                                        />
                                        <span
                                            class="price text-price"
                                            v-if="!acceptsPat"
                                        >
                                            {{
                                                (calculateItemTotal(item) / 100)
                                                    | customCurrency
                                            }}

                                            <loading-dots
                                                v-if="isCalculationLoading"
                                            />

                                            <span
                                                class="text-sm text-green-700"
                                                v-if="
                                                    showDiscountValue(item) &&
                                                    !isCalculationLoading
                                                "
                                            >
                                                <br />
                                                {{
                                                    `-${
                                                        showDiscountValue(
                                                            item
                                                        ) / 100
                                                    }` | customCurrency
                                                }}
                                            </span>
                                        </span>
                                    </span>

                                    <span>
                                        <div
                                            class="quantity-stepper-small relative inline-flex select-none flex-row items-center justify-between"
                                        >
                                            <div
                                                class="flex cursor-pointer items-center"
                                                @click="
                                                    handleProductRemoval([
                                                        item,
                                                        -1,
                                                        item.modifiers
                                                    ])
                                                "
                                            >
                                                <bin-icon
                                                    v-if="item.quantity === 1"
                                                    class="text-secondaryactions"
                                                />
                                                <minus-circle-icon
                                                    v-else
                                                    class="h-5 w-5 text-secondaryactions"
                                                />
                                            </div>
                                            <div
                                                class="w-6 text-center text-itembody"
                                            >
                                                {{ item.quantity }}
                                            </div>
                                            <div
                                                class="flex cursor-pointer items-center"
                                                @click="
                                                    validateAndAddProductToCart(
                                                        [
                                                            item,
                                                            1,
                                                            item.modifiers
                                                        ]
                                                    )
                                                "
                                            >
                                                <plus-circle-icon
                                                    class="h-5 w-5"
                                                    :class="{
                                                        'text-gray-400':
                                                            item.trackInventory &&
                                                            item.inventory <=
                                                                item.quantity
                                                    }"
                                                />
                                            </div>
                                        </div>
                                    </span>

                                </li>
                            </transition-group>
                            <li v-else class="py-8 text-center">
                                <span class="text-gray-600">
                                    {{ $t('basket.empty') }}
                                </span>

                                <span
                                    v-if="isMobile"
                                    class="mt-6 flex w-full justify-center rounded-sm bg-button font-button p-3 text-center text-sm font-bold text-white"
                                    @click="setShowMobileBasket(false)"
                                >
                                    {{ $t('basket.backToMenu') }}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div
                    class="basket-contents__footer w-full pt-4 pb-36 sm:pb-4"
                    v-if="cartItems.length > 0 && venueId !== 9711"
                >
                    <menu-recommendations
                        v-if="hasShapedRecs()"
                        id="basket-recs"
                        :instance-id="'basket'"
                        location="basket"
                        class="mt-2 flex px-5 w-full border-t border-gray-100"
                    />
                    <div
                        v-if="isMobile && oderNotesVisibility"
                        class="-mt-4 border-b border-gray-100 bg-secondary px-5"
                    >
                        <order-notes />
                    </div>
                    <ul class="basket-totals list-none">
                        <li
                            class="mt-2 flex flex-auto flex-row justify-between px-5 text-subheading"
                            v-if="
                                fulfillmentMethod ===
                                FulfillmentMethodsEnum.DELIVERY
                            "
                        >
                            <span class="total">
                                {{ $t('basket.deliveryFee') }}
                            </span>
                            <span
                                class="price"
                                :class="{
                                    'flex flex-1 justify-end line-through':
                                        hasDeliveryDiscount
                                }"
                            >
                                {{ (deliveryFee / 100) | customCurrency }}
                            </span>
                            <span
                                class="ml-1 flex items-center text-sm"
                                v-if="hasDeliveryDiscount"
                            >
                                {{ formattedDeliveryFee }}
                            </span>
                        </li>
                        <li
                            class="mt-2 flex flex-auto flex-row justify-between px-5 text-subheading"
                            v-if="serviceCharge > 0"
                        >
                            <span class="total">{{
                                serviceChargeLabel || $t('basket.serviceCharge')
                            }}</span>
                            <span class="price">{{
                                (serviceCharge / 100) | customCurrency
                            }}</span>
                        </li>
                        <li
                            v-if="defaultServiceChargeEnabled"
                            class="mt-2 flex flex-auto flex-row justify-between px-5"
                        >
                            <div
                                class="mt-1 flex items-center text-base text-gray-600 text-secondary"
                            >
                                <span class="mr-1">
                                    {{
                                        tipLabel || $t('basket.serviceCharge')
                                    }}</span
                                >
                                <span
                                    v-if="!hasMandatoryServiceCharge"
                                    class="text-gray-400"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="ml-1 h-5 w-5 cursor-pointer"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        @click="showMandatoryTipModal"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </span>
                            </div>
                            <span class="price text-price">{{
                                (tip / 100) | customCurrency
                            }}</span>
                        </li>
                        <li
                            class="mt-2 flex flex-auto flex-row justify-between px-5 text-subheading"
                            v-else-if="tip > 0 && tipVariant === 'enabled'"
                        >
                            <span class="total">
                                {{ $t('basket.tip') }}
                            </span>
                            <span class="price">{{
                                (tip / 100) | customCurrency
                            }}</span>
                        </li>

                        <li
                            class="mt-2 flex flex-auto flex-row justify-between px-5 text-subheading"
                            v-if="totalAmountDiscounted"
                        >
                            <span class="total">
                                {{ $t('basket.discount') }}
                            </span>
                            <loading-dots v-if="isCalculationLoading" />
                            <span v-else class="price text-green-700">{{
                                (totalAmountDiscounted / 100) | customCurrency
                            }}</span>
                        </li>
                        <li
                            class="mt-2 flex flex-auto flex-row justify-between px-5 text-subheading"
                            v-if="
                                discountCodeDetails &&
                                discountCodeDetails.minimumAmount &&
                                discountCodeDetails.minimumAmount -
                                    cartSubtotal >
                                    0
                            "
                        >
                            <span class="total">
                                {{ $t('checkout.discountCode') }}
                            </span>
                            <span
                                v-if="discountCodeDetails.minimumAmount"
                                class="price text-red-600"
                            >
                                {{ $t('checkout.spend') }}
                                {{
                                    ((discountCodeDetails.minimumAmount -
                                        cartSubtotal) /
                                        100)
                                        | customCurrency
                                }}
                                {{ $t('checkout.more') }}
                            </span>
                        </li>
                        <li
                            class="mt-2 flex flex-auto flex-row justify-between px-5 font-heading font-bold text-heading"
                        >
                            <span class="total">
                                {{ $t('basket.total') }}
                            </span>
                            <loading-dots v-if="isCalculationLoading" />
                            <span v-else class="price">
                                {{ (cartTotalWithDiscount / 100) | customCurrency }}
                            </span>
                        </li>
                        <li
                            v-if="isCateringMethod"
                            class="flex justify-end items-center px-5"
                        >
                            <span
                                @click="openCalculationMethodModal()"
                                class="price-per-person text-xs cursor-pointer"
                            >
                                {{ (pricePerPerson / 100) | customCurrency }}
                                /
                                {{ $t('basket.person') }}
                            </span>
                        </li>
                    </ul>

                    <piggy-text
                        v-if="showEstimatedCredits"
                        class="px-5"
                        :is-calculate="isCalculating"
                    />
                    <como-rewards-list
                        v-if="
                            (isAuthenticated &&
                                $store.state.venue.venue.comoEnabled) ||
                            $store.state.venue.venue.comoNonMemberBenefits
                        "
                        @is-calculating="isCalculationLoading = $event"
                    />
                    <piggy-rewards-list
                        v-if="
                            isAuthenticated &&
                            piggySettings?.enabled &&
                            piggySettings.enabledShowRewards &&
                            piggySettings.enabledShowInlineRewardsOnBasket
                        "
                        listDirection="horizontal"
                        :is-external-reward-modal="true"
                    />

                    <button
                        v-if="
                            isAuthenticated &&
                            piggySettings?.enabled &&
                            piggySettings.enabledShowRewards &&
                            !piggySettings.enabledShowInlineRewardsOnBasket
                        "
                        class="px-5 text-sm text-subheading"
                        @click="$modal.show('piggy-rewards-modal')"
                        >view rewards</button
                    >
                    <customer-auth
                        class="mx-auto mt-2 mb-5"
                        v-if="
                            (!isAuthenticated &&
                                piggySettings?.enabled &&
                                piggySettings.enabledShowRewards) ||
                            (!isAuthenticated &&
                                $store.state.venue.venue.comoEnabled)
                        "
                    />
                </div>
            </div>
        </div>
        <order-notes-modal v-if="oderNotesVisibility" />
        <piggy-reward-selected-modal />
        <loyalty-contact-verification-modal />
        <confirm-item-removal-modal
            @confirm-item-removal="removeProductFromCart"
        />
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import isMobile from '@/helpers/isMobileDevice';
import { calculateItemTotal } from '@/services/cart';
import { FulfillmentMethodsEnum } from '@/enums';
import OrderNotesModal from '@/components/modals/OrderNotesModal';
import OrderNotes from '@/components/formElements/OrderNotes';
import PlusCircleIcon from './icons/PlusCircleIcon';
import MinusCircleIcon from './icons/MinusCircleIcon';
import CloseIcon from './icons/CloseIcon';
import PiggyText from '@/components/elements/PiggyText';
import { isMatchingProduct } from '@/util/piggyHelper';
import PiggyRewardsList from '@/components/PiggyRewardsList';
import CustomerAuth from '@/components/CustomerAuth';
import LoyaltyContactVerificationModal from '@/components/modals/LoyaltyContactVerificationModal.vue';
import ItemLineNotes from '@/components/elements/ItemLineNotes';
import BinIcon from './icons/BinIcon';
import ConfirmItemRemovalModal from '@/components/modals/ConfirmItemRemovalModal';
import PiggyRewardSelectedModal from '@/components/modals/PiggyRewardSelectedModal';
import ArrowDownIcon from '@/components/icons/ArrowDownIcon';
import { debounce } from '@/helpers/debounce';
import MenuRecommendations from '@/components/Recommendations.vue';
const ComoRewardsList = () => import('@/components/ComoRewardsList');
import LoadingDots from '@/components/elements/LoadingDots';
import { loginWithTemporaryToken } from '@/services/comoService';

export default {
    name: 'basket',
    components: {
        ComoRewardsList,
        MenuRecommendations,
        ItemLineNotes,
        OrderNotesModal,
        OrderNotes,
        PlusCircleIcon,
        MinusCircleIcon,
        CloseIcon,
        PiggyText,
        PiggyRewardsList,
        CustomerAuth,
        LoyaltyContactVerificationModal,
        BinIcon,
        ConfirmItemRemovalModal,
        PiggyRewardSelectedModal,
        ArrowDownIcon,
        LoadingDots
    },
    props: {
        showBasket: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data() {
        return {
            isLoading: false,
            FulfillmentMethodsEnum,
            nonAlcoholicItemRequired: false,
            calculateTimeout: null,
            isCalculating: false,
            hasOverflow: false,
            scrollTimeout: null,
            showFade: false,
            debouncedCheckOverflow: null,
            isCalculationLoading: false
        };
    },
    created() {
        if (this.showBasket) {
            this.setShowMobileBasket(true);
        } else {
            this.setShowMobileBasket(false);
        }

        if (!this.customerAuth && this.$route.query.temporaryToken) {
            this.loginWithTempToken(this.$route.query.temporaryToken);
        }

        this.isCalculating = true;
        this.calculatePiggyCredits(this.totalWithDiscount / 100);
    },
    beforeMount() {
        if (
            this.tipVariant !== 'disabled' &&
            this.defaultServiceChargeEnabled
        ) {
            this.initTipValue();
        }
    },
    mounted() {
        if (this.isMobile) {
            const container = this.$refs.basketItemsContainer;
            this.debouncedCheckOverflow = debounce(this.checkOverflow, 200);

            if (container) {
                container.addEventListener('scroll', this.handleBasketScroll);
            }
        }
    },
    beforeDestroy() {
        this.unlockScroll();
        this.removeEventListener();
    },
    watch: {
        // @TODO fix mobile basket scrolling
        // showMobileBasket(value) {
        //     if (value) {
        //         this.lockScroll();
        //         this.checkOverflow();
        //     } else {
        //         this.unlockScroll();
        //     }
        // },
        totalWithDiscount() {
            // if (isMobile) {
            this.isCalculating = true;

            clearTimeout(this.emailTimeout);

            this.emailTimeout = setTimeout(() => {
                this.calculatePiggyCredits(this.totalWithDiscount / 100);
            }, 100);
        }
        // }
    },
    computed: {
        ...mapState({
            cartItems: state => state.cart.items,
            deliveryFee: state =>
                state.cart.deliveryFee || state.venue.venue.deliveryFee || 0,
            serviceCharge: state => state.venue.venue.serviceCharge || 0,
            serviceChargeLabel: state =>
                state.venue.venue.serviceChargeLabel || '',
            tipLabel: state => state.venue.venue.tipLabel || '',
            fulfillmentMethod: state => state.cart.fulfillmentMethod,
            enabled: state => state.venue.venue.enabled,
            minDeliveryAmount: state => state.venue.venue.minDeliveryAmount,
            minPickupAmount: state => state.venue.venue.minPickupAmount,
            minInStoreAmount: state => state.venue.venue.minInStoreAmount,
            maxDeliveryAmount: state => state.venue.venue.maxDeliveryAmount,
            maxPickupAmount: state => state.venue.venue.maxPickupAmount,
            maxInStoreAmount: state => state.venue.venue.maxInStoreAmount,
            hours: state => state.venue.venue.venueHours,
            venueId: state => state.venue.venue.id,
            venueSlug: state => state.venue.venue.slug,
            enableOrderNotes: state => state.venue.venue.enableOrderNotes,
            visualMenu: state => state.venue.venue.visualMenu,
            tableId: state => state.cart.selectedTableId,
            defaultServiceChargeEnabled: state =>
                state.venue.venue.defaultServiceChargeEnabled,
            acceptsPreOrders: state => state.venue.venue.acceptsPreOrders,
            alwaysOpenForPreorders: state =>
                state.venue.venue.alwaysOpenForPreorders,
            totalWithDiscount: state => state.cart.totalWithDiscount,
            piggySettings: state => state.venue.venue.piggySettings,
            customerEmail: state => state.customer.email,
            notes: state => state.customer.notes,
            piggyProductDiscount: state => state.cart.piggyProductDiscount,
            customerAuth: state => state.customer.auth,
            zoneDeliveryMinCost: state => state.cart.zoneDeliveryMinCost,
            discountedDeliveryFee: state => state.cart.discountedDeliveryFee,
            acceptsPat: state => state.venue.venue.acceptsPat
        }),
        ...mapGetters({
            cartQuantity: 'cart/cartQuantity',
            subTotal: 'cart/cartSubtotal',
            totalAmountDiscounted: 'cart/totalAmountDiscounted',
            tip: 'cart/tip',
            autoAppliedDiscountCode: 'venue/autoAppliedDiscountCode',
            showMobileBasket: 'basket/showMobileBasket',
            tipVariant: 'venue/tipVariant',
            venueValue: 'venue/venueValue',
            discountCodeDetails: 'discount/details',
            categoryExists: 'venue/categoryExists',
            billId: 'bill/id',
            cartTotalWithDiscount: 'cart/cartTotalWithDiscount',
            pricePerPerson: 'cart/getCalculatedPricePerPerson',
            auth: 'customer/auth'
        }),
        isMobile() {
            return isMobile();
        },
        itemCount() {
            return this.cartItems.length;
        },
        hasRecommendationsEnabled() {
            return this.$growthbook.isOn('shaped_recs');
        },
        minimumDeliveryAmount() {
            if (this.zoneDeliveryMinCost) {
                return this.zoneDeliveryMinCost;
            } else {
                return this.minDeliveryAmount;
            }
        },
        isFullHeightMenu() {
            return this.venueId === 9711;
        },
        canCheckout() {
            if (!this.enabled || this.itemCount === 0 || this.isClosed) {
                return false;
            }
            const limits = {
                [FulfillmentMethodsEnum.DELIVERY]: {
                    min: this.minimumDeliveryAmount,
                    max: this.maxDeliveryAmount
                },
                [FulfillmentMethodsEnum.CATERING_DELIVERY]: {
                    min: this.minimumDeliveryAmount,
                    max: this.maxDeliveryAmount
                },
                [FulfillmentMethodsEnum.PICKUP]: {
                    min: this.minPickupAmount,
                    max: this.maxPickupAmount
                },
                [FulfillmentMethodsEnum.CATERING_PICKUP]: {
                    min: this.minPickupAmount,
                    max: this.maxPickupAmount
                },
                [FulfillmentMethodsEnum.IN_STORE]: {
                    min: this.minInStoreAmount,
                    max: this.maxInStoreAmount
                }
            };

            const methodLimits = limits[this.fulfillmentMethod];
            // Check if subtotal is within allowed range
            return !(
                (methodLimits.min && this.subTotal < methodLimits.min) ||
                (methodLimits.max && this.subTotal > methodLimits.max)
            );
        },
        isClosed() {
            if (this.acceptsPreOrders && this.alwaysOpenForPreorders) {
                return false;
            }

            if (!this.hours) {
                return true;
            }

            const currentTime = this.$dayjs();
            const dayOfWeek = (currentTime.day() || 7) - 1;
            const today = this.hours.filter(
                interval => interval.day === dayOfWeek
            );

            for (const interval of today) {
                let openTime = this.$dayjs(interval.openTime, 'HH:mm:ss');
                let closeTime = this.$dayjs(interval.closeTime, 'HH:mm:ss');

                if (
                    this.$dayjs(currentTime, 'HH:mm:ss').isAfter(openTime) &&
                    this.$dayjs(currentTime, 'HH:mm:ss').isBefore(closeTime)
                ) {
                    return false;
                }
            }

            return true;
        },
        showEstimatedCredits() {
            return !!(
                this.piggySettings &&
                this.piggySettings?.enabled &&
                this.piggySettings?.loyaltyOptIn?.enabledLoyalty
            );
        },
        accountId() {
            return this.venueValue('accountId');
        },
        isAuthenticated() {
            return this.auth;
        },
        checkOrderNotes() {
            return (
                this.enableOrderNotes === 'mandatory' && this.notes.length === 0
            );
        },
        oderNotesVisibility() {
            return this.enableOrderNotes !== 'hidden';
        },
        hasMandatoryServiceCharge() {
            return this.$growthbook.isOn('mandatory_service_charge');
        },
        totalAmountDiscountedFormatted() {
            return this.$options.filters.customCurrency(
                this.totalAmountDiscounted / -100
            );
        },
        isCateringMethod() {
            if (this.venueId === 9133 || this.venueId === 9469) {
                return true;
            }
            return FulfillmentMethodsEnum.isCateringMethod(
                this.fulfillmentMethod
            );
        },
        formattedDeliveryFee() {
            return this.discountedDeliveryFee === 0
                ? this.$t('basket.free')
                : this.$options.filters.customCurrency(
                      this.discountedDeliveryFee / 100
                  );
        },
        hasDeliveryDiscount() {
            return (
                this.fulfillmentMethod === FulfillmentMethodsEnum.DELIVERY &&
                this.discountCodeDetails?.minimumAmount <= this.cartSubtotal &&
                this.discountedDeliveryFee !== this.deliveryFee
            );
        }
    },
    methods: {
        ...mapActions({
            addProductToCart: 'cart/addProductToCart',
            setCartTotalWithDiscount: 'cart/cartTotalWithDiscount',
            setShowMobileBasket: 'basket/setShowMobileBasket',
            initTipValue: 'cart/initTipValue',
            calculateEstimatedCredits: 'customer/calculateEstimatedCredits',
            updatePiggyDiscount: 'cart/updatePiggyDiscount'
        }),
        ...mapMutations({
            setAuth: 'customer/SET_AUTH'
        }),
        calculateItemTotal(item) {
            return calculateItemTotal(item);
        },
        validateAndAddProductToCart([product, quantityDelta, modifiers]) {
            if (
                product.trackInventory &&
                product.quantity >= product.inventory
            ) {
                return;
            }

            this.addProductToCart([
                product,
                quantityDelta,
                modifiers,
                'basket'
            ]);
            this.updatePiggyDiscount({
                productId: product.id,
                quantity: product.quantity
            });
            this.setCartTotalWithDiscount();
        },
        handleProductRemoval(product) {
            if (product[0].quantity === 1) {
                this.$modal.show('confirm-item-removal-modal', {
                    product: product
                });
            } else {
                this.removeProductFromCart(product);
            }
        },
        removeProductFromCart(product) {
            this.addProductToCart(product);
            this.removePiggyProductDiscount(product);
            this.clearComoRewards();
            this.setCartTotalWithDiscount();
        },
        checkoutConditions() {
            return !this.canCheckout && this.itemCount > 0 && this.enabled;
        },
        checkFulfillmentMethod() {
            const methodConfig = {
                [FulfillmentMethodsEnum.PICKUP]: {
                    min: this.minPickupAmount,
                    max: this.maxPickupAmount
                },
                [FulfillmentMethodsEnum.DELIVERY]: {
                    min: this.minimumDeliveryAmount,
                    max: this.maxDeliveryAmount
                },
                [FulfillmentMethodsEnum.IN_STORE]: {
                    min: this.minInStoreAmount,
                    max: this.maxInStoreAmount
                }
            };

            const currentMethod = methodConfig[this.fulfillmentMethod];
            if (!currentMethod) return '';

            const formatAmount = amount =>
                this.$options.filters.currency(amount / 100);

            if (this.subTotal < currentMethod.min) {
                return `${this.$t('basket.spendMore')} ${formatAmount(currentMethod.min)}`;
            }

            return `${this.$t('basket.spendLess')} ${formatAmount(currentMethod.max)}`;
        },

        showMandatoryTipModal() {
            this.$modal.show('mandatory-tip-modal');
        },
        goToPayAtTableLandingPage() {
            this.$router.push({
                name: 'LandingPage',
                params: { tableId: this.tableId }
            });
        },
        checkNonAlcoholicItems() {
            this.nonAlcoholicItemRequired = false;

            const alcoholicItem = this.cartItems.findIndex(({ tags }) =>
                tags.includes(1)
            );

            const nonAlcoholicItem = this.cartItems.findIndex(({ tags }) =>
                tags.includes(3)
            );

            if (~alcoholicItem) {
                if (~nonAlcoholicItem) {
                    return true;
                } else {
                    this.nonAlcoholicItemRequired = true;
                    return false;
                }
            }

            return true;
        },
        hasCrosssellEnabled() {
            if (
                this.venueValue('crossSellCategory') &&
                this.categoryExists(this.venueValue('crossSellCategory'))
            ) {
                return true;
            }
            return false;
        },
        shouldShowCrossellOrNavigate() {
            if (this.hasCrosssellEnabled()) {
                this.$modal.show('cross-sell-modal');
            } else {
                this.setShowMobileBasket(true);
            }
        },
        hasShapedRecs() {
            if (this.venueId === 7918 || this.venueId === 9773) {
                return false;
            }
            // BAO
            return true;
        },
        async calculatePiggyCredits(unitValue) {
            if (this.itemCount === 0 || !this.piggySettings?.enabled) {
                return;
            }

            const params = {
                email: this.customerEmail,
                piggyShopId: this.piggySettings.piggyShopId,
                unitValue,
                accountId: this.accountId,
                unitName: this.piggySettings.creditUnitName || '',
                venueId: this.venueId
            };

            await this.calculateEstimatedCredits(params);

            this.isCalculating = false;
        },
        showDiscountValue(item) {
            if (item.discountAmount > 0) {
                return item.discountAmount;
            }

            const modifierIds = item.modifiers.map(modifier => modifier.id);
            const discountValue = this.piggyProductDiscount.find(product =>
                isMatchingProduct(product, item.id, modifierIds)
            );

            return discountValue?.value;
        },
        removePiggyProductDiscount(product) {
            if (product[0].quantity === 0) {
                const modifierIds = product[0].modifiers.map(
                    modifier => modifier.id
                );

                this.$store.commit('cart/REMOVE_PIGGY_PRODUCT_DISCOUNT', {
                    id: product[0].id,
                    modifierIds
                });

                if (!this.piggyProductDiscount.length) {
                    this.$store.commit('cart/SET_PIGGY_REWARD_SELECTED', {});
                }
            }

            this.updatePiggyDiscount({
                productId: product[0].id,
                quantity: product[0].quantity
            });
        },
        lockScroll() {
            this.offsetY = window.pageYOffset;
            document.body.style.top = `${-this.offsetY}px`;
            document.body.classList.add('lock-scroll-position');
        },
        unlockScroll() {
            const offsetY = Math.abs(
                parseInt(document.body.style.top || 0, 10)
            );
            document.body.classList.remove('lock-scroll-position');
            document.body.style.removeProperty('top');
            window.scrollTo(0, offsetY || 0);
        },
        openCalculationMethodModal() {
            this.$modal.show('calculation-method-modal');
        },
        scrollToBottom() {
            const list = this.$refs.basketList;

            if (list) {
                list.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
        },
        handleBasketScroll() {
            this.hasOverflow = false;
            this.showFade = true;

            if (this.scrollTimeout) {
                cancelAnimationFrame(this.scrollTimeout);
            }
            this.scrollTimeout = requestAnimationFrame(
                this.debouncedCheckOverflow
            );
        },
        checkOverflow() {
            this.scrollTimeout = null;
            const basketItemsContainer = this.$refs.basketItemsContainer;

            if (!basketItemsContainer) {
                return;
            }

            const { scrollTop, scrollHeight, clientHeight } =
                basketItemsContainer;

            if (scrollHeight && clientHeight) {
                this.hasOverflow = scrollTop + clientHeight <= scrollHeight - 1;
                this.showFade = this.hasOverflow;
            }
        },
        removeEventListener() {
            const container = this.$refs.basketItemsContainer;

            if (container) {
                container.removeEventListener(
                    'scroll',
                    this.handleBasketScroll
                );
            }
        },
        clearComoRewards() {
            if (!this.cartItems.length) {
                this.$store.commit('customer/CLEAR_COMO_REWARDS');
            }
        },
        async loginWithTempToken(token) {
            const isAuth = await loginWithTemporaryToken({
                venueId: this.venueId,
                accountId: this.accountId,
                temporaryToken: token
            });

            this.$store.dispatch('customer/setAuth', {
                accountId: this.accountId,
                value: isAuth
            });
        }
    }
};
</script>

<style src="@/assets/css/basket_scoped.css" scoped></style>
