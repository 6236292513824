<template>
    <div class="pb-32 h-full bg-page">
        <div
            v-if="globalLoading"
            class="global-loading fixed z-50 left-0 top-0 w-full h-full min-h-screen"
        >
            <div
                class="relative flex items-center justify-center w-full h-full"
            >
                <div class="block"> </div>
                <div
                    class="spinner spinner-blue flex justify-center text-center"
                >
                    <span class="text-md block mb-36 text-white">
                        {{ $t('checkout.processingPayment') }}
                        <br />
                        {{ $t('checkout.dontCloseWindow') }}
                    </span>
                </div>
            </div>
        </div>
        <header
            class="fixed z-50 left-0 top-0 flex flex-row py-1 w-full bg-primary sm:py-0 shadow-sm"
        >
            <div class="z-50 pl-2 w-16 w-auto">
                <div
                    @click="$router.push({ name: 'menu' })"
                    class="z-50 left-0 top-0 mr-4 mt-1 p-2.5 text-gray-700 bg-white rounded-full hover:shadow-lg shadow-md transition transition-shadow sm:hidden"
                >
                    <svg
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                </div>
            </div>
            <div class="mx-auto px-14 w-full text-left sm:hidden">
                <h1
                    class="mb-3 ml-5 mt-3 text-heading font-heading text-xl font-black leading-tight sm:ml-0 sm:text-3xl"
                    >Checkout</h1
                >
            </div>
        </header>
        <main
            v-if="!globalLoading"
            class="checkout container-lg pt-3 max-w-6xl min-h-screen sm:pt-10 md:px-3 lg:px-6 xl:px-8"
        >
            <div class="checkout flex">
                <div class="checkout-details">
                    <div
                        class="checkout-details__info sm:mt-14 pb-3 px-2 md:px-3"
                        :class="{
                            'mt-8': !isPickup,
                            'mt-0': isPickup
                        }"
                    >
                        <h1
                            class="hidden mb-3 mt-2 text-heading font-heading text-2xl font-black leading-tight sm:block sm:text-3xl"
                            >{{ venueName }}</h1
                        >
                        <div
                            v-if="
                                fulfillmentMethod !==
                                FulfillmentMethodsEnum.IN_STORE
                            "
                            class="hidden flex-row items-center pb-4 pt-4 sm:flex sm:pt-0"
                        >
                            <div class="flex flex-row mr-3 text-itembody">
                                <span class="">
                                    {{ fulfillmentMethodLabel }}
                                </span>
                            </div>
                            <div
                                class="flex flex-row items-center mr-4 text-itembody"
                            >
                                <svg
                                    class="mr-1 w-4 h-4 text-itembody"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                >
                                    <path
                                        class="heroicon-ui"
                                        d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm1-8.41l2.54 2.53a1 1 0 0 1-1.42 1.42L11.3 12.7A1 1 0 0 1 11 12V8a1 1 0 0 1 2 0v3.59z"
                                    />
                                </svg>
                                <span v-if="isPreOrder">
                                    {{ $t('checkout.preOrder') }}
                                </span>
                                <span v-else>
                                    {{ $t('checkout.asap') }}
                                    {{ prepTime }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        class="checkout-details__payment p-2 bg-secondary rounded-sm md:p-6 lg:p-8"
                        :class="{
                            'pt-0 sm:pt-8': isPickup,
                            'pt-8': !isPickup
                        }"
                    >
                        <div class="checkout__personal px-1 sm:pt-5 md:px-3">
                            <store-notice
                                location="basket"
                                class="mb-6 sm:mb-8"
                                :class="{
                                    'mt-8 sm:-mt-4': isPickup,
                                    '-mt-4': !isPickup
                                }"
                            />

                            <venue-pickup-location v-if="isPickup" />

                            <PreOrder
                                v-if="isPreOrder"
                                id="pickup-date"
                                class="mb-4 md:mb-0"
                                :v="$v"
                                :venueId="venueId"
                                :availablePreorderDays="
                                    selectedMethodPreorderDays
                                "
                                :blockAutoDatePreFill="
                                    !!this.$route.query.id ||
                                    !!this.$route.query.redirectResult
                                "
                                :disableAll="disableAll"
                                :fulfillmentMethod="fulfillmentMethod"
                            />

                            <div
                                v-if="isPreOrder"
                                class="hidden my-8 sm:block sm:border-t sm:border-gray-200"
                            >
                            </div>

                            <customer-details
                                id="customer-details"
                                :v="$v.customer"
                                :disableAll="disableAll"
                                :is-loyalty-auth="customerAuth"
                                :loyalty-identifier-type="identifierType"
                                @phoneValidation="phoneValidation"
                            />

                            <delivery-address
                                v-if="
                                    FulfillmentMethodsEnum.isDeliveryMethod(
                                        fulfillmentMethod
                                    )
                                "
                                id="delivery-address"
                                :v="$v.deliveryAddress"
                                :disableAll="disableAll"
                                :requiredFields="requiredFields"
                            />

                            <PolicyConsents
                                v-if="
                                    !policyAgreedInLocalStorage && optInMessage
                                "
                                :venueId="venueId"
                                :accountId="accountId"
                                :disableAll="disableAll"
                                :optInMessage="optInMessage"
                            />

                            <piggy-checkbox
                                :is-calculate="isCalculate"
                                v-if="!billId"
                            />

                            <piggy-text
                                v-if="showEstimatedCredits && !billId"
                                :is-calculate="isCalculate"
                            />

                            <cutlery-checkbox v-if="askAboutCutlery" />

                            <guest-count-input v-if="hasGuestCountInput" />

                            <OrderNotes
                                id="order-notes"
                                v-if="!isMobile && oderNotesVisibility"
                                :v="$v.notes"
                            />
                        </div>

                        <div
                            class="hidden mt-4 mx-1 border-t sm:block sm:border-gray-200 md:mx-3"
                        />

                        <TipSelection
                            v-if="showTipSelection"
                            class="pt-5 px-1 md:px-3"
                            :disableAll="disableAll"
                            :venue-id="venueId"
                        />

                        <DiscountCodeBox
                            :pickup-date="pickupDate"
                            :pickup-time-slot="pickupTimeSlot"
                            :disableAll="disableAll"
                        />

                        <GiftCardBox v-if="false" :disableAll="disableAll" />

                        <PoNumber v-if="isPoNumberEnabled" />

                        <div
                            class="mt-2 flex sm:hidden flex-auto px-1 flex-row justify-between text-subheading"
                            v-if="
                                discountCodeDetails &&
                                discountCodeDetails.minimumAmount &&
                                discountCodeDetails.minimumAmount -
                                    cartSubtotal >
                                    0
                            "
                        >
                            <span class="total">
                                {{ $t('checkout.discountCode') }}
                            </span>
                            <span
                                v-if="discountCodeDetails.minimumAmount"
                                class="price text-red-600"
                            >
                                {{ $t('checkout.spend') }}
                                {{
                                    ((discountCodeDetails.minimumAmount -
                                        cartSubtotal) /
                                        100)
                                        | currency
                                }}
                                {{ $t('checkout.more') }}
                            </span>
                        </div>

                        <div class="checkout__buy px-1 py-3 md:px-3 md:py-6">
                            <span
                                v-if="paymentError"
                                class="block py-3 text-red-500 text-sm"
                            >
                                {{ $t('checkout.errors.paymentError') }}
                            </span>
                            <div
                                v-if="$store.state.cart.fulfillmentMethod"
                                class="relative"
                            >
                                <countdown-button
                                    v-if="isCounting"
                                    @toggle-counting="toggleCounting"
                                    @complete-confirmation-step="
                                        validateAndCheckout(
                                            PaymentMethodsEnum.CASH
                                        )
                                    "
                                />
                                <button
                                    v-else-if="
                                        billId ||
                                        venueId === 8376 ||
                                        venueId === 9708 ||
                                        venueId === 9710 ||
                                        venueId === 9745 ||
                                        venueId === 9746 ||
                                        venueId === 9714 ||
                                        venueId === 9711 ||
                                        venueId === 9400 ||
                                        venueId === 9712 ||
                                        venueId === 9713
                                    "
                                    @click="
                                        hasAddToBillOrder
                                            ? toggleCounting()
                                            : validateAndCheckout(
                                                  PaymentMethodsEnum.CASH
                                              )
                                    "
                                    :class="{ 'spinner white': isLoading }"
                                    :disabled="isLoading"
                                    class="border-button-primary py-3 w-full text-center text-button font-bold bg-button font-button rounded-sm shadow-sm select-none"
                                >
                                    <span v-show="!isLoading">
                                        Add to your bill
                                    </span>

                                    <span class="inline-block"></span>
                                </button>
                                <button
                                    v-else-if="totalWithDiscount === 0"
                                    @click="
                                        validateAndCheckout(
                                            PaymentMethodsEnum.CASH
                                        )
                                    "
                                    :class="{ spinner: isLoading }"
                                    :disabled="isLoading"
                                    class="py-3 w-full text-white font-bold bg-button font-button rounded-sm shadow-sm select-none"
                                >
                                    <span v-show="!isLoading">
                                        Place
                                        {{
                                            fulfillmentMethod ===
                                            FulfillmentMethodsEnum.IN_STORE
                                                ? ''
                                                : fulfillmentMethod.toLowerCase()
                                        }}
                                        order
                                    </span>
                                    <span class="inline-block"></span>
                                </button>
                                <adyen-checkout
                                    v-else-if="
                                        activePaymentSystem ===
                                        PaymentMethodsEnum.ADYEN
                                    "
                                    :checkoutObject="checkoutObject"
                                    :redirectResult="adyenRedirectResult"
                                    :errors="errors || checkoutError"
                                    :is-loading="isLoading"
                                    @cash-checkout="
                                        disableAllFields(true);
                                        validateAndCheckout(
                                            PaymentMethodsEnum.CASH
                                        );
                                    "
                                    @is-validated="isValidated"
                                    @disable-all-fields="disableAllFields"
                                />

                                <button
                                    v-else-if="
                                        activePaymentSystem !==
                                        PaymentMethodsEnum.ADYEN
                                    "
                                    @click="
                                        validateAndCheckout(
                                            PaymentMethodsEnum.CASH
                                        )
                                    "
                                    :class="{ 'spinner white': isLoading }"
                                    :disabled="isLoading"
                                    class="border-button-primary py-3 w-full text-center text-button font-bold bg-button font-button rounded-sm shadow-sm select-none"
                                >
                                    <span v-show="!isLoading">
                                        Place
                                        {{
                                            fulfillmentMethod ===
                                            FulfillmentMethodsEnum.IN_STORE
                                                ? ''
                                                : fulfillmentMethod.toLowerCase()
                                        }}

                                        {{ $t('checkout.order') }}
                                    </span>

                                    <span class="inline-block"></span>
                                </button>
                            </div>
                            <div v-else>
                                <button
                                    @click="reloadPage()"
                                    class="border-button-primary py-3 w-full text-center text-button font-bold bg-button font-button rounded-sm shadow-sm select-none"
                                >
                                    <span>
                                        Something went wrong, please refresh the
                                        page
                                    </span>

                                    <span class="inline-block"></span>
                                </button>
                            </div>
                        </div>
                        <div class="px-1 md:hidden" v-if="!billId">
                            <div
                                @click="toggleOrderSummary"
                                style="height: 50px"
                                class="email-input flex items-center justify-between p-2 pl-0 rounded cursor-pointer"
                            >
                                <span
                                    class="text-itembody text-sm font-black select-none"
                                >
                                    Order summary
                                </span>
                                <span class="text-itembody text-sm font-medium">
                                    <dropdown-icon
                                        class="transition duration-300"
                                        :class="{
                                            'rotate-180': showOrderSummary
                                        }"
                                    />
                                </span>
                            </div>
                            <checkout-basket
                                class="mt-2"
                                v-show="showOrderSummary"
                                :mobile="true"
                                :cart-items="cartItems"
                                :is-calculate="isCalculate"
                            />
                        </div>
                    </div>
                    <div class="pt-6 px-8" v-if="paymentsEnabled">
                        <span
                            class="block py-3 text-center text-gray-500 text-xs"
                        >
                            {{ $t('checkout.byClickingContinueYouAgree') }}
                            <router-link
                                :to="{ name: 'Privacy' }"
                                class="font-bold"
                            >
                                {{
                                    $t('checkout.termsAndConditions')
                                }} </router-link
                            >.
                        </span>
                    </div>
                </div>
                <checkout-basket
                    class="hidden md:block"
                    :cart-items="cartItems"
                    :is-calculate="isCalculate"
                />
            </div>
        </main>
        <order-notes-modal v-if="oderNotesVisibility" />
        <address-modal />
        <rewards-modal />
        <loyalty-logout-modal @confirm-logout="handleConfirmLogout" />
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import {
    required,
    email,
    requiredUnless,
    maxLength,
    minLength,
    requiredIf
} from 'vuelidate/lib/validators';
import isMobile from '@/helpers/isMobileDevice';
import {
    FulfillmentMethodsEnum,
    PaymentMethodsEnum,
    DiscountTypesEnum,
    TrackingEventsEnum
} from '@/enums';
import StoreNotice from '@/components/StoreNotice';
import TipSelection from '@/components/formElements/TipSelection';
import CustomerDetails from '@/components/formElements/CustomerDetails';
import DeliveryAddress from '@/components/formElements/DeliveryAddress';
import PreOrder from '@/components/formElements/PreOrder';
import PolicyConsents from '@/components/formElements/PolicyConsents';
import DiscountCodeBox from '@/components/formElements/DiscountCodeBox';
import GiftCardBox from '@/components/formElements/GiftCardBox';
import AdyenCheckout from '@/components/payments/AdyenCheckout';
import isLocalStorageAvailable from '@/helpers/isLocalStorageAvailable';
import OrderNotesModal from '@/components/modals/OrderNotesModal';
import OrderNotes from '@/components/formElements/OrderNotes';
import VenuePickupLocation from '@/components/formElements/VenuePickupLocation';
import addressRequirements from '@/util/addressRequirements';
import PiggyText from '@/components/elements/PiggyText';
import PiggyCheckbox from '@/components/formElements/PiggyCheckbox';
import RewardsModal from '@/components/modals/RewardsModal';
import ItemLineNotes from '@/components/elements/ItemLineNotes';
import CutleryCheckbox from '@/components/formElements/CutleryCheckbox';
import AddressModal from '@/components/modals/DeliveryAddressModal';
import CheckoutBasket from '@/components/CheckoutBasket';
import GuestCountInput from '@/components/formElements/GuestCountInput';
import CountdownButton from '@/components/formElements/CountdownButton';
import DropdownIcon from '@/components/icons/DropdownIcon.vue';
import PoNumber from '@/components/formElements/PoNumber';
import LoyaltyLogoutModal from '@/components/modals/LoyaltyLogoutModal';

export default {
    name: 'Checkout',
    head() {
        return {
            title: 'Checkout'
        };
    },
    components: {
        DropdownIcon,
        AddressModal,
        CutleryCheckbox,
        ItemLineNotes,
        StoreNotice,
        TipSelection,
        CustomerDetails,
        DeliveryAddress,
        PreOrder,
        DiscountCodeBox,
        GiftCardBox,
        AdyenCheckout,
        PolicyConsents,
        OrderNotes,
        OrderNotesModal,
        VenuePickupLocation,
        PiggyText,
        PiggyCheckbox,
        RewardsModal,
        CheckoutBasket,
        GuestCountInput,
        CountdownButton,
        PoNumber,
        LoyaltyLogoutModal
    },
    validations: {
        pickupDate: {
            required: requiredUnless('!isPreOrder')
        },
        pickupTimeSlot: {
            required: requiredUnless('!isPreOrder')
        },
        email: {
            required,
            email
        },
        firstName: {
            required
        },
        lastName: {
            required
        },
        phone: {
            required,
            minLength: minLength(7)
        },
        deliveryAddress: {
            buildingNumber: {
                maxLength: maxLength(100)
            },
            street: {
                required: requiredIf(function () {
                    if (
                        !FulfillmentMethodsEnum.isDeliveryMethod(
                            this.fulfillmentMethod
                        )
                    ) {
                        return false;
                    }

                    return !(!this.hasField('street') && this.hasAutocomplete);
                }),
                maxLength: maxLength(100)
            },
            city: {
                required: requiredIf(function () {
                    if (
                        this.fulfillmentMethod !==
                        FulfillmentMethodsEnum.DELIVERY
                    ) {
                        return false;
                    }

                    return !(!this.hasField('city') && this.hasAutocomplete);
                }),
                maxLength: maxLength(100)
            },
            postCode: {
                required: requiredIf(function () {
                    if (
                        this.fulfillmentMethod !==
                        FulfillmentMethodsEnum.DELIVERY
                    ) {
                        return false;
                    }

                    return this.hasField('postCode');
                }),
                maxLength: maxLength(100)
            }
        },
        customer: {
            firstName: {
                required: requiredIf(function () {
                    return this.userInformationSettings.firstName;
                })
            },
            lastName: {
                required: requiredIf(function () {
                    return this.userInformationSettings.lastName;
                })
            },
            email: {
                required: requiredIf(function () {
                    return this.userInformationSettings.email === 'required';
                }),
                email
            },
            phone: {
                required: requiredIf(function () {
                    return (
                        this.userInformationSettings.phone === 'enabled' ||
                        this.userInformationSettings.phone === 'validated'
                    );
                }),
                minLength: minLength(7)
            },
            companyName: {
                required: requiredIf(function () {
                    return (
                        this.userInformationSettings.companyName === 'required'
                    );
                })
            }
        },
        notes: {
            required: requiredIf(function () {
                return (
                    this.$store.state.venue.venue.enableOrderNotes ===
                        'mandatory' && this.notes.length === 0
                );
            })
        }
    },
    data() {
        return {
            isLoading: false,
            globalLoading: false,
            paymentError: false,
            checkoutError: false,
            savedCheckout: null,
            discountCode: null,
            discountCodeError: null,
            discountCodeErrorMessage: null,
            showDiscount: false,
            discountIsLoading: false,
            errors: '',
            adyenRedirectResult: '',
            disableAll: false,
            FulfillmentMethodsEnum,
            PaymentMethodsEnum,
            DiscountTypesEnum,
            isCalculate: false,
            phoneValidationError: false,
            isCounting: false,
            showOrderSummary: false
        };
    },
    async created() {
        if (this.deliveryPostCode) {
            this.setPostCode(this.deliveryPostCode);
        }

        if (this.hasTooLowAmount || this.hasTooHighAmount) {
            this.$router.go(-1);
        }

        const menuVersionCorrect = await this.checkMenuVersion();

        if (!menuVersionCorrect) {
            this.$router.go(-1);
        }
    },

    async mounted() {
        if (this.isPreOrder) {
            this.$store.commit('cart/setPreOrder', true);
        }

        if (
            !this.$store.state.cart.fulfillmentMethod &&
            !this.$route.params.orderId
        ) {
            /* eslint-disable-next-line */
            console.error('Fulfillment method not set');
        }

        if (
            (this.cartItems.length === 0 && !this.$route.params.orderId) ||
            (!this.selectedTableId &&
                this.isInStore &&
                !this.$route.params.orderId)
        ) {
            await this.$router.replace({ name: 'menu' });
        }

        await this.cartTotalWithDiscount();

        if (this.activePaymentSystem === PaymentMethodsEnum.ADYEN) {
            await this.checkAdyenRedirect();
        }

        this.isCalculate = true;
        await this.calculatePiggyCredits(this.totalWithDiscount / 100);

        if (this.$analytics) {
            const totalNumeric = parseFloat(
                (this.totalWithDiscount / 100).toFixed(2)
            );

            await this.$analytics.track(TrackingEventsEnum.STARTED_CHECKOUT, {
                currency: this.currencyCode,
                items: this.cartItems,
                venueSlug: this.$store.state.venue?.venue?.slug,
                value: totalNumeric
            });
        }
    },
    methods: {
        ...mapActions({
            checkout: 'cart/checkout',
            cartTotalWithDiscount: 'cart/cartTotalWithDiscount',
            checkMenuVersion: 'venue/checkMenuVersion',
            setPaymentType: 'cart/setPaymentType',
            setDeliveryAddress: 'deliveryAddress/setAddress',
            setPostCode: 'deliveryAddress/setPostCode',
            catchOrder: 'cart/catchOrder',
            calculateEstimatedCredits: 'customer/calculateEstimatedCredits',
            setAuth: 'customer/setAuth',
            clearRewards: 'customer/clearRewardsAndDiscounts'
        }),
        async validateAndCheckout(paymentType) {
            this.isLoading = true;

            if (this.cartItems.length === 0) {
                return false;
            }

            if (this.hasTooLowAmount || this.hasTooHighAmount) {
                this.isLoading = false;

                return false;
            }

            if (this.isPreOrder && !this.pickupDateValidated()) {
                return false;
            }

            await this.setPaymentType(paymentType);

            if (this.isPickup) {
                if (!this.customerValidated() || !this.orderNotesValidated()) {
                    return false;
                } else {
                    try {
                        await this.checkout(this.checkoutObject);
                    } catch (e) {
                        this.checkoutError = true;
                        this.disableAllFields(false);
                    } finally {
                        this.isLoading = false;
                    }
                }
            }

            if (this.isInStore) {
                if (!this.customerValidated() || !this.orderNotesValidated()) {
                    return false;
                } else {
                    this.isLoading = true;

                    try {
                        await this.checkout(this.checkoutObject);
                    } catch (e) {
                        this.checkoutError = true;
                        this.disableAllFields(false);
                    } finally {
                        this.isLoading = false;
                    }
                }
            } else if (this.isDelivery) {
                if (
                    !this.customerValidated() ||
                    !this.deliveryAddressValidated() ||
                    !this.orderNotesValidated()
                ) {
                    return false;
                } else {
                    // this.globalLoading = true;
                    this.isLoading = true;
                    try {
                        await this.checkout(this.checkoutObject);
                    } catch (e) {
                        this.checkoutError = true;
                        this.disableAllFields(false);
                    } finally {
                        this.isLoading = false;
                    }
                }
            }
        },
        pickupDateValidated() {
            this.$v.pickupTimeSlot.$touch();
            this.$v.pickupDate.$touch();

            if (
                this.$v.pickupDate.$invalid ||
                this.$v.pickupTimeSlot.$invalid
            ) {
                this.errors = 'ERROR';
                this.isLoading = false;

                this.scrollToInvalidField('pickup-date');

                /* eslint-disable-next-line */
                console.error('Pickup date error');
                return false;
            }

            return true;
        },
        customerValidated() {
            this.$v.customer.$touch();

            if (this.$v.customer.$invalid || this.phoneValidationError) {
                this.errors = 'ERROR';
                this.isLoading = false;

                this.scrollToInvalidField('customer-details');

                /* eslint-disable-next-line */
                console.error('Customer info error');
                return false;
            }

            return true;
        },

        phoneValidation(value) {
            this.phoneValidationError = value;
        },
        deliveryAddressValidated() {
            this.$v.deliveryAddress.$touch();

            if (this.$v.deliveryAddress.$invalid) {
                this.errors = 'ERROR';
                this.isLoading = false;

                this.scrollToInvalidField('delivery-address');
                /* eslint-disable-next-line */
                console.error('Delivery address error');
                return false;
            }

            return true;
        },
        orderNotesValidated() {
            this.$v.notes.$touch();

            if (this.$v.notes.$invalid) {
                this.errors = 'ERROR';
                this.isLoading = false;

                this.scrollToInvalidField('order-notes');
                /* eslint-disable-next-line */
                console.error('Order notes error');

                return false;
            }

            return true;
        },
        clearErrors(initCheck) {
            this.errors = '';
            this.checkoutError = null;

            if (initCheck) {
                this.$v.$reset();
            }
        },
        scrollToInvalidField(id) {
            const element = document.getElementById(id);

            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        },
        isValidated() {
            this.clearErrors(false);

            if (!this.cartItems.length) {
                /* eslint-disable-next-line */
                console.error('Cart is empty');
                return false;
            }

            if (this.isPreOrder) {
                this.pickupDateValidated();
            }
            if (this.isPickup || this.isInStore) {
                this.customerValidated();
                this.orderNotesValidated();
            } else {
                this.deliveryAddressValidated();
                this.customerValidated();
                this.orderNotesValidated();
            }

            return !this.errors;
        },
        async checkAdyenRedirect() {
            this.adyenRedirectResult = this.$route.query.redirectResult
                ? decodeURIComponent(this.$route.query.redirectResult)
                : '';

            if (this.adyenRedirectResult) {
                this.globalLoading = true;

                this.$modal.hide('fulfillment-method-modal');

                await this.catchOrder({
                    orderId: this.$route.params.orderId,
                    venueId: this.venueId,
                    isPaymentRedirect: true
                });

                this.globalLoading = false;
            }
        },
        disableAllFields(value) {
            this.disableAll = value;
        },
        async calculatePiggyCredits(unitValue) {
            if (!this.piggySettings?.enabled) {
                return;
            }

            const params = {
                email: this.email,
                piggyShopId: this.piggySettings.piggyShopId,
                unitValue,
                accountId: this.accountId,
                unitName: this.piggySettings.creditUnitName || '',
                venueId: this.venueId
            };

            await this.calculateEstimatedCredits(params);

            this.isCalculate = false;
        },
        reloadPage() {
            window.location.reload();
        },
        toggleCounting() {
            this.isCounting = !this.isCounting;
        },
        toggleOrderSummary() {
            this.showOrderSummary = !this.showOrderSummary;
        },
        handleConfirmLogout() {
            this.setAuth({ accountId: this.accountId, value: false });
            this.clearRewards();
            this.cartTotalWithDiscount();
        }
    },
    computed: {
        ...mapGetters('cart', ['cartSubtotalWithoutAlcohol', 'availableTips']),
        ...mapState({
            cartItems: state => state.cart.items,
            fulfillmentMethod: state => state.cart.fulfillmentMethod,
            venueName: state => state.venue.venue.name,
            currencyCode: state => state.venue.venue.currencyCode,
            venueId: state => state.venue.venue.id,
            availablePreorderDays: state =>
                state.venue.venue.availablePreorderDays,
            minDeliveryAmount: state => state.venue.venue.minDeliveryAmount,
            minPickupAmount: state => state.venue.venue.minPickupAmount,
            maxDeliveryAmount: state => state.venue.venue.maxDeliveryAmount,
            maxPickupAmount: state => state.venue.venue.maxPickupAmount,
            paymentsEnabled: state => state.venue.venue.paymentsEnabled,
            serviceCharge: state => state.venue.venue.serviceCharge,
            acceptsPreOrders: state => state.venue.venue.acceptsPreOrders,
            colour: state => state.venue.venue.primaryColour,
            enableOrderNotes: state => state.venue.venue.enableOrderNotes,
            activePaymentSystem: state => state.venue.venue.activePaymentSystem,
            // Customer
            firstName: state => state.customer.firstName,
            lastName: state => state.customer.lastName,
            email: state => state.customer.email,
            phone: state => state.customer.phone,
            companyName: state => state.customer.companyName,
            notes: state => state.customer.notes,
            transactionId: state => state.customer.como.transactionId,
            openTime: state => state.customer.como.openTime,
            comoDiscountSum: state => state.customer.como.discountSum,
            deals: state => state.customer.como.deals,
            redeemAssets: state => state.customer.como.redeemAssets,
            brandedPayment: state => state.cart.brandedPayment,
            billId: state => state.bill.id,
            defaultServiceChargeEnabled: state =>
                state.venue.venue.defaultServiceChargeEnabled,
            piggySettings: state => state.venue.venue.piggySettings,
            piggyCheckbox: state => state.customer.piggy.loyaltyCheckboxState,
            piggyProductDiscount: state => state.cart.piggyProductDiscount,
            totalPiggyDiscount: state => state.cart.totalPiggyDiscount,
            rewardSelected: state => state.cart.piggyRewardSelected,
            selectedTableId: state => state.cart.selectedTableId,
            identifierType: state => state.customer.identifierType
        }),
        ...mapGetters({
            auth: 'customer/auth',
            cartSubtotal: 'cart/cartSubtotal',
            cartTotal: 'cart/cartTotal',
            totalWithDiscount: 'cart/cartTotalWithDiscount',
            deliveryPostCode: 'cart/deliveryPostCode',
            findTableById: 'venue/findTableById',
            policyAgreed: 'customer/policyAgreed',
            venueValue: 'venue/venueValue',
            discountCodeDetails: 'discount/details',
            deliveryAddress: 'deliveryAddress/address',
            prepTime: 'venue/prepTime',
            userInformationSettings: 'venue/userInformationSettings',
            askAboutCutlery: 'venue/cutlerySetting',
            pickupDate: 'cart/getPickupDate',
            pickupTimeSlot: 'cart/getPickupTimeSlot',
            preOrderDateTime: 'cart/getPreOrderDateTimeSlot'
        }),
        ...mapGetters('venue', ['findTableById']),
        fulfillmentMethodLabel() {
            return {
                [FulfillmentMethodsEnum.IN_STORE]: this.$t(
                    'fulfillmentMethods.InStore'
                ),
                [FulfillmentMethodsEnum.DELIVERY]: this.$t(
                    'fulfillmentMethods.Delivery'
                ),
                [FulfillmentMethodsEnum.PICKUP]: this.$t(
                    'fulfillmentMethods.Pickup'
                )
            }[this.$store.state.cart.fulfillmentMethod];
        },
        selectedMethodPreorderDays() {
            if (
                FulfillmentMethodsEnum.isDeliveryMethod(this.fulfillmentMethod)
            ) {
                return this.availablePreorderDays.deliveryDates;
            }

            return this.availablePreorderDays.pickupDates;
        },
        customer() {
            const customer = {
                notes: this.notes,
                policyAgreed: this.policyAgreed(),
                piggy: {
                    credits: 0,
                    checkbox: this.piggyCheckbox,
                    piggyProductDiscount: this.totalPiggyDiscount,
                    rewardSelected: this.rewardSelected
                }
            };

            if (this.userInformationSettings?.firstName) {
                customer.firstName = this.firstName;
            }

            if (this.userInformationSettings?.lastName) {
                customer.lastName = this.lastName;
            }

            if (
                // @TODO remove phone number fallback
                this.userInformationSettings?.phone === true ||
                this.userInformationSettings?.phone === 'enabled' ||
                this.userInformationSettings.phone === 'validated'
            ) {
                customer.phone = this.phone + '';
            }

            if (this.userInformationSettings?.email !== 'disabled') {
                customer.email = this.email;
            }

            if (this.userInformationSettings?.companyName) {
                customer.companyName = this.companyName;
            }

            if (
                this.comoDiscountSum > 0 ||
                this.$store.state.venue?.venue?.comoEnabled
            ) {
                customer.como = {
                    isAuth: this.customerAuth,
                    transactionId: this.transactionId,
                    openTime: this.openTime,
                    discountSum: this.comoDiscountSum,
                    deals: this.deals,
                    redeemAssets: this.redeemAssets ? this.redeemAssets : [],
                    identifierType: this.identifierType
                };
            }

            return customer;
        },
        pivotY() {
            return isMobile() ? 1 : 0.5;
        },
        isPreOrder() {
            return (
                this.acceptsPreOrders &&
                this.$store.state.venue.venue.pickupSlots.length > 0
            );
        },
        checkoutObject() {
            const payload = {
                customer: this.customer,
                savedCheckout: this.savedCheckout,
                deliveryAddress: this.deliveryAddress,
                fulfillmentMethod: this.fulfillmentMethod,
                venueId: this.venueId,
                total: this.cartTotal,
                preOrderDateTime: this.preOrderDateTime || null
            };

            return payload;
        },
        isPickup() {
            return FulfillmentMethodsEnum.isPickupMethod(
                this.fulfillmentMethod
            );
        },
        isInStore() {
            return this.fulfillmentMethod === FulfillmentMethodsEnum.IN_STORE;
        },
        isDelivery() {
            return FulfillmentMethodsEnum.isDeliveryMethod(
                this.fulfillmentMethod
            );
        },
        hasTooLowAmount() {
            return (
                (this.fulfillmentMethod === FulfillmentMethodsEnum.DELIVERY &&
                    this.minDeliveryAmount > this.cartSubtotal) ||
                (this.fulfillmentMethod === FulfillmentMethodsEnum.PICKUP &&
                    this.minPickupAmount > this.cartSubtotal)
            );
        },
        hasTooHighAmount() {
            return (
                (this.fulfillmentMethod === FulfillmentMethodsEnum.DELIVERY &&
                    this.maxDeliveryAmount &&
                    this.maxDeliveryAmount < this.cartSubtotal) ||
                (this.fulfillmentMethod === FulfillmentMethodsEnum.PICKUP &&
                    this.maxPickupAmount &&
                    this.maxPickupAmount < this.cartSubtotal)
            );
        },
        table() {
            return this.findTableById(this.selectedTableId);
        },
        policyAgreedValue() {
            return this.policyAgreed(this.venueId);
        },
        accountId() {
            return this.venueValue('accountId');
        },
        optInMessage() {
            return this.venueValue('optInMessage');
        },
        policyAgreedInLocalStorage() {
            if (!isLocalStorageAvailable()) {
                return false;
            }

            return (
                localStorage.getItem(`sk_optin_${this.accountId}`) &&
                localStorage.getItem(`sk_optin_${this.accountId}`) === 'true'
            );
        },
        showTipSelection() {
            return (
                this.availableTips &&
                !this.billId &&
                (!this.defaultServiceChargeEnabled || this.adyenRedirectResult)
            );
        },
        isFreeDelivery() {
            return (
                this.discountCodeDetails?.type ===
                DiscountTypesEnum.FREE_DELIVERY
            );
        },
        hasAutocomplete() {
            return this.$growthbook.isOn('address_autocomplete');
        },
        isPoNumberEnabled() {
            return this.$growthbook.isOn('po_number');
        },
        hasField() {
            return fieldName => {
                return this.requiredFields.includes(fieldName);
            };
        },
        requiredFields() {
            return (
                addressRequirements.countries[this.deliveryAddress.country]
                    ?.fields || addressRequirements.default.fields
            );
        },
        showEstimatedCredits() {
            return !!(
                this.piggySettings?.enabled &&
                !this.piggySettings.loyaltyOptIn.enabledLoyalty
            );
        },
        oderNotesVisibility() {
            return this.enableOrderNotes !== 'hidden';
        },
        isMobile() {
            return isMobile();
        },
        hasGuestCountInput() {
            return this.$growthbook.isOn('guest_count');
        },
        hasAddToBillOrder() {
            return this.$growthbook.isOn('add_to_bill_order');
        },
        customerAuth() {
            return this.auth;
        }
    }
};
</script>

<style src="@/assets/css/checkout.css"></style>
<style src="@/assets/css/checkout_scoped.css" scoped></style>
