<template>
    <modal
        name="language-switcher-modal"
        height="auto"
        width="575"
        :maxWidth="575"
        :adaptive="true"
        :scrollable="true"
        :clickToClose="true"
        :reset="true"
        transition="pop-out"
        :class="{ 'mobile-item-modal': pivotY === 1 }"
        :pivotY="pivotY"
    >
        <div class="item-modal-content relative flex w-full flex-col">
            <div class="bg-secondary flex-auto overflow-y-auto">
                <div
                    class="flex items-center justify-between border border-b pl-4 py-2"
                >
                    <h2
                        class="text-base flex items-center font-heading text-heading font-bold"
                        >{{ $t('language.title') || 'Choose your language' }}
                        <language-icon class="ml-1" />
                    </h2>

                    <button
                        @click="closeModal"
                        class="text-primary bg-secondary rounded-full p-1.5"
                    >
                        <close-icon class="h-5 w-5" />
                    </button>
                </div>
                <div class="px-1 pb-4 pt-2">
                    <ul>
                        <li
                            @click="save(language.code)"
                            v-for="language in languages"
                            :key="language.code"
                            :class="{
                                'bg-primary':
                                    language.code.startsWith(selectedLang)
                            }"
                            class="flex items-center cursor-pointer text-primary rounded-md border-b-1 hover:opacity-80 py-2 last:border-b-0"
                        >
                            <div class="pl-2 text-lg">
                                {{ language.emoji }}
                            </div>
                            <div class="flex-1 ml-1.5 text-sm">
                                {{ language.name }}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import isMobile from '@/helpers/isMobileDevice';
import CloseIcon from '@/components/icons/CloseIcon';
import LanguageIcon from '@/components/icons/LanguageIcon';
import { languages, availableLanguages } from '@/util/languages';
import switchToLangOrCloserLang from '@/services/switchToLangOrCloserLang';

export default {
    components: { CloseIcon, LanguageIcon },
    name: 'language-switcher-modal',
    data() {
        return {
            languages: [],
            selectedLanguage: null
        };
    },
    created() {
        const uniqueResults = new Set();

        for (const code of availableLanguages) {
            const foundItemFullCode = languages.find(
                item => item.code === code
            );

            if (foundItemFullCode) {
                uniqueResults.add(foundItemFullCode);
            } else {
                const foundItemPartialCode = languages.filter(item =>
                    item.code.startsWith(code.slice(0, 2))
                );

                foundItemPartialCode.forEach(item => uniqueResults.add(item));
            }
        }

        this.languages = Array.from(uniqueResults);
    },
    computed: {
        ...mapGetters({
            selectedLang: 'customer/lang'
        }),
        pivotY() {
            return isMobile() ? 1 : 0.5;
        }
    },
    methods: {
        closeModal() {
            this.$modal.hide('language-switcher-modal');
        },
        save(code) {
            switchToLangOrCloserLang.handle(code);

            this.closeModal();
        }
    }
};
</script>
