<template>
    <div>
        <div
            v-if="!$store.state.venue.venue.acceptsInStore"
            class="venue-contact mt-2"
        >
            <div class="flex items-center mt-1 sm:mt-2" v-if="phoneNumber">
                <svg
                    class="inline-block shrink-0 mr-1 w-4 h-4 text-muted"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                >
                    <path
                        d="M13.04 14.69l1.07-2.14a1 1 0 0 1 1.2-.5l6 2A1 1 0 0 1 22 15v5a2 2 0 0 1-2 2h-2A16 16 0 0 1 2 6V4c0-1.1.9-2 2-2h5a1 1 0 0 1 .95.68l2 6a1 1 0 0 1-.5 1.21L9.3 10.96a10.05 10.05 0 0 0 3.73 3.73zM8.28 4H4v2a14 14 0 0 0 14 14h2v-4.28l-4.5-1.5-1.12 2.26a1 1 0 0 1-1.3.46 12.04 12.04 0 0 1-6.02-6.01 1 1 0 0 1 .46-1.3l2.26-1.14L8.28 4z"
                    />
                </svg>
                <a :href="'tel:' + phoneNumber" rel="noreferrer noopener">
                    <span class="inline-block ml-1 text-muted text-sm">
                        {{ phoneNumber }}
                    </span>
                </a>
            </div>
            <div class="flex items-center mt-1 sm:mt-2" v-if="url">
                <svg
                    class="inline-block shrink-0 mr-1 w-4 h-4 text-muted"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                >
                    <path
                        d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                    ></path>
                </svg>
                <a
                    :href="clickableVenueUrl"
                    rel="noreferrer noopener"
                    target="_blank"
                    class="venue-url"
                >
                    <span class="inline-block ml-1 text-muted text-sm">
                        {{ clickableVenueUrl }}
                    </span>
                </a>
            </div>
            <div
                class="flex items-center mt-1 sm:mt-2"
                v-if="instagramUsername"
            >
                <instagram-icon />

                <a
                    :href="'https://instagram.com/' + instagramUsername"
                    rel="noreferrer noopener"
                    target="_blank"
                >
                    <span class="inline-block ml-1 text-muted text-sm">
                        {{ instagramUsername }}
                    </span>
                </a>
            </div>
            <div
                v-if="address && showAddress"
                class="flex items-center mt-1 sm:mt-2"
            >
                <location-icon />
                <a
                    :href="googleMapsUrl"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <span class="inline-block ml-1 text-muted text-sm">{{
                        formattedAddress
                    }}</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import LocationIcon from './icons/LocationIcon';
import InstagramIcon from './icons/InstagramIcon';

export default {
    name: 'VenueInformation',
    components: { InstagramIcon, LocationIcon },
    computed: {
        ...mapState('venue', {
            name: state => state.venue.name,
            phoneNumber: state => state.venue.phoneNumber,
            address: state => state.venue.address,
            url: state => state.venue.url,
            instagramUsername: state => state.venue.instagramUsername,
            showAddress: state => state.venue.showAddress,
            googlePlaceId: state => state.venue.googlePlaceId
        }),
        clickableVenueUrl() {
            return this.url.startsWith('http://') ||
                this.url.startsWith('https://')
                ? this.url
                : `http://${this.url}`;
        },
        googleMapsUrl() {
            if (this.googlePlaceId) {
                return `https://www.google.com/maps/place/?q=place_id:${this.googlePlaceId}`;
            } else {
                return `https://maps.google.com/?q=${this.name}, ${this.address.buildingNumber} ${this.address.street}, ${this.address.city}, ${this.address.postCode}`;
            }
        },
        formattedAddress() {
            return `${this.address.buildingNumber} ${this.address.street}, ${this.address.city}, ${this.address.postCode}`;
        }
    }
};
</script>
