<template>
    <modal
        name="order-notes-modal"
        height="auto"
        width="575"
        :maxWidth="575"
        :adaptive="true"
        :scrollable="true"
        :clickToClose="true"
        :pivotY="pivotY"
        :reset="true"
        transition="pop-out"
        :class="{ 'mobile-item-modal': pivotY === 1 }"
        @before-open="beforeOpen"
    >
        <div class="item-modal-content relative flex flex-col w-full">
            <div class="item-modal-body">
                <div
                    class="item-modal-details flex-auto p-6 pb-1 overflow-y-auto"
                >
                    <div
                        class="relative block w-full pb-3 shadow-sm sm:hidden"
                    >
                        <h4 class="font-heading text-xl font-bold text-subheading">
                            {{ $t('orderNotes.addOrderNotes') }}
                        </h4>
                        <div class="absolute right-0 top-0 ">
                            <button
                                type="button"
                                aria-label="Hide notes"
                                class="text-primary transition duration-150 ease-in-out hover:text-gray-500 focus:text-gray-500 focus:outline-none"
                                @click="close"
                            >
                                <close-icon />
                            </button>
                        </div>
                    </div>

                    <div class="relative">
                        <textarea
                            v-model.trim="notes"
                            type="text"
                            name="notes"
                            id="notes"
                            class="email-input block pt-7 px-4 w-full text-sm bg-secondary border rounded shadow-sm"
                            style="height: 120px"
                            :class="{
                                'ring ring-red-200 border-red-300':
                                    $v.notes.$error
                            }"
                            required
                        ></textarea>
                        <label
                            for="notes"
                            class="email-input-label absolute pt-2 px-4 rounded pointer-events-none select-none"
                        >
                            {{ $t('orderNotes.orderNotesOrRequests') }}
                        </label>
                    </div>
                    <div class="flex justify-end">
                        <span
                            class="flex flex-auto justify-start my-2 text-red-500 text-xs"
                            v-show="$v.notes.$error"
                        >
                            {{
                                notes.length > 200
                                    ? $t('orderNotes.errors.tooLong')
                                    : 'required'
                            }}
                        </span>
                        <span
                            class="flex justify-end my-2 text-xs"
                            :class="{
                                'text-red-500': $v.notes.$error
                            }"
                        >
                            {{ `${notes.length}/200` }}
                        </span>
                    </div>
                </div>
                <div
                    class="item-modal-footer flex flex-col-reverse justify-center p-6 md:flex-row"
                >
                    <button
                        v-if="!isMobile"
                        @click="close"
                        class="no-highlight hidden flex-auto p-3 px-10 w-full text-center text-secondaryactions text-sm font-bold font-button border border-transparent rounded-sm select-none sm:block md:w-auto"
                    >
                        {{ $t('orderNotes.close') }}
                    </button>
                    <button
                        @click="update"
                        class="border-button-primary no-highlight flex-auto px-12 py-4 w-full text-center text-button text-sm font-bold bg-button font-button rounded-sm shadow select-none sm:mb-3 md:mb-0 md:ml-6 md:w-auto"
                    >
                        {{ $t('orderNotes.save') }}
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import isMobile from "@/helpers/isMobileDevice";
import { maxLength, requiredIf } from 'vuelidate/lib/validators';
import CloseIcon from "@/components/icons/CloseIcon";

export default {
    name: 'order-notes-modal',
    components: {CloseIcon},
    data() {
        return {
            notes: ''
        };
    },
    validations: {
        notes: {
            maxLength: maxLength(200),
            notes: {
                required: requiredIf(function () {
                    return (
                        this.$store.state.venue.venue.enableOrderNotes ===
                            'mandatory' && this.notes.length === 0
                    );
                })
            }
        }
    },
    computed: {
        pivotY() {
            return isMobile()? 1 : 0.5;
        },
        isMobile() {
            return isMobile();
        }
    },
    methods: {
        update() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }

            this.$store.commit('customer/setNotes', this.notes);

            this.close();
        },
        close() {
            this.$modal.hide('order-notes-modal');
        },
        beforeOpen() {
            this.notes = this.$store.state.customer.notes;

            if (
                this.$store.state.venue.venue.enableOrderNotes === 'mandatory'
            ) {
                this.$v.$touch();
            }
        }
    }
};
</script>
