<template>
    <div
        class="menu-sticky-header z-50 bg-secondary border-t border-gray-200 shadow-md md:border-b md:shadow-none"
        :class="{
            '!top-12': hasNewHeader,
            'bg-secondary border-transparent shadow-none': isBao
        }"
        v-click-outside="onClickOutside"
    >
        <div class="mx-auto w-full md:px-5 lg:px-8">
            <div class="container-lg">
                <div class="flex flex-row">
                    <nav
                        class="venue-category-menu relative flex md:pr-8"
                        :class="[
                            isSearchEnabled
                                ? ''
                                : 'venue-category-menu-no-search',
                            { 'visual-menu': visualMenu }
                        ]"
                    >
                        <div
                            class="relative flex items-center w-full"
                            style="flex: 0 1 100%"
                        >
                            <scrollactive
                                active-class="sticky-item-active"
                                :modifyUrl="false"
                                :offset="computedOffset"
                                v-if="categories && categories.length"
                                v-on:itemchanged="onItemChanged"
                                class="venue-category-menu__list no-scrollbar flex-nowrap select-none pl-2 text-accent sm:pl-0 md:flex"
                                :class="{
                                    'thin-scrollbar':
                                        $store.state.venue?.venue?.id === 9250
                                }"
                            >
                                <a
                                    :href="'#category-' + category.id"
                                    :id="'#menu-category-' + category.id"
                                    class="scrollactive-item font-button inline-block my-3 px-3 text-subheading text-sm font-medium rounded-full transition duration-100 sm:ml-2 sm:ml-3 md:my-3 md:px-3"
                                    :class="{
                                        '!my-4 !px-4 text-heading font-body !text-base':
                                            isBao,
                                        'min-w-36': hasCategoryHeaderImages
                                    }"
                                    v-for="(category, index) in navCategories"
                                    :key="index"
                                    @click="moveSliderElement($event.target)"
                                    :aria-label="
                                        'Navigate to ' +
                                        category.name +
                                        ' category'
                                    "
                                    role="menuitem"
                                    tabindex="0"
                                >
                                    <div
                                        v-if="hasCategoryHeaderImages"
                                        class="flex flex-col items-center"
                                    >
                                        <img
                                            v-if="category.navImage"
                                            class="w-full mb-2 h-20 object-contain"
                                            :src="
                                                category.navImage +
                                                '/-/format/auto/'
                                            "
                                            :alt="category.name"
                                        />

                                        <!-- @TODO replace this div with an img element -->
                                        <div
                                            v-else
                                            class="crossed mb-2 w-full h-20 rounded border-2 border-gray-500"
                                        >
                                        </div>
                                        <span>{{ category.name }}</span>
                                    </div>
                                    {{
                                        !hasCategoryHeaderImages
                                            ? category.name
                                            : ''
                                    }}
                                </a>
                                <div
                                    class="menu-slider-element"
                                    v-show="isSliderElementVisible"
                                    :style="sliderElementProperties"
                                ></div>
                            </scrollactive>
                            <div
                                v-else
                                class="venue-category-menu__list no-scrollbar flex-nowrap text-blue-900 md:flex"
                            >
                                <span
                                    class="inline-block ml-3 my-2.5 px-2 w-28 rounded-full md:my-3 md:pt-1 md:px-3"
                                    v-for="(n, index) in 5"
                                    :key="index"
                                    :id="'loading-cat-' + n"
                                >
                                    <vue-content-loading
                                        :width="100"
                                        :height="17"
                                        :speed="2"
                                        primaryColor="#f3f3f3"
                                        secondaryColor="#ecebeb"
                                    >
                                        <rect
                                            x="0"
                                            y="3"
                                            rx="0"
                                            ry="0"
                                            width="93"
                                            height="10"
                                        />
                                    </vue-content-loading>
                                </span>
                            </div>
                            <span v-if="!isLoading && isSearchEnabled">
                                <div
                                    class="searchInput relative flex grow items-stretch cursor-pointer"
                                    :style="
                                        isSearchExpanded
                                            ? 'width: 100%;'
                                            : 'width: 50px'
                                    "
                                    @click="focusOnSearchInput"
                                >
                                    <div
                                        class="absolute inset-y-0 left-0 flex items-center"
                                    >
                                        <svg
                                            class="z-100 w-6 h-6 text-primary"
                                            :class="
                                                isSearchExpanded
                                                    ? 'mx-2'
                                                    : 'ml-4'
                                            "
                                            :style="[
                                                isBao
                                                    ? 'margin-left: 0.4rem'
                                                    : ''
                                            ]"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                    </div>
                                    <div
                                        class="absolute inset-y-0 right-0 flex items-center pl-3"
                                    >
                                        <button
                                            v-show="isSearchExpanded"
                                            @click.stop="cancelSearch"
                                            class="relative inline-flex items-center -ml-px px-4 py-2 text-gray-700 text-itembody text-sm font-medium hover:bg-gray-100 hover:bg-secondary rounded-sm focus:outline-none space-x-2"
                                            aria-label="Cancel search"
                                            tabindex="0"
                                        >
                                            <span>
                                                {{
                                                    $t(
                                                        'menuStickyHeader.cancel'
                                                    )
                                                }}
                                            </span>
                                        </button>
                                    </div>
                                    <input
                                        class="font-heading pl-10 w-full text-heading text-sm leading-5 bg-secondary border-none focus:outline-none"
                                        type="text"
                                        name="search"
                                        v-model="search"
                                        id="searchInput"
                                        ref="searchInput"
                                        autocomplete="off"
                                        :aria-label="
                                            $t('menuStickyHeader.searchTheMenu')
                                        "
                                        :placeholder="
                                            isSearchExpanded
                                                ? $t(
                                                      'menuStickyHeader.searchTheMenu'
                                                  )
                                                : ''
                                        "
                                    />
                                </div>
                            </span>
                        </div>
                    </nav>
                    <div class="basket-wrapper relative flex">
                        <basket :showBasket="showBasket" />
                    </div>
                </div>
                <DietaryFilters
                    v-if="hasDietaryFilters"
                    :showIcons="true"
                    :iconStyle="
                        $store.state.venue.venue.branding?.dietIconStyle
                    "
                    v-show="isSearchExpanded"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Basket from './Basket.vue';
import easyScroll from 'easy-scroll';
import { mapActions, mapGetters, mapState } from 'vuex';
import isMobile from '@/helpers/isMobileDevice';
import VueContentLoading from 'vue-content-loading';
import vClickOutside from 'v-click-outside';
import DietaryFilters from '@/components/DietaryFilters';
import { FulfillmentMethodsEnum, CategoryNavImagesEnum } from '@/enums';

export default {
    name: 'MenuStickyHeader',
    components: {
        DietaryFilters,
        Basket,
        VueContentLoading
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    data() {
        return {
            sliderElementProperties: {
                left: 0,
                width: 0,
                height: 0,
                top: 0
            },
            isSliderElementVisible: false,
            search: '',
            isSearchExpandedKey: 0
        };
    },
    props: {
        showBasket: {
            type: Boolean,
            required: false,
            default: false
        },
        hasNewHeader: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    created() {
        if (this.isSearchEnabled) {
            this.search = this.$route.query.search || '';
        }
    },
    mounted() {
        if (
            this.$store.state.venue?.venue?.id === 8376 ||
            this.$store.state.venue?.venue?.id === 9708 ||
            this.$store.state.venue?.venue?.id === 9710 ||
            this.$store.state.venue?.venue?.id === 9714 ||
            this.$store.state.venue?.venue?.id === 9711 ||
            this.$store.state.venue?.venue?.id === 9713
        ) {
            setTimeout(() => {
                const firstCategoryElement =
                    this.$el.querySelector('.scrollactive-item');
                if (firstCategoryElement) {
                    this.moveSliderElement(firstCategoryElement);
                    firstCategoryElement.classList.add('sticky-item-active');
                }
            }, 600);
        }
    },
    methods: {
        ...mapActions({
            setActiveFilterTags: 'helpers/setActiveFilterTags'
        }),
        moveSliderElement(event) {
            if (!event) {
                return;
            }

            let targetElement = event;

            if (this.hasCategoryHeaderImages) {
                targetElement = targetElement
                    .closest('a')
                    .querySelector('span');
            }

            this.isSliderElementVisible = true;

            this.sliderElementProperties.width =
                targetElement.offsetWidth + 5 + 'px';
            this.sliderElementProperties.left =
                targetElement.offsetLeft - 2.5 + 'px';
            this.sliderElementProperties.top =
                targetElement.offsetTop - 2.5 + 'px';
            this.sliderElementProperties.height =
                targetElement.offsetHeight + 5 + 'px';
        },
        onItemChanged(event, currentItem, lastActiveItem) {
            if (!currentItem || !currentItem.parentNode) {
                this.isSliderElementVisible = false;

                return;
            }

            if (event) {
                this.moveSliderElement(currentItem);
            }

            const currentItemPos =
                currentItem.getBoundingClientRect().left + window.scrollX;

            let lastItemPos = 0;
            if (lastActiveItem) {
                lastItemPos =
                    lastActiveItem.getBoundingClientRect().left +
                    window.scrollX;
            }

            easyScroll({
                scrollableDomEle: currentItem.parentNode,
                direction: currentItemPos > lastItemPos ? 'right' : 'left',
                duration: 300,
                easingPreset: 'easeOutQuad',
                scrollAmount:
                    currentItemPos > lastItemPos
                        ? currentItemPos - this.offset
                        : this.offset - currentItemPos
            });
        },
        focusOnSearchInput() {
            this.$refs.searchInput.focus();
            this.isSearchExpandedKey++;
            this.searchListener();
        },
        onClickOutside() {
            if (!this.$refs.searchInput) {
                return;
            }

            this.$refs.searchInput.blur();

            if (!this.searchExpandedLock) {
                this.isSearchExpandedKey++;
            }

            if (!this.isSearchExpanded) {
                this.setActiveFilterTags(null);
            }
        },
        cancelSearch() {
            this.search = '';
            this.$refs.searchInput.blur();
            this.isSearchExpandedKey++;
            this.setActiveFilterTags(null);
        },
        scrollToElement(className) {
            const element = document.getElementsByClassName(className);

            if (element && element[0]) {
                setTimeout(() => {
                    easyScroll({
                        scrollableDomEle: window,
                        direction: 'top',
                        duration: 300,
                        easingPreset: 'easeOutQuad',
                        scrollAmount: Math.abs(
                            window.scrollY - element[0].offsetTop - 25
                        )
                    });
                }, 100);
            }
        },
        searchListener() {
            if (this.isSearchEnabled) {
                const searchInput = document.getElementById('searchInput');

                if (searchInput) {
                    const search = ({ key }) => {
                        if (key === 'Enter') {
                            this.$refs.searchInput.blur();
                            searchInput.removeEventListener('keydown', search);
                        }
                    };

                    searchInput.addEventListener('keydown', search);
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            activeFilterTags: 'helpers/activeFilterTags',
            searchExpandedLock: 'helpers/searchExpandedLock'
        }),
        ...mapState('venue', {
            categories: state => state.categories,
            visualMenu: state => state.venue.visualMenu,
            isLoading: state => state.isLoading
        }),
        ...mapState('cart', {
            fulfillmentMethod: state => state.fulfillmentMethod
        }),
        isMobile() {
            return isMobile();
        },
        offset() {
            const windowWidth = window.innerWidth;
            return isMobile() ? 12 : windowWidth * 0.2;
        },
        isSearchExpanded() {
            this.isSearchExpandedKey;
            const isFocused = document.activeElement.id === 'searchInput';

            return (
                this.search || isFocused || this.activeFilterTags?.length > 0
            );
        },
        isSearchEnabled() {
            let productsLength = 0;

            this.categories.forEach(category => {
                productsLength += category.products.length;
            });

            return productsLength > 20;
        },
        hasDietaryFilters() {
            return (
                this.$growthbook.isOn('dietary_filters') ||
                this.$store.state.venue?.venue?.id === 9469 ||
                FulfillmentMethodsEnum.isCateringMethod(this.fulfillmentMethod)
            );
        },
        navCategories() {
            return this.categories.filter(category => !category.hiddenInMenu);
        },
        isBao() {
            return (
                this.$store.state.venue?.venue?.accountId ===
                '5f4e9ddc-3431-43f5-9de0-7aa356564afc'
            );
        },
        categoryNavImagesSetting() {
            return this.$store.state.venue?.venue?.categoryNavImages;
        },
        hasCategoryHeaderImages() {
            if (!this.categoryNavImagesSetting) {
                return false;
            }
            return (
                this.categoryNavImagesSetting ===
                    CategoryNavImagesEnum.DESKTOP_MOBILE ||
                (this.categoryNavImagesSetting ===
                    CategoryNavImagesEnum.DESKTOP &&
                    !this.isMobile)
            );
        },
        computedOffset() {
            const baseOffset = 80;
            const imageOffset = 160;
            return this.hasCategoryHeaderImages ? imageOffset : baseOffset;
        }
    },

    watch: {
        search(value) {
            this.$emit('search', value);
            this.scrollToElement('menu-page__menu-category');
        }
    }
};
</script>

<style src="@/assets/css/menu-sticky-header_scoped.css" scoped></style>
