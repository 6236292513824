import Vue from 'vue';
import VueI18n from 'vue-i18n';
import LANG_EN_GB from '@/lang/en-GB';

Vue.use(VueI18n);

const DEFAULT_LANG = 'en-GB';

export const i18n = new VueI18n({
    locale: DEFAULT_LANG,
    fallbackLocale: DEFAULT_LANG,
    messages: {
        [DEFAULT_LANG]: LANG_EN_GB
    },
    silentFallbackWarn: true
});
