<template>
    <div
        class="question-content custom-radio-box-container -mx-2 w-full"
        :class="containerLayout"
    >
        <label
            :for="generateInputId(option, i)"
            class="custom-radio-box relative block mb-1 w-full rounded cursor-pointer select-none"
            :class="{
                'px-2 pr-0 bg-transparent': !hasCardStyleModifier,
                checked: isSelected(option),
                'w-full': fullWidth && !hasCardStyleModifier,
                'w-1/2': !fullWidth && !hasCardStyleModifier,
                'rounded-xl ring-2 ring-gray-700 bg-modifier-selectedcard shadow-modifier':
                    isSelected(option) && hasCardStyleModifier,
                'rounded-xl ring-1 ring-gray-200 bg-modifier-card shadow-modifier':
                    !isSelected(option) && hasCardStyleModifier
            }"
            v-for="(option, i) in options"
            :key="`radio-${i}`"
            :aria-label="option[labelField]"
            tabindex="0"
            role="radio"
            :aria-checked="isSelected(option).toString()"
        >
            <input
                v-if="!visualMenu"
                type="radio"
                :id="generateInputId(option, i)"
                :name="generateInputId(option, i)"
                :value="option[`${valueField}`].toString()"
                class="custom-control-input form-check-input absolute opacity-0"
                @change="onChange($event.target.value)"
                :checked="isSelected(option)"
                :disabled="isUnavailable(option)"
                :aria-label="option[labelField]"
                :aria-disabled="isUnavailable(option).toString()"
            />
            <div
                v-if="hasCardStyleModifier"
                class="p-3 pb-6 relative flex gap-2 items-center h-full justify-center flex-col"
            >
                <div>
                    <header class="line-clamp-2 text-xs sm:text-sm text-center">
                        {{ option[labelField] }}
                    </header>
                </div>
                <img
                    v-if="option.modifierImage"
                    :src="getOptimizedImageSrc(option.modifierImage)"
                    :srcset="getOptimizedImageSrcset(option.modifierImage)"
                    :alt="option[labelField]"
                    loading="lazy"
                    decoding="async"
                    class="size-full object-contain transition-all duration-150 ease-in-out"
                />
                <p
                    v-show="option.price"
                    class="absolute inset-x-0 pb-1 text-center bottom-0 text-price text-xs"
                >
                    <span v-if="option.price > 0">{{ pricePrefix }}</span>
                    {{ (option.price / 100) | customCurrency }}
                    <s v-if="option.compareAtPrice" class="ml-1 text-gray-400">
                        {{ pricePrefix
                        }}{{ (option.compareAtPrice / 100) | customCurrency }}
                    </s>
                </p>
            </div>
            <div
                v-else
                class="flex p-2 px-0 items-center"
                :class="{ 'cursor-default': visualMenu }"
            >
                <span
                    v-if="!visualMenu && !option.modifierImage"
                    class="custom-radio-indicator"
                />

                <modifier-image
                    v-if="option.modifierImage"
                    :image="option.modifierImage"
                    :alt="option[labelField]"
                    :is-selected="isSelected(option)"
                />
                <div class="custom-radio-box-align">
                    <div class="pl-2 text-itembody text-sm">
                        {{ option[labelField] }}
                        <div class="flex">
                            <span
                                v-if="hasCalories(option)"
                                class="block whitespace-pre-line text-sm text-muted mr-1"
                            >
                                {{ option.nutrition.calories }} kcal
                            </span>
                            <span
                                v-if="
                                    !isUnavailable(option) &&
                                    hasModifierAllergenDisplay &&
                                    hasAllergens(option)
                                "
                                class="block whitespace-pre-line text-sm text-muted mr-1"
                            >
                                {{ getAllergenText(option) }}
                            </span>
                            <span class="text-red-600">
                                {{
                                    isUnavailable(option)
                                        ? $t('radioButton.currentlyUnavailable')
                                        : ''
                                }}
                            </span>
                            <span
                                v-if="showPopularBadge(option)"
                                class="flex items-center text-orange-400 text-xs"
                            >
                                <star-icon class="mr-0.5 w-4 h-4" />
                                {{ $t('menuItem.popular') }}
                            </span>
                        </div>
                    </div>

                    <span v-if="option.compareAtPrice">
                        <p
                            v-show="option.price"
                            class="shrink-0 pl-2 whitespace-nowrap text-right text-price text-sm"
                        >
                            {{ pricePrefix
                            }}{{ (option.price / 100) | customCurrency }}

                            <s class="ml-1 text-gray-400">
                                {{ pricePrefix
                                }}{{
                                    (option.compareAtPrice / 100)
                                        | customCurrency
                                }}
                            </s>
                        </p>
                    </span>
                    <span v-else>
                        <p
                            v-show="option.price"
                            class="shrink-0 pl-2 whitespace-nowrap text-right text-price text-sm"
                        >
                            <span v-if="option.price > 0">{{
                                pricePrefix
                            }}</span
                            >{{ (option.price / 100) | customCurrency }}
                        </p>
                    </span>
                </div>
            </div>
        </label>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import isMobile from '@/helpers/isMobileDevice';
import StarIcon from '@/components/icons/StarIcon';
import ModifierImage from '@/components/ModifierImage';
import { processItemAllergens } from '@/helpers/allergens';

export default {
    name: 'RadioButton',
    props: {
        options: Array,
        value: String,
        keyId: {
            type: String,
            required: false,
            default: 'keyId'
        },
        labelField: {
            type: String,
            required: false,
            default: 'label'
        },
        valueField: {
            type: String,
            required: false,
            default: 'value'
        },
        fullWidth: {
            type: Boolean,
            required: false,
            default: false
        },
        itemPrice: {
            type: Number,
            required: false
        }
    },
    components: {
        ModifierImage,
        StarIcon
    },
    methods: {
        onChange(value) {
            this.$emit('input', value);
        },
        isSelected(option) {
            return option[this.valueField].toString() === this.value;
        },
        generateInputId(option, index) {
            return `${option.id}-${index}-${this.keyId}`;
        },
        hasAllergens(option) {
            const allergenInfo = processItemAllergens(
                option,
                [],
                this.$t('allergens')
            );
            return (
                allergenInfo.allergens.length > 0 ||
                allergenInfo.possibleAllergens.length > 0
            );
        },
        getAllergenText(option) {
            const { allergens, possibleAllergens } = processItemAllergens(
                option,
                [],
                this.$t('allergens')
            );

            const allergenText = [];

            if (allergens.length > 0) {
                allergenText.push(`Contains: ${allergens.join(', ')}`);
            }

            if (possibleAllergens.length > 0) {
                allergenText.push(
                    `May contain: ${possibleAllergens.join(', ')}`
                );
            }

            return allergenText.join(' • ');
        },
        getOptimizedImageSrc(imageUrl) {
            return `${imageUrl}/-/format/auto/`;
        },
        getOptimizedImageSrcset(imageUrl) {
            return `${imageUrl}/-/format/auto/-/resize/128x/ 1x, ${imageUrl}/-/format/auto/-/resize/192x/ 2x, ${imageUrl}/-/format/auto/-/resize/256x/ 3x`;
        }
    },
    computed: {
        ...mapState({
            visualMenu: state => state.venue.venue.visualMenu
        }),
        isUnavailable() {
            return option => option.snooze || option.isAvailable === false;
        },
        hasCalories() {
            return option => option.nutrition && option.nutrition.calories;
        },
        showPopularBadge() {
            return option => option.popular && option.isAvailable;
        },
        isMobile() {
            return isMobile();
        },
        containerLayout() {
            if (this.hasCardStyleModifier) {
                return `grid gap-3 auto-rows-fr grid-cols-3 sm:grid-cols-4`;
            }

            const baseFlexClasses =
                'space-between flex flex-nowrap items-start';

            if (this.fullWidth)
                return { [`${baseFlexClasses} flex-col`]: true };

            return {
                [`${baseFlexClasses} flex-col`]: this.options.length > 2,
                [`${baseFlexClasses} flex-row`]: this.options.length < 3
            };
        },
        pricePrefix() {
            return this.itemPrice === 0 ? '' : '+';
        },
        hasModifierAllergenDisplay() {
            return this.$growthbook.isOn('modifier_allergens');
        },
        hasCardStyleModifier() {
            return this.$growthbook.isOn('card_style_modifier');
        }
    }
};
</script>

<style src="@/assets/css/radio-button.css"></style>
