<template>
    <div class="relative">
        <div
            :class="{
                'ring-2 ring-gray-700 ring-offset-2 ': isSelected,
                'ring-1 ring-gray-200': !isSelected
            }"
            class="w-16 h-16 flex-shrink-0 overflow-hidden bg-white rounded"
        >
            <img
                :src="`${image}/-/format/auto/`"
                :srcset="`${image}/-/format/auto/-/resize/64x64/ 1x, ${image}/-/format/auto/-/resize/128x128/ 2x, ${image}/-/format/auto/-/resize/192x192/ 3x`"
                :alt="alt"
                loading="lazy"
                width="56"
                height="56"
                decoding="async"
                class="w-full h-full object-cover transition-all duration-150 ease-in-out"
                :class="{
                    'scale-125': isSelected
                }"
            />
        </div>
        <div class="absolute inset-0 overflow-visible">
            <div
                class="absolute top-0 right-0 -mt-2 -mr-2 rounded-full bg-button text-button p-1"
            >
                <svg
                    v-if="!isSelected"
                    class="w-3.5 h-3.5"
                    data-slot="icon"
                    fill="none"
                    stroke-width="1.5"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                    ></path>
                </svg>
                <svg
                    v-else
                    class="w-3.5 h-3.5"
                    data-slot="icon"
                    fill="none"
                    stroke-width="1.5"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m4.5 12.75 6 6 9-13.5"
                    ></path>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModifierImage',
    props: {
        image: {
            type: String,
            required: true
        },
        alt: {
            type: String,
            required: true
        },
        isSelected: {
            type: Boolean,
            default: false
        }
    }
};
</script>
