<template>
    <nav class="z-10 hidden bg-gray-900 shadow md:block" :style="navStyles">
        <div class="container-lg px-2 sm:px-6 lg:px-8">
            <div class="relative flex h-16 items-center justify-between">
                <div
                    class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start"
                >
                    <div v-if="logo" class="z-50 flex-shrink-0">
                        <router-link :to="{ name: 'menu' }">
                            <img
                                :src="logoSrc"
                                :srcset="logoSrcset"
                                :alt="logoAlt"
                                loading="lazy"
                                class="absolute -mt-4 mr-4 h-20 w-20 rounded-full border-white bg-white shadow-md"
                            />
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { mapState } from 'vuex';

const LOGO_SIZES = [
    { width: 80, dpr: '1x' },
    { width: 160, dpr: '2x' },
    { width: 320, dpr: '3x' }
];

export default {
    name: 'PublicNav',
    computed: {
        ...mapState('venue', {
            name: state => state.venue.name,
            logo: state => state.venue.logo,
            primaryColour: state => state.venue.primaryColour
        }),
        navStyles() {
            return {
                backgroundColor: this.primaryColour || '#292a31'
            };
        },
        logoSrc() {
            return `${this.logo}-/resize/80x/-/format/auto/`;
        },
        logoSrcset() {
            return LOGO_SIZES.map(
                ({ width, dpr }) =>
                    `${this.logo}-/resize/${width}x/-/format/auto/ ${dpr}`
            ).join(',');
        },
        logoAlt() {
            return `${this.name} Logo`;
        }
    }
};
</script>
