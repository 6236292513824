<template>
    <fieldset v-if="showPiggyOptIn" class="flex w-full px-1 pb-4 pt-2 text-sm">
        <div class="mt-1 flex pr-2" :class="{ hidden: customerAuth }">
            <input
                v-model="loyaltyCheckboxState"
                class="optin-checkbox form-checkbox h-4 w-4 cursor-pointer rounded border-gray-200 transition duration-150 ease-in-out focus:border-blue-200 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                type="checkbox"
                :checked="piggySettings.loyaltyOptIn.preSelected"
            />
        </div>

        <div class="text-price">
            <legend>
                {{ piggySettings.loyaltyOptIn.label }} <br />
                <piggy-text :is-calculate="isCalculate" />
            </legend>
        </div>
    </fieldset>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import PiggyText from '@/components/elements/PiggyText';

export default {
    name: 'PiggyCheckbox',
    components: { PiggyText },
    props: {
        isCalculate: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        if (this.customerAuth === true) {
            this.loyaltyCheckboxState = true;
            return;
        }
        this.loyaltyCheckboxState =
            this.piggySettings?.loyaltyOptIn?.preSelected;
    },
    computed: {
        ...mapState({
            piggySettings: state => state.venue.venue.piggySettings
        }),
        ...mapGetters({
            auth: 'customer/auth'
        }),
        customerAuth() {
            return this.auth;
        },
        showPiggyOptIn() {
            return !!(
                this.piggySettings &&
                this.piggySettings?.enabled &&
                this.piggySettings?.loyaltyOptIn?.enabledLoyalty
            );
        },
        loyaltyCheckboxState: {
            get() {
                return this.$store.state.customer.piggy.loyaltyCheckboxState;
            },
            set(value) {
                this.$store.commit(
                    'customer/SET_LOYALTY_CHECKBOX_STATE',
                    value
                );
            }
        }
    }
};
</script>

<style scoped>
.optin-checkbox {
    color: #172d48;
    color: var(--color-bg-button, #292a31);
}
</style>
